import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { match, useHistory } from "react-router-dom";
import { SearchStyled } from "./indexl.styled";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "store/app/action";
import CardUseCaseGr1d from "components/portal/card/useCase";
import { useAction } from "store";
import { portalActions } from "store/portal/action";
import { ApiState, CategoryState, UseCaseState } from "store/portal/reducer";
import CardApiGr1d from "components/portal/card/api";
import PaginationGr1d from "components/portal/pagination";
import { UseCaseModel } from "models/useCase";
import NoResults from 'assets/svg/no-results.svg'
import _ from "lodash";
import { Spin } from "antd";

type Props = {
    match: match,
}

const Search: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const actions = useAction(appActions());
    const portalAction = useAction(portalActions());
    const [title, setTitle] = useState<string>();
    const [first, setFirst] = useState<boolean>(true);
    const [typeTitle, setTypeTitle] = useState<string>();
    const [type, setType] = useState<string>();
    const [resultCount, setResultCount] = useState<number>(0);
    const apiState: ApiState = useSelector((state: any) => state.portal?.api);
    const useCaseState: UseCaseState = useSelector((state: any) => state.portal?.useCase);
    const [useCaseFilter, setUseCaseFilter] = useState<UseCaseModel[]>([]);
    const categoryState: CategoryState = useSelector((state: any) => state.portal?.category);
    const [loading, setLoading] = useState(true);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        if (type !== 'all-useCase') {
            setResultCount(apiState.total ?? 0);
            setLoading(apiState.loading)
        } else {
            setResultCount(useCaseState.list?.length ?? 0);
            setLoading(useCaseState.loading)
        }
    }, [type, apiState, useCaseState]);

    useEffect(() => {
        if (useCaseState.list != null) {
            const page = (props.match.params as any).page;
            setUseCaseFilter(_.take(useCaseState.list.slice((page - 1) * 6, ((page - 1) * 6 + 6)), 6));
        }
    }, [useCaseState, props.match.params])

    useEffect(() => {
        const id = (props.match.params as any).id;
        const typeTemp = (props.match.params as any).type;
        const page = (props.match.params as any).page;
        setType(typeTemp);

        if (typeTemp === 'all-apis') {
            setTitle('Todas as APIs');
            setTypeTitle('APIs');
            dispatch(portalAction.getApis('', page, 12));
        } else if (typeTemp === 'all-useCase') {
            if (first) {
                setTitle('Todos os Casos de Uso');
                setTypeTitle('Casos de Uso');
                dispatch(portalAction.getUseCases());
            }
        } else if (typeTemp === 'category') {
            if (categoryState.list != null && categoryState.list.length > 0) {
                const category = categoryState.list.find(x => x.id.toString() === id);
                if (category != null) {
                    setTitle(`Categoria: ${category.name}`);
                    setTypeTitle('APIs');
                    dispatch(portalAction.getApis('', page, 12, id, (history.location.state as any)?.log === true));
                }
            }
        } else if (typeTemp === 'related-apis') {
            if (first) {
                setTypeTitle('APIs');
                dispatch(portalAction.getRelatedApiSearch(parseInt(id), page, setTitle));
            }
        } else {
            setTitle(id);
            setTypeTitle('APIs');
            dispatch(portalAction.getApis(id, page, 12, [], (history.location.state as any)?.log === true));
            history.location.state = null;
        }
        setFirst(false);
    }, [props.match, portalAction, dispatch, first, categoryState]);

    useEffect(() => {
        const type = (props.match.params as any).type;

        if (type === 'text') {
            dispatch(actions.showQuestion(2));
        } else {
            dispatch(actions.showQuestion(1));
        }
        return () => {
            dispatch(actions.showQuestion(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pagination = (page: number) => {
        const id = (props.match.params as any).id;
        const type = (props.match.params as any).type;

        history.push(`/search/${type}/${id}/${page}`);
    }

    return <SearchStyled>
        <div className='title'>
            <TypographyGr1d style={{ marginBottom: 8 }} component='headingLG' color='colorPrimitiveBlack'>
                <>{title}</>
            </TypographyGr1d>
            {((props.match.params as any).type !== 'all-apis' && !loading) && <TypographyGr1d style={{ marginBottom: 48 }} component='subtitleMD' color='colorPrimitive600'>
                {resultCount} {resultCount === 1 ? 'resultado' : 'resultados'}
            </TypographyGr1d>}
            {((props.match.params as any).type !== 'all-apis' && !loading && resultCount > 0) ?
                <TypographyGr1d style={{ marginBottom: 32 }} component='headingMD' color='colorPrimitiveBlack'>
                    {typeTitle}
                </TypographyGr1d>
                : <div style={{ marginBottom: 32 }}></div>}
        </div>
        <Spin spinning={loading} >
            {resultCount === 0 && !loading &&
                <div className="empty">
                    <img alt="no-result" src={NoResults} />
                    <TypographyGr1d fontWeight="500" font="Manrope" fontSize="20px" color="colorPrimitive500">
                        <br />
                        Infelizmente sua pesquisa não retornou resultados...
                    </TypographyGr1d>
                    <TypographyGr1d fontWeight="500" font="Manrope" fontSize="16px" color="colorPrimitive400">
                        Tente usar outros termos ou filtros para encontrar o que está procurando
                    </TypographyGr1d>
                </div>
            }
        </Spin>
        <div style={{ marginBottom: 40 }} className={`cards ${grid}`}>
            {type !== 'all-useCase' ? <>
                {apiState.list?.map((x, i) => {
                    return <CardApiGr1d height="230px" key={i} categories={x.relatedCategories} id={x.id!} title={x.name} subTitle={x.provider}
                        description={x.description} gateway={x.gateway} />
                })}
            </> : <>
                {useCaseFilter?.map((x, i) => {
                    return <CardUseCaseGr1d key={i} id={x.id} img={x.image} title={x.title} apiCount={x.relatedAPIs.length} description={x.subtitle} />
                })}
            </>}
        </div>
        {(props.match.params as any).type !== 'all-useCase' ?
            <PaginationGr1d page={parseInt((props.match.params as any).page)} onChange={(event: any) => pagination(event)} style={{ marginBottom: 96, display: apiState.total != null && apiState.total > 12 && !loading ? 'flex' : 'none' }} pageSize={12} total={apiState.total} /> :
            <PaginationGr1d page={parseInt((props.match.params as any).page)} onChange={(event: any) => pagination(event)} style={{ marginBottom: 96, display: useCaseState.list != null && useCaseState.list.length > 6 && !loading ? 'flex' : 'none' }} pageSize={6} total={useCaseState.list?.length} />}
    </SearchStyled>;
}

export default Search;