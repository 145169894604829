import React, { ReactNode, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { ColStyled } from "./col.styled";

type ColGr1dProps = {
    children?: ReactNode,
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    className?: string
}

const ColGr1d: React.FC<ColGr1dProps> = (props) => {
    const [col, setCol] = useState('');
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        if (grid === 'sm') {
            if (props.sm != null) {
                setCol(`gr1d-col-${props.sm}`)
            } else if (props.md != null) {
                setCol(`gr1d-col-${props.md}`)
            } else if (props.lg != null) {
                setCol(`gr1d-col-${props.lg}`)
            }
        } else if (grid === 'md') {
            if (props.md != null) {
                setCol(`gr1d-col-${props.md}`)
            } else if (props.sm != null) {
                setCol(`gr1d-col-${props.sm}`)
            } else if (props.lg != null) {
                setCol(`gr1d-col-${props.lg}`)
            }
        } else {
            if (props.lg != null) {
                setCol(`gr1d-col-${props.lg}`)
            } else if (props.md != null) {
                setCol(`gr1d-col-${props.md}`)
            } else if (props.sm != null) {
                setCol(`gr1d-col-${props.sm}`)
            }
        }
    }, [grid, props]);

    return <ColStyled className={col + ' ' + props.className}>
        {props.children}
    </ColStyled>
}

export default ColGr1d;