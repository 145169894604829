import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"
import HeaderLayout from "../header"

const PurchaseReviewStyled: React.FC<any> = ({ className, children }) => {
  return (
  <div>
    <HeaderLayout />
    <div className={className}>{children}</div>
  </div>
  )
}

export default styled(PurchaseReviewStyled)`
  background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};

  & > div {
    gap: 75px;
    flex-wrap: wrap;
  }
  .content-white {
    position: relative;
    background-color: #ffffff;
    max-width: 785px;
    width: 100%;
    height: calc(100vh - 64px);
    padding: 30px 0px;
    margin: auto 0;
    z-index: 1;
    flex: 1;
  }

  .border-top {
    position: absolute;
    background: linear-gradient(45deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} 50%);
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }

  .border-bottom {
    position: absolute;
    background: linear-gradient(135deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} 50%);
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }

  .content-grey {
    padding: 30px 0px;
    width: 100%;
    max-width: 395px;
    flex: 1;
  }

  .new-content {
    width: 100%;
    max-width: 589px;
    margin: auto;
    padding: 0 12px;
  }
`