import TypographyGr1d from "components/portal/typography";
import React from "react";
import { SandboxApiStyled } from "./sandbox.styled";
import { useSelector } from "react-redux";
import { ApiKeyState, ApiState } from "store/portal/reducer";
import SwaggerUIGr1d from "components/portal/swagger";

const SandboxApi: React.FC = () => {
    const apiState: ApiState = useSelector((state: any) => state.portal?.api);

    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const apiKeySandbox: ApiKeyState = useSelector((state: any) => state.portal?.apiKeySandbox);

    return <SandboxApiStyled>
        <TypographyGr1d style={{ marginBottom: 24 }} component='headingSM' color='colorPrimitiveBlack'>
            Sandbox
        </TypographyGr1d>
        <div className={`swagger ${grid}`}>
            <SwaggerUIGr1d gateway={apiState.item?.gateway} apiKey={apiKeySandbox.item?.apiKey} spec={apiState.item?.definitionFile} />
        </div>
    </SandboxApiStyled>;
}

export default SandboxApi;