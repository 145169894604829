import { ModalStyled } from './modalRemoveSavedApi.styled'
import Modal from 'components/portal/modal/index'
import ButtonGr1d from '../button'
import TypographyGr1d from '../typography'

type props = {
    onRemove: (e: any) => void
    onCancel: (e: any) => void
}

const ModalRemoveSavedApi: React.FC<props> = (props) => {
    return (
        <div onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }}>
            <ModalStyled>
                <Modal width="480px" modalActions={(
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <ButtonGr1d type="secondary" onClick={props.onCancel}> Cancelar</ButtonGr1d>
                        <ButtonGr1d type="danger" onClick={props.onRemove}> Remover</ButtonGr1d>
                    </div>
                )} top={300}>
                    <TypographyGr1d font="Manrope" fontWeight="500" color="colorPrimitiveBlack" fontSize="20px">Deseja remover a API de “APIs salvas”?</TypographyGr1d>
                    <TypographyGr1d style={{marginTop: '21px'}} font="Manrope" fontWeight="400" color="colorPrimitiveBlack" fontSize="14px">Você pode salvá-la novamente a qualquer momento.</TypographyGr1d>
                </Modal>
            </ModalStyled>
        </div>
    )
}

export default ModalRemoveSavedApi