import ButtonGr1d from "components/portal/button";
import Modal from "components/portal/modal";
import TypographyGr1d from "components/portal/typography";
import { FieldValues, FormContextValues } from "react-hook-form";
import { ConfirmUserCompanyDataStyled } from "./confirmUserCompanyDataModal.styled";
import InfoCookies from 'assets/svg/infoCookies.svg'
import { useSelector } from "react-redux";

type props = {
  show: boolean;
  formUserData: FormContextValues<FieldValues>;
  formCompanyData: FormContextValues<FieldValues>;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmUserDataComponent: React.FC<props> = ({ show, formUserData, formCompanyData, onCancel, onConfirm }) => {
  const loadingSave: boolean | undefined = useSelector((state: any) => state.company?.loadingSave);
  
  return (
    <ConfirmUserCompanyDataStyled>
      <Modal show={show} width="490px" modalActions={(
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <ButtonGr1d type="secondary" onClick={onCancel}> Cancelar</ButtonGr1d>
              <ButtonGr1d type="primary" loading={loadingSave} onClick={onConfirm}> Confirmar cadastro</ButtonGr1d>
          </div>
        )}>
        <TypographyGr1d font="Manrope" fontSize="20px" color="colorPrimitiveBlack" fontWeight="500">
          Revise as informações
        </TypographyGr1d>
        <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontSize="14px" color="colorPrimitive600" fontWeight="400">
          Confira com atenção os dados fornecidos.
        </TypographyGr1d>
        <div className="alertDataChange">
          <div>
            <img style={{ width: "15px", marginRight: "10px", marginTop: "-5px" }} alt="Info" src={InfoCookies} />
          </div>
          <div>
            <TypographyGr1d font="Manrope" fontSize="12px" color="colorPrimitive600" fontWeight="400">
            Após a confirmação do cadastro, para alterar o CNPJ da empresa você deverá entrar em contato com o suporte da Gr1d.
            </TypographyGr1d>
          </div>
        </div>
        <div className="dataSection">
          <span className="dataTitle"><TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700" fontWeight="700">Sua conta</TypographyGr1d></span>
          <div className="dataRow">
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700">CPF</TypographyGr1d>
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{formUserData.getValues().cpf}</TypographyGr1d>
          </div>
          <div className="dataRow">
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700">Telefone</TypographyGr1d>
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{formUserData.getValues().phone}</TypographyGr1d>
          </div>
        </div>
        <div className="dataSection">
          <span className="dataTitle"><TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700" fontWeight="700">Empresa</TypographyGr1d></span>
          <div className="dataRow">
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700">CNPJ</TypographyGr1d>
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{formCompanyData.getValues().cnpj}</TypographyGr1d>
          </div>
          <div className="dataRow">
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700">Razão social</TypographyGr1d>
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{formCompanyData.getValues().companyname}</TypographyGr1d>
          </div>
          <div className="dataRow">
            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive700">Endereço</TypographyGr1d>
            <TypographyGr1d style={{ whiteSpace: "pre-line" }} font="Manrope" fontSize="14px" color="colorPrimitive500">{formCompanyData.getValues().address ? formCompanyData.getValues().address.replace('\n', '') : ''}</TypographyGr1d>
          </div>
        </div>
      </Modal>
    </ConfirmUserCompanyDataStyled>
  )
}

export const ConfirmUserData = ConfirmUserDataComponent

