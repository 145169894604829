import React from "react";
import { ApiPreviewStyled } from "./index.styled";
import logotemp from "assets/svg/logo-temp.svg";
import dots from "assets/svg/api/dots.svg";
import { Rate, Tag } from "antd";
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import tag from "assets/svg/tag.svg";
import { useSelector } from "react-redux";

// const { TabPane } = Tabs;

const ApiPreview: React.FC = () => {
    // const [tabValue, setTabValue] = useState<string>('1');
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    return <ApiPreviewStyled className={grid}>
        <div className='api-header'>
            <div className='title-logo'>
                <img className='logo' src={logotemp} alt='logo-temp' />
                <div className='title'>
                    <div>
                        <Tag style={{ marginBottom: 8 }} className='card-tag'>
                            <TypographyGr1d component='caption' color='colorBrandPrimary600'>
                                Finanças
                            </TypographyGr1d>
                        </Tag>
                    </div>
                    <TypographyGr1d style={{ marginBottom: 4 }} component='headingLG' color='colorPrimitiveBlack'>
                        Cashback
                    </TypographyGr1d>
                    <TypographyGr1d component='subtitleSM' color='colorPrimitive700'>
                        por QQCusta
                    </TypographyGr1d>
                </div>
            </div>
            <div className='raking'>
                <div>
                    <TypographyGr1d component='overline' color='colorPrimitiveBlack'>
                        Avaliação
                    </TypographyGr1d>
                    <div className='average'>
                        <TypographyGr1d component='headingLG' color='colorPrimitiveBlack'>
                            5.0
                        </TypographyGr1d>&nbsp;
                        <TypographyGr1d component='headingMD' color='colorPrimitive500'>
                            / 5.0
                        </TypographyGr1d>
                    </div>
                    <Rate disabled={true} value={5} />
                </div>
            </div>
        </div>
        <div className='description'>
            <div className='dots'>
                <img alt='dot' src={dots} />
            </div>
            <TypographyGr1d style={{ width: '50%', marginBottom: 24 }} component='bodyLG' color='colorPrimitive600'>
                A API da QQCUSTA oferece através de sua plataforma os serviços de cashback, que auxilia na fidelização de consumidores, publicidade e marketing.
            </TypographyGr1d>
            <ButtonGr1d style={{ marginBottom: 48 }} background='white'>
                Ativar API
            </ButtonGr1d>
            <div className='options'>
                <div className='tab-options'>
                    <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack'>
                        {/* <Tabs onChange={(v) => setTabValue(v)}> */}
                            {/* <TabPane tab="Visão geral" key="1" /> */}
                            {/* <TabPane tab="Documentação" key="2" />
                            <TabPane tab="Preços" key="3" />
                            <TabPane tab="Sandbox" key="4" />
                            <TabPane tab="Comentários" key="5" /> */}
                        {/* </Tabs> */}
                    </TypographyGr1d>
                </div>
                <div className='buttons-options'>
                    <TypographyGr1d style={{ marginRight: 32 }} component='action' color='colorPrimitive600'>
                        <><img width='14' height='14' src={tag} alt='tag' /> Salvar API</>
                    </TypographyGr1d>
                    <ButtonGr1d background='white'>
                        Ver chave de sandbox
                    </ButtonGr1d>
                </div>
            </div>
        </div>
        <div className='tabs'>
            {/* <div className='div-1280'>
                {tabValue === "1" && <GeneralApi title='Sobre' />}
                {tabValue === "2" && <DocumentationApi />}
                {tabValue === "3" && <PriceApi />}
                {tabValue === "4" && <SandboxApi />}
                {tabValue === "5" && <CommentApi />}
            </div> */}
        </div>
    </ApiPreviewStyled>;
}

export default ApiPreview;