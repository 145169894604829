import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Modal = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const ModalStyled = styled(Modal)`
.modalBackground {
    left: 0;
    top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    opacity: 0.4;
    z-index: 1;
}
.modalWrapper {
    z-index: 2;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .modalPositioned {
        border-radius: 8px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        .modalContent {
            padding: 24px;
        }
        .modalActions {
            border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            padding: 16px 24px;
        }
    }
}
`