import { ApiModel } from "models/api";
import { ApiPriceModel, PaymentIntentModel } from "models/prepaid";
import { CNPJModelResponse } from "models/cnpj";
import { CompanyContactModel, CompanyModel } from "models/company";
import { UserModel } from "models/user";
import { createApiService } from "service";

export const createPortaPrepaidService = () => {
    const service = createApiService('/core/api/v1');
    const serviceBilling = createApiService('/billing/api/v1');

    const getApi = (id: number): Promise<ApiModel> => {
        return service.get(`/apis/${id}`);
    }

    const getApiPrice = (id: number): Promise<ApiPriceModel> => {
        return serviceBilling.get(`/payments/${id}/pricing`);
    }

    const getContacts = (orgId: string): Promise<CompanyContactModel[]> => {
        return service.get(`/contacts-company/${orgId}`)
    }

    const getCnpj = (cnpj: string): Promise<CNPJModelResponse> => {
        return service.get(`/cnpj/${cnpj}`);
    }

    const updateUser = (user: { cpf: string, phone: string }): Promise<UserModel> => {
        return service.put('/user', { cpf: user.cpf.replace(/\./g, '').replace('-', ''), telephone: user.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '') });
    }

    const postOrganization = (organization: CompanyModel): Promise<CompanyModel> => {
        return service.post('/company', organization);
    }

    const postContact = (contact: CompanyContactModel[]): Promise<CompanyContactModel[]> => {
        return service.post(`/contacts-company/`, contact);
    }

    const getPayment = (quantity: number, api: number): Promise<PaymentIntentModel> => {
        return serviceBilling.post(`/payments/organization`, { quantity, api });
    }

    const getPaymentTransaction = (api: number, id: string): Promise<{ status: string }> => {
        return serviceBilling.get(`/payments/${api}/${id}`);
    }

    return { getApi, getApiPrice, getContacts, getCnpj, updateUser, postOrganization, postContact, getPayment, getPaymentTransaction };
}