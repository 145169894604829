import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const EmptyStateList = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const EmptyStateListStyled = styled(EmptyStateList)`
  .fullHeightContainer {
    width: 100%;
    height: calc(100vh - 118px); ${ /* 118px do header, ajustar conforme necessário */ ''}
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image {
    display: flex;
    justify-content: center;
  }
  .imageCircleBg {
    width: 184px;
    height: 184px;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;