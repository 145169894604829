import { Breadcrumb } from "antd";
import TypographyGr1d from "components/portal/typography";
import React, { useEffect } from "react";
import { Link, match } from "react-router-dom";
import { AdminApiConsumedFormStyled } from "./form.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as info } from "assets/svg/info.svg";
import { ReactComponent as copy } from "assets/svg/copy.svg";
import { ReactComponent as download } from "assets/svg/api/download.svg";
import { ApiModel } from "models/api";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { adminApiConsumedActions } from "store/admin/apiConsumed/action";
import noImage from 'assets/svg/no-image.svg';
import ButtonGr1d from "components/portal/button";
import { useForm } from "react-hook-form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import FormAdmin from "components/admin/form";
import { ApiKeyState } from "store/portal/reducer";
import { UserModel } from "models/user";
import { appActions } from "store/app/action";
import { portalActions } from "store/portal/action";

type Props = {
  match: match,
}

const AdminApiConsumedForm: React.FC<Props> = (props) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const actions = useAction(adminApiConsumedActions());
  const portalAction = useAction(portalActions());
  const api: ApiModel = useSelector((state: any) => state.adminApiConsumed.apiItem);
  const fetchingSwagger: boolean = useSelector((state: any) => state.adminApiConsumed.fetchingSwagger);
  const swagger: string = useSelector((state: any) => state.adminApiConsumed.apiSwagger);
  const deleteLoading: boolean = useSelector((state: any) => state.adminApiConsumed.deleteLoading);
  const apiKeyProd: ApiKeyState | undefined = useSelector((state: any) => state.adminApiConsumed.apiKeyProd);
  const apiKeySandbox: ApiKeyState | undefined = useSelector((state: any) => state.adminApiConsumed.apiKeySandbox);
  const appAction = useAction(appActions());
  const user: UserModel | undefined = useSelector((state: any) => state.login?.user);
  const form = useForm();

  // useEffect(() => {
  //   reducerManager.add('apiComsumed', adminApiConsumedReducer);
  //   return () => {
  //     if (!history.location.pathname.includes('/admin/apiComsumed')) {
  //       reducerManager.remove('apiComsumed');
  //     }
  //   };
  // }, [history])

  const downloadSwagger = () => {
    dispatch(actions.getApiSwagger((props.match.params as any).id));
  }

  useEffect(() => {
    if (swagger !== null) {
      const blob = new Blob([JSON.stringify(swagger)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', 'swagger.json');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      dispatch(actions.deleteSwagger());
    }
  }, [swagger, dispatch, actions])

  const deleteEndPoint = () => {
    dispatch(actions.deleteApiKey((props.match.params as any).id, 'SANDBOX'));
  }

  useEffect(() => {
    dispatch(actions.getApiId((props.match.params as any).id, false));
    dispatch(actions.getApiKey((props.match.params as any).id, 'SANDBOX'));
    dispatch(actions.getApiKey((props.match.params as any).id, 'PRODUCTION'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const copyTxt = (name: string) => {
    navigator.clipboard.writeText(form.getValues()[name]);
  }

  useEffect(() => {
    if (apiKeyProd?.item != null && api != null) {
      form.setValue('url-prod', apiKeyProd.item.urlContext);
      form.setValue('api-key-prod', apiKeyProd.item?.apiKey);
    } else {
      form.setValue('url-prod', undefined);
      form.setValue('api-key-prod', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeyProd, api])

  useEffect(() => {
    if (apiKeySandbox?.item != null && api != null) {
      form.setValue('url-sandbox', apiKeySandbox.item.urlContext);
      form.setValue('api-key-sandbox', apiKeySandbox.item.apiKey);
    } else {
      form.setValue('url-sandbox', undefined);
      form.setValue('api-key-sandbox', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeySandbox, api])

  const formConfigProd: ConfigFormItem[] = [
    {
      id: 'url',
      name: 'url-prod',
      label: 'URL',
      typeComponent: 'input',
      placeholder: 'Não disponivel',
      disabled: apiKeyProd?.item == null,
      copy: <ButtonGr1d disabled={apiKeyProd?.item == null} type='tertiary'>
        <Icon className='copy' component={copy} />
      </ButtonGr1d>
    },
    {
      id: 'api-key',
      name: 'api-key-prod',
      label: 'API key',
      typeComponent: 'input',
      placeholder: 'Não disponivel',
      disabled: apiKeyProd?.item == null,
      copy: <ButtonGr1d disabled={apiKeyProd?.item == null} type='tertiary'>
        <Icon className='copy' component={copy} />
      </ButtonGr1d>
    }
  ]

  const formConfigSandbox: ConfigFormItem[] = [
    {
      id: 'url',
      name: 'url-sandbox',
      label: 'URL',
      typeComponent: 'input',
      onKeyPress: (e) => e.preventDefault(),
      placeholder: 'Não disponivel',
      disabled: apiKeySandbox?.item == null,
      copy: <ButtonGr1d disabled={apiKeySandbox?.item == null} onClick={() => copyTxt('url-sandbox')} type='tertiary'>
        <Icon className='copy' component={copy} />
      </ButtonGr1d>
    },
    {
      id: 'api-key',
      name: 'api-key-sandbox',
      label: 'API key',
      typeComponent: 'input',
      onKeyPress: (e) => e.preventDefault(),
      placeholder: 'Não disponivel',
      disabled: apiKeySandbox?.item == null,
      copy: <ButtonGr1d disabled={apiKeySandbox?.item == null} onClick={() => copyTxt('api-key-sandbox')} type='tertiary'>
        <Icon className='copy' component={copy} />
      </ButtonGr1d>
    },
    {
      name: 'delete',
      typeComponent: 'custom',
      element: <ButtonGr1d disabled={apiKeySandbox?.item == null} onClick={() => deleteEndPoint()} loading={deleteLoading} type='dangersecondary'>
        Excluir chave
      </ButtonGr1d>
    },
  ]

  const formConfigDownload: ConfigFormItem[] = [
    {
      name: 'swagger',
      typeComponent: 'custom',
      element: <ButtonGr1d onClick={() => downloadSwagger()} type='primitive' loading={fetchingSwagger}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ fontSize: 18, paddingRight: 6 }} component={download} />Swagger OpenAPI
        </div>
      </ButtonGr1d>
    },
  ]

  const activeApi = () => {
    if (user?.isAdmin) {
      dispatch(portalAction.postApiKey((props.match.params as any).id, 'PRODUCTION'));
    } else {
      dispatch(appAction.changeModalPermission(true));
    }
  }

  return <AdminApiConsumedFormStyled>
    <div className='header'>
      <TypographyGr1d component='bodySM'>
        <Breadcrumb>
          {/* <Breadcrumb.Item onClick={() => dispatch(action.goTo('/admin/apiConsumed'))}>Minhas API's</Breadcrumb.Item> */}
          <Breadcrumb.Item>Minhas API's</Breadcrumb.Item>
          <Breadcrumb.Item>API's Consumidas</Breadcrumb.Item>
        </Breadcrumb>
      </TypographyGr1d>
      <div className='header-flex'>
        <img src={`/core/api/v1/apis/${api?.id}/image`} alt='logo' onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} />
        <div style={{ flex: 1 }}>
          <Link to={`/api/${api?.id}`}>
            <TypographyGr1d componentType='span' style={{ alignItems: 'center' }} component='headingMD' color='colorPrimitive700'>
              {api?.name}
            </TypographyGr1d>
          </Link>
          <TypographyGr1d component='bodyLG' color='colorPrimitive500'>
            {api?.provider ?? <>&nbsp;</>}
          </TypographyGr1d>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <ButtonGr1d onClick={() => activeApi()} style={{ visibility: 'hidden' }} type='brand'>
            Ativar API
          </ButtonGr1d>
        </div>
      </div>
    </div>
    {(apiKeyProd?.item == null && !apiKeyProd?.loading) && <div className='info'>
      <TypographyGr1d style={{ display: 'flex' }} font='Manrope' fontWeight='600' fontSize='14px' color='colorPrimitive600'>
        <Icon component={info} /> Para utilizar os endpoints de produção, ative a API.
      </TypographyGr1d>
    </div>}
    <div className='form'>
      <FormAdmin padding='0'>
        <FormItensAdmin style={{ opacity: apiKeyProd?.item == null ? '0.7' : '1' }} form={form} config={formConfigProd} labelTitle={{ title: 'Endpoints - Produção', subtitle: 'Utilize este ambiente quando sua aplicação já estiver homologada. O consumo no ambiente de produção será contabilizada de acordo com a tabela de preços da API.' }} />
        <div className='hr' />
        <FormItensAdmin style={{ opacity: apiKeySandbox?.item == null ? '0.7' : '1' }} form={form} config={formConfigSandbox} labelTitle={{ title: 'Endpoints - Sandbox', subtitle: 'Utilize este ambiente para testar a integração da API com sua aplicação. ' }} />
        <div className='hr' />
        <FormItensAdmin form={form} config={formConfigDownload} labelTitle={{ title: 'Baixar arquivos', subtitle: 'Recursos para te ajudar na integração da API com seu ambiente de desenvolvimento' }} />
      </FormAdmin>
    </div>
  </AdminApiConsumedFormStyled>;
}

export default AdminApiConsumedForm;