import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Switch } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import TypographyGr1d from 'components/portal/typography';
import _ from 'lodash';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Controller, FormContextValues } from 'react-hook-form';
import MultiSelectAdmin from '../multiselect';
import MaskedInput from "antd-mask-input";

export type ConfigFormItem = {
    id?: string,
    name: string,
    disabled?: boolean,
    element?: ReactNode,
    typeComponent: "custom" | "custom-form" | "button" | "input" | "textarea" | "save-cancel" | "check" | "switch" | "select" | "radio" |
    "multiselect" | "tags" | "masked-input" | "date" | "number",
    typeBtn?: "link" | "text" | "ghost" | "primary" | "default" | "dashed",
    htmlType?: "button" | "submit" | "reset",
    noLabel?: boolean,
    label?: ReactNode,
    helpText?: string,
    labelTitle?: {
        inRow?: boolean,
        title: string,
        subtitle: string
    },
    prefix?: ReactNode,
    suffix?: ReactNode,
    defaultValue?: any,
    maxLength?: number,
    max?: number,
    min?: number,
    showMaxLength?: boolean,
    addonBefore?: string,
    addonAfter?: ReactNode,
    copy?: ReactNode,
    elementBefore?: ReactNode,
    elementAfter?: ReactNode,
    noSpace?: boolean,
    onSearch?: (event: any) => void,
    onKeyUp?: (event: any) => void,
    onKeyPress?: (event: any) => void,
    onChange?: (event: any) => void,
    onBlur?: (event: any) => void,
    onFocus?: (event: any) => void,
    mode?: string,
    placeholder?: string,
    loading?: boolean,
    data?: any[],
    hide?: boolean,
    rules?: any,
    mask?: string,
    span?: number,
    sm?: number,
    md?: number,
    lg?: number,
    maxWidth?: number | string,
    width?: number | string,
    textAreaOverflow?: string,
    textAreaHeight?: string,
    textAreaPadding?: string,
}

export type PropsFormItens = {
    id?: string,
    form: FormContextValues<any>,
    config?: ConfigFormItem[],
    labelTitle?: {
        inRow?: boolean,
        title: string,
        subtitle: string
    },
    style?: CSSProperties,
    classNameCol?: string,
    marginBottom?: number
}

const { Option } = Select;

const FormItensAdmin: React.FC<PropsFormItens> = (props) => {
    const [elements, setElements] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let elementsTemp: JSX.Element[] = [];
        _.forEach(props.config, (config: ConfigFormItem) => {
            let elementTemp: JSX.Element | undefined = undefined;
            let onChange: any;
            switch (config.typeComponent) {
                case 'input':
                    elementTemp = <Input onKeyPress={config.onKeyPress} onFocus={config.onFocus} disabled={config.disabled} suffix={config.suffix}
                        addonAfter={config.addonAfter} addonBefore={config.addonBefore} maxLength={config.maxLength}
                        className={`${config.addonBefore == null || config.addonAfter == null ? 'input-form' : 'input-form-before'} 
                        ${config.suffix != null ? 'input-form-suffix' : ''} bodySMBase`}
                        onKeyUp={config.onKeyUp} size='middle' placeholder={config.placeholder} style={{ maxWidth: config.maxWidth }} />;
                    if (config.noSpace === true)
                        onChange = (v: any) => { return v[0].target.value.trim() };
                    else {
                        if (config.onChange != null)
                            onChange = ((v: any) => {
                                if (v != null) {
                                    config.onChange!(v[0]);
                                    return v[0].target.value
                                }
                            });
                        else
                            onChange = (v: any) => { return v[0].target.value };
                    }
                    break;
                case 'number':
                    elementTemp = <InputNumber max={config.max} min={config.min} onKeyPress={config.onKeyPress} onFocus={config.onFocus} disabled={config.disabled}
                        className='input-form bodySMBase' style={{ width: '100%' }}
                        onKeyUp={config.onKeyUp} size='middle' placeholder={config.placeholder} />;
                    if (config.noSpace === true)
                        onChange = (v: any) => { return v[0].target.value.trim() };
                    else {
                        if (config.onChange != null)
                            onChange = ((v: any) => {
                                if (v != null) {
                                    config.onChange!(v[0]);
                                    return v[0]
                                }
                            });
                        else
                            onChange = (v: any) => { return v[0] };
                    }
                    break;
                case 'masked-input':
                    elementTemp = <MaskedInput onChange={config.onChange} onFocus={config.onFocus} style={{ maxWidth: config.maxWidth }}
                        mask={config.mask || ""} name={config.name} disabled={config.disabled} width={config.width}
                        className='input-form' size="middle" placeholder={config.placeholder} />;
                    if (config.onChange != null)
                        onChange = ((v: any) => {
                            if (v != null) {
                                config.onChange!(v[0]);
                                return v[0].target.value
                            }
                        });
                    else
                        onChange = ((v: any) => { if (v != null) return v[0].target.value });
                    break;
                case 'textarea':
                    elementTemp = <TextArea maxLength={config.maxLength} className='input-form bodySMBase' size='middle' placeholder={config.placeholder} disabled={config.disabled} style={{ overflow: config.textAreaOverflow, height: config.textAreaHeight, padding: config.textAreaPadding }} />;
                    onChange = (v: any) => { return v[0].target.value };
                    break;
                case 'button':
                    elementTemp = <Button htmlType={config.htmlType} type={config.typeBtn}>{config.element}</Button>;
                    break;
                case 'check':
                    elementTemp = <Checkbox />;
                    onChange = (event: any) => event[0].target.checked;
                    break;
                case 'switch':
                    elementTemp = <Switch />;
                    onChange = (event: any) => event[0];
                    break;
                case 'select':
                    elementTemp = <Select className='select-form' placeholder={config.placeholder}>
                        {_.map(config.data, x => <Option value={x.value} key={x.value}>{x.title}</Option>)}
                    </Select>;
                    if (config.onChange != null)
                        onChange = (v: any) => { config.onChange!(v[0]); return v[0] };
                    else
                        onChange = (v: any) => { return v[0] };
                    break;
                case 'multiselect':
                    elementTemp = <div><MultiSelectAdmin placeholder={config.placeholder} name={config.name} form={props.form} data={config.data!} mode='multiple' /></div>
                    if (config.onChange != null)
                        onChange = (v: any) => { config.onChange!(v[0]); return v[0] };
                    else
                        onChange = (v: any) => { return v[0] };
                    break;
                case 'tags':
                    elementTemp = <div><MultiSelectAdmin placeholder={config.placeholder} name={config.name} form={props.form} data={config.data!} mode='tags' /></div>
                    if (config.onChange != null)
                        onChange = (v: any) => { config.onChange!(v[0]); return v[0] };
                    else
                        onChange = (v: any) => { return v[0] };
                    break;
                case 'radio':
                    elementTemp = <Radio.Group defaultValue={config.defaultValue}>
                        {_.map(config.data, x => <Radio value={x.value} key={x.value}>{x.title}</Radio>)}
                    </Radio.Group>
                    if (config.onChange != null)
                        onChange = (v: any) => { config.onChange!(v[0].target.value); return v[0].target.value };
                    else
                        onChange = (event: any) => event[0].target.value;
                    break;
                case 'custom':
                case 'custom-form':
                    elementTemp = <>{config.element}</>;
                    break;
            }

            let label: ReactNode | undefined = undefined;
            if (config.label != null) {
                label = <TypographyGr1d component='bodySM' color='colorPrimitive500'>{config.label}</TypographyGr1d>
            } else if (config.labelTitle != null) {
                label = <div>
                    <TypographyGr1d style={{ paddingBottom: config.labelTitle.inRow ? 24 : 8 }} component='subtitleSM' color='colorPrimitiveBlack'>{config.labelTitle.title}</TypographyGr1d>
                    <TypographyGr1d component='bodySM' color='colorPrimitive500'>{config.labelTitle.subtitle}</TypographyGr1d>
                </div>
            }

            if (!['button', 'custom'].includes(config.typeComponent)) {
                elementsTemp.push(<Col id={config.id} style={{ display: config.hide ? 'none' : 'block' }} key={'col' + config.name} span={config.span ?? 24} sm={config.sm} md={config.md} lg={config.lg}>
                    <div className='element-before'>
                        {config.elementBefore != null ? <div>{config.elementBefore}</div> : <></>}
                        <FormItemAdmin marginBottom={props.marginBottom} onChange={onChange} onBlur={config.onBlur} name={config.name}
                            maxLength={config.maxLength} showMaxLength={config.showMaxLength} copy={config.copy}
                            label={label} helpText={config.helpText}
                            rules={config.rules} form={props.form}>
                            {elementTemp}
                        </FormItemAdmin>
                        {config.elementAfter != null ? <div>{config.elementAfter}</div> : <></>}
                    </div>
                </Col>);
            } else {
                elementsTemp.push(<Col id={config.id} style={{ display: config.hide ? 'none' : 'block' }} key={'col' + config.name} span={config.span ?? 24} sm={config.sm} md={config.md} lg={config.lg}>
                    <div className='element-before'>
                        {config.elementBefore != null ? <div>{config.elementBefore}</div> : <></>}
                        <Form.Item label={config.noLabel === true ? undefined : config.label == null ? <>&nbsp;</> : config.label}>
                            {elementTemp}
                            {config.helpText != null && <TypographyGr1d style={{ paddingTop: 8 }} component='caption' color='colorPrimitive500'>{config.helpText}</TypographyGr1d>}
                        </Form.Item>
                        {config.elementAfter != null ? <div>{config.elementAfter}</div> : <></>}
                    </div>
                </Col>);
            }
        });

        setElements(elementsTemp);
    }, [props]);

    if (props.labelTitle == null) {
        return <Row id={props.id} style={props.style} gutter={[8, 8]}>
            {elements}
        </Row>;
    } else {
        return <Row id={props.id} style={props.style} gutter={[8, 8]}>
            <Col style={{ paddingBottom: !props.labelTitle.inRow ? 0 : 24 }} span={24} sm={!props.labelTitle.inRow ? 8 : 24}>
                <div>
                    <TypographyGr1d style={{ paddingBottom: 8 }} component='subtitleMD' color='colorPrimitive700'>{props.labelTitle.title}</TypographyGr1d>
                    <TypographyGr1d component='bodySM' color='colorPrimitive500'>{props.labelTitle.subtitle}</TypographyGr1d>
                </div>
            </Col>
            <Col className={props.classNameCol} span={24} sm={!props.labelTitle.inRow ? { span: 14, offset: 2 } : 24}>
                <Row gutter={[8, 0]}>
                    {elements}
                </Row>
            </Col>
        </Row>;
    }
}

export type PropsFormItem = {
    name: string,
    label?: ReactNode,
    maxLength?: number,
    marginBottom?: number,
    showMaxLength?: boolean,
    form: FormContextValues<any>,
    onChange?: any,
    onBlur?: any,
    helpText?: string,
    rules?: any,
    copy?: ReactNode
}

const FormItemAdmin: React.FC<PropsFormItem> = (props) => {
    const { errors } = props.form!;
    const [length, setLength] = useState<number | undefined>(props.maxLength);
    let error: any = undefined;
    if (errors)
        error = errors[props.name!];

    return <Form.Item style={{ marginBottom: props.marginBottom ?? 24 }} className='form-admin' label={props.label}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Controller onChange={event => {
                if (length != null) {
                    setLength(props.maxLength! - event[0].target.value.length);
                }

                return props.onChange(event);
            }} onBlur={props.onBlur} mode='onChange' as={props.children as JSX.Element} name={props.name}
                control={props.form.control} rules={props.rules} />
            {props.copy}
        </div>
        {props.helpText != null && <TypographyGr1d style={{ paddingTop: 8 }} component='caption' color='colorPrimitive500'>{props.helpText}</TypographyGr1d>}
        {props.showMaxLength && <TypographyGr1d style={{ paddingTop: 8 }} component='caption' color='colorPrimitive500'>{length} caracteres restantes</TypographyGr1d>}
        <div className='error-field'>{error?.message}</div>
    </Form.Item>;
}

export default FormItensAdmin;
