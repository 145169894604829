import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const PriceApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const PriceApiStyled = styled(PriceApi)`
    padding-top: 56px;
    padding-bottom: 67px;
    
    .inline-content {
        padding-right: 1rem;
        > div {
            display: inline;
        }
    }

    .ant-table-cell::before {
        display: none !important;
    }

    .pagination {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
    }

    .price-card {
        padding: 30px;
        border-radius: 8px;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        width: 250px;

        .price-card-description {
            padding-top: 24px;
            border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }
    }

    .price-card:hover{
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};;
        box-shadow: 0px 16px 32px rgba(20, 22, 31, 0.08);
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
    }

    .price-cards {
        display: flex;
        gap: 24px;
        padding: 24px 0;
        justify-content: center;
        flex-wrap: wrap;
    }

    .info-prepaid svg {
        fill: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
    }
`