import { ApiModel } from "models/api";
import { HelpActionType } from "./action";

export interface HelpState {
    subjects: string[];
    files: {
        name: string,
        success: boolean
    }[];
    apiHelpForm?: ApiModel,
    loadingApiHelpForm: boolean
}

export const INITIAL_STATE = { subjects: [], files: [], loadingApiHelpForm: false }
export function helpReducer(state: HelpState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case HelpActionType.GET_SUBJECTS:
            state = { ...state, subjects: action.payload.subjects }
            break;
        case HelpActionType.SEND_FILE_SUCCESS:
            state = { ...state, files: [...state.files, action.payload] }
            break;
        case HelpActionType.SEND_FILE_ERROR:
            state = { ...state, files: [...state.files, action.payload] }
            break;
        case HelpActionType.GET_API_HELPFORM:
            state = { ...state, apiHelpForm: undefined, loadingApiHelpForm: true }
            break;
        case HelpActionType.GET_API_HELPFORM_SUCCESS:
            state = { ...state, apiHelpForm: action.payload, loadingApiHelpForm: false }
            break;
        case HelpActionType.GET_API_HELPFORM_ERROR:
            state = { ...state, apiHelpForm: undefined, loadingApiHelpForm: false }
            break
        case HelpActionType.API_HELPFORM_DELETE:
            state = { ...state, apiHelpForm: undefined }
            break;
    }


    return state;
}