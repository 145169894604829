import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import Table, { ColumnsType } from "antd/lib/table";
import DrawerAdmin from "components/admin/drawer";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import TypographyGr1d from "components/portal/typography";
import { useForm } from "react-hook-form";
import { PayableDetailStyled } from "./payableDetail.styled";

type Props = {
  api: any,
  setVisible: (check: boolean) => void,
  visible: boolean
}

const PayableDetail: React.FC<Props> = (props) => {
  const form = useForm();

  const formConfig: ConfigFormItem[] = [
    {
      name: 'search',
      noLabel: true,
      typeComponent: 'input',
      placeholder: 'Pesquisar por nome da operação',
      suffix: <SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} />,
      span: 24,
      sm: 18,
      md: 14
    }
  ]

  const columns: ColumnsType<any> = [
    {
      title: 'Operações',
      dataIndex: 'operation',
      width: 342,
      key: 'operation',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.operation - b.operation,
      render: (value: string, row: any) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <TypographyGr1d color='colorPrimitiveBlack' component='subtitleSM' fontWeight='600' className={`method ${row.method}`} >
            {row.method}
          </TypographyGr1d>
          <TypographyGr1d color='colorPrimitiveBlack' component='bodyLG'>
            {row.operation}
          </TypographyGr1d>
        </div>
      }
    },
    {
      title: <>Número de chamadas&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'calls',
      width: 210,
      key: 'calls',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.calls - b.calls,
    },
    {
      title: <>Consumo total&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'consume',
      width: 178,
      key: 'consume',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.consume - b.consume,
    }
  ]

  const data = [{
    operation: '/pet',
    method: 'POST',
    calls: 3459,
    consume: 274.12
  }, {
    operation: '/pet/findByStatus',
    method: 'DELETE',
    calls: 3459,
    consume: 274.12
  }, {
    operation: '/pet/findByStatus',
    method: 'GET',
    calls: 3459,
    consume: 274.12
  }, {
    operation: '/pet/findByStatus',
    method: 'PUT',
    calls: 3459,
    consume: 274.12
  }]

  return <DrawerAdmin className='payable' title={'Detalhes API ' + props.api.api} setVisible={props.setVisible} visible={props.visible}>
    <PayableDetailStyled>
      <div className='total'>
        <div>
          <TypographyGr1d component='bodySM' color='colorPrimitive600'>
            Chamadas totais
          </TypographyGr1d>
          <TypographyGr1d fontWeight='600' component='subtitleSM' color='colorPrimitive700'>
            {props.api.calls}
          </TypographyGr1d>
        </div>
        <div>
          <TypographyGr1d component='bodySM' color='colorPrimitive600'>
            Consumo total
          </TypographyGr1d>
          <TypographyGr1d fontWeight='600' component='subtitleSM' color='colorPrimitive700'>
            {props.api.consume}
          </TypographyGr1d>
        </div>
      </div>
      <FormAdmin padding='0px'>
        <FormItensAdmin form={form} config={formConfig} />
      </FormAdmin>
      <Table pagination={false} columns={columns} dataSource={data} />
    </PayableDetailStyled>
  </DrawerAdmin>;
}

export default PayableDetail;