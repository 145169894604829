import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiConsumedForm = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiConsumedFormStyled = styled(AdminApiConsumedForm)`
    .header {
        padding: 32px 32px 0px 32px;
    }

    .header-flex {
        display: flex;
        position: relative;

        > img {
            width: 64px;
            height: 64px;
            margin-right: 16px;
            object-fit: scale-down;
        }
    }

    .info {
        padding: 0 32px;
        display: flex;
        align-items: center;
        height: 56px;
        margin-top: 32px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};

        div > span {
            padding-right: 10px;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            font-size: 20px;
        }
    }

    .form {
        padding: 40px 32px 0px 32px;
    }

    .copy {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        font-size: 20px;
        cursor: pointer;
    }
`