import { EmptyStateListStyled } from "./index.styled"
import codeimage from "assets/svg/code.svg"
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import CoolIcon from "assets/svg/coolicon.svg"
import { useHistory } from "react-router";

const ApiListEmptyState: React.FC = () => {
  const history = useHistory();

  return (
    <EmptyStateListStyled>
      <div className="fullHeightContainer">
        <div className="centeredContent">
          <div className="image" style={{ textAlign: "center", width: "100%" }}>
            <div className="imageCircleBg">
              <img alt="Nenhuma api" src={codeimage} />
            </div>
          </div>
          <TypographyGr1d font="Manrope" fontSize="20px" color="colorPrimitive500" style={{ textAlign: "center", marginTop: "32px" }}>
            Descubra APIs no nosso Marketplace
          </TypographyGr1d>
          <TypographyGr1d font="Manrope" fontSize="16px" color="colorPrimitive400" style={{ textAlign: "center", marginTop: "8px" }}>
            Seja qual for a sua necessidade, existe uma API que pode te ajudar. 
          </TypographyGr1d>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "32px" }}>
            <ButtonGr1d onClick={() => { history.push("/") }}><img alt='cool' src={CoolIcon} style={{ marginRight: "12px" }} />Explorar APIs</ButtonGr1d>
          </div>
        </div>
      </div>
    </EmptyStateListStyled>
  )
}

export default ApiListEmptyState;