import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const ReviewPurchaseCardStyled: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{ children }</div>
}

export default styled(ReviewPurchaseCardStyled)`
  width: 100%;
  .cardcontainer {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    // height: 262px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .card-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .card-header {
    display: flex;
    img {
      width: 56px;
      height: 56px;
    }
    .card-title-container {
      margin-left: 16px;
    }
  }
  .card-content {
    // background-color: yellow;
    > div {
      margin-top: 14px;
    }
    .version {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .payment-description {
    a {
      color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
      text-decoration: underline;
    }
  }

  .activationInfo {
    width: 100%;
    border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    margin-top: 24px;
    .activationText {
      margin-top: 24px;
      width: 100%;
      background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
      padding: 16px;
      border-radius: 4px;
      a {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
        text-decoration: underline;
      }
    }
  }
`
