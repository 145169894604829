import { ReactMode } from "enums";

export enum TypeManageStorage {
    session = 0,
    local = 1
}

const managerStorage = (type: TypeManageStorage) => {
    const ID_APP = "Gr1d";
    const storage: Storage = type === TypeManageStorage.local ? localStorage : sessionStorage;

    const set = (key: string, value: any) => {
        const obj = get() ?? {};
        obj[key] = value;
        if (process.env.NODE_ENV === ReactMode.desenv)
            storage.setItem(ID_APP, JSON.stringify(obj))
        else
            storage.setItem(ID_APP, btoa(unescape(encodeURIComponent(JSON.stringify(obj)))));
    }

    const get = <T = any>(key?: string): T | null => {
        const value = storage.getItem(ID_APP);
        if (value) {
            let objSession: any = {};
            if (process.env.NODE_ENV === ReactMode.desenv)
                objSession = JSON.parse(value);
            else
                objSession = JSON.parse(decodeURIComponent(escape(atob(value))));
            if (key)
                return objSession[key] as T;
            else
                return objSession;
        } else return null
    }

    const remove = (key: string) => {
        let objSession: any = {}
        if (process.env.NODE_ENV === ReactMode.desenv)
            objSession = JSON.parse(storage.getItem(ID_APP) ?? '');
        else
            objSession = JSON.parse(atob(storage.getItem(ID_APP) ?? ''));
        delete objSession[key];
        if (process.env.NODE_ENV === ReactMode.desenv)
            storage.setItem(ID_APP, JSON.stringify(objSession));
        else
            storage.setItem(ID_APP, btoa(unescape(encodeURIComponent(JSON.stringify(objSession)))));
    }

    const removeAll = () => {
        let objSession: any = {}
        if (process.env.NODE_ENV === ReactMode.desenv)
            objSession = JSON.parse(storage.getItem(ID_APP) ?? '');
        else
            objSession = JSON.parse(atob(storage.getItem(ID_APP) ?? ''));

        for (const key in objSession) {
            if (Object.prototype.hasOwnProperty.call(objSession, key)) {
                delete objSession[key];
            }
        }

        if (process.env.NODE_ENV === ReactMode.desenv)
            storage.setItem(ID_APP, JSON.stringify(objSession));
        else
            storage.setItem(ID_APP, btoa(unescape(encodeURIComponent(JSON.stringify(objSession)))));
    }

    return { set, get, remove, removeAll };
}

export const useStorage = (type: TypeManageStorage) => {
    return managerStorage(type);
}

export const session = managerStorage(TypeManageStorage.session);
export const local = managerStorage(TypeManageStorage.local);
