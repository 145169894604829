import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const GeneralInfo = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const GeneralInfoStyled = styled(GeneralInfo)`
  .cnpjAlert {
    width: 100%;
    padding: 18px;
    background-color: yellow;
    margin-top: 10px;
    display: flex;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    border-radius: 8px;
    .text {
      > div {
        margin-top: unset !important;
      }
    }
  }
  .review-row {
    display: flex;
    .review-column {
      min-width: 35%;
      > div {
        margin-top: 8px !important;
      }
    }
  }
`;