import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiConfig = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiConfigStyled = styled(AdminApiConfig)`
    padding: 32px 32px 0px 32px;
    
    .btn-footer {
        display: flex;
        padding: 16px 0px;
    }

    .hr-final {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        height: 1px;
        margin-top: 80px;
    }

    .get {
        padding: 8px 12px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
        border-radius: 4px;
    }
`