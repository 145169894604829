import { Input, Select, Tooltip } from "antd";
import Password from "antd/lib/input/Password";
import TextArea from "antd/lib/input/TextArea";
import TypographyGr1d from "components/portal/typography";
import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import { Controller, FieldValues } from "react-hook-form";
import { FormContextValues } from "react-hook-form/dist/contextTypes";
import { useEffectDisabled } from "utils/utils";
import { InputStyled } from "./index.styled";
import MaskedInput from "antd-mask-input";

export type InputGr1dProps = {
    style?: CSSProperties,
    label?: string | ReactNode,
    className?: string;
    placeholder?: string,
    helpLink?: {
        text: string,
        description: string
    },
    helpText?: {
        element: string | JSX.Element,
        onClick?(): void
    },
    disabled?: boolean,
    type?: 'masked-input' | 'input' | 'select' | 'password' | 'text-area' | 'password-input',
    dataSelect?: {
        description: any,
        value: any
    }[],
    mask?: string,
    value?: any,
    defaultValue?: any,
    onChange?(event: any): void
    onBlur?(event: any): void
    onKeyPress?(event: any): void
    onKeyDown?(event: any): void
    width?: string | number;
    showEye?: boolean,
    prefix?: JSX.Element,
    suffix?: JSX.Element,
    tabIndex?: number,
    dynamic?: {
        background?: 'colorPrimitiveBlack' | 'colorPrimitive700' | 'colorPrimitive600' | 'colorPrimitive500' | 'colorPrimitive400' | 'colorPrimitive300' | 'colorPrimitive200' |
        'colorPrimitive100' | 'colorPrimitive50' | 'colorPrimitiveWhite' | 'colorFeedbackPositive700' | 'colorFeedbackPositive600' | 'colorFeedbackPositive500' |
        'colorFeedbackPositive400' | 'colorFeedbackPositive300' | 'colorFeedbackPositive200' | 'colorFeedbackPositive100' | 'colorFeedbackPositiv50' |
        'colorFeedbackNegative700' | 'colorFeedbackNegative600' | 'colorFeedbackNegative500' | 'colorFeedbackNegative400' | 'colorFeedbackNegative300' |
        'colorFeedbackNegative200' | 'colorFeedbackNegative100' | 'colorFeedbackNegative50' |
        'colorBrandPrimary700' | 'colorBrandPrimary600' | 'colorBrandPrimary500' | 'colorBrandPrimary400' | 'colorBrandPrimary300' | 'colorBrandPrimary200' |
        'colorBrandPrimary100' | 'colorBrandPrimary50',
        height?: string
    },
    size?: '40px' | '44px',
    colorBrand?: boolean,
    form?: {
        form: FormContextValues<FieldValues>,
        name: string,
        validation?: {
            isRequired?: boolean,
            isEmail?: boolean,
            isPassword?: boolean
        }
    },
    inputRef?: React.MutableRefObject<any>,
    errorMessage?: string,
    forceRefreshFlag?: boolean,
    maskplaceholder?: string,
    maxLength?: number,
    autoComplete?: string
}

const { Option } = Select;

const InputGr1d: React.FC<InputGr1dProps> = (props) => {
    let prefix = props.prefix != null ? props.prefix : <></>;
    const type = props.type ?? 'input';
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const size = props.size ?? '44px';
    const [element, setElement] = useState<JSX.Element>(<></>);
    const [onChange, setOnChange] = useState<any>();

    useEffect(() => {
        if (props.form != null && props.form!.form != null) {
            if (props.form.form.errors[props.form.name] != null) {
                setErrorMsg((props.form.form.errors[props.form.name]! as any).message);
            } else {
                setErrorMsg(undefined);
            }
        }
    }, [props.form]);

    useEffect(() => {
        setErrorMsg(props.errorMessage)
    }, [props.errorMessage])

    const createRules = useCallback(() => {
        let rulesTemp: any = {};

        if (props.form?.validation != null) {
            if (props.form.validation.isRequired === true) {
                rulesTemp = { ...rulesTemp, required: { value: true, message: 'Campo obrigatório' } };
            }
            if (props.form.validation.isPassword === true) {
                rulesTemp = {
                    ...rulesTemp, pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&:;_+./-])[A-Za-z\d@$!%#*?&:;_+./-]{8,}/,
                        message: 'Senha deve conter: no mínimo oito caracteres, no máximo cem caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial'
                    }
                };
            }
            if (props.form.validation.isEmail === true) {
                rulesTemp = {
                    ...rulesTemp, pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Email Inválido'
                    }
                };
            }
        }

        return rulesTemp;
    }, [props.form?.validation]);

    useEffectDisabled(() => {
        if (props.form?.validation != null) {
            props.form!.form.control.unregister(props.form!.name);
            props.form!.form.control.register(props.form!.name, createRules());
        }

        switch (type) {
            case 'masked-input':
                setElement(<MaskedInput mask={props.mask as string} placeholderChar={props.maskplaceholder} name={props.form?.name} disabled={props.disabled} onChange={props.onChange} ref={props.inputRef} tabIndex={props.tabIndex} defaultValue={props.defaultValue} onKeyDown={props.onKeyDown} onKeyPress={props.onKeyPress} onBlur={props.onBlur} value={props.value} className={`bodySMBase ${props.colorBrand === true ? 'brand' : ''}`} suffix={props.suffix} prefix={prefix} size="large" placeholder={props.placeholder} />);
                if (props.onChange != null)
                    setOnChange((v: any) => {
                        if (v != null)
                            return v[0].target.value
                    });
                else
                    setOnChange((v: any) => { if (v != null) return v[0].target.value });
                break;
            case 'input': {
                setElement(<Input maxLength={props.maxLength} name={props.form?.name} disabled={props.disabled} onChange={props.onChange} ref={props.inputRef} tabIndex={props.tabIndex} defaultValue={props.defaultValue} onKeyDown={props.onKeyDown} onKeyPress={props.onKeyPress} onBlur={props.onBlur} value={props.value} className={`bodySMBase ${props.colorBrand === true ? 'brand' : ''}`} suffix={props.suffix} prefix={prefix} size="large" placeholder={props.placeholder} />);
                if (props.onChange != null)
                    setOnChange((v: any) => {
                        if (v != null)
                            return v[0].target.value
                    });
                else
                    setOnChange((v: any) => { if (v != null) return v[0].target.value });
                break;
            }
            case 'password-input': {
                setElement(<Input maxLength={props.maxLength} onChange={props.onChange} name={props.form?.name} ref={props.inputRef} tabIndex={props.tabIndex} defaultValue={props.defaultValue}
                    onKeyDown={props.onKeyDown} onKeyPress={props.onKeyPress} onBlur={props.onBlur} value={props.value} type='password'
                    className={`bodySMBase ${props.colorBrand === true ? 'brand' : ''}`} suffix={props.suffix} prefix={prefix} disabled={props.disabled}
                    size="large" placeholder={props.placeholder} />);
                if (props.onChange != null)
                    setOnChange((v: any) => {
                        if (v != null)
                            return v[0].target.value
                    });
                else
                    setOnChange((v: any) => { if (v != null) return v[0].target.value });
                break;
            }
            case 'password': {
                setElement(<Password autoComplete={props.autoComplete} maxLength={props.maxLength} ref={props.inputRef} tabIndex={props.tabIndex} defaultValue={props.defaultValue}
                    onKeyDown={props.onKeyDown} onKeyPress={props.onKeyPress} onBlur={props.onBlur} value={props.value}
                    className='bodySMBase' prefix={prefix} disabled={props.disabled} size="large" visibilityToggle={props.showEye}
                    placeholder={props.placeholder} suffix={props.suffix} />);
                if (props.onChange != null)
                    setOnChange((v: any) => {
                        props.onChange!(v);
                        if (v != null)
                            return v[0].target.value
                    });
                else
                    setOnChange((v: any) => { if (v != null) return v[0].target.value });
                break;
            }
            case 'select': {
                setElement(<Select tabIndex={props.tabIndex} defaultValue={props.defaultValue} onBlur={props.onBlur} value={props.value} size="large" placeholder={props.placeholder} style={{ width: props.width }}>
                    {props.dataSelect?.map((item) =>
                        <Option key={item.value} value={item.value}>{item.description}</Option>
                    )}
                </Select>);
                if (props.onChange != null)
                    setOnChange((v: any) => { props.onChange!(v); if (v != null) return v[0] });
                else
                    setOnChange((v: any) => { if (v != null) return v[0] });
                break;
            }
            case 'text-area': {
                setElement(<TextArea disabled={props.disabled} tabIndex={props.tabIndex} value={props.value} defaultValue={props.defaultValue} size="large" onBlur={props.onBlur} placeholder={props.placeholder} style={{ width: props.width }} />);
                if (props.onChange != null)
                    setOnChange((v: any) => { props.onChange!(v); if (v != null) return v[0].target.value });
                else
                    setOnChange((v: any) => { if (v != null) return v[0].target.value });
                break;
            }
        }
    }, [props.value, props.dataSelect, props.placeholder, props.type, props.errorMessage, props.forceRefreshFlag, props.suffix]);

    return <InputStyled className={props.className} size={size} background={props.dynamic?.background} height={props.dynamic?.height} style={props.style}>
        {(props.label != null || props.helpLink != null) && <div className={`text ${props.disabled ? 'disabled' : ''}`}>
            {props.label && <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                {props.label}
            </TypographyGr1d>}
            {props.helpLink && <div className='helper-link'>
                <Tooltip placement="bottomRight" trigger='click' title={props.helpLink.description}>
                    <div>{props.helpLink.text}</div>
                </Tooltip>
            </div>}
        </div>}
        <TypographyGr1d style={{ width: props.width }} className={`btn ${props.dynamic != null ? 'background-dynamic' : ''} ${errorMsg != null ? 'error-field' : ''}`} component='bodySM' font="Manrope">
            {props.form != null && props.form!.form != null ?
                <Controller
                    name={props.form.name}
                    control={props.form.form.control}
                    as={element}
                    onChange={onChange}
                    defaultValue={props.defaultValue}
                    mode={'onChange'}
                /> : element}
        </TypographyGr1d>
        <TypographyGr1d style={{ bottom: props.helpText?.element ? '-15px' : 'initial' }} className={`error-msg ${props.colorBrand === true ? 'brand' : ''}`} component='caption'>
            {errorMsg}
        </TypographyGr1d>
        {props.helpText?.element && <div className={`text ${props.disabled ? 'disabled' : ''}`}>
            <div className={`label ${props.helpText.onClick && 'point'}`}>
                {props.helpText.element}
            </div>
        </div>}
    </InputStyled>;
}

export default InputGr1d;