import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography"
import { useEffect, useState } from "react";
import { FieldValues, FormContextValues } from "react-hook-form";
import { isValidCpf, isValidPhoneNumber } from "utils/utils";
import ApiInfoStyled from "./apiInfo.styled"

type props = {
  form: FormContextValues<FieldValues>;
  userFormData: any;
  next: () => void;
  setUserInfo: (userInfo: any) => void; 
};

const UserInfo: React.FC<props> = ({ form, userFormData, setUserInfo, next }) => {
  const { handleSubmit } = form;

  const [phone, setPhone] = useState<string>('');
  const [lastPhone, setLastPhone] = useState<string>('');
  const [mask, setMask] = useState<string>('(11) 1111-1111');

  useEffect(() => {
    if (lastPhone.length > phone.length) {
      setMask('(11) 1111-1111');
    } else if (phone.length < 10) {
      setMask('(11) 1111-1111');
    } else {
      setMask('(11) 11111-1111')
    }
  }, [phone, lastPhone])

  const formConfigData: ConfigFormItem[] = [
    {
      id: 'cpf',
      name: 'cpf',
      typeComponent: 'masked-input',
      mask: "111.111.111-11",
      label: 'CPF',
      maxWidth: 200,
      defaultValue: '097.120.734-83',
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidCpf(value);
        }
      },
    },
    {
      name: 'phone',
      typeComponent: 'masked-input',
      label: 'Número de celular',
      mask: mask,
      maxWidth: 200,
      onChange: (v) => {
        setLastPhone(phone);
        setPhone((v.target.value as string).replaceAll(/_|-|[)]|[(]/g, ''));
      },
      onBlur: (v) => {
        if (phone.length === 11) {
          setMask('(11) 1111-1111');
          setTimeout(() => {
            form.setValue('phone', phone);
            setMask('(11) 11111-1111');
          }, 0)
        }
      },
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidPhoneNumber(value);
        }
      }
    },
  ]

  const save = (data: any) => {
    setUserInfo(data);
    next();
  }

  return <ApiInfoStyled>
    <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope" color="colorPrimitiveBlack">Completar cadastro</TypographyGr1d>
    <TypographyGr1d fontSize="14px" fontWeight="400" font="Manrope" color="colorPrimitive500" style={{ marginTop: "20px" }}>Antes de prosseguir para a contratação da API, é preciso completar o seu cadastro de usuário.</TypographyGr1d>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={form} config={formConfigData} />
      <div style={{ justifyContent: 'flex-end' }} className="buttons">
        <ButtonGr1d htmlType='submit'>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
  </ApiInfoStyled>
}

export default UserInfo