import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Header = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const HeaderLayoutStyled = styled(Header)`
    position: relative;
    padding: 10px 0px;
    height: 64px;
    border-bottom: 1px solid #DBDDE5;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};

    > div {
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin: auto;
        height: 100%;
    }

    .logo-sm {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .logo-div {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .logo {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};

        > svg {
            height: 26px;
            width: 152px;
        }
    }

    .actions {
        flex: 1;
        text-align: center;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 16px;

        > div {
            width: 100%;
        }
    }

    .menu-btn {
        display: none;
    }

    .header-btn {
        right: 20px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            margin-left: 16px;
        }
    }

    .actions.sm {
        display: none;
    }

    .header-btn.sm {
        display: none;
    }

    .menu {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        font-size: 25px;
        cursor: pointer;
    }

    .menu.md,
    .menu.lg {
        display: none;
    }

    .new-menu {
        position: absolute;
    }

    .portal.sm {
        display: none;
    }

    .popover.sm {
        display: none;
    }
`

export const HeaderDrawerLayoutStyled = styled(Header)`
    .all-drawer {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    }

    .header {
        height: 60px;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        align-items: center;
        padding-left: 32px;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .logo-icon {
        flex: 1;
        font-size: 130px;
    }

    .btn-collapse {
        z-index: 10;
        margin-right: 10px;
    }

    .body {
        flex: 1;
        padding-top: 20px;
        padding: 20px 10px 20px 10px;
        border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .select-no-select {
        width: 100%;
        margin-bottom: 10px;
    }

    .footer {
        padding-top: 10px;
        display: flex;
        margin: 0 10px 0 10px;
        align-items: center;

        .text {
            padding-left: 10px;
            flex: 1;
        }
    }

    .header-btn {
        display: flex;
        width: 100%;
    }
`