import { Breadcrumb, Spin } from "antd";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import TypographyGr1d from "components/portal/typography";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AdminAccountStyled } from "./account.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as add } from "assets/svg/add.svg";
import { ReactComponent as editPencil } from "assets/svg/edit-pencil.svg";
import ButtonGr1d from "components/portal/button";

const PaymentAccount: React.FC = () => {
  const form = useForm();
  const { handleSubmit } = form;

  const [screen, setScreen] = useState<number>(3);
  const [elementForm, setElementForm] = useState<any>();
  const [, setUpdate] = useState<boolean>(false);

  const save = (data: any) => {
    console.log(data);
  }

  const reset = () => {
    form.reset();
    setUpdate(x => !x);
  }

  useEffect(() => {
    if (screen === 1) {
      setElementForm(formConfig);
    } else if (screen === 2) {
      setElementForm(formConfigForm);
    } else if (screen === 3) {
      setElementForm(formConfigAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  const formConfig: ConfigFormItem[] = [
    {
      name: 'add',
      typeComponent: 'custom',
      noLabel: true,
      element: <div>
        <TypographyGr1d component='subtitleMD' color='colorPrimitive500' style={{ paddingBottom: 12 }}>
          Conta ainda não cadastrada
        </TypographyGr1d>
        <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack' style={{ paddingBottom: 32 }}>
          Cadastre uma conta bancária para receber seus faturamentos.
        </TypographyGr1d>
        <ButtonGr1d onClick={() => setScreen(2)} style={{ display: 'flex', justifyContent: 'flex-end' }} type='primitive'>
          <Icon component={add} />&nbsp;&nbsp;Adicionar conta
        </ButtonGr1d>
      </div>
    },
  ]

  const formConfigForm: ConfigFormItem[] = [
    {
      name: 'bankId',
      label: 'Código do banco',
      typeComponent: 'select',
      placeholder: 'Digite ou selecione o código do banco',
      data: []
    },
    {
      name: 'agency',
      label: 'Agência',
      typeComponent: 'input',
      placeholder: 'Digite a agência',
      span: 16,
    },
    {
      name: 'agencyDigit',
      label: 'Dígito',
      typeComponent: 'input',
      placeholder: 'Dígito',
      span: 8,
    },
    {
      name: 'account',
      label: 'Conta',
      typeComponent: 'input',
      placeholder: 'Digite a conta',
      span: 16,
    },
    {
      name: 'agencyDigit',
      label: 'Dígito',
      typeComponent: 'input',
      placeholder: 'Dígito',
      span: 8,
    },
    {
      name: 'btn',
      typeComponent: 'custom',
      element: <div style={{ display: 'flex' }}>
        <ButtonGr1d style={{ flex: 1 }} type='primitive'>
          Cancelar
        </ButtonGr1d>
        <ButtonGr1d type='primary'>
          Salvar conta
        </ButtonGr1d>
      </div>
    },
  ]

  const formConfigAccount: ConfigFormItem[] = [
    {
      name: 'account',
      typeComponent: 'custom',
      noLabel: true,
      element: <div>
        <TypographyGr1d component='subtitleMD' color='colorBrandPrimary400' style={{ paddingBottom: 8 }}>
          01 - Banco do Brasil
        </TypographyGr1d>
        <TypographyGr1d component='subtitleSM' color='colorPrimitive400' style={{ paddingBottom: 8 }}>
          Conta Corrente
        </TypographyGr1d>
        <div style={{ marginTop: 0, marginBottom: 12 }} className='hr' />
        <div style={{ display: 'flex' }}>
          <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack' style={{ paddingRight: 32 }}>
            Agência: 9800-0
          </TypographyGr1d>
          <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack'>
            Conta: 205190-1
          </TypographyGr1d>
        </div>
        <ButtonGr1d onClick={() => setScreen(2)} style={{ display: 'flex', justifyContent: 'flex-end' }} type='primary'>
          <Icon component={editPencil} />&nbsp;&nbsp;Editar conta
        </ButtonGr1d>
      </div>
    },
  ]

  return <AdminAccountStyled>
    <Spin spinning={false}>
      <div className='header'>
        <TypographyGr1d component='bodySM'>
          <Breadcrumb>
            <Breadcrumb.Item>Pagamentos</Breadcrumb.Item>
            <Breadcrumb.Item>Conta bancária</Breadcrumb.Item>
          </Breadcrumb>
        </TypographyGr1d>
        <TypographyGr1d component='headingMD' style={{ paddingTop: 16 }}>
          Conta bancária
        </TypographyGr1d>
      </div>
      <div style={{ marginTop: 32, marginBottom: 0 }} className='hr' />
      <FormAdmin onSubmit={handleSubmit(save)} onReset={reset}>
        <FormItensAdmin classNameCol='account-div' form={form} config={elementForm} labelTitle={{ title: 'Dados da conta', subtitle: 'Ao tornar-se provedora, esses serão os dados da conta que receberá o faturamento das APIs Fornecidas pela sua empresa.' }} />
      </FormAdmin>
    </Spin>
  </AdminAccountStyled >;
}

export default PaymentAccount;