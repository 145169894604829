import styled from "styled-components"

const PayableDetail = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const PayableDetailStyled = styled(PayableDetail)`
    .total {
        display: flex;
        gap: 32px;
        padding-bottom: 40px;
    }

    .method {
        padding: 8px 12px;
        margin-right: 8px;
        border-radius: 4px;
    }

    .method.POST {
        background-color: #49cc90;
    }

    .method.DELETE {
        background-color: #f93e3e;
    }

    .method.PUT {
        background-color: #fca130;
    }

    .method.PATCH {
        background-color: #50e3c2;
    }

    .method.GET {
        background-color: #61affe;
    }
`