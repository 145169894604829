import { CEPModel } from "models/cep";
import { CNPJModelResponse } from "models/cnpj";
import { CompanyContactModel, CompanyModel, CreditCardModel } from "models/company";
import { MemberModel } from "models/member";
import { PortalActionType } from "./action";

export interface PortalState {
    loadingCnpj: boolean;
    cnpj?: CNPJModelResponse;
    loadingCep: boolean;
    cep?: CEPModel;
    loadingSave: boolean;
    saveSuccess: boolean,
    item?: CompanyModel;
    loadingContacts: boolean;
    loadingSaveContacts: boolean;
    contacts?: CompanyContactModel[];
    loadingItem: boolean;
    creditCard?: CreditCardModel;
    creditCardLoading: boolean;
    creditCardSaving: boolean;
    creditCardSavingSuccess: boolean;
    creditCardSavingError: boolean;
    orgAdminAdded: boolean;
    loadingOrgMembers: boolean;
    orgMembers: MemberModel[];
    addOrgMembersLoading: boolean;
    addOrgMembersSuccess: boolean;
    addOrgMemberErrorCode?: number;
    updatingOrgMember: boolean;
    updateOrgMemberSuccess: boolean;
    removingOrgMember: boolean,
    orgMemberRemoved: boolean,
    pageToRedirectAfterCreateCompany?: string | undefined;
    requestingApiConsume: boolean;
    requestApiConsumeSuccess: boolean;
    requestApiConsumeError: boolean;
}

export const INITIAL_STATE = {
    loadingCnpj: false,
    loadingCep: false,
    loadingSave: false,
    saveSuccess: false,
    creditCardLoading: false,
    creditCardSaving: false,
    creditCardSavingError: false,
    creditCardSavingSuccess: false,
    loadingItem: false,
    loadingContacts: false,
    loadingSaveContacts: false,
    orgAdminAdded: false,
    loadingOrgMembers: false,
    orgMembers: [],
    addOrgMembersLoading: false,
    addOrgMembersSuccess: false,
    updatingOrgMember: false,
    updateOrgMemberSuccess: false,
    removingOrgMember: false,
    orgMemberRemoved: false,
    requestingApiConsume: false,
    requestApiConsumeSuccess: false,
    requestApiConsumeError: false,
}

export function companyReducer(state: PortalState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case PortalActionType.GET_CNPJ:
            state = { ...state, loadingCnpj: true }
            break;
        case PortalActionType.GET_CNPJ_SUCCESS:
            state = { ...state, loadingCnpj: false, cnpj: action.payload }
            break;
        case PortalActionType.GET_CNPJ_ERROR:
            state = { ...state, loadingCnpj: false }
            break;
        case PortalActionType.GET_CEP:
            state = { ...state, loadingCep: true }
            break;
        case PortalActionType.GET_CEP_SUCCESS:
            state = { ...state, loadingCep: false, cep: action.payload }
            break;
        case PortalActionType.GET_CEP_ERROR:
            state = { ...state, loadingCep: false }
            break;
        case PortalActionType.GET_COMPANY:
            state = { ...state, item: undefined, loadingItem: true }
            break;
        case PortalActionType.GET_COMPANY_SUCCESS:
            state = { ...state, item: action.payload, loadingItem: false }
            break;
        case PortalActionType.GET_COMPANY_ERROR:
            state = { ...state, loadingItem: false }
            break;
        case PortalActionType.POST_COMPANY:
        case PortalActionType.UPDATE_COMPANY:
            state = { ...state, loadingSave: true, saveSuccess: false }
            break;
        case PortalActionType.POST_COMPANY_SUCCESS:
        case PortalActionType.UPDATE_COMPANY_SUCCESS:
            state = { ...state, loadingSave: false, saveSuccess: true, item: action.payload }
            break;
        case PortalActionType.GET_CONTACTS:
            state = { ...state, item: undefined, loadingItem: true }
            break;
        case PortalActionType.GET_CONTACTS_SUCCESS:
            state = { ...state, contacts: action.payload, loadingItem: false }
            break;
        case PortalActionType.GET_CONTACTS_ERROR:
            state = { ...state, loadingItem: false }
            break;
        case PortalActionType.POST_CONTACTS:
            state = { ...state, loadingSaveContacts: true }
            break;
        case PortalActionType.POST_CONTACTS_SUCCESS:
            state = { ...state, loadingSaveContacts: false, contacts: action.payload }
            break;
        case PortalActionType.POST_CONTACTS_ERROR:
            state = { ...state, loadingSaveContacts: false }
            break;
        case PortalActionType.POST_COMPANY_ERROR:
        case PortalActionType.UPDATE_COMPANY_ERROR:
            state = { ...state, loadingSave: false }
            break;
        case PortalActionType.POST_CREDIT_CARD:
            state = { ...state, creditCardSaving: true, creditCardSavingSuccess: false }
            break;
        case PortalActionType.POST_CREDIT_CARD_ERROR:
            state = { ...state, creditCardSavingError: true, creditCardSaving: false }
            break;
        case PortalActionType.POST_CREDIT_CARD_SUCCESS:
            state = { ...state, creditCardSaving: false, creditCardSavingError: false, creditCardSavingSuccess: true, creditCard: action.payload }
            break;
        case PortalActionType.GET_CREDIT_CARD_SUCCESS:
            state = { ...state, creditCard: action.payload }
            break;
        case PortalActionType.GET_MEMBERS:
            state = { ...state, orgMembers: [], loadingOrgMembers: true }
            break;
        case PortalActionType.GET_MEMBERS_SUCCESS:
            state = { ...state, orgMembers: action.payload, loadingOrgMembers: false }
            break;
        case PortalActionType.GET_MEMBERS_ERROR:
            state = { ...state, loadingOrgMembers: false }
            break;
        case PortalActionType.ADD_ORG_MEMBER:
            state = { ...state, addOrgMemberErrorCode: undefined, addOrgMembersSuccess: false, addOrgMembersLoading: true }
            break;
        case PortalActionType.ADD_ORG_MEMBER_SUCCESS:
            state = { ...state, addOrgMembersSuccess: true, addOrgMembersLoading: false }
            break;
        case PortalActionType.ADD_ORG_MEMBER_ERROR:
            state = { ...state, addOrgMemberErrorCode: action.payload, addOrgMembersLoading: false }
            break;
        case PortalActionType.UPDATE_ORG_MEMBER:
            state = { ...state, updateOrgMemberSuccess: false, updatingOrgMember: true }
            break;
        case PortalActionType.UPDATE_ORG_MEMBER_SUCCESS:
            state = { ...state, updateOrgMemberSuccess: true, updatingOrgMember: false }
            break;
        case PortalActionType.UPDATE_ORG_MEMBER_ERROR:
            state = { ...state, updatingOrgMember: false }
            break;
        case PortalActionType.REMOVE_MEMBER:
            state = { ...state, removingOrgMember: true, orgMemberRemoved: false }
            break;
        case PortalActionType.REMOVE_MEMBER_ERROR:
            state = { ...state, removingOrgMember: false }
            break;
        case PortalActionType.REMOVE_MEMBER_SUCCESS:
            state = { ...state, removingOrgMember: false, orgMemberRemoved: true }
            break;
        case PortalActionType.SET_PAGE_REDIRECT_AFTER_CREATE_COMPANY:
            state = { ...state, pageToRedirectAfterCreateCompany: action.payload }
            break;
        case PortalActionType.REQUEST_COMPANY_API_CONSUME:
            state = { ...state, requestingApiConsume: true, requestApiConsumeSuccess: false, requestApiConsumeError: false }
            break;
        case PortalActionType.REQUEST_COMPANY_API_CONSUME_SUCCESS:
            state = { ...state, requestApiConsumeSuccess: true, requestingApiConsume: false }
            break;
        case PortalActionType.REQUEST_COMPANY_API_CONSUME_ERROR:
            state = { ...state, requestApiConsumeError: true, requestingApiConsume: false }
    }

    return state;
}