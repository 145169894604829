import { useEffect } from 'react';
import SwaggerUI from 'swagger-ui';
import { useState } from 'react';

type props = {
  spec?: string,
  gateway?: string,
  apiKey?: string
}

const SwaggerUIGr1d: React.FC<props> = (props) => {
  const [ui, setUi] = useState<SwaggerUI>();
  const [securityName, setSecurityName] = useState<string>();

  useEffect(() => {
    if (props.spec != null) {
      let objs: any = (JSON.parse(props.spec) as any);
      if (props.gateway === 'WSO2') {
        objs.components.securitySchemes = {};
        objs.components.securitySchemes["default"] = {
          "type": "apiKey",
          "name": "apikey",
          "in": "header"
        };
      } else if (props.gateway === 'GRAVITEE') {
        let securityNameTemp = Object.keys(objs.components.securitySchemes)[0];
        objs.components.securitySchemes[securityNameTemp] = {
          "type": "apiKey",
          "name": "X-Api-Key",
          "in": "header"
        };
        setSecurityName(securityNameTemp);
      }

      const uiTemp = SwaggerUI({
        dom_id: '#swagger-ui',
        spec: objs
      })
      setUi(uiTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.spec])

  useEffect(() => {
    if (ui != null && props.apiKey != null) {
      ui.preauthorizeApiKey(props.gateway === 'GRAVITEE' ? securityName : "default", props.apiKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui, props.apiKey])

  return (
    <div id='swagger-ui'>
    </div>
  )
}

export default SwaggerUIGr1d