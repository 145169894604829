import { Login } from "models/login";
import { Register } from "models/register";
import { createApiService } from "service";

export const createLoginService = () => {
    const service = createApiService('/core/api/v1');

    const postRegister = (register: Partial<Register>, ignoreError?: boolean | undefined): Promise<any> => {
        return service.post(`/user${ignoreError ? '?ignoreError=true' : ''}`, register);
    }

    const postInvitedRegister = (register: Partial<Register>, token: string): Promise<any> => {
        return service.put('user', register, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    const postLogin = (login: Login): Promise<any> => {
        return service.post('/auth', login);
    }

    const forgotPassword = (email: string): Promise<any> => {
        return service.post('/auth/send-reset-password', { username: email.toLowerCase() });
    }

    const verifyEmail = (email: string): Promise<any> => {
        return service.post('/auth/send-email-verify', { username: email.toLowerCase() });
    }

    const postRefreshToken = (refreshtoken: string): Promise<any> => {
        return service.post('/auth/refreshToken', { refreshtoken });
    }

    const postLogout = (refreshtoken: string): Promise<any> => {
        return service.post('/auth/logout', { refreshtoken });
    }

    const getUserEmail = (id: string): Promise<any> => {
        return service.get('/user/' + id);
    }

    const acceptPolicyChange = (id: string): Promise<any> => {
        return service.post('/terms-policies/accept/' + id, {
            type: [
                'termo',
                'politica'
            ]
        });
    }

    const addMember = (register: Partial<Register>): Promise<any> => {
        return service.post(`/user/addMember`, register);
    }

    return { postRegister, postInvitedRegister, postLogin, forgotPassword, verifyEmail, postRefreshToken, postLogout, getUserEmail, acceptPolicyChange, addMember };
}