import { Layout } from "antd"
import styled from "styled-components"

const AdminLayout = (props: any) =>
    <Layout style={props.style} className={props.className}>
        {props.children}
    </Layout>

export const AdminLayoutStyled = styled(AdminLayout)`
    min-height: 100vh;
    position: relative;
    overflow-y: hidden;
    flex-direction: row;

    .layout-admin {
        overflow-y: auto;
    }

    .g-content {
        background: #ffffff;
        padding: none;
        border-radius: 2px;
        position: relative;
        overflow-x: hidden;
        box-shadow: 1px 0px 8px -1px rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
    }

    .g-content-admin {
        background: #ffffff;
        position: relative;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .menu-add {
        position: absolute;
        right: 15px;
        top: 70px;
    }
`