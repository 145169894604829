import { Breadcrumb, Spin, Tabs } from "antd";
import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { CompanyStyled } from "./index.styled";
import CompanyGeneral from "./generalInfo";
import CompanyContact from "./contact";
import { useDispatch, useSelector } from "react-redux";
import { CompanyModel } from "models/company";
import { useAction } from "store";
import { companyActions } from "store/admin/company/action";
import { UserModel } from "models/user";

const { TabPane } = Tabs;

const Company: React.FC = () => {
  const dispatch = useDispatch();
  const action = useAction(companyActions());
  const [screen, setScreen] = useState<string>('1');
  const company: CompanyModel | undefined = useSelector((state: any) => state.company?.item);
  const loadingItem: boolean = useSelector((state: any) => state.company?.loadingItem);
  const user: UserModel | undefined = useSelector((state: any) => state.login?.user);

  useEffect(() => {
    dispatch(action.getCompany());
  }, [dispatch, action])

  return <CompanyStyled>
    <Spin spinning={loadingItem}>
      <div className='header'>
        <TypographyGr1d component='bodySM'>
          <Breadcrumb>
            <Breadcrumb.Item>Minha Empresa</Breadcrumb.Item>
            <Breadcrumb.Item>Dados da empresa</Breadcrumb.Item>
          </Breadcrumb>
        </TypographyGr1d>
        <TypographyGr1d component='headingMD' style={{ paddingTop: 16 }}>
          Dados da empresa
        </TypographyGr1d>
      </div>
      <div style={{ marginTop: 32, marginBottom: 0 }} className='hr' />
      <div className='options'>
        <div className='tab-options'>
          <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack'>
            <Tabs defaultValue={screen} onChange={(v) => setScreen(v)}>
              <TabPane tab="Informações gerais" key="1" />
              <TabPane disabled={company == null || !user?.isAdmin} tab="Informações de contato" key="2" />
            </Tabs>
          </TypographyGr1d>
        </div>
      </div>
      <div className='content'>
        <div style={{ display: screen === '1' ? 'initial' : 'none' }}><CompanyGeneral /></div>
        <div style={{ display: screen === '2' ? 'initial' : 'none' }}><CompanyContact /></div>
        {/* {screen === '2' && <AdminApiConfig />} */}
      </div>
    </Spin>
  </CompanyStyled >;
}

export default Company;