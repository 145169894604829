import { Button } from "antd";
import React, { CSSProperties, ReactNode } from "react";
import { ButtonStyled } from "./index.styled";
import TypographyGr1d from '../typography/index'
import Icon from '@ant-design/icons';

export type ButtonGr1dProps = {
    style?: CSSProperties,
    children: ReactNode,
    icon?: any,
    href?: string,
    background?: 'black' | 'white',
    type?: 'primitive' | 'primary' | 'secondary' | 'tertiary' | 'icon' | 'danger' | 'dangersecondary' | 'feedback' | 'brand' | 'dashed'
    // color?: 'colorPrimitiveBlack' | 'colorPrimitive700' | 'colorPrimitive600' | 'colorPrimitive500' | 'colorPrimitive400' | 'colorPrimitive300' | 'colorPrimitive200' |
    // 'colorPrimitive100' | 'colorPrimitive50' | 'colorPrimitiveWhite' | 'colorFeedbackPositive700' | 'colorFeedbackPositive600' | 'colorFeedbackPositive500' |
    // 'colorFeedbackPositive400' | 'colorFeedbackPositive300' | 'colorFeedbackPositive200' | 'colorFeedbackPositive100' | 'colorFeedbackPositiv50' |
    // 'colorFeedbackNegative700' | 'colorFeedbackNegative600' | 'colorFeedbackNegative500' | 'colorFeedbackNegative400' | 'colorFeedbackNegative300' |
    // 'colorFeedbackNegative200' | 'colorFeedbackNegative100' | 'colorFeedbackNegative50' |
    // 'colorBrandPrimary700' | 'colorBrandPrimary600' | 'colorBrandPrimary500' | 'colorBrandPrimary400' | 'colorBrandPrimary300' | 'colorBrandPrimary200' |
    // 'colorBrandPrimary100' | 'colorBrandPrimary50',
    font?: {
        type: 'Base' | 'Variant',
        style: 'headingXL' | 'headingLG' | 'headingMD' | 'headingSM' | 'headingXS' | 'subtitleLG' | 'subtitleMD' | 'subtitleSM' | 'paragraph' | 'bodyLG' | 'bodySM' |
        'caption' | 'overline' | 'action'
    },
    disabled?: boolean,
    width?: number | string,
    onClick?: (e: any) => void,
    loading?: boolean,
    isCenter?: boolean,
    htmlType?: 'button' | 'submit' | 'reset',
    className?: string
}

const ButtonGr1d: React.FC<ButtonGr1dProps> = (props) => {
    let classFont = 'actionBase';
    const background = props.background ?? 'white';
    const type = props.type ?? 'primary';
    const htmlType = props.htmlType ?? 'button';
    if (props.font) {
        classFont = props.font.style + props.font.type;
    }
    return <ButtonStyled className={props.className} style={props.style}>
        <TypographyGr1d font="Manrope" fontWeight="700" fontSize="14px" lineHeight="100%">
            <div className={background}>
                <Button loading={props.loading} htmlType={htmlType} disabled={props.disabled} href={props.href} type={props.href ? 'link' : undefined} onClick={props.onClick} className={type + ' ' + classFont + ' ' +  (props.isCenter ? 'center' : '') + ' ' + (props.loading ? 'removeBorders': '')} size='large' style={{ width: props.width }}>
                    <span>
                        <span style={{'display': 'flex'}} >
                            {props.icon && <Icon component={props.icon} style={{'paddingRight': '5px'}} />}
                            {props.children}
                        </span>
                    </span>
                </Button>
            </div>
        </TypographyGr1d>
    </ButtonStyled>;
}

export default ButtonGr1d;