import { ApiModel } from "models/api";
import { CNPJModelResponse } from "models/cnpj";
import { CompanyContactModel } from "models/company";
import { PaymentIntentModel } from "models/prepaid";
import { PortalPrepaidActionType } from "./action";

export interface AdminApiState {
    api?: ApiModel,
    apiLoading: boolean,
    unlockScreen: boolean,
    contactsLoading: boolean,
    contacts?: CompanyContactModel[];
    loadingCnpj: boolean,
    cnpj?: CNPJModelResponse,
    loadingSave: boolean,
    loadingPayment: boolean,
    payment?: PaymentIntentModel,
    price: number
}

export const INITIAL_STATE = {
    apiLoading: false,
    unlockScreen: false,
    contactsLoading: true,
    loadingCnpj: false,
    loadingSave: false,
    loadingPayment: false,
    price: 0
}
export function prepaidReducer(state: AdminApiState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case PortalPrepaidActionType.GET_API:
            state = { ...state, apiLoading: true, api: undefined }
            break;
        case PortalPrepaidActionType.GET_API_SUCCESS:
            state = { ...state, apiLoading: false, api: action.payload }
            break;
        case PortalPrepaidActionType.GET_API_ERROR:
            state = { ...state, apiLoading: false }
            break;
        case PortalPrepaidActionType.GET_PAYMENT:
            state = { ...state, loadingPayment: true, payment: undefined }
            break;
        case PortalPrepaidActionType.GET_PAYMENT_SUCCESS:
            state = { ...state, loadingPayment: false, payment: action.payload }
            break;
        case PortalPrepaidActionType.GET_PAYMENT_ERROR:
            state = { ...state, loadingPayment: false, payment: undefined }
            break;
        case PortalPrepaidActionType.UNLOCK_SCREEN:
            state = { ...state, unlockScreen: action.payload }
            break;
        case PortalPrepaidActionType.GET_CONTACTS:
            state = { ...state, contactsLoading: true, contacts: undefined }
            break;
        case PortalPrepaidActionType.GET_CONTACTS_SUCCESS:
            state = { ...state, contactsLoading: false, contacts: action.payload }
            break;
        case PortalPrepaidActionType.GET_CONTACTS_ERROR:
            state = { ...state, contactsLoading: false }
            break;
        case PortalPrepaidActionType.GET_CNPJ:
            state = { ...state, loadingCnpj: true }
            break;
        case PortalPrepaidActionType.GET_CNPJ_SUCCESS:
            state = { ...state, loadingCnpj: false, cnpj: action.payload }
            break;
        case PortalPrepaidActionType.GET_CNPJ_ERROR:
            state = { ...state, loadingCnpj: false }
            break;
        case PortalPrepaidActionType.SAVING:
            state = { ...state, loadingSave: true }
            break;
        case PortalPrepaidActionType.SAVING_SUCCESS:
            state = { ...state, loadingSave: false }
            break;
        case PortalPrepaidActionType.SAVING_ERROR:
            state = { ...state, loadingSave: false }
            break;
        case PortalPrepaidActionType.GET_API_PRICE:
            state = { ...state, price: 0 }
            break;
        case PortalPrepaidActionType.GET_API_PRICE_SUCCESS:
            state = { ...state, price: action.payload }
            break;
        case PortalPrepaidActionType.GET_API_PRICE_ERROR:
            state = { ...state, price: 0 }
            break;
    }

    return state;
}