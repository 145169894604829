import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiMarketplace = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiMarketplaceStyled = styled(AdminApiMarketplace)`
    padding: 32px 32px 0px 32px;

    .hr-final {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        height: 1px;
        margin-top: 80px;
    }

    .drag-upload-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 64px;
        margin-bottom: 54px;
    }

    .drag-upload {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
        border-radius: 8px;
        width: 200px;
        height: 200px;
        background-size: 100%;
        position: relative;
    }

    .file-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        .anticon {
            font-size: 36px;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            padding: 22px;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            border-radius: 100px;
            margin-bottom: 24px;
        }
    }

    .doc {
        padding: 24px;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        box-sizing: border-box;
        border-radius: 8px;
    }

    .marketplace-footer {
        display: flex;
        padding: 16px 0px;
    }
`