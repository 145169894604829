import styled from "styled-components"

const ApiInfoStyled: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>
}

export default styled(ApiInfoStyled)`
  .vertical-flex {
    display: flex;
    flex-direction: column;
  }
  .payment-method {
    display: flex;
    justify-content: center;
    width: 100%;
    div {
      display: inline;
    }
    img {
      margin-right: 10px;
    }
  }
  .api-card-container {
    margin: 24px 0;
  }
  .user-data-form {
    margin-top: 32px;
  }
`
