import tagfill from "assets/svg/tag_fill.svg";
import tag from "assets/svg/tag.svg";
import { useEffect, useState } from "react";
import ModalRemove from './modalRemoveSavedApi'
import { createApiSavedService } from "store/admin/savedApis/service";
import { auth } from "utils/auth";
import { notification } from "antd";
import TypographyGr1d from "../typography";
import { useDispatch } from "react-redux";
import { appActions } from "store/app/action";
import { useAction } from "store";
import { useHistory, useLocation } from "react-router-dom";
import { adminApiSavedActions } from "store/admin/savedApis/action";

type props = {
    apiId: number
    saved?: boolean
    width?: number
    height?: number,
    type?: 'button',
}

const SaveApiFlag: React.FC<props> = (props) => {
    const history = useHistory();
    const [saved, setSaved] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
    const apiSavedService = createApiSavedService();
    const location = useLocation();

    const appAction = useAction(appActions());
    const action = useAction(adminApiSavedActions());
    const dispatch = useDispatch();

    function removeSavedApi() {
        setShowRemoveModal(false)
        apiSavedService.removeApi(`${props.apiId}`).then(() => {
            if (!history.location.pathname.includes('/api/saved'))
                setSaved(false);
            dispatch(action.saveApiRemove(props.apiId))
        })
        return false
    }

    useEffect(() => {
        if (props.saved === undefined && auth.isAuthenticated()) {

            setLoadingFlag(true);
            apiSavedService.apiIsSaved(`${props.apiId}`).then((res => {
                setLoadingFlag(false);
                setSaved(res)
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apiId])

    function handleRemoveClick(e: any) {
        e.preventDefault()
        e.stopPropagation()
        if (auth.isAuthenticated()) {
            setShowRemoveModal(true)
        } else {
            dispatch(appAction.goTo('/login', { type: 'ActionApi', path: location.pathname }))
        }

        return false
    }

    function saveApi(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (auth.isAuthenticated()) {
            apiSavedService.saveApi(`${props.apiId}`).then(() => {
                setSaved(true)
                notification.success({
                    message: 'API salva com sucesso!',
                    placement: 'bottomLeft'
                })
            })
        } else {
            dispatch(appAction.goTo('/login', { type: 'ActionApi', path: location.pathname }))
        }
    }

    if (props.type === 'button') {
        return (
            <TypographyGr1d font="Manrope" fontWeight="600" style={{cursor: 'pointer'}}>
                {showRemoveModal && <ModalRemove onCancel={(e) => { 
                    e.preventDefault();
                    e.stopPropagation();
                    setShowRemoveModal(false) }} onRemove={() => { removeSavedApi() }} />}
                {(saved && !loadingFlag) && <div onClick={handleRemoveClick} style={{ width: props.width, height: props.height }}> <img style={{ cursor: 'pointer' }} alt="salvar" src={tagfill} /> Salvar API</div>}
                {(!saved && !loadingFlag) && <div onClick={saveApi} style={{ width: props.width, height: props.height }}> <img style={{ cursor: 'pointer' }} alt="salvar" src={tag} /> Salvar API</div>}
            </TypographyGr1d>
        )
    }

    return (
        <>
            {showRemoveModal && <ModalRemove onCancel={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowRemoveModal(false)
            }} onRemove={(e) => {
                e.preventDefault()
                e.stopPropagation()
                removeSavedApi()
            }} />}
            {(saved && !loadingFlag) && <img style={{ cursor: 'pointer', width: props.width, height: props.height }} onClick={handleRemoveClick} alt="salvar" src={tagfill} />}
            {(!saved && !loadingFlag) && <img style={{ cursor: 'pointer', width: props.width, height: props.height }} onClick={saveApi} alt="salvar" src={tag} />}
        </>
    )
}

export default SaveApiFlag