import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import React from "react";
import { DocumentationApiStyled } from "./documentation.styled";
import { ReactComponent as extenal } from "assets/svg/api/external-link.svg";
import { ReactComponent as download } from "assets/svg/api/download.svg";
import { useSelector } from "react-redux";
import { ApiState } from "store/portal/reducer";

const DocumentationApi: React.FC = () => {
    const apiState: ApiState = useSelector((state: any) => state.portal?.api);

    return <DocumentationApiStyled>
        {!apiState.loading && 
            apiState.item?.documents?.map((document, i) => {
                return (
                    <div key={i}>
                        <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                            { document.name }
                        </TypographyGr1d>
                        <TypographyGr1d style={{ marginBottom: 32 }} component='bodyLG' color='colorPrimitive600'>
                        { document.description }
                        </TypographyGr1d>
                        { document.type==='link' && 
                            <a target="_blank" rel="rel=noopener noreferrer" href={document.url as string}>
                                <ButtonGr1d type="secondary" style={{ marginBottom: 56 }} icon={extenal}>
                                    Link para a documentação externa
                                </ButtonGr1d>
                            </a>
                        }
                        { document.type==='file' && 
                            <a target="_blank" rel="rel=noopener noreferrer" download={document.filename as string} href={`data:${document.contentType};base64,${document.file}`}>
                                <ButtonGr1d type="secondary" style={{ marginBottom: 56 }} icon={download}>
                                    Link para a documentação externa
                                </ButtonGr1d>
                            </a>
                        }
                        {/* <a href="a"><ButtonGr1d type="secondary" style={{ marginBottom: 56 }} icon={extenal}>
                            Link para a documentação externa
                        </ButtonGr1d></a> */}
                        {/* <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                            Título do documento
                        </TypographyGr1d>
                        <TypographyGr1d style={{ marginBottom: 32 }} component='bodyLG' color='colorPrimitive600'>
                            Descrição do documento
                        </TypographyGr1d>
                        <ButtonGr1d type="secondary" style={{ paddingBottom: 96 }} icon={download}>
                            Baixar documentação
                        </ButtonGr1d> */}
                    </div>
                )
            })
        }
        
    </DocumentationApiStyled>;
}

export default DocumentationApi;