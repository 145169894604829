import { ConfigProvider } from "antd";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Theme } from "theme";
import Api from "views/portal/api";
import HelpForm from "views/portal/helpForm";
import Home from "views/portal/home";
import Layout from "views/layout/portal";
import Login from "views/portal/login";
import Register from "views/portal/login/register";
import UseCase from "views/portal/useCase";
import ptBR from 'antd/lib/locale/pt_BR';
import Search from "views/portal/search";
import AdminApi from "views/admin/api";
import AdminApiForm from "views/admin/api/form";
import LoginInfo from "views/portal/login/loginVerify";
import AdminTemplate from "views/layout/admin";
import AdminApiConsumedForm from "views/admin/apiConsumed/form";
import Policy from "views/portal/policy"
import AdminApiConsumed from "views/admin/api/consumed"
import AdminApiSaved from 'views/admin/api/saved'
import AccountSettings from "views/admin/api/accountSettings";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { appActions } from "store/app/action";
import Company from "views/admin/company";
import PaymentAccount from "views/admin/payment/account";
import PaymentCreditCard from "views/admin/payment/creditCard";
import OrgMembers from "views/admin/users";
import ComplelteRegister from "views/portal/login/completeRegister";
import PaymentPayable from "views/admin/payment/payable";
import PaymentPayableBill from "views/admin/payment/payableBill";
import PaymentPayableBillResume from "views/admin/payment/payableBillResume";
import Screen401 from "views/401";
import PermissionModal from "views/modals/permission";
import PurchaseReview from "views/portal/purchaseReview"
import Prepaid from "views/portal/prepaid";
import HelpSuccessForm from "views/portal/helpForm/success";

const Routes: React.FC = () => {
    const dispatch = useDispatch();
    const action = useAction(appActions());
    const user: any = useSelector((state: any) => state.login?.user);
    let history = useHistory();

    // Verify Route
    const vr = (id?: string, adminRoute?: boolean) => {
        dispatch(action.changeMenuId(id));
        if (adminRoute != null && (adminRoute === true && user?.isAdmin === false)) {
            history.push('/401');
        }
    }

    return <Theme>
        <ConfigProvider locale={ptBR}>
            {/* <div className='center-component'>
                <UploadImageAdmin setImage={() => false} />
            </div> */}
            <Switch>
                <Route exact path="/401">
                    <Screen401 />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/login/:key" render={(propsRoute) => { return <LoginInfo {...propsRoute} /> }} />
                <Route exact path="/register">
                    <Register />
                </Route>
                <Route path="/complete-register-redirect" render={(props) => {
                    return <Redirect to={{ pathname: '/complete-register', search: props.location.search }} />
                }} />
                <Route path="/complete-register" render={(props) => <ComplelteRegister {...props as any} />} />
                <Route path='/admin'>
                    <AdminTemplate>
                        <Route exact path="/admin/api" render={() => { vr(undefined); return <AdminApi /> }} />
                        <Route exact path="/admin/api/form" render={() => { vr(undefined); return <AdminApiForm /> }} />
                        <Route exact path="/admin/api/saved" render={() => { vr(undefined); return <AdminApiSaved /> }} />
                        <Route exact path="/admin/account" render={() => { vr(undefined); return <AccountSettings /> }} />
                        <Route exact path="/admin/api/consumed" render={() => { vr('/admin/api/consumed'); return <AdminApiConsumed /> }} />
                        <Route exact path="/admin/api/consumed/:id" render={(propsRoute) => { vr('/admin/api/consumed'); return <AdminApiConsumedForm {...propsRoute} /> }} />
                        <Route exact path="/admin/company/form" render={() => { vr('/admin/company/form'); return <Company /> }} />
                        <Route exact path="/admin/payment/payable" render={() => { vr('/admin/payment/payable', true); return <PaymentPayable /> }} />
                        <Route exact path="/admin/payment/payable/bill" render={() => { vr('/admin/payment/payable', true); return <PaymentPayableBill /> }} />
                        <Route exact path="/admin/payment/payable/bill/:id" render={(propsRoute) => { vr('/admin/payment/payable', true); return <PaymentPayableBillResume {...propsRoute} /> }} />
                        <Route exact path="/admin/payment/account" render={() => { vr('/admin/payment/account'); return <PaymentAccount /> }} />
                        <Route exact path="/admin/payment/creditcard" render={() => { vr('/admin/payment/creditcard', true); return <PaymentCreditCard /> }} />
                        <Route exact path="/admin/company/permission" render={() => { vr(); return <OrgMembers /> }} />
                    </AdminTemplate>
                </Route>
                <Route exact path="/purchase-review" render={() => { return <PurchaseReview /> }} />
                <Route path="/prepaid/:id" render={(propsRoute) => { return <Prepaid {...propsRoute} /> }} />
                <Route path='/'>
                    <Layout>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/terms">
                            <Policy pageTab="terms" />
                        </Route>
                        <Route exact path="/privacy">
                            <Policy pageTab="privacy" />
                        </Route>
                        <Route exact path="/cookies">
                            <Policy pageTab="cookies" />
                        </Route>
                        <Route path="/api/:id" render={(propsRoute) => { return <Api {...propsRoute} /> }} />
                        <Route exact path="/useCase/:id" render={(propsRoute) => { return <UseCase {...propsRoute} /> }} />
                        <Route exact path="/helpForm/:apiId?" render={(propsRoute) => { return <HelpForm {...propsRoute} /> }} />
                        <Route exact path="/helpForm/success" render={(propsRoute) => { return <HelpSuccessForm {...propsRoute} /> }} />
                        <Route exact path="/search/:type/:id/:page" render={(propsRoute) => { return <Search {...propsRoute} /> }} />
                    </Layout>
                </Route>
            </Switch>
        </ConfigProvider>
        <PermissionModal />
    </Theme>;
}

export default Routes;