import React, { useMemo, useState } from "react";
import { HeaderDrawerLayoutStyled, HeaderLayoutStyled } from "./header.styled";
import { ReactComponent as logo } from "assets/svg/logo.svg"
import { useTranslation } from "react-i18next";
import { ResourceHeader } from "assets/i18n/resource";
import ButtonGr1d from "components/portal/button";
import { useHistory } from "react-router-dom";
import InputGr1d from "components/portal/input";
import { SearchOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as hamburguer } from "assets/svg/hamburguer.svg"
import { ReactComponent as portal } from "assets/svg/home/portal.svg"
import { auth } from "utils/auth";
import { Drawer, Popover, Select } from "antd";
import { useAction } from "store";
import { loginActions } from "store/login/action";
import TypographyGr1d from "components/portal/typography";
import { appActions } from "store/app/action";
import { useEffect } from "react";
import { ReactComponent as fold } from "assets/svg/menu/fold.svg";
import { ReactComponent as unfold } from "assets/svg/menu/unfold.svg";
import _ from "lodash";
import { CategoryState } from "store/portal/reducer";
import { ReactComponent as shape } from "assets/svg/menu/shape.svg";

const { Option } = Select;

type Props = {
    hideSearch?: boolean,
    maxWidth?: number | string,
    padding?: number | string
}

const HeaderLayout: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appAction = useAction(appActions());
    const loginAction = useAction(loginActions());
    const i18n: ResourceHeader = useMemo(() => t("resource.header", { returnObjects: true }), [t]);
    const [search, setSearch] = useState<string>('');
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const [verify] = useState(auth.isAuthenticated());
    const user = useSelector((state: any) => state.login?.user);
    const [popoverCollapseVisible, setPopoverCollapseVisible] = useState(false);
    const [popoverDrawerCollapseVisible, setPopoverDrawerCollapseVisible] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const categoryState: CategoryState = useSelector((state: any) => state.portal?.category);

    useEffect(() => {
        setDrawerVisible(false);
    }, [grid])

    let history = useHistory();

    history.listen((location, action) => {
        if (action === 'PUSH') {
            setSearch('')
        }
    })

    const onSearch = (value: string) => {
        setSearch(value);
    }

    const enterSearch = (code: string) => {
        if ((search != null && search.trim() !== '') && (code === 'Enter' || code === 'NumpadEnter')) {
            setDrawerVisible(false);
            history.push(`/search/text/${search}/1`, { log: true });
        }
    }

    const logout = () => {
        dispatch(loginAction.logout())
    }

    return <HeaderLayoutStyled>
        <div style={{ maxWidth: props.maxWidth, padding: props.padding }}>
            <div onClick={() => setDrawerVisible(true)} className={`menu new-menu ${grid}`}>
                <ButtonGr1d className='menu' background='black' onClick={() => setDrawerVisible(true)} type='tertiary'>
                    <Icon className='collapse-icon' component={unfold} />
                </ButtonGr1d>
            </div>
            <div className={`logo-div logo-${grid}`} onClick={() => history.push('/')}>
                <Icon className='logo' component={logo} />
            </div>
            <div className={`actions ${grid}`}>
                {props.hideSearch !== true && <InputGr1d onKeyPress={(v) => enterSearch(v.key)} onChange={(v) => onSearch(v ? v.target.value : undefined)} value={search} style={{ maxWidth: 500 }}
                    suffix={<SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} onClick={() => enterSearch('Enter')} className='search' />}
                    placeholder='O que você está procurando?' size='40px' />}
            </div>
            {auth.isAuthenticated() && <div className={`portal ${grid}`}>
                <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/api/consumed'))} style={{ display: 'flex', cursor: 'pointer', paddingRight: 32 }} font='Manrope' fontWeight='700' color='colorPrimitive200'>
                    <Icon style={{ paddingRight: 12 }} component={portal} />
                    Portal GR1D
                </TypographyGr1d>
            </div>}
            <div>
                {!verify ? <div className={`header-btn ${grid}`}>
                    <ButtonGr1d type="secondary" background='black' onClick={() => history.push('/login')}>{i18n.enter}</ButtonGr1d>
                    <ButtonGr1d type="primary" onClick={() => history.push('/register')} background='black' >{i18n.register}</ButtonGr1d>
                </div> : <div style={{ cursor: 'pointer' }}>
                    <Popover className={`popover ${grid}`} onVisibleChange={(v) => {
                        if (!v)
                            setPopoverCollapseVisible(false);
                    }} visible={popoverCollapseVisible} overlayClassName='admin-menu' overlayInnerStyle={{ width: 200 }} trigger='click' placement='topLeft' content={<div className='user-menu'>
                        <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/account'))} component='bodySM' color='colorPrimitive600'>Minha conta</TypographyGr1d>
                        <div className='hr-menu' />
                        <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/api/saved'))} component='bodySM' color='colorPrimitive600'>APIs salvas</TypographyGr1d>
                        <TypographyGr1d onClick={() => dispatch(appAction.goTo('/helpform'))} component='bodySM' color='colorPrimitive600'>Ajuda</TypographyGr1d>
                        <div className='hr-menu' />
                        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                            <div onClick={() => logout()}>
                                Sair
                            </div>
                        </TypographyGr1d>
                    </div>}>
                        <div className='logo-user' onClick={() => {
                            setPopoverCollapseVisible(x => !x);
                        }}>
                            <img src={`https://ui-avatars.com/api/?name=${user?.name}&background=EDEEF2&color=404554&rounded=true`} alt="User" width="40px" />
                        </div>
                    </Popover>
                </div>}
            </div>
        </div>
        <Drawer width={256} closeIcon={false} bodyStyle={{ padding: 0 }} onClose={() => setDrawerVisible(false)} placement='left' visible={drawerVisible}>
            <HeaderDrawerLayoutStyled>
                <div style={{ display: 'flex', flexDirection: 'column', height: '99vh' }}>
                    <div className='header'>
                        <Icon className='logo-icon' component={logo} />
                        <ButtonGr1d className='btn-collapse' onClick={() => setDrawerVisible(false)} type='tertiary'>
                            <Icon className='collapse-icon' component={fold} />
                        </ButtonGr1d>
                    </div>
                    <div className='body'>
                        {props.hideSearch !== true && <>
                            <Select getPopupContainer={trigger => trigger.parentNode}
                                onChange={(event: any) => history.push(`/search/category/${event}/1`)} className='select-no-select' value={-1}>
                                <Option style={{ display: 'none' }} value={-1}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                                        <div className='hamburguer'>
                                            <Icon className='logo' component={hamburguer} />
                                        </div>&nbsp;&nbsp;
                                        <TypographyGr1d font="Manrope" color="colorPrimitive600" fontSize="14px" fontWeight="600" lineHeight="100%">
                                            <div className='all-categories'>Todas as categorias</div>
                                        </TypographyGr1d>
                                    </div>
                                </Option>
                                {_.orderBy(categoryState.list, x => x.name)?.map((x, i) => {
                                    return <Option value={x.id} key={i}>
                                        <TypographyGr1d font="Manrope" color="colorPrimitive600" fontSize="14px" fontWeight="600" lineHeight="100%">
                                            {x.name}
                                        </TypographyGr1d>
                                    </Option>
                                })}
                            </Select>
                            <InputGr1d onKeyPress={(v) => enterSearch(v.key)}
                                onChange={(v) => onSearch(v ? v.target.value : undefined)} value={search} style={{ maxWidth: 500 }}
                                suffix={<SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} onClick={() => enterSearch('Enter')} className='search' />}
                                placeholder='Pesquisar...' size='40px' />
                        </>}
                    </div>
                    {auth.isAuthenticated() ? <div className='footer'>
                        <div className='logo-user'>
                            <img src={`https://ui-avatars.com/api/?name=${user?.name}&background=EDEEF2&color=404554&rounded=true&size=40`} alt="User" width="40px" />
                        </div>
                        <div className='text'>
                            <div className='name'>
                                <TypographyGr1d font='Manrope' fontWeight='600' fontSize='14px' color='colorPrimitive600'>
                                    {user?.name}
                                </TypographyGr1d>
                            </div>
                            <div className='email'>
                                <TypographyGr1d font='Manrope' fontWeight='500' fontSize='12px' color='colorPrimitive400'>
                                    {user?.email}
                                </TypographyGr1d>
                            </div>
                        </div>
                        <Popover onVisibleChange={(v) => {
                            if (!v)
                                setPopoverDrawerCollapseVisible(false);
                        }} visible={popoverDrawerCollapseVisible} overlayClassName='admin-menu' overlayInnerStyle={{ width: 200 }} trigger='click' placement='topLeft' content={<div className='user-menu'>
                            <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/api/consumed'))} style={{ display: 'flex', cursor: 'pointer', paddingRight: 32 }} component='bodySM' color='colorPrimitive600'>
                                <Icon style={{ paddingRight: 12 }} component={portal} />
                                Portal GR1D
                            </TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/account'))} component='bodySM' color='colorPrimitive600'>Minha conta</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d onClick={() => dispatch(appAction.goTo('/admin/api/saved'))} component='bodySM' color='colorPrimitive600'>APIs salvas</TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(appAction.goTo('/helpform'))} component='bodySM' color='colorPrimitive600'>Ajuda</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                                <div onClick={() => logout()}>
                                    Sair
                                </div>
                            </TypographyGr1d>
                        </div>}>
                            <ButtonGr1d isCenter={true} onClick={() => setPopoverDrawerCollapseVisible(x => !x)}
                                type='tertiary'>
                                <Icon component={shape} />
                            </ButtonGr1d>
                        </Popover>
                    </div> : <div className='footer'>
                        <div className={`header-btn`}>
                            <ButtonGr1d style={{ flex: 1 }} type="secondary" onClick={() => history.push('/login')}>{i18n.enter}</ButtonGr1d>
                            <ButtonGr1d type="primary" onClick={() => history.push('/register')} >{i18n.register}</ButtonGr1d>
                        </div>
                    </div>}
                </div>
            </HeaderDrawerLayoutStyled>
        </Drawer>
    </HeaderLayoutStyled>;
}

export default HeaderLayout;