import { Select, Tag } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TypographyGr1d from 'components/portal/typography';
import { CSSProperties, useState, useEffect } from 'react';
import { MultiSelectProviderStyled } from './provider.styled'
import { ReactComponent as arrow } from "assets/svg/input/arrow_down.svg";
import Icon from '@ant-design/icons';

export type provider = {
    'name': string,
    'id': string,
    'selected': boolean
}

type Props = {
    style?: CSSProperties,
    height?: string,
    bordered: boolean,
    selectionChanged?: (providers: provider[]) => void,
    providers: provider[]
}

const MultiSelectProvider: React.FC<Props> = (props) => {
    const [providers, setProviders] = useState([] as Props['providers'])
    const [selectedCounter, setSelectedCounter] = useState(props.providers.filter(provider => (provider.selected === true)).length)

    useEffect(() => {
        const groupByName = props.providers.reduce((acumulator, current) => {
            acumulator[current.name] = current;
            return acumulator
        }, {} as {[key: string]: provider})

        const providers = Object.keys(groupByName).map(k => groupByName[k])

        setProviders(providers)
    }, [props.providers])

    function handleSelect(index: number) {
        const newProviderState = Object.assign({}, providers[index], {'selected': !providers[index].selected})
        setProviders([...providers.slice(0, index), newProviderState, ...providers.slice(index + 1, providers.length)])
        setSelectedCounter(newProviderState.selected ? (selectedCounter + 1) : (selectedCounter - 1))
    }

    function uncheckAll() {
        setProviders(providers.map(provider => {
          provider.selected = false
          return provider  
        }))
        setSelectedCounter(0)
    }

    useEffect(() => {
        if (props.selectionChanged) {
            props.selectionChanged(providers)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers])

    return (
        <MultiSelectProviderStyled style={{...props.style}} height={props.height}>
            <Select
                className={selectedCounter > 0 ? 'hasItemSelected' : undefined}
                style={{ width: 260 }}
                placeholder="custom dropdown render"
                mode="multiple"
                showArrow
                bordered={props.bordered}
                showSearch={false}
                defaultValue={['']}
                suffixIcon={(<Icon component={arrow}></Icon>)}
                tagRender={(data) => (
                    <TypographyGr1d font="Manrope" fontWeight="600" fontSize="14px" color={selectedCounter > 0 ? 'colorBrandPrimary400' : 'colorPrimitive700'}>
                        {selectedCounter > 0 && <span style={{ lineHeight: '30px', marginLeft: '16px' }}>Filtrando por provedora</span>}
                        {selectedCounter === 0 && <span style={{ lineHeight: '30px', marginLeft: '16px'}}>Filtrar por provedora</span>}
                        { selectedCounter > 0 &&
                            <Tag style={{marginLeft: '8px'}}>
                                {selectedCounter}
                            </Tag>
                        }
                    </TypographyGr1d>
                )}
                dropdownRender={(menu) => (
                <div>
                    <div className='dropdownContentProviderSelect'>
                        <div className='contentCollumn'>
                            <TypographyGr1d font="Manrope" fontWeight="600" fontSize="12px" color="colorPrimitive400" style={{letterSpacing: '2px'}}>PROVEDORA</TypographyGr1d>
                            {
                                providers.map((provider, index) => (
                                    <div className='options' key={index}>
                                        <Checkbox onChange={() => {handleSelect(index)}} checked={provider.selected} style={{paddingRight: '12px'}}></Checkbox>
                                        <TypographyGr1d font="Manrope" fontWeight="400" fontSize="14px" color="colorPrimitive600">
                                            <span style={{cursor: 'pointer'}} onClick={() => {handleSelect(index)}}>{provider.name}</span>
                                        </TypographyGr1d>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='contentCollumn' style={{cursor: 'pointer'}} onClick={uncheckAll}>
                            <TypographyGr1d font="Manrope" fontWeight="600" fontSize="14px" color="colorBrandPrimary400">Desmarcar</TypographyGr1d>
                        </div>
                    </div>
                </div>
                )}
            >
            </Select>
        </MultiSelectProviderStyled>
    )
}

export default MultiSelectProvider;