import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AccountSettings = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AccountSettingsStyled = styled(AccountSettings)`
.header {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 33px;
    border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    .headingLGBase {
        font-size: 38px;
        font-weight: 500;
    }
}
.content {
    padding: 32px;
    padding-top: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .description {
        .headingLGBase {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            font-size: 20px;
            font-weight: 500;
        }
    }
    .form {
        width: 60%;
        .formoptions {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
}
`