import styled from "styled-components"

const Search = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const SearchStyled = styled(Search)`
    max-width: 1280px;
    margin: auto;
    margin-top: 48px;
    padding: 0 20px;
    .empty {
        text-align: center;
        margin-bottom: 161px;
    }

    .cards.sm {
        justify-content: center;
    }
`