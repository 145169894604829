import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAction } from 'store';
import { AdminApiSavedState } from "store/admin/savedApis/reducer";
import { adminApiSavedActions } from 'store/admin/savedApis/action';
import APIlist from 'components/admin/api/list'
import { ApiSavedModel } from 'models/savedApi';

const ConsumedApi: React.FC = () => {
    const dispatch = useDispatch();
    const action = useAction(adminApiSavedActions());
    const apiSavedState: AdminApiSavedState = useSelector((state: any) => state.adminApiSaved);

    const [savedApis, setSavedApis] = useState<ApiSavedModel[]>([])

    useEffect(() => {
        dispatch(action.getSavedApis())
    }, [dispatch, action])

    useEffect(() => {
        if (!apiSavedState.savedApisLoading && apiSavedState.savedApis !== undefined) {
            setSavedApis([...apiSavedState.savedApis])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiSavedState])

    return  (
        <div>
            { APIlist({apis: savedApis, type: "saved"}) }
        </div>
    )
}

export default ConsumedApi