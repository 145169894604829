import styled from 'styled-components';
import React from 'react';
import { IGlobalStyle } from 'theme/global.styles';

const Menu = (props: any) => {
  return <div className={props.className}>
    {props.children}
  </div>
}

export const AdminLayoutMenuStyled = styled(Menu)`
  .ant-layout-sider {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    border-right: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0.1);
  }

  ::-webkit-scrollbar {
    width: .55em;
  }

  ::-webkit-scrollbar {
    width: .55em;
    height: .55em;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .logo {
    /* height: 30px; */
    padding-top: 8px;
    margin-right: 10px;
    margin-top: -3px;
  }

  .logo-expand {
    margin-right: 10px;
  }

  .hamburguer {
    position: absolute !important;
    top: 16px;
  }

  .hamburguer > button {
    border-radius: 30px;
    width: 10px !important;
    position: relative;
    left: 0px;
    font-size: 14px;
  }

  .hamburguer > button.hamburguer-right {
    left: 183px;
  }

  .hamburguer > button.hamburguer-right-collapsed {
     left: 64px;
  }

  .hamburguer > button.hamburguer-right-collapsed,
  .hamburguer > button.hamburguer-right {
    transition: all 0.2s;
    z-index: 1;
  }

  .hamburguer > button > span > svg {
    position: relative;
    right: 8px;
  }

  .hamburguer > button.hamburguer-right-smartphone {
    left: 0;
    z-index: 1001;
    padding-left: 9px;
    padding-bottom: 2px;
    border-radius: 0px 30px 30px 0;
    width: 23px !important;
  }

  /* .hamburguer > button.hamburguer-right-collapsed {
    left: 80px;
    transition: all 0.2s;
  } */

  .hamburguer > button > i {
    position: relative !important;
    right: 7px !important;
  }

  .ant-layout-sider-collapsed .g-content-preference {
    font-size: 18px;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding: 16px 32px !important;
  }

  .ant-menu-item svg {
    font-size: 14px;
  }

  .ant-menu-submenu {
    border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  }

  .logo {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      padding-left: 32px;
      margin-bottom: 20px;

      .logo-icon {
        font-size: 130px;
        flex: 1;
      }

      button {
        top: 6px;
      }
  }

  .logo.collapsed {
    margin-right: 0;
    padding-left: 0px;
  }

  .collapsed .logo-icon {
    font-size: 20px;
    flex: 1;
    justify-content: center;

    button {
      display: none;
    }
  }

  .user {
    padding: 16px 8px 16px 16px;
    display: flex;
    border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};

    > .text {
      padding-left: 16px;
      padding-right: 8px;
      width: 136px;
    }
  }

  .btn-marketplace {
    display: flex;
    justify-content: center;
  }

  .collapsed .first {
    border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  }

  .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
  }

  .email > div {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
  }

  .marketplacemenu {
    border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    li {
      justify-content: center;
      span {
        flex: unset !important;
      }
    }
  }
  .collapsed {
    span {
      margin-left: unset !important;
    }
  }
`;

export const AdminLayoutHeaderMenuStyled = styled(Menu)`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
  
  .new-menu {
    position: absolute;
    left: 10px;
  }

  .logo-sm {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .logo-div {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .logo {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        height: 60px;
        margin-bottom: 0px;
        padding-top: 0px;

        > svg {
            height: 26px;
            width: 152px;
        }
    }
`