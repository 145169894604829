import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const GeneralApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const GeneralApiStyled = styled(GeneralApi)`
    padding-top: 48px;
    display: flex;

    > div {
        flex: 1;
    }

    .card-detail {
        display: flex;
        justify-content: flex-end;
    }

    .card-detail-txt {
        width: 376px;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        border-radius: 8px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        padding: 24px;
    }

    hr {
        border: 0; border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        margin: 16px 0;
    }

    .txt-2 {
        display: flex;

        > div {
            flex: 1;
        }

        .body-text-2 {
            display: flex;
            justify-content: flex-end;
        }
    }

    .general-description {
        margin-bottom: 48px;
    }
    
    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
      }
      
      .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
`