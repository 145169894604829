import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiStyled = styled(AdminApi)`
    height: 100%;
    
    .api {
        padding: 0px 32px;
    }

    .api-tab {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        padding: 32px;

        > div {
            max-width: 1024px;
        }
    }

    .search {
        display: flex;
        gap: 16px;
        align-items: center;

        > .input {
            flex: 2;
        }

        > .select {
            flex: 1;
        }
    }

    .menu {
        width: 256px;

        > ul {
            height: 100%;
        }
    }
`