import { useRef } from "react";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { accountReducer } from "./admin/account/reducer";
import { adminApiReducer } from "./admin/api/reducer";
import { adminApiConsumedReducer } from "./admin/apiConsumed/reducer";
import { adminApiSavedReducer } from "./admin/savedApis/reducer";
import { appReducer } from "./app/reducer";
import { loginReducer } from "./login/reducer";
import { navigatorReducer } from "./navigator/reducer";
import { portalReducer } from "./portal/reducer";
import { createReducerManager } from "./reducerManager";
import { helpReducer } from "./help/reducer";
import { companyReducer } from "./admin/company/reducer";
import { prepaidReducer } from "./portal/prepaid/reducer";

export const staticReducers = {
    navigator: navigatorReducer,
    app: appReducer,
    login: loginReducer,
    portal: portalReducer,
    adminApi: adminApiReducer,
    adminApiConsumed: adminApiConsumedReducer,
    adminApiSaved: adminApiSavedReducer,
    account: accountReducer,
    help: helpReducer,
    company: companyReducer,
    prepaid: prepaidReducer
}

export function useAction<TAction>(action: TAction): TAction
export function useAction(action: any): any {
    return useRef(action).current;
}

export const reducerManager = createReducerManager(staticReducers);
export const store = createStore(reducerManager.reduce, composeWithDevTools(applyMiddleware(thunk)));