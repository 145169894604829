import styled from "styled-components"

const CreditCardInfo: React.FC<any> = ({ className, children }) => {
  return <div className={className} style={{ height: "100%" }}>{children}</div>
}

export const CreditCardInfoStyled = styled(CreditCardInfo)`
height: 100%;
.vertical-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}`
