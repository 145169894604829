import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Pagination = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const PaginationStyled = styled(Pagination)`
    display: flex;
    
    &.right {
        justify-content: flex-end;
    }

    & .ant-pagination-item:hover {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-pagination-item a:hover {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    }

    & .ant-pagination-item-active {
        border: none;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-pagination-item-active a {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .ant-pagination-item-active a:hover {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, 
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-pagination-prev svg, 
    .ant-pagination-next svg {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-pagination-prev:focus-visible .ant-pagination-item-link, 
    .ant-pagination-next:focus-visible .ant-pagination-item-link, 
    .ant-pagination-prev:hover .ant-pagination-item-link, 
    .ant-pagination-next:hover .ant-pagination-item-link
    {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-pagination {
        li {
            border-radius: 4px;
        }
    }
`