import { Drawer, Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { AdminLayoutHeaderMenuStyled, AdminLayoutMenuStyled } from "./menu.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as logo } from "assets/svg/logo.svg";
import { ReactComponent as logoIcon } from "assets/svg/logo-icon.svg";
import { ReactComponent as shape } from "assets/svg/menu/shape.svg";
import { ReactComponent as marketplace } from "assets/svg/menu/marketplace_.svg";
import { ReactComponent as fold } from "assets/svg/menu/fold.svg";
import { ReactComponent as unfold } from "assets/svg/menu/unfold.svg";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { useAction } from "store";
import { appActions } from "store/app/action";
import { RouteEntity } from "models/route";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { allModulesRoutes } from "router/routes.module";
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import { loginActions } from "store/login/action";
import { HeaderDrawerLayoutStyled } from "../portal/header.styled";
import { useHistory } from "react-router-dom";

const AdminLayoutMenu: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [allMenus, setMenu] = useState<JSX.Element[]>();
    const [onlyMenu, setOnlyMenu] = useState<JSX.Element[]>();
    const actions = useAction(appActions());
    const loginAction = useAction(loginActions());
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverDrawerVisible, setPopoverDrawerVisible] = useState(false);
    const [popverCollapseVisible, setPopverCollapseVisible] = useState(false);
    const [defaultOpenKey, setDefaultOpenKey] = useState<string[]>([]);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const user = useSelector((state: any) => state.login?.user);
    const menuId = useSelector((state: any) => state.app?.menuId);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    useEffect(() => {
        setPopoverVisible(false);
        setPopverCollapseVisible(false);
        setMouseEnter(false);
    }, [collapsed])

    useEffect(() => {
        history.listen(() => {
            setDrawerVisible(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let newMenus: JSX.Element[] = [];
        let newOnlyMenu: JSX.Element[] = [];
        let defaultOpen: string[] = [];

        function createMenu(route: RouteEntity, first: boolean): JSX.Element {
            return <Menu.Item className={first ? 'first' : ''} key={route.path} icon={route.icon} onClick={() => dispatch(actions.goTo(route.path!))}>
                {t(route.title)}
            </Menu.Item>;
        }

        function createSubMenu(route: RouteEntity): JSX.Element {
            let menus: JSX.Element[] = [];

            _.forEach(route.children, (child, i) => {
                let menu = createMenu(child, i === 0);
                newOnlyMenu.push(menu);
                menus.push(menu);
            });

            defaultOpen.push(route.id);

            return (
                <SubMenu key={route.id} title={
                    <span>
                        <TypographyGr1d font='Manrope' fontWeight='800' fontSize='10px' color='colorPrimitive400'>
                            {t(route.title)}
                        </TypographyGr1d>
                    </span>
                }>{menus}</SubMenu>
            )
        }

        _.forEach(allModulesRoutes, route => {
            newMenus.push(createSubMenu(route));
        });
        setDefaultOpenKey(defaultOpen);
        setMenu(newMenus);
        setOnlyMenu(newOnlyMenu);

    }, [t, dispatch, actions]);

    const mouseEnterCollapse = () => {
        if (collapsed)
            setMouseEnter(true)
    }

    const logout = () => {
        dispatch(loginAction.logout());
    }



    return <AdminLayoutMenuStyled>
        {grid === 'sm' ? <AdminLayoutHeaderMenuStyled>
            <div onClick={() => setDrawerVisible(true)} className={`menu new-menu`}>
                <ButtonGr1d className='menu' background='black' onClick={() => setDrawerVisible(true)} type='tertiary'>
                    <Icon className='collapse-icon' component={unfold} />
                </ButtonGr1d>
            </div>
            <div className={`logo-div logo-sm`}>
                <Icon className='logo' component={logo} />
            </div>
        </AdminLayoutHeaderMenuStyled> :
            <Sider onMouseEnter={() => mouseEnterCollapse()} onMouseLeave={() => setMouseEnter(false)} width={256} trigger={null} collapsible collapsed={collapsed}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <div className={`logo ${collapsed ? 'collapsed' : ''}`}>
                        {!mouseEnter ? <Icon className={`logo-icon`} component={!collapsed ? logo : logoIcon} /> :
                            <ButtonGr1d style={{ display: 'flex', width: '100%', justifyContent: 'center' }} onClick={() => setCollapsed(false)} type='tertiary'>
                                <Icon className='collapse-icon' component={unfold} />
                            </ButtonGr1d>}
                        <ButtonGr1d style={{ display: !collapsed ? 'block' : 'none' }} onClick={() => setCollapsed(true)} type='tertiary'>
                            <Icon className='collapse-icon' component={fold} />
                        </ButtonGr1d>
                    </div>
                    <div className={collapsed ? 'collapsed' : ''} style={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
                        {allMenus != null && <Menu selectedKeys={[menuId]} defaultOpenKeys={defaultOpenKey} mode="inline">
                            {!collapsed ? allMenus : onlyMenu}
                        </Menu>}
                    </div>
                    <div>
                        <div className={"marketplacemenu " + (collapsed ? 'collapsed' : '')}>
                            <Menu mode="inline" style={{}}>
                                <Menu.Item icon={<Icon component={marketplace} />} onClick={() => dispatch(actions.goTo("/"))}>
                                    <TypographyGr1d>
                                        Ir para o Marketplace
                                    </TypographyGr1d>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                    <div style={{ justifyContent: !collapsed ? 'initial' : 'center' }} className='user'>
                        <Popover onVisibleChange={(v) => {
                            if (!v)
                                setPopverCollapseVisible(false);
                        }} visible={popverCollapseVisible} overlayClassName='admin-menu' overlayInnerStyle={{ width: 200 }} trigger='click' placement='topLeft' content={<div className='user-menu'>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/'))} style={{ display: 'flex' }} component='bodySM' color='colorPrimitive600'>
                                <div style={{ flex: 1 }}>
                                    Ir para Marketplace
                                </div>
                                <Icon component={marketplace} />
                            </TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/account'))} component='bodySM' color='colorPrimitive600'>Minha conta</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/api/saved'))} component='bodySM' color='colorPrimitive600'>APIs salvas</TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/helpform'))} component='bodySM' color='colorPrimitive600'>Ajuda</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                                <div onClick={() => logout()}>
                                    Sair
                                </div>
                            </TypographyGr1d>
                        </div>}>
                            <div className='logo-user' onClick={() => {
                                if (collapsed)
                                    setPopverCollapseVisible(x => !x);
                            }}>
                                <img style={{ cursor: collapsed ? 'pointer' : 'initial' }} src={`https://ui-avatars.com/api/?name=${user?.name}&background=EDEEF2&color=404554&rounded=true`} alt="User" width="40px" />
                            </div>
                        </Popover>
                        <div style={{ display: !collapsed ? 'block' : 'none' }} className='text'>
                            <div className='name'>
                                <TypographyGr1d style={{ width: 120 }} className='oneLine' font='Manrope' fontWeight='600' fontSize='14px' color='colorPrimitive600'>
                                    {user?.name}
                                </TypographyGr1d>
                            </div>
                            <div className='email'>
                                <TypographyGr1d style={{ width: 120, display: 'block' }} className='oneLine' font='Manrope' fontWeight='500' fontSize='12px' color='colorPrimitive400'>
                                    {user?.email}
                                </TypographyGr1d>
                            </div>
                        </div>
                        <Popover onVisibleChange={(v) => {
                            if (!v)
                                setPopoverVisible(false);
                        }} visible={popoverVisible} overlayClassName='admin-menu' overlayInnerStyle={{ width: 200 }} trigger='click' placement='topLeft' content={<div className='user-menu'>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/account'))} component='bodySM' color='colorPrimitive600'>Minha conta</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/api/saved'))} component='bodySM' color='colorPrimitive600'>APIs salvas</TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/helpform'))} component='bodySM' color='colorPrimitive600'>Ajuda</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                                <div onClick={() => logout()}>
                                    Sair
                                </div>
                            </TypographyGr1d>
                        </div>}>
                            <ButtonGr1d isCenter={true} onClick={() => {
                                if (!collapsed)
                                    setPopoverVisible(x => !x);
                            }} style={{ display: !collapsed ? 'block' : 'none' }} type='tertiary'>
                                <Icon component={shape} />
                            </ButtonGr1d>
                        </Popover>
                    </div>
                </div>
            </Sider>}
        <Drawer onClose={() => setDrawerVisible(false)} bodyStyle={{ padding: 0 }} closeIcon={false} placement='left' visible={drawerVisible}>
            <HeaderDrawerLayoutStyled>
                <div style={{ display: 'flex', flexDirection: 'column', height: '99vh' }}>
                    <div style={{ height: 54 }} className='header'>
                        <Icon className='logo-icon' component={logo} />
                        <ButtonGr1d className='btn-collapse' onClick={() => setDrawerVisible(false)} type='tertiary'>
                            <Icon className='collapse-icon' component={fold} />
                        </ButtonGr1d>
                    </div>
                    <div style={{ padding: 0 }} className='body'>
                        <div className={'collapsed'} style={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
                            {allMenus != null && <Menu selectedKeys={[menuId]} defaultOpenKeys={defaultOpenKey} mode="inline">
                                {allMenus}
                            </Menu>}
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='logo-user'>
                            <img src={`https://ui-avatars.com/api/?name=${user?.name}&background=EDEEF2&color=404554&rounded=true`} alt="User" width="40px" />
                        </div>
                        <div className='text'>
                            <div className='name'>
                                <TypographyGr1d font='Manrope' fontWeight='600' fontSize='14px' color='colorPrimitive600'>
                                    {user?.name}
                                </TypographyGr1d>
                            </div>
                            <div className='email'>
                                <TypographyGr1d font='Manrope' fontWeight='500' fontSize='12px' color='colorPrimitive400'>
                                    {user?.email}
                                </TypographyGr1d>
                            </div>
                        </div>
                        <Popover onVisibleChange={(v) => {
                            if (!v)
                                setPopoverDrawerVisible(false);
                        }} visible={popoverDrawerVisible} overlayClassName='admin-menu' overlayInnerStyle={{ width: 200 }} trigger='click' placement='topLeft' content={<div className='user-menu'>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/'))} style={{ display: 'flex' }} component='bodySM' color='colorPrimitive600'>
                                <div style={{ flex: 1 }}>
                                    Ir para Marketplace
                                </div>
                                <Icon component={marketplace} />
                            </TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/account'))} component='bodySM' color='colorPrimitive600'>Minha conta</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/admin/api/saved'))} component='bodySM' color='colorPrimitive600'>APIs salvas</TypographyGr1d>
                            <TypographyGr1d onClick={() => dispatch(actions.goTo('/helpform'))} component='bodySM' color='colorPrimitive600'>Ajuda</TypographyGr1d>
                            <div className='hr-menu' />
                            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                                <div onClick={() => logout()}>
                                    Sair
                                </div>
                            </TypographyGr1d>
                        </div>}>
                            <ButtonGr1d isCenter={true} onClick={() => {
                                if (!collapsed)
                                    setPopoverDrawerVisible(x => !x);
                            }} style={{ display: !collapsed ? 'block' : 'none' }} type='tertiary'>
                                <Icon component={shape} />
                            </ButtonGr1d>
                        </Popover>
                    </div>
                </div>
            </HeaderDrawerLayoutStyled>
        </Drawer>
    </AdminLayoutMenuStyled >;
}

export default AdminLayoutMenu;