import { createApiService } from "service";
import axios  from "axios";
import { UploadProgressEvent } from "rc-upload/lib/interface";

export const createHelpService = () => {
    const service = createApiService('/core/api/v1');

    const getSubjects = (): Promise<any> => {
        return service.get('/help/subject');
    }

    const sendFile = async (file: File, onProgress: (event: UploadProgressEvent) => void): Promise<string> => {
        const urlFile: any = await service.post('/help/urlfile', { filename: file.name });
        await axios.put(urlFile.signedUrl, file, { onUploadProgress: onProgress , headers: { 'content-type': file.type }})
        return urlFile.filename
    }

    const createHelp = async (data: { name: string, email: string, subject: string, text: string, phone: string, organization: string, anexos?: string[], notification:boolean}): Promise<any> => {
        await service.post('/help', data )
    }

    return { getSubjects, sendFile, createHelp };
}