import { CEPModel } from "models/cep";
import { CNPJModelResponse } from "models/cnpj";
import { CompanyContactModel, CompanyModel, CreditCardModel, CreditCardPostModel } from "models/company";
import { MemberModel } from "models/member";
import { createApiService } from "service";

export const createCompanyService = () => {
    const service = createApiService('/core/api/v1');
    const billingService = createApiService('/billing/api/v1');

    const getCnpjInfo = (cnpj: string): Promise<CNPJModelResponse> => {
        return service.get(`/cnpj/${cnpj}`);
    }

    const getCepInfo = (cep: string): Promise<CEPModel> => {
        return service.get(`/company/cep/${cep}`);
    }

    const postCompany = (company: CompanyModel): Promise<CompanyModel> => {
        return service.post(`/company/`, company);
    }

    const updateCompany = (company: CompanyModel): Promise<CompanyModel> => {
        const newCompany: CompanyModel = {
            ...company, id: undefined, cnpj: undefined, razaoSocial: undefined
        }
        return service.put(`/company/${company.id}`, newCompany);
    }

    const updateCreditCard = (creditCard: CreditCardPostModel, orgId: string, recaptchaResponse?: string): Promise<void> => {
        return billingService.post(`/organizations/${orgId}/credit-card?recaptcha=${recaptchaResponse}`, creditCard);
    }

    const requestApiConsume = (apiId: string, consume: number) => {
        return billingService.post(`api-consumption-forecast/${apiId}`, {
            consumptionForecast: consume
        })
    }

    const getCreditCard = (orgId: string): Promise<CreditCardModel[]> => {
        return service.get(`/company/${orgId}/credit-card`)
    }

    const getCompany = (orgId: string): Promise<CompanyModel> => {
        return service.get(`/company/${orgId}`)
    }

    const getContacts = (orgId: string): Promise<CompanyContactModel[]> => {
        return service.get(`/contacts-company/${orgId}`)
    }

    const getOrgMembers = async (orgId: string): Promise<MemberModel[]> => {
        return service.get(`/company/${orgId}/members`)
    }

    const updateOrgMember = async (orgId: string, userId: string, isAdmin: boolean, cpf: string | undefined): Promise<void> => {
        return service.put(`/company/${orgId}/members/${userId}`, {
            isAdmin,
            cpf,
        })
    }

    const addOrgMember = async (orgId: string, userEmail: string, userCPF: string | undefined): Promise<void> => {
        return service.post(`/company/${orgId}/members`, {
            email: userEmail,
            cpf: userCPF,
        })
    }

    const removeOrgMember = async (orgId: string, userId: string) => {
        return service.delete(`/company/${userId}/${orgId}`)
    }

    const postContact = (contact: CompanyContactModel[]): Promise<CompanyContactModel[]> => {
        return service.post(`/contacts-company/`, contact);
    }

    const updateContact = (contact: CompanyContactModel[], orgId: string): Promise<CompanyContactModel[]> => {
        return service.put(`/contacts-company/${orgId}`, contact);
    }

    return { getCnpjInfo, getCepInfo, postCompany, updateCompany, updateCreditCard, getCreditCard, getOrgMembers, updateOrgMember, addOrgMember, 
        removeOrgMember, getCompany, getContacts, postContact, updateContact, requestApiConsume };
}