import TypographyGr1d from "components/portal/typography";
import React, { ReactNode, useEffect, useState } from "react";
import { PriceApiStyled } from "./price.styled";
import { Modal, Spin, Table, Tooltip } from 'antd'
import ButtonGr1d from "components/portal/button";
import { useHistory } from "react-router";
import { ApiBusinessModelType, ApiBusinessPlan } from "models/apiBusinessModel";
import Icon from '@ant-design/icons';
import { ReactComponent as external } from "assets/svg/api/external-link.svg";
import { ReactComponent as info } from "assets/svg/info.svg";
import { useSelector } from "react-redux";
import { ApiPriceState } from "store/portal/reducer";
import _ from "lodash";
import { ApiPriceModel } from "models/prepaid";
import { formatCurrency } from "utils/utils";
import PrepaidPrice from "./prepaidModal";

const PriceApi: React.FC = () => {
    const history = useHistory();
    const [priceName, setPriceName] = useState<string>();
    const [priceDescription, setPriceDescription] = useState<ReactNode>();
    const apiPrice: ApiPriceState = useSelector((state: any) => state.portal?.apiPrice);
    const prepaid: ApiPriceModel | undefined = useSelector((state: any) => state.portal?.prepaid);
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (apiPrice.item != null) {
            if (apiPrice.item.type === ApiBusinessModelType.PER_PROVIDER) {
                setPriceName('Tabela de preços e/ou comissões');
            } else if (apiPrice.item.type === ApiBusinessModelType.PER_REQUEST || apiPrice.item.type === ApiBusinessModelType.PLAN) {
                setPriceName('Tabela de preços');
            }

            if (apiPrice.item.pricingDescription?.includes('<table>')) {
                let titles: string[] = [];
                let values: any[] = [];
                let newColumns: any[] = [];

                _.forEach(apiPrice.item.pricingDescription.split('<th>'), (th: string, i: number) => {
                    if (i !== 0) {
                        const title = th.substring(0, th.indexOf('</th>'));
                        titles.push(title);
                        newColumns.push({
                            dataIndex: title,
                            key: title,
                            title: title
                        });
                    }
                })

                _.forEach(apiPrice.item.pricingDescription.split('<tr>'), (tr: string, i: number) => {
                    if (i > 1) {
                        _.forEach(tr.split('<td>'), (td: string, i: number) => {
                            if (i !== 0) {
                                values.push(td.substring(0, td.indexOf('</td>')));
                            }
                        })
                    }
                })

                const newValues: string[][] = _.chunk(values, 2);
                let newDatas: any[] = [];

                _.forEach(newValues, datas => {
                    let newData: any = {};
                    _.forEach(titles, (title, i) => {
                        newData[title] = datas[i];
                    })
                    newDatas.push(newData);
                })

                setPriceDescription(<Table pagination={false} style={{ paddingTop: 32 }} columns={newColumns} dataSource={newDatas} />);
            } else {
                setPriceDescription(<div style={{ paddingTop: 32, whiteSpace: 'pre-line' }}>{apiPrice.item.pricingDescription}</div>);
            }
        }
    }, [apiPrice.item])

    if (prepaid?.price == null) {
        return <PriceApiStyled>
            <Spin spinning={apiPrice.loading}>
                {!apiPrice.loading && <div>
                    {(apiPrice.item != null && ((apiPrice.item.pricingLink != null && apiPrice.item.pricingLink !== '')
                        || (apiPrice.item.pricingDescription != null && apiPrice.item.pricingDescription !== '') ||
                        (apiPrice.item.plans != null && apiPrice.item.plans.length > 0))) ?
                        <div><TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                            {priceName}
                        </TypographyGr1d>
                            {priceDescription != null && <TypographyGr1d style={{ marginBottom: 44 }} component='bodyLG' color='colorPrimitive600'>
                                {priceDescription}
                            </TypographyGr1d>}
                            {(apiPrice.item != null && apiPrice.item?.plans.length > 0) && <div className='price-cards'>
                                {apiPrice.item?.plans.map(x => <PriceApiPlan plan={x} />)}
                            </div>}
                            {apiPrice.item?.pricingLink && <ButtonGr1d onClick={() => window.open(apiPrice.item?.pricingLink, '_blank')}>
                                <Icon component={external} style={{ paddingRight: 12 }} />Ver preços
                            </ButtonGr1d>}
                        </div> : <div>
                            <TypographyGr1d fontSize="24px" font="Manrope" fontWeight="600" style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                                Os preços estão disponíveis sob consulta
                            </TypographyGr1d>
                            <TypographyGr1d style={{ marginBottom: 32 }} component='bodyLG' color='colorPrimitiveBlack'>
                                A cobrança pode variar de acordo com as operações realizadas.
                            </TypographyGr1d>
                            <ButtonGr1d onClick={() => history.push('/helpform')} style={{ paddingBottom: 76 }}>
                                Entrar em contato
                            </ButtonGr1d>
                        </div>}
                </div>}
            </Spin>
        </PriceApiStyled>;
    } else {
        return <PriceApiStyled>
            <TypographyGr1d style={{ marginBottom: 24 }} component='headingSM' color='colorPrimitiveBlack'>
                Precificação da API
            </TypographyGr1d>
            <div style={{ display: 'flex', marginBottom: 40 }}>
                <TypographyGr1d style={{ position: 'relative', bottom: 2 }} componentType='span' fontWeight='600' fontSize='16px' color='colorPrimitive400'>
                    R$
                </TypographyGr1d>
                <TypographyGr1d componentType='span' style={{ paddingRight: 8 }} fontWeight='600' fontSize='24px' component='headingSM' color='colorPrimitiveBlack'>
                    {formatCurrency.format(prepaid.price / 100).replace('R$', '')}
                </TypographyGr1d>
                <TypographyGr1d style={{ position: 'relative', top: 4 }} componentType='span' component='subtitleSM' fontWeight='600' fontSize='16px' color='colorPrimitive400'>
                    /chamada
                </TypographyGr1d>
            </div>
            <TypographyGr1d componentType='span' style={{ marginBottom: 24, display: 'flex', gap: 6, alignItems: 'center' }} component='headingSM' color='colorPrimitiveBlack'>
                Contratação mínima <Tooltip overlayClassName='info-prepaid-tooltip' placement="bottomRight" title={<TypographyGr1d style={{ width: 300 }} fontSize='12px' fontWeight='600' color='colorPrimitive100'>
                    Há uma contratação mínima de R$ 10 no marketplace
                </TypographyGr1d>}> <Icon className='info-prepaid' component={info} /></Tooltip>
            </TypographyGr1d>
            <div style={{ display: 'flex', marginBottom: 40 }}>
                <TypographyGr1d style={{ position: 'relative', top: 4 }} componentType='span' fontWeight='600' fontSize='16px' color='colorPrimitive400'>
                    R$
                </TypographyGr1d>
                <div style={{ display: 'flex', gap: 32, alignItems: 'center' }}>
                    <TypographyGr1d componentType='span' style={{ paddingRight: 8 }} fontWeight='600' fontSize='24px' component='headingSM' color='colorPrimitiveBlack'>
                        10,00
                    </TypographyGr1d>
                    <ButtonGr1d onClick={() => setVisible(true)} type='primitive'>Simular contratação</ButtonGr1d>
                </div>
            </div>
            <Modal className='modal-prepaid' width={480} destroyOnClose={true}
                title={<TypographyGr1d fontWeight='500' fontSize='20px' color='colorPrimitiveBlack'>Simular contratação</TypographyGr1d>}
                onCancel={() => setVisible(false)} visible={visible} footer={<div style={{ justifyContent: 'flex-end', display: 'flex' }} className="buttons">
                    <ButtonGr1d onClick={() => setVisible(false)}>Fechar</ButtonGr1d>
                </div>}>
                <PrepaidPrice price={prepaid.price} />
            </Modal>
        </PriceApiStyled>;
    }
}

type Props = {
    plan: ApiBusinessPlan
}

const PriceApiPlan: React.FC<Props> = (props) => {
    return <div className='price-card'>
        <TypographyGr1d style={{ textAlign: 'center', paddingBottom: 16 }} component='headingSM'>
            {props.plan.name}
        </TypographyGr1d>
        <TypographyGr1d style={{ textAlign: 'center', paddingBottom: 24 }} component='headingMD'>
            {props.plan.price}<TypographyGr1d style={{ display: "inline-block" }} component='bodySM'>/mês</TypographyGr1d>
        </TypographyGr1d>
        <TypographyGr1d component='caption' fontWeight='500' className='price-card-description'>
            Quantidade de Requisições: {props.plan.requestsQtty}<br /><br />
            Requisições Adicionais: {props.plan.additionalReqPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </TypographyGr1d>
    </div>;
}

export default PriceApi;