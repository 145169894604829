import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import UploadFileAdmin from "components/admin/upload/file";
import UploadImageAdmin from "components/admin/upload/image";
import ButtonGr1d from "components/portal/button";
import { ApiModel } from "models/api";
import { CategoryModel } from "models/category";
import React, { useEffect, useState } from "react";
import { FormContextValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AdminApiMarketplaceStyled } from "./marketplace.styled";
import { ReactComponent as add } from "assets/svg/add.svg";
import Icon from '@ant-design/icons';
import { appActions } from "store/app/action";
import { useAction } from "store";

interface Doc {
    id?: number,
    idTemp: string,
    title: string,
    description: string,
    type: string,
    data: any
}

const AdminApiMarketplace: React.FC = () => {
    const dispatch = useDispatch();
    const [image, setImage] = useState<any>();
    const [docs, setDocs] = useState<Doc[]>([]);

    const api: ApiModel = useSelector((state: any) => state.adminApi.item);
    const form = useForm({ defaultValues: api });
    const { handleSubmit } = form;

    const categories: CategoryModel[] = useSelector((state: any) => state.adminApi.categoryList);
    const categoryLoading: boolean = useSelector((state: any) => state.adminApi.categoryLoading);
    const appAction = useAction(appActions());

    const makeid = (length: number) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    useEffect(() => {
        if (api == null) {
            dispatch(appAction.goTo('/admin/api'));
        }
    }, [api, appAction, dispatch])

    const addDoc = () => {
        setDocs([...docs, {
            data: undefined,
            description: '',
            title: '',
            type: 'file',
            id: undefined,
            idTemp: makeid(10)
        }])
    }

    const save = (data: any) => {
    }

    const deleteDoc = (id: string) => {
        setDocs([...docs.filter(x => x.idTemp !== id)]);
    }

    const formConfigGeneral: ConfigFormItem[] = [
        {
            id: 'marketplace-image',
            name: 'image',
            typeComponent: 'custom',
            element: <UploadImageAdmin setImage={setImage} image={image} />
        },
        {
            name: 'name',
            typeComponent: 'input',
            label: 'Nome da API',
            placeholder: 'Em alta',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'basePath',
            typeComponent: 'input',
            label: 'Contexto',
            noSpace: true,
            placeholder: '_provedor_nome_da_api',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'marketplace-category',
            name: 'relatedCategories',
            typeComponent: 'multiselect',
            label: 'Categoria',
            data: categories.map(x => { return { value: x.id, title: x.name } }),
            loading: categoryLoading,
            placeholder: 'Selecione a categoria',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'marketplace-description',
            name: 'description',
            typeComponent: 'textarea',
            label: 'Descrição resumida',
            placeholder: 'Descreva de forma resumida a sua API.',
            maxLength: 500,
            showMaxLength: true,
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        }
    ]

    const formConfigDetail: ConfigFormItem[] = [
        {
            id: 'marketplace-detailedDescription',
            name: 'detailedDescription',
            typeComponent: 'textarea',
            label: 'Descrição detalhada',
            placeholder: 'Descreva detalhadamento sobre a API.',
            maxLength: 500,
            showMaxLength: true,
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'marketplace-benefit',
            name: 'benefit',
            typeComponent: 'textarea',
            label: 'Benefícios (opcional)',
            placeholder: 'Quais as principais vantagens ao utilizá-la?',
            maxLength: 500,
            showMaxLength: true,
        },
        {
            id: 'marketplace-target',
            name: 'target',
            typeComponent: 'textarea',
            label: 'Público-alvo (opcional)',
            placeholder: 'Para qual grupo de consumidores essa API é indicada?',
            maxLength: 500,
            showMaxLength: true,
        },
        {
            id: 'marketplace-keyword',
            name: 'keyword',
            typeComponent: 'tags',
            label: 'Palavras-chave',
            placeholder: 'Defina as palavras-chave',
            helpText: 'Aperte Enter depois de cada palavra-chave.'
        }
    ]

    const formConfigDoc: ConfigFormItem[] = [
        {
            name: 'doc',
            typeComponent: 'custom',
            element: <div id='marketplace-doc' style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>{docs.map(x => <AdminApiDocument form={form} deleteDoc={deleteDoc} id={x.idTemp} />)}</div>
        },
    ]

    return <AdminApiMarketplaceStyled>
        <FormAdmin onSubmit={handleSubmit(save)} padding='30px 0px 0px 0px'>
            <FormItensAdmin form={form} config={formConfigGeneral} labelTitle={{ title: 'Visão geral', subtitle: 'Informações básicas da sua API.' }} />
            <div className='hr' />
            <FormItensAdmin form={form} config={formConfigDetail} labelTitle={{ title: 'Detalhes da API', subtitle: 'Conte mais sobre a API. Dê informações como os recursos fornecidos e tecnologias utilizadas.' }} />
            <div className='hr' />
            <FormItensAdmin form={form} config={formConfigDoc} labelTitle={{ title: 'Documentação', subtitle: 'Você pode prover diferentes tipos de documentação referentes a sua API. Uma documentação apropriada ajuda os provedores a posicionar melhor suas APIs no mercado.' }} />
            <ButtonGr1d onClick={() => addDoc()} style={{ display: 'flex', justifyContent: 'flex-end' }} type='primitive'>
                <Icon component={add} /> Adicionar documento
            </ButtonGr1d>
            <div className='hr-final' />
            <div className='marketplace-footer'>
                <ButtonGr1d style={{ flex: 1 }} type='danger'>
                    Descartar alterações
                </ButtonGr1d>
                <ButtonGr1d htmlType='submit' type='feedback'>
                    Salvar mudanças
                </ButtonGr1d>
            </div>
        </FormAdmin>
    </AdminApiMarketplaceStyled>;
}

type PropsDoc = {
    id: string,
    deleteDoc(id: string): void,
    form: FormContextValues<any>
}

const AdminApiDocument: React.FC<PropsDoc> = (props) => {
    const [type, setType] = useState<string>('file');

    const formConfig: ConfigFormItem[] = [
        {
            name: 'title-' + props.id,
            typeComponent: 'input',
            label: 'Título',
            placeholder: 'Título do documento',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'description-' + props.id,
            typeComponent: 'input',
            label: 'Descrição',
            placeholder: 'Descrição do documento',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'type-' + props.id,
            typeComponent: 'radio',
            label: 'Tipo',
            defaultValue: 'file',
            onChange: (v) => setType(v),
            data: [{ value: 'file', title: 'Arquivo' }, { value: 'link', title: 'Link' }],
        },
        {
            name: 'file-' + props.id,
            typeComponent: 'custom',
            hide: type !== 'file',
            element: <UploadFileAdmin form={props.form} name={'file-' + props.id} /> ,
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'link-' + props.id,
            typeComponent: 'input',
            hide: type !== 'link',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        }
    ]

    return <div className='doc'>
        <FormItensAdmin form={props.form} config={formConfig} />
        {/* <Form.Item label='Título'>
            <Input className='input-form bodySMBase' size='middle' placeholder='Título do documento' />
        </Form.Item>
        <Form.Item label='Descrição'>
            <TextArea style={{ width: '100%' }} className='input-form bodySMBase' placeholder='Descrição do documento' />
        </Form.Item> */}
        {/* <Form.Item label='Tipo'>
            <Radio.Group value={type} onChange={(v) => setType(v.target.value)}>
                <Radio value='file'>Arquivo</Radio>
                <Radio value='link'>Link</Radio>
            </Radio.Group>
        </Form.Item> */}
        {/* {type === 'file' ?
            <UploadFileAdmin setFile={() => { }} /> :
            <Form.Item style={{ marginBottom: 0 }}>
                <Input className='input-form bodySMBase' size='middle' placeholder='Título do documento' />
            </Form.Item>} */}
        <ButtonGr1d onClick={() => props.deleteDoc(props.id)} type='danger' style={{ paddingTop: 24 }} width='100%'>
            Excluir documento
        </ButtonGr1d>
    </div>;
}

export default AdminApiMarketplace;