import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useEffect } from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as info } from "assets/svg/info.svg";
import { useHistory } from "react-router";

type Props = {
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
}

const PrepaidSuccess: React.FC<Props> = (props) => {
  const history = useHistory();
  useEffect(() => {
    props.setTitle(undefined);
  }, [props])

  return <div className='prepaid-success'>
    <TypographyGr1d style={{ paddingBottom: 16 }} fontWeight='600' fontSize='24px' color='colorPrimitiveBlack'>Sua compra foi finalizada com sucesso!</TypographyGr1d>
    <TypographyGr1d style={{ paddingBottom: 16 }} fontWeight='600' fontSize='16px' color='colorPrimitive500'>Quais os próximos passos?</TypographyGr1d>
    <TypographyGr1d style={{ paddingBottom: 32, display: 'inline-block' }} fontWeight='400' fontSize='16px' color='colorPrimitive500'>
      Enviaremos a chave de uso da API para os seus contatos cadastrados.&nbsp;
      <TypographyGr1d componentType='span' fontWeight='700' fontSize='16px' color='colorPrimitive500'>Por gentileza, aguarde o nosso contato.</TypographyGr1d>
    </TypographyGr1d>
    <div className='info'>
      <Icon component={info}></Icon>Após o recebimento da chave, você poderá começar a utilizar a API.
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 32 }}>
      <ButtonGr1d onClick={() => history.push('/')}>Ir para página inicial</ButtonGr1d>
    </div>
  </div>;
}

export default PrepaidSuccess;