import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import { CNPJModelResponse } from "models/cnpj";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAction } from "store";
import { portalPrepaidActions } from "store/portal/prepaid/action";
import { isValidCnpj, isValidPhoneNumber } from "utils/utils";
import { PropsPrepaid } from ".";

type Props = {
  step?: number,
  organizationObj: any,
  setOrganizationObj: React.Dispatch<React.SetStateAction<any>>
}

const PrepaidOrganization: React.FC<Props & PropsPrepaid> = (props) => {
  const history = useHistory();
  const { handleSubmit } = props.form;
  const action = useAction(portalPrepaidActions());
  const dispatch = useDispatch();
  const cnpj: CNPJModelResponse = useSelector((state: any) => state.prepaid?.cnpj);
  const [phone, setPhone] = useState<string>('');
  const [lastPhone, setLastPhone] = useState<string>('');
  const [first, setFirst] = useState<boolean>(true);
  const [mask, setMask] = useState<string>(props.organizationObj?.phone === '' ? '(11) 11111-1111' :
    props.organizationObj?.phone?.length !== 15 ? '(11) 1111-1111' : '(11) 11111-1111');

  const getCnpjInfo = (v: any) => {
    if (v?.target?.value != null) {
      const cnpj: string = v.target.value.replaceAll(/_|-|[/]|[.]/g, '');
      if (cnpj.length === 14 && isValidCnpj(cnpj)) {
        dispatch(action.getCnpj(cnpj));
      }
    }
  }

  useEffect(() => {
    if (!first) {
      if (lastPhone.length > phone.length) {
        setMask('(11) 1111-1111');
      } else if (phone.length < 10) {
        setMask('(11) 1111-1111');
      } else {
        setMask('(11) 11111-1111')
      }
    } else {
      setFirst(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, lastPhone])

  useEffect(() => {
    setTimeout(() => {
      props.form.setValue('nickname', props.organizationObj.nickname);
      props.form.setValue('name', props.organizationObj.name);
      props.form.setValue('phone', props.organizationObj.phone);
      props.form.setValue('address', props.organizationObj.address);
      props.form.setValue('cnpj', props.organizationObj.cnpj);
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cnpj?.message != null) {
      props.form.setError('cnpj', 'focus', cnpj.message);
      props.form.setValue('name', undefined);
      props.form.setValue('address', undefined);
    } else if (cnpj != null) {
      props.form.clearError();
      if (props.form.getValues().nickname == null || props.form.getValues().nickname.trim() === '') {
        props.form.setValue('nickname', cnpj.fantasia);
      }
      props.form.setValue('name', cnpj.nome);
      props.form.setValue('phone', cnpj.telefone.split('/')[0]);
      if (props.form.getValues().address == null || props.form.getValues().address.trim() === '') {
        props.form.setValue('address', `${cnpj.logradouro}, ${cnpj.numero}
        ${cnpj.bairro}, ${cnpj.municipio} - ${cnpj.uf}
        ${cnpj.cep}
      `)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj])

  useEffect(() => {
    props.setNextStep('Próximo: Informações de contato');
    props.setTitle('Forneça os dados da sua empresa');
    props.setSubTitle('Para realizar a ativação da API, é necessário fornecer as informações da empresa.');
  }, [props])

  const formConfigData: ConfigFormItem[] = [
    {
      typeComponent: "input",
      id: 'nickname',
      name: 'nickname',
      label: 'Nome fantasia',
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
      }
    },
    {
      maxWidth: 200,
      name: 'phone',
      typeComponent: 'masked-input',
      label: 'Telefone da empresa',
      mask: mask,
      onFocus: () => {
        setMask('(11) 11111-1111');
      },
      onChange: (v) => {
        setLastPhone(phone);
        setPhone((v.target.value as string).replaceAll(/_|-|[)]|[(]/g, ''));
      },
      onBlur: (v) => {
        if (phone.length === 11) {
          setMask('(11) 1111-1111');
          setTimeout(() => {
            props.form.setValue('phone', phone);
            setMask('(11) 11111-1111');
          }, 0)
        }
      },
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidPhoneNumber(value);
        }
      },
    },
    {
      typeComponent: "masked-input",
      id: 'cnpj',
      name: 'cnpj',
      label: 'CNPJ',
      maxWidth: 200,
      mask: "11.111.111/1111-11",
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidCnpj(value);
        }
      },
      onChange: (v) => {
        getCnpjInfo(v);
        props.form.clearError('cnpj');
      },
    },
    {
      typeComponent: "input",
      id: 'name',
      name: 'name',
      label: 'Razão social',
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
      },
      disabled: true,
    },
    {
      typeComponent: "textarea",
      id: "address",
      name: "address",
      disabled: true,
      textAreaPadding: "0",
      textAreaOverflow: "hidden",
      textAreaHeight: "110px",
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
      },
    }
  ]

  const save = (data: any) => {
    props.setCurrentStep(x => x! + 1);
    props.setOrganizationObj(data);
    history.push(history.location.pathname.replace('/organization', '/contacts'));
  }

  const back = () => {
    props.setCurrentStep(x => x! - 1);
    props.setOrganizationObj(props.form.getValues());
    history.push(history.location.pathname.replace('/organization', '/user'))
  }

  return <div className='prepaid-user'>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={props.form} config={formConfigData} marginBottom={16} />
      <div style={{ justifyContent: 'space-between', display: 'flex', paddingTop: 12 }} className="buttons">
        {props.step !== 1 ? <ButtonGr1d type="tertiary" onClick={back}>Voltar</ButtonGr1d> : <div></div>}
        <ButtonGr1d htmlType='submit'>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
  </div>;
}

export default PrepaidOrganization;