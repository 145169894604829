import { RouteEntity } from 'models/route';
import Icon from '@ant-design/icons';
// import { ReactComponent as apiSource } from "assets/svg/menu/api-sources.svg";
import { ReactComponent as apiConsumed } from "assets/svg/menu/api-consumed.svg";
import { ReactComponent as companyData } from "assets/svg/menu/company-data.svg";
import { ReactComponent as companyPermission } from "assets/svg/menu/company-permission.svg";

export const allModulesRoutes: RouteEntity[] = [{
  title: "MINHAS API'S",
  id: '01',
  path: '/admin/api',
  children: [
    {
      title: 'API’s consumidas',
      id: '0102',
      path: '/admin/api/consumed',
      icon: <Icon component={apiConsumed} />,
      children: []
    }
  ]
},
{
  title: "MINHA EMPRESA",
  id: '02',
  children: [
    {
      title: 'Dados da empresa',
      id: '0201',
      path: '/admin/company/form',
      icon: <Icon component={companyData} />,
      children: []
    },
    {
      title: 'Usuários e permissões',
      id: '0202',
      path: '/admin/company/permission',
      icon: <Icon component={companyPermission} />,
      children: []
    },
  ]
},
// {
//   title: "PAGAMENTOS",
//   id: '03',
//   children: [
//     {
//       title: 'A pagar',
//       id: '0301',
//       path: '/admin/payment/payable',
//       icon: <Icon component={companyData} />,
//       children: []
//     },
//     {
//       title: 'A receber',
//       id: '0302',
//       path: '/admin/payment/permission',
//       icon: <Icon component={companyPermission} />,
//       children: []
//     },
//     {
//       title: 'Conta bancária',
//       id: '0303',
//       path: '/admin/payment/account',
//       icon: <Icon component={paymentAccount} />,
//       children: []
//     },
//     {
//       title: 'Cartão de crédito',
//       id: '0304',
//       path: '/admin/payment/creditcard',
//       icon: <Icon component={creditCard} />,
//       children: []
//     }
//   ]
// }
];
