import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useState } from "react";
import { formatCurrency } from "utils/utils";
import Icon from '@ant-design/icons';
import { ReactComponent as error } from "assets/svg/error.svg";
import { useForm } from "react-hook-form";

type Props = {
  price: number
}

const PrepaidPrice: React.FC<Props> = (props) => {
  const form = useForm();
  const { handleSubmit } = form;
  const [totalCall, setTotalCall] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();

  const formConfigData: ConfigFormItem[] = [
    {
      name: 'calls',
      typeComponent: 'number',
      label: 'Quantidade de chamadas mensais',
      placeholder: 'Escreva a quantidade',
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        min: {
          value: 1,
          message: "A quantidade mínima é 1"
        }
      }
    },
  ]

  const calculate = (data: any) => {
    setTotalCall(data.calls);
    setTotalPrice(data.calls * props.price);
  }

  return <div>
    <TypographyGr1d style={{ paddingTop: 20 }} fontWeight='400' fontSize='14px' color='colorPrimitive600'>Insira a quantidade estimada de chamadas que deseja para esta API.</TypographyGr1d>
    <FormAdmin padding='32px 0 8px 0' onSubmit={handleSubmit(calculate)}>
      <div style={{ display: "flex", gap: 12 }}>
        <FormItensAdmin style={{ flex: 1 }} form={form} config={formConfigData} />
        <ButtonGr1d htmlType='submit' style={{ position: 'relative', top: 33 }} type='primitive'>Calcular</ButtonGr1d>
      </div>
      {totalPrice && <div className='price'>
        <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>Valor total</TypographyGr1d>
        {totalPrice >= 1000 ?
          <TypographyGr1d style={{ fontSize: 18 }} fontWeight='700' color='colorPrimitiveBlack'>{formatCurrency.format(totalPrice / 100)}</TypographyGr1d> :
          <TypographyGr1d style={{ fontSize: 18 }} fontWeight='700' color='colorFeedbackNegative300'>{formatCurrency.format(totalPrice / 100)}</TypographyGr1d>}
        <TypographyGr1d fontWeight='300' fontSize='12px' color='colorPrimitive500'>Qtd. de chamadas: {totalCall}</TypographyGr1d>
        <TypographyGr1d fontWeight='300' fontSize='14px' color='colorPrimitive500'>Valor por chamada: {formatCurrency.format(props.price / 100)}</TypographyGr1d>
        {totalPrice < 1000 &&
          <TypographyGr1d className='error-flex' style={{ fontSize: 12 }} fontWeight='300' color='colorFeedbackNegative500'><Icon component={error}></Icon>Valor mínimo para a compra é de R$ 10,00.</TypographyGr1d>}
      </div>}
    </FormAdmin>
  </div>;
}

export default PrepaidPrice;