import TypographyGr1d from "components/portal/typography";
import { ApiModel } from "models/api";
import { useDispatch, useSelector } from "react-redux";
import HeaderLayout from "views/layout/portal/header";
import PrepaidStyled, { PrepaidConfirmStyled } from "./index.styled";
import noImage from "assets/svg/no-image.svg"
import {useCallback, useEffect, useRef, useState} from "react";
import { useAction } from "store";
import { portalPrepaidActions } from "store/portal/prepaid/action";
import { match, Route, Switch, useHistory } from "react-router";
import Icon from '@ant-design/icons';
import { ReactComponent as lock } from "assets/svg/prepaid/lock.svg";
import PrepaidUser from "./user";
import { UserModel } from "models/user";
import { Modal, Progress } from "antd";
import PrepaidPayment from "./payment";
import useTheme from "theme/useTheme";
import { FieldValues, FormContextValues, useForm } from "react-hook-form";
import { CompanyContactModel } from "models/company";
import PrepaidOrganization from "./organization";
import PrepaidContacts from "./contacts";
import ButtonGr1d from "components/portal/button";
import PrepaidConsumption from "./consumption";
import PrepaidSuccess from "./success";
import { formatCurrency } from "utils/utils";
import _ from "lodash";

type Props = {
  match: match
}

export type PropsPrepaid = {
  setNextStep: React.Dispatch<React.SetStateAction<string>>,
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
  setSubTitle: React.Dispatch<React.SetStateAction<string>>,
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  form: FormContextValues<FieldValues>
}

const Prepaid: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
  const api: ApiModel | undefined = useSelector((state: any) => state.prepaid?.api);
  const price: number = useSelector((state: any) => state.prepaid?.price);
  const loadingSave: boolean = useSelector((state: any) => state.prepaid?.loadingSave);
  const [userObj, setUserObj] = useState<any>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [goTo, setGoTo] = useState<string>('');
  const [organizationObj, setOrganizationObj] = useState<any>({});
  const [emailTecObj, setEmailTecObj] = useState<string[]>([]);
  const [emailFinObj, setEmailFinObj] = useState<string[]>([]);
  const [emailBusinessObj, setEmailBusinessObj] = useState<string[]>([]);
  const [calls, setCalls] = useState<number>(0);
  const [triggerReviewModal, setTriggerReviewModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const unlockScreen: boolean = useSelector((state: any) => state.prepaid?.unlockScreen);
  const contacts: CompanyContactModel[] | undefined = useSelector((state: any) => state.prepaid?.contacts);
  const contactsLoading: CompanyContactModel[] | undefined = useSelector((state: any) => state.prepaid?.contactsLoading);
  const user: UserModel = useSelector((state: any) => state.login?.user);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [nextStep, setNextStep] = useState<string>('');
  const [title, setTitle] = useState<string | undefined>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [totalSteps, setTotalSteps] = useState(5);
  const action = useAction(portalPrepaidActions());
  const history = useHistory();
  const userRef = useRef<UserModel>();
  const theme = useTheme();
  const formUser = useForm();
  const formOrganization = useForm();
  const formContacts = useForm();
  const formConsumption = useForm();
  const unblock = useRef<any>();

  useEffect(() => {
    if (_.isEmpty(user)) return
    userRef.current = user;
    dispatch(action.getContacts())
  }, [user])

  useEffect(() => {
    if (triggerReviewModal) {
      setTriggerReviewModal(false)
      if (_.isEmpty(organizationObj)) {
        save()
        return
      }
      setShowReviewModal(true)
    }
  }, [triggerReviewModal])

  useEffect(() => {
    if (unlockScreen) {
      if (totalSteps === 5) {
        history.push(`/prepaid/${(props.match.params as any).id}/user`);
      } else if (totalSteps === 4) {
        history.push(`/prepaid/${(props.match.params as any).id}/organization`);
      } else if (totalSteps === 3) {
        history.push(`/prepaid/${(props.match.params as any).id}/contacts`);
      } else if (totalSteps === 2) {
        history.push(`/prepaid/${(props.match.params as any).id}/consumption`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlockScreen])

  useEffect(() => {
    if (contactsLoading) return;
    unblock.current = history.block((tx) => {
      if (!tx.pathname.includes('/prepaid/')) {
        setGoTo(tx.pathname);
        setModalVisible(true);
        return false;
      }
    });

    if (history.action === 'PUSH') {
      setTimeout(() => {
        verifyUser();
      }, 100)
    } else {
      verifyUser();
    }

    return () => {
      dispatch(action.unlockScreen(false));
      unblock.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsLoading])


  const verifyUser = useCallback(() => {
    if (!userRef.current) return
    if (userRef.current.orgId) {
      if (!contacts?.length) {
        setTotalSteps(3);
        setTimeout(() => {
          dispatch(action.unlockScreen(true));
        }, 0)
        return
      }
      setTotalSteps(2);
      setTimeout(() => {
        dispatch(action.unlockScreen(true));
      }, 0)
      return
    } else {
      setTotalSteps(4);
      setTimeout(() => {
        dispatch(action.unlockScreen(true));
      }, 0)
    }
  }, [contacts])

  useEffect(() => {
    dispatch(action.getApi((props.match.params as any).id));
    dispatch(action.getApiPrice((props.match.params as any).id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action])

  const save = () => {
    dispatch(action.saving(emailTecObj, emailFinObj, emailBusinessObj, userObj.cpf != null ? userObj : undefined, organizationObj));
  }

  const cancelModal = () => {
    setModalVisible(false);
  }

  const descarteChange = () => {
    unblock.current();
    history.push(goTo);
    setModalVisible(false);
  }

    return <div>
      <HeaderLayout hideSearch={true} padding='0 32px' />
      { unlockScreen &&
      <PrepaidStyled grid={grid}>
        <div className='step-screen'>
          <div className='border-top' />
          <div className='border-bottom' />
          {title && <div className='steps'>
            <Progress type="circle" width={36} strokeColor={theme.currentTheme.colorBrandPrimary400} percent={Math.round(currentStep / totalSteps * 100)} format={() => ``} />
            <div>
              <TypographyGr1d fontWeight='400' color='colorBrandPrimary400' fontSize='12px'>Passo {currentStep} de {totalSteps}</TypographyGr1d>
              {nextStep && <TypographyGr1d fontWeight='400' color='colorPrimitive700' fontSize='12px'>{nextStep}</TypographyGr1d>}
            </div>
          </div>}
          {title && <div className='titles'>
            <TypographyGr1d style={{ paddingTop: 32 }} fontWeight='600' fontSize='24px' color='colorPrimitiveBlack'>{title}</TypographyGr1d>
            <TypographyGr1d style={{ paddingTop: 16 }} fontWeight='400' fontSize='14px' color='colorPrimitive500'>{subTitle}</TypographyGr1d>
          </div>}
          {user != null && <Switch>
            <Route exact path="/prepaid/:id/user">
              <PrepaidUser setNextStep={setNextStep} setTitle={setTitle} setSubTitle={setSubTitle} setCurrentStep={setCurrentStep}
                setUserObj={setUserObj} userObj={userObj} user={user} form={formUser} />
            </Route>
            <Route exact path="/prepaid/:id/organization">
              <PrepaidOrganization setNextStep={setNextStep} setTitle={setTitle} setSubTitle={setSubTitle} setCurrentStep={setCurrentStep}
                setOrganizationObj={setOrganizationObj} organizationObj={organizationObj} step={currentStep} form={formOrganization} />
            </Route>
            <Route exact path="/prepaid/:id/contacts">
              <PrepaidContacts setNextStep={setNextStep} setTitle={setTitle} setSubTitle={setSubTitle} setCurrentStep={setCurrentStep}
                emailBusinessObj={emailBusinessObj} emailFinObj={emailFinObj} emailTecObj={emailTecObj}
                setEmailBusinessObj={setEmailBusinessObj} setEmailFinObj={setEmailFinObj} setEmailTecObj={setEmailTecObj}
                setShowReviewModal={setTriggerReviewModal} step={currentStep} form={formContacts} />
            </Route>
            <Route exact path="/prepaid/:id/consumption">
              <PrepaidConsumption setShowReviewModal={setShowReviewModal} price={price} setNextStep={setNextStep} setTitle={setTitle} setSubTitle={setSubTitle} setCurrentStep={setCurrentStep}
                setCalls={setCalls} calls={calls} form={formConsumption} />
            </Route>
            <Route exact path="/prepaid/:id/success">
              <PrepaidSuccess setTitle={setTitle} />
            </Route>
            <Route exact path="/prepaid/:id/payment">
              <PrepaidPayment unblock={unblock} calls={calls} apiId={(props.match.params as any).id} setCurrentStep={setCurrentStep} setNextStep={setNextStep} setSubTitle={setSubTitle} setTitle={setTitle} />
            </Route>
          </Switch>}
        </div>
        <div className='review-screen'>
          <TypographyGr1d style={{ paddingBottom: 24, paddingTop: 56 }} fontSize='24px' component='headingSM' color='colorPrimitiveBlack'>Revise sua compra</TypographyGr1d>
          <div className='api-card'>
            <div className='api-card-header'>
              <img width='56px' height='56px' src={`/core/api/v1/apis/${api?.id}/image`} alt='logo' onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} />
              <div>
                <TypographyGr1d fontWeight='600' color='colorPrimitive700' component='subtitleSM'>{api?.name}</TypographyGr1d>
                {api?.provider && <TypographyGr1d color='colorPrimitive600' component='bodySM'>por {api?.provider}</TypographyGr1d>}
              </div>
            </div>
            <div className='api-card-version'>
              <div>Versão</div>
              <div style={{ textAlign: 'right' }}>{api?.version}</div>
            </div>
            {title != null ? <div>
              <TypographyGr1d style={{ paddingTop: 8 }} fontSize='14px' fontWeight='700' color='colorPrimitive700'>Modelo de cobrança</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 4 }} fontSize='14px' fontWeight='400' color='colorPrimitive700'>O valor será de acordo com a quantidade de chamadas que você escolher para esta API. </TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 8 }} fontSize='12px' fontWeight='400' color='colorPrimitive500'>Valor por chamada {formatCurrency.format(price / 100)} </TypographyGr1d>
              {calls > 0 && <div>
                <TypographyGr1d fontSize='12px' fontWeight='400' color='colorPrimitive500'>Qtd. de chamadas: {calls} </TypographyGr1d>
                <TypographyGr1d onClick={() => {
                  setCurrentStep(x => x - 1);
                  history.push(`/prepaid/${(props.match.params as any).id}/consumption`)
                }} style={{ paddingTop: 8, cursor: 'pointer' }} fontSize='14px' fontWeight='500' color='colorBrandPrimary400'>Alterar quantidade de chamadas</TypographyGr1d>
                <TypographyGr1d style={{ paddingTop: 24 }} fontSize='14px' fontWeight='700' color='colorPrimitive700'>Total</TypographyGr1d>
                <TypographyGr1d style={{ paddingTop: 4, fontSize: 18 }} fontWeight='700' color='colorPrimitive700'>{formatCurrency.format((price * calls) / 100)}</TypographyGr1d>
              </div>}
            </div> : <div>
              <TypographyGr1d style={{ paddingTop: 24 }} fontSize='14px' fontWeight='700' color='colorPrimitive700'>Quantidade de chamadas</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 4 }} fontSize='14px' fontWeight='400' color='colorPrimitive700'>{calls}</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 24 }} fontSize='14px' fontWeight='700' color='colorPrimitive700'>Valor por chamadas</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 4 }} fontSize='14px' fontWeight='400' color='colorPrimitive700'>{formatCurrency.format(price / 100)}</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 24 }} fontSize='14px' fontWeight='700' color='colorPrimitive700'>Total</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 4, fontSize: 18 }} fontWeight='700' color='colorPrimitive700'>{formatCurrency.format((price / 100) * calls)}</TypographyGr1d>
              <TypographyGr1d style={{ paddingTop: 4 }} fontSize='12px' fontWeight='300' color='colorPrimitive500'>Pagamento à vista - 1x de {formatCurrency.format((price / 100) * calls)}</TypographyGr1d>
            </div>}
          </div>
          <div className='safe-prepaid'>
            <Icon component={lock} />
            <TypographyGr1d component='bodySM' color='colorPrimitive500'>Transação segura por Stripe</TypographyGr1d>
          </div>
        </div>
      </PrepaidStyled>}
      <Modal className='modal-prepaid' title={<TypographyGr1d fontWeight='500' fontSize='20px' color='colorPrimitiveBlack'>Confirmar dados</TypographyGr1d>}
        footer={<div>
          <div style={{ justifyContent: 'space-between', display: 'flex' }} className="buttons">
            <ButtonGr1d disabled={loadingSave} type="tertiary" onClick={() => {
              if (!loadingSave)
                setShowReviewModal(false)
            }}>Voltar</ButtonGr1d>
            <ButtonGr1d loading={loadingSave} onClick={save}>Confirmar cadastro</ButtonGr1d>
          </div>
        </div>}
        visible={showReviewModal} onCancel={() => {
          if (!loadingSave)
            setShowReviewModal(false)
        }} width="480px">
        <PrepaidConfirmStyled>
          <TypographyGr1d style={{ paddingBottom: 16 }} fontWeight='400' fontSize='14px' color='colorPrimitive600'>
            Confira com atenção os dados fornecidos.
            Após a confirmação do cadastro, para alterar o CPF e o CNPJ da empresa você deverá entrar em contato com o suporte da Gr1d.
          </TypographyGr1d>
          {userObj.cpf && <div className='prepaid-modal-user'>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>CPF</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{userObj.cpf}</TypographyGr1d>
            </div>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>Número de telefone</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{userObj.phone}</TypographyGr1d>
            </div>
          </div>}
          {organizationObj.cnpj && <div className='prepaid-modal-company'>
            <TypographyGr1d style={{ paddingBottom: 8 }} fontWeight='700' fontSize='14px' color='colorPrimitive700'>Empresa</TypographyGr1d>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>Nome fantasia</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{organizationObj.nickname}</TypographyGr1d>
            </div>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>Telefone da empresa</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{organizationObj.phone}</TypographyGr1d>
            </div>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>CNPJ</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{organizationObj.cnpj}</TypographyGr1d>
            </div>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>Razão Social</TypographyGr1d>
              <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>{organizationObj.name}</TypographyGr1d>
            </div>
            <div className='prepaid-modal-info'>
              <TypographyGr1d className='prepaid-modal-info-title' fontWeight='400' fontSize='14px' color='colorPrimitive700'>Endereço</TypographyGr1d>
              <TypographyGr1d style={{ whiteSpace: 'pre-line' }} fontWeight='400' fontSize='14px' color='colorPrimitive500'>{organizationObj.address}</TypographyGr1d>
            </div>
          </div>}
        </PrepaidConfirmStyled>
      </Modal>
      <Modal width={480} centered onCancel={() => cancelModal()} visible={modalVisible} title={<TypographyGr1d component='subtitleMD'
        color='colorPrimitiveBlack'>As informações serão descartadas</TypographyGr1d>} footer={<div style={{ display: 'flex' }}>
          <ButtonGr1d onClick={() => cancelModal()} style={{ flex: 1 }} type='primitive'>Voltar</ButtonGr1d>
          <ButtonGr1d onClick={() => descarteChange()} type='danger'>Descartar alterações e sair</ButtonGr1d>
        </div>}>
        <TypographyGr1d style={{ paddingTop: 21 }} font='Manrope' fontWeight='400' fontSize='14px' color='colorPrimitive600'>
          Ao sair desta página, as informações fornecidas até agora serão descartadas. Deseja continuar?
        </TypographyGr1d>
      </Modal>
    </div>;
}

export default Prepaid;