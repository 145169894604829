import styled from "styled-components"

const UseCase = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const UseCaseStyled = styled(UseCase)`
    max-width: 1280px;
    padding: 0 20px;
    margin: auto;

    .header {
        margin-top: 48px;
        margin-bottom: 96px;
        display: flex;
    }

    .description {
        flex: 1;
    }

    .image {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        width: 460px;
        height: 290px;
        > img {
            object-fit: scale-down;
            max-width: 400px;
        }
    }

    .apis-title {
        padding: 16px;
    }

    .questions {
        position: relative;
        margin-bottom: 96px;
        
        .dots {
            position: absolute;
            right: 0px;
        }
    }

    .carousel-card {
        display: flex !important;
    }

    .slick-dots-bottom {
        bottom: -24px;
    }

    .div-carousel-card {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        gap: 16px;

        .cards {
            gap: 16px;

            > div {
                width: 360px;
            }
        }

        .ant-carousel {
            width: 100%;
            max-width: 1116px;
        }
    }
`