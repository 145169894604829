import { Spin } from "antd";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { CEPModel } from "models/cep";
import { CNPJModelResponse } from "models/cnpj";
import { CompanyModel } from "models/company";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { companyActions } from "store/admin/company/action";
import Modal from 'components/portal/modal/index'
import InputGr1d from "components/portal/input";
import { GeneralInfoStyled } from "./generalnfo.styled";
import InfoCookies from 'assets/svg/infoCookies.svg'
import { loginActions } from "store/login/action";
import { useHistory } from "react-router";
import { UserModel } from "models/user";

const CompanyGeneral: React.FC = () => {
  const dispatch = useDispatch();
  const action = useAction(companyActions());
  const loginAction = useAction(loginActions());
  const form = useForm();
  const { handleSubmit } = form;
  const history = useHistory()

  const [phone, setPhone] = useState<string>('');
  const [lastPhone, setLastPhone] = useState<string>('');
  const [mask, setMask] = useState<string>('(11) 1111-1111');
  const cnpj: CNPJModelResponse | undefined = useSelector((state: any) => state.company?.cnpj);
  const user: UserModel | undefined = useSelector((state: any) => state.login?.user);
  const cep: CEPModel | undefined = useSelector((state: any) => state.company?.cep);
  const loadingCnpj: boolean = useSelector((state: any) => state.company?.loadingCnpj);
  const loadingCep: boolean = useSelector((state: any) => state.company?.loadingCep);
  const company: CompanyModel | undefined = useSelector((state: any) => state.company?.item);
  const pageToRedirectAfterCreateCompany: string | undefined = useSelector((state: any) => state.company?.pageToRedirectAfterCreateCompany);
  const loadingSave: boolean | undefined = useSelector((state: any) => state.company?.loadingSave);
  const saveSuccess: boolean = useSelector((state: any) => state.company?.saveSuccess);
  const [, setUpdate] = useState<boolean>(false);
  const [userCPF, setUserCPF] = useState("");
  const [userCPFRequested, setUserCPFRequested] = useState(false);
  const [showInputCPFModal, setShowInputCPFModal] = useState(false);
  const [showConfirmDataModal, setShowConfirmDataModal] = useState(false);

  function confirmData() {
    setShowInputCPFModal(false)
    setShowConfirmDataModal(true)
  }

  function requestUserCPF() {
    setUserCPFRequested(true)
    setShowInputCPFModal(true)
  }

  function sendData() {
    setShowConfirmDataModal(false);
    dispatch(action.saveCompany({
      cnpj: form.getValues().cnpj.replaceAll(/_|-|[/]|[.]/g, ''),
      name: form.getValues().name,
      endereco: {
        bairro: form.getValues().district,
        cep: form.getValues().cep,
        cidade: form.getValues().city,
        complemento: form.getValues().complement,
        nomeRua: form.getValues().address,
        numero: form.getValues().number,
        uf: form.getValues().state
      },
      razaoSocial: form.getValues().razao,
      telefone: form.getValues().phone,
      id: company?.id,
      cpfResponsavel: userCPFRequested ? userCPF.replace(/\./g, '').replace('-', '') : user!.cpf,
    }, userCPFRequested ? userCPF.replace(/\./g, '').replace('-', '') : undefined));
  }

  const save = (data: any) => {
    if (user?.cpf === undefined) {
      requestUserCPF()
    } else if (user?.orgId === null) {
      confirmData()
    } else {
      sendData()
    }
  }

  useEffect(() => {
    if (saveSuccess && userCPFRequested) {
      setUserCPFRequested(false)
    }

    const companyCreationRequested = (user && user.orgId === null)

    if (userCPFRequested || companyCreationRequested) {
      dispatch(loginAction.forceRefreshToken())
    }

    if (saveSuccess && companyCreationRequested && pageToRedirectAfterCreateCompany !== undefined) {
      history.push(pageToRedirectAfterCreateCompany)
      dispatch(action.setPageToRedirectAfterCreateCompany(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSuccess])

  useEffect(() => {
    if (company != null && company.endereco != null) {
      form.setValue('cnpj', company.cnpj);
      form.setValue('name', company.name);
      form.setValue('razao', company.razaoSocial);
      form.setValue('phone', company.telefone);
      form.setValue('cep', company.endereco.cep);
      form.setValue('address', company.endereco.nomeRua);
      form.setValue('number', company.endereco.numero);
      form.setValue('complement', company.endereco.complemento);
      form.setValue('district', company.endereco.bairro);
      form.setValue('city', company.endereco.cidade);
      form.setValue('state', company.endereco.uf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  useEffect(() => {
    if (lastPhone.length > phone.length) {
      setMask('(11) 1111-1111');
    } else if (phone.length < 11) {
      setMask('(11) 1111-1111');
    } else {
      setMask('(11) 11111-1111')
    }
  }, [phone, lastPhone])

  useEffect(() => {
    if (cnpj?.message != null) {
      form.setError('cnpj', 'focus', cnpj.message);
    } else if (cnpj != null) {
      let newPhone = cnpj.telefone.replace('(0', '(');
      if (newPhone.indexOf('/') !== -1) {
        newPhone = newPhone.substring(0, newPhone.indexOf('/'));
      }

      newPhone = newPhone.replaceAll(/_|-|[)]|[(]/g, '').replaceAll(' ', '');

      if (newPhone.length <= 10) {
        setMask('(11) 1111-1111');
      }
      form.setValue('name', cnpj.fantasia);
      form.setValue('razao', cnpj.nome);
      form.setValue('phone', newPhone);
      form.setValue('cep', cnpj.cep);
      form.setValue('address', cnpj.logradouro);
      form.setValue('number', cnpj.numero);
      form.setValue('complement', cnpj.complemento);
      form.setValue('district', cnpj.bairro);
      form.setValue('city', cnpj.municipio);
      form.setValue('state', cnpj.uf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj])

  useEffect(() => {
    if (cep?.erro == null && cep != null) {
      form.setValue('address', cep.logradouro);
      form.setValue('district', cep.bairro);
      form.setValue('city', cep.localidade);
      form.setValue('state', cep.uf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep])

  const getCnpjInfo = (v: any) => {
    if (v?.target?.value != null) {
      const cnpj: string = v.target.value.replaceAll(/_|-|[/]|[.]/g, '');
      if (cnpj.length === 14) {
        dispatch(action.getCnpjInfo(cnpj));
      }
    }
  }

  const getCepInfo = (v: any) => {
    if (v?.target?.value != null) {
      const cep: string = v.target.value.replaceAll(/_|-|[/]|[.]/g, '');
      if (cep.length === 8) {
        dispatch(action.getCepInfo(cep));
      }
    }
  }

  const reset = () => {
    form.reset();
    setUpdate(x => !x);
  }

  function isValidCPF(cpf: any) {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = cpf.split('');
    const validator = cpf
      .filter((digit: any, index: number, array: string | any[]) => index >= array.length - 2 && digit)
      .map((el: string | number) => +el);
    const toValidate = (pop: number) => cpf
      .filter((digit: any, index: number, array: string | any[]) => index < array.length - pop && digit)
      .map((el: string | number) => +el);
    const rest = (count: number, pop: number) => (toValidate(pop)
      .reduce((soma: number, el: number, i: number) => soma + el * (count - i), 0) * 10) % 11 % 10;
    const isValid = !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
    return isValid;
  }

  const formConfigData: ConfigFormItem[] = [
    {
      name: 'name',
      typeComponent: 'input',
      label: 'Nome da empresa',
      placeholder: 'Digite o nome da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'cnpj',
      typeComponent: 'masked-input',
      label: 'CNPJ',
      mask: "11.111.111/1111-11",
      disabled: company?.endereco != null,
      onChange: (v) => {
        getCnpjInfo(v);
        form.clearError('cnpj');
        setUpdate(x => !x);
      },
      placeholder: 'Digite o cnpj da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'razao',
      typeComponent: 'input',
      label: 'Razão social',
      placeholder: 'Digite a razão social da empresa',
      disabled: true,
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'phone',
      typeComponent: 'masked-input',
      label: 'Telefone',
      mask: mask,
      disabled: !user?.isAdmin,
      onChange: (v) => {
        setLastPhone(phone);
        setPhone((v.target.value as string).replaceAll(/_|-|[)]|[(]/g, ''));
      },
      onBlur: (v) => {
        if (phone.length === 11)
          form.setValue('phone', phone.replace('_', ''));
      },
      placeholder: 'Digite o telefone da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
  ]

  const formConfigAddress: ConfigFormItem[] = [
    {
      name: 'cep',
      typeComponent: 'masked-input',
      label: <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          CEP
        </div>
        <TypographyGr1d onClick={() => window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank')} style={{ cursor: 'pointer' }} font='Manrope' fontWeight='400' fontSize='12px' color='colorBrandPrimary400'>
          Não sei meu CEP
        </TypographyGr1d>
      </div>,
      onChange: (v) => {
        getCepInfo(v);
        form.clearError('cep');
        setUpdate(x => !x);
      },
      mask: "11.111-111",
      span: 24,
      md: 10,
      placeholder: 'Digite o cep da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'address',
      typeComponent: 'input',
      label: 'Nome da rua, avenida, estrada, travessa, etc.',
      placeholder: 'Digite o endereço da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'number',
      typeComponent: 'input',
      label: 'Número',
      placeholder: 'Digite o número do endereço da empresa',
      span: 24,
      md: 14,
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'complement',
      typeComponent: 'input',
      label: 'Complemento (apartamento, casa, bloco etc)',
      placeholder: 'Digite o complemento do endereço da empresa',
      span: 24,
      md: 14,
      disabled: !user?.isAdmin,
    },
    {
      name: 'district',
      typeComponent: 'input',
      label: 'Bairro',
      placeholder: 'Digite o bairro da empresa',
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'city',
      typeComponent: 'input',
      label: 'Cidade',
      placeholder: 'Digite a cidade da empresa',
      span: 24,
      md: 14,
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    },
    {
      name: 'state',
      typeComponent: 'input',
      label: 'Estado (UF)',
      placeholder: 'Digite o estado da empresa',
      span: 24,
      md: 10,
      rules: { required: { value: true, message: 'Campo obrigatório' } },
      disabled: !user?.isAdmin,
    }
  ]

  return <GeneralInfoStyled>
    <Spin spinning={loadingCnpj}>
      <FormAdmin onSubmit={handleSubmit(save)} onReset={reset}>
        <FormItensAdmin form={form} config={formConfigData} labelTitle={{ title: 'Dados cadastrais', subtitle: 'Informações de identificação da sua empresa.' }} />
        <div className='hr' />
        <Spin spinning={loadingCep}>
          <FormItensAdmin form={form} config={formConfigAddress} labelTitle={{ title: 'Endereço', subtitle: 'Dados de localização da empresa. Ao informar o CEP, vários campos serão preenchidos ou atualizados automaticamente.' }} />
        </Spin>
        <div style={{ marginTop: 44, marginBottom: 16 }} className='hr' />
        <div className='btn-general'>
          <ButtonGr1d disabled={!user?.isAdmin} htmlType='reset' style={{ flex: 1 }} type='dangersecondary'>
            Descartar alterações
          </ButtonGr1d>
          <ButtonGr1d disabled={!user?.isAdmin} loading={loadingSave} htmlType='submit' type='feedback'>
            Salvar mudanças
          </ButtonGr1d>
        </div>
      </FormAdmin>
      <Modal show={showInputCPFModal} width="480px" modalActions={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonGr1d type="secondary" onClick={() => setShowInputCPFModal(false)}> Voltar</ButtonGr1d>
          <ButtonGr1d type="primary" disabled={!userCPF.match(/\d\d\d\.\d\d\d\.\d\d\d-\d\d/g) || !isValidCPF(userCPF)} onClick={() => confirmData()}> Próximo</ButtonGr1d>
        </div>
      )}>
        <TypographyGr1d font="Manrope" fontWeight="500" color="colorPrimitiveBlack" fontSize="20px">Complete seu cadastro</TypographyGr1d>
        <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive600" fontSize="14px">Para finalizar o cadastro da sua empresa, é necessário fornecer as seguintes informações:</TypographyGr1d>
        <InputGr1d value={userCPF} onChange={(e) => { setUserCPF(e.target.value) }} style={{ marginTop: "20px", marginBottom: "10px" }} label="CPF" type="masked-input" mask="111.111.111-11" errorMessage={userCPF.match(/\d\d\d\.\d\d\d\.\d\d\d-\d\d/g) && !isValidCPF(userCPF) ? 'CPF informado inválido' : undefined} />
      </Modal>
      <Modal show={showConfirmDataModal} width="480px" modalActions={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonGr1d type="secondary" onClick={() => { if (userCPFRequested) { setShowConfirmDataModal(false); setShowInputCPFModal(true); } else { setShowConfirmDataModal(false) } }}> Voltar</ButtonGr1d>
          <ButtonGr1d type="primary" onClick={() => { sendData() }}> Confirmar cadastro</ButtonGr1d>
        </div>
      )}>
        <TypographyGr1d font="Manrope" fontWeight="500" color="colorPrimitiveBlack" fontSize="20px">Revise as informações</TypographyGr1d>
        <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive600" fontSize="14px">Confira com atenção os dados fornecidos.</TypographyGr1d>
        <div className="cnpjAlert">
          <div className="icon">
            <img style={{ width: "15px", marginRight: "10px", marginTop: "-10px" }} alt="Info" src={InfoCookies} />
          </div>
          <div className="text">
            <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive600" fontSize="12px">Após a confirmação do cadastro, para alterar o CNPJ da empresa você deverá entrar em contato com o suporte da Gr1d.</TypographyGr1d>
          </div>
        </div>
        {userCPFRequested &&
          <div className="account-data-container">
            <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="600" color="colorPrimitive700" fontSize="14px">Sua conta</TypographyGr1d>
            <div className="review-row">
              <div className="review-column">
                <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive700" fontSize="14px">CPF</TypographyGr1d>
              </div>
              <div className="review-column">
                <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive500" fontSize="14px">{userCPF}</TypographyGr1d>
              </div>
            </div>
          </div>
        }
        <div className="company-data-container">
          < TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="600" color="colorPrimitive700" fontSize="14px">Empresa</TypographyGr1d>
          <div className="review-row">
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive700" fontSize="14px">CNPJ</TypographyGr1d>
            </div>
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive500" fontSize="14px">{form.getValues().cnpj}</TypographyGr1d>
            </div>
          </div>
          <div className="review-row">
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive700" fontSize="14px">Razão Social</TypographyGr1d>
            </div>
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive500" fontSize="14px">{form.getValues().razao}</TypographyGr1d>
            </div>
          </div>
          <div className="review-row">
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive700" fontSize="14px">Endereço</TypographyGr1d>
            </div>
            <div className="review-column">
              <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive500" fontSize="14px">{form.getValues().address}, {form.getValues().number} <br /> {form.getValues().district}, {form.getValues().city} - {form.getValues().state} <br /> {form.getValues().cep} </TypographyGr1d>
            </div>
          </div>
        </div>
      </Modal>
    </Spin>
  </GeneralInfoStyled>;
}

export default CompanyGeneral;