import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import React, { useEffect } from "react";
import { useState } from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as edit } from "assets/svg/admin/company/edit.svg";
import { ReactComponent as deleteSvg } from "assets/svg/admin/company/delete.svg";
import _ from "lodash";
import { PropsPrepaid } from ".";
import { useHistory } from "react-router";
import { isEmail } from "utils/utils";

type Props = {
  step?: number,
  emailTecObj: string[],
  setEmailTecObj: React.Dispatch<React.SetStateAction<string[]>>,
  emailFinObj: string[],
  setEmailFinObj: React.Dispatch<React.SetStateAction<string[]>>,
  emailBusinessObj: string[],
  setEmailBusinessObj: React.Dispatch<React.SetStateAction<string[]>>,
  setShowReviewModal: React.Dispatch<React.SetStateAction<boolean>>
}

const PrepaidContacts: React.FC<Props & PropsPrepaid> = (props) => {
  const { handleSubmit } = props.form;
  const history = useHistory();
  const [countTec, setCountTec] = useState<number>(0);
  const [listTec, setListTec] = useState<number[]>([0]);
  const [countFin, setCountFin] = useState<number>(0);
  const [listFin, setListFin] = useState<number[]>([0]);
  const [countBusiness, setCountBusiness] = useState<number>(0);
  const [listBusiness, setListBusiness] = useState<number[]>([0]);

  useEffect(() => {
    props.setNextStep('Próximo: Estimativa de uso');
    props.setTitle('Informações de contato da sua empresa');
    props.setSubTitle('Mantenha as informações de contato sempre atualizadas. É possível alterá-las após o cadastro acessando o menu Minha Empresa no Portal Gr1d.');
  }, [props])

  useEffect(() => {
    if (props.emailTecObj.length > 0) {
      setListTec(_.range(props.emailTecObj.length));
      setTimeout(() => {
        _.forEach(_.range(props.emailTecObj.length), x => {
          props.form.setValue(`emailTec${x}`, props.emailTecObj[x]);
        })
      }, 0)
    }
    if (props.emailFinObj.length > 0) {
      setListTec(_.range(props.emailFinObj.length));
      setTimeout(() => {
        _.forEach(_.range(props.emailFinObj.length), x => {
          props.form.setValue(`emailFin${x}`, props.emailFinObj[x]);
        })
      }, 0)
    }
    if (props.emailBusinessObj.length > 0) {
      setListTec(_.range(props.emailBusinessObj.length));
      setTimeout(() => {
        _.forEach(_.range(props.emailBusinessObj.length), x => {
          props.form.setValue(`emailBusiness${x}`, props.emailBusinessObj[x]);
        })
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data: any) => {
    addEmails(data);
    props.setShowReviewModal(true);
  }

  const back = () => {
    addEmails(props.form.getValues());
    props.setCurrentStep(x => x! - 1);
    history.push(history.location.pathname.replace('/contacts', '/organization'));
  }

  const addEmails = (data: any) => {
    let emailsTec: string[] = [];
    let emailsFin: string[] = [];
    let emailsBusiness: string[] = [];
    _.forEach(data, (x, i) => {
      if (i.includes('emailTec'))
        emailsTec.push(x);
      else if (i.includes('emailFin'))
        emailsFin.push(x);
      else if (i.includes('emailBusiness'))
        emailsBusiness.push(x);
    });
    props.setEmailTecObj(emailsTec);
    props.setEmailFinObj(emailsFin);
    props.setEmailBusinessObj(emailsBusiness);
  }

  const deleteEmail = (type: string, id: number) => {
    if (type === 'emailTec') {
      setListTec(listTec.filter((x) => x !== id));
    } else if (type === 'emailFin') {
      setListFin(listFin.filter((x) => x !== id));
    } else if (type === 'emailBusiness') {
      setListBusiness(listBusiness.filter((x) => x !== id));
    }
  }

  const validateEmail = (type: string, email: string) => {
    props.form.clearError();
    if (isEmail(email)) {
      let cont = 0;
      _.forEach(props.form.getValues(), (x, i) => {
        if (i.includes(type) && x === email) {
          cont = cont + 1;
        }
      });
      if (cont > 1) {
        return 'Email já incluido'
      }
    }
  }

  const formConfigTec: ConfigFormItem[] = [...listTec.map((x) => {
    return {
      name: `emailTec${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      placeholder: 'ex.: tech@empresa.com.br',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailTec', value);
        }
      },
      elementAfter: listTec.length > 1 ? <Icon onClick={() => deleteEmail('emailTec', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailTecBtn`,
    typeComponent: 'custom',
    noLabel: true,
    element: <div>
      <ButtonGr1d onClick={() => { setListTec([...listTec, countTec + 1]); setCountTec(countTec + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  const formConfigFin: ConfigFormItem[] = [...listFin.map((x) => {
    return {
      name: `emailFin${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      placeholder: 'ex.: financeiro@empresa.com.br',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailFin', value);
        }
      },
      elementAfter: listFin.length > 1 ? <Icon onClick={() => deleteEmail('emailFin', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailFinBtn`,
    typeComponent: 'custom',
    noLabel: true,
    element: <div>
      <ButtonGr1d onClick={() => { setListFin([...listFin, countFin + 1]); setCountFin(countFin + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  const formConfigBusiness: ConfigFormItem[] = [...listBusiness.map((x) => {
    return {
      name: `emailBusiness${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      placeholder: 'ex.: contato@empresa.com.br',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailBusiness', value);
        }
      },
      elementAfter: listBusiness.length > 1 ? <Icon onClick={() => deleteEmail('emailBusiness', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailBusinessBtn`,
    typeComponent: 'custom',
    noLabel: true,
    element: <div>
      <ButtonGr1d onClick={() => { setListBusiness([...listBusiness, countBusiness + 1]); setCountBusiness(countBusiness + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  return <div>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={props.form} config={formConfigTec} labelTitle={{ title: 'Contato técnico', subtitle: 'Recebe notificações de problemas técnicos encontrados nas APIs ou atualizações de versões nas APIs consumidas.', inRow: true }} />
      <div style={{ marginTop: 0, marginBottom: 16 }} className='hr' />
      <FormItensAdmin form={props.form} config={formConfigFin} labelTitle={{ title: 'Contato financeiro', subtitle: 'Recebe notificações referentes a faturas de APIs consumidas ou informações de recebimentos de APIs fornecidas.', inRow: true }} />
      <div style={{ marginTop: 0, marginBottom: 16 }} className='hr' />
      <FormItensAdmin form={props.form} config={formConfigBusiness} labelTitle={{ title: 'Contato de negócios', subtitle: 'Recebe notificações referentes a dúvidas do negócio e planos de cobrança.', inRow: true }} />
      <div style={{ marginTop: 0, marginBottom: 16 }} className='hr' />
      <div style={{ justifyContent: 'space-between', display: 'flex', paddingTop: 12 }} className="buttons">
        {props.step !== 1 ? <ButtonGr1d type="tertiary" onClick={back}>Voltar</ButtonGr1d> : <div></div>}
        <ButtonGr1d htmlType='submit'>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
  </div>;
}

export default PrepaidContacts;