import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Spin } from 'antd';
import { PaymentIntentModel } from 'models/prepaid';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAction } from 'store';
import { portalPrepaidActions } from 'store/portal/prepaid/action';
import PrepaidCheckout from './checkoutForm';

type Props = {
  setNextStep: React.Dispatch<React.SetStateAction<string>>,
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
  setSubTitle: React.Dispatch<React.SetStateAction<string>>,
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  apiId: number,
  calls: number,
  unblock: React.MutableRefObject<any>
}

const PrepaidPayment: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const action = useAction(portalPrepaidActions());
  const payment: PaymentIntentModel | undefined = useSelector((state: any) => state.prepaid?.payment);
  const loadingPayment: boolean = useSelector((state: any) => state.prepaid?.loadingPayment);
  const [element, setElement] = useState<ReactNode>();

  useEffect(() => {
    dispatch(action.getPayment(props.calls, props.apiId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (payment != null) {
      const options = {
        clientSecret: payment.client,
      };
      setElement(<Elements stripe={stripePromise} options={options}>
        <PrepaidCheckout unblock={props.unblock} apiId={props.apiId} setCurrentStep={props.setCurrentStep} />
      </Elements>);
    } else {
      setElement(<Spin spinning={loadingPayment} />);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment])

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!, {
    locale: 'pt-BR'
  });

  useEffect(() => {
    props.setNextStep('');
    props.setTitle('Adicionar cartão de crédito');
    props.setSubTitle('Este método de pagamento será utilizado para realizar a cobrança referente a todas as suas APIs consumidas.');
  }, [props])

  return <div className='prepaid-payment'>
    {element}
  </div>
}

export default PrepaidPayment;