import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { helpActions } from "store/help/action";
import { HelpFormStyled } from "./index.styled";
import dots from "assets/svg/helpForm/dots.svg";
import ButtonGr1d from "components/portal/button";
import FormGr1d from "components/admin/form";
import { Checkbox, Upload } from "antd";
import Icon from '@ant-design/icons';
import { ReactComponent as arrow } from "assets/svg/helpForm/arrow-up.svg";
import { useForm } from "react-hook-form";
import { ReactComponent as mail } from "assets/svg/logon/mail.svg";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import { useAction } from "store";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { RcFile } from "antd/lib/upload";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { appActions } from "store/app/action";
import { match, useHistory } from "react-router-dom";
import { HelpState } from "store/help/reducer";
import { isValidPhoneNumber } from "utils/utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type Props = {
    match: match,
}

const HelpForm: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const actions = useAction(helpActions());
    const appAction = useAction(appActions());
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const helpFormState: HelpState | undefined = useSelector((state: any) => state.help);

    const subjects = useSelector((state: any) => state.help?.subjects);
    const user = useSelector((state: any) => state.login?.user);
    const [notification, setNotification] = useState<boolean>(true)
    const [phone, setPhone] = useState<string>('');
    const [lastPhone, setLastPhone] = useState<string>('');
    const [mask, setMask] = useState<string>('(11) 1111-1111');

    useEffect(() => {
        if (lastPhone.length > phone.length) {
            setMask('(11) 1111-1111');
        } else if (phone.length < 10) {
            setMask('(11) 1111-1111');
        } else {
            setMask('(11) 11111-1111')
        }
    }, [phone, lastPhone])

    useEffect(() => {
        dispatch(actions.getSubjects());
    }, [dispatch, actions]);


    function onChange(e: CheckboxChangeEvent) {
        setNotification(e.target.checked)
    }


    useEffect(() => {
        if ((props.match?.params as any).apiId != null) {
            dispatch(actions.getApiHelpForm((props.match?.params as any).apiId))
        }

        return () => {
            dispatch(actions.apiHelpFormDelete());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (helpFormState?.apiHelpForm != null) {
            form.setValue('description', `Desejo assinar a API ${helpFormState?.apiHelpForm.name} do provedor ${helpFormState?.apiHelpForm.provider}`);
            form.setValue('subject', 'Contratação de API');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helpFormState?.apiHelpForm])

    const upload = async ({ file, onSuccess, onError, onProgress }: RcCustomRequestOptions) => {
        try {
            // @ts-ignore
            const handleProgress = ({ loaded, total }) => {
                const progress = Math.round((loaded * 100) / total)
                if (onProgress) {
                    // @ts-ignore
                    onProgress({ percent: progress })
                }
            }
            const fileName = await actions.sendFile(file as RcFile, handleProgress);
            if (onSuccess) {
                // @ts-ignore
                onSuccess(fileName)
            }
        } catch (error: any) {
            if (onError) {
                onError(error)
            }
        }
    }

    const handleUploadChange = async ({ fileList }: UploadChangeParam) => {
        setFileList(fileList)
    }

    const form = useForm();
    const { handleSubmit } = form;

    const config: ConfigFormItem[] = [
        {
            name: 'name',
            label: 'Qual o seu nome?',
            placeholder: 'Digite seu nome!',
            typeComponent: 'input',
            defaultValue: user?.name || '',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'email',
            label: 'Seu melhor e-mail',
            placeholder: 'Digite seu e-mail!',
            typeComponent: 'input',
            defaultValue: user?.name || '',
            rules: {
                required: { value: true, message: 'Campo obrigatório' }, pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Email Inválido'
                }
            }
        },
        {
            name: 'phone',
            typeComponent: 'masked-input',
            label: 'Telefone para contato',
            mask: mask,
            onChange: (v) => {
                setLastPhone(phone);
                setPhone((v.target.value as string).replaceAll(/_|-|[)]|[(]/g, ''));
            },
            onBlur: (v) => {
                if (phone.length === 11) {
                    setMask('(11) 1111-1111');
                    setTimeout(() => {
                        form.setValue('phone', phone);
                        setMask('(11) 11111-1111');
                    }, 0)
                }
            },
            rules: {
                required: { value: true, message: 'Campo obrigatório' },
                validate: (value: string) => {
                    return isValidPhoneNumber(value);
                }
            }
        },
        {
            name: 'organization',
            label: 'Empresa',
            placeholder: 'Digite o nome de sua empresa',
            typeComponent: 'input',
            defaultValue: user?.name || '',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'subject',
            label: 'Assunto',
            placeholder: 'Selecione o assunto',
            typeComponent: 'select',
            data: subjects.map((item: string) => ({ value: item, title: item })),
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            name: 'description',
            label: 'Conte-nos sobre seu projeto ou dúvida :)',
            placeholder: 'Explique-nos aqui!',
            typeComponent: 'textarea',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        }
    ]


    useEffect(() => {
        if (user != null) {
            setTimeout(() => {
                form.setValue('name', user.name)
                form.setValue('email', user.email)
            }, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const send = async (data: any) => {
        try {
            setLoading(true);
            await actions.createHelp({
                name: data.name,
                email: data.email,
                subject: data.subject,
                text: data.description,
                phone: data.phone,
                organization: data.organization,
                anexos: fileList.map(item => item.response),
                notification: notification
            })
            history.push('/helpform/success');
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        dispatch(appAction.showQuestion(0));
        return () => {
            dispatch(appAction.showQuestion(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if ((props.match?.params as any).apiId !== 'success') {
        return <HelpFormStyled className={grid} style={{ margin: grid !== 'sm' ? '48px 0' : '10px 0' }}>
            <FormGr1d padding={grid !== 'sm' ? '30px' : '0'} onSubmit={handleSubmit(send)}>
                <div className='div-1280 all-screen'>
                    <div className='help-txt'>
                        {(props.match?.params as any).apiId == null ?
                            <div>
                                <TypographyGr1d style={{ marginBottom: 24 }} component='headingLG' color='colorPrimitiveBlack' >
                                    <>Olá! Estamos ansiosos <br /> para te ajudar.</>
                                </TypographyGr1d>
                                <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive500' >
                                    Você só precisa nos enviar uma mensagem!
                                </TypographyGr1d>
                            </div> :
                            <div>
                                <TypographyGr1d style={{ marginBottom: 24 }} component='headingLG' color='colorPrimitiveBlack' >
                                    <>Olá! A assinatura desta API <br /> está disponível sob consulta.</>
                                </TypographyGr1d>
                                <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive500' >
                                    Envie-nos uma mensagem e entraremos em contato!
                                </TypographyGr1d>
                            </div>
                        }
                        <div className='dots'>
                            <img width={200} alt='dots' src={dots} />
                        </div>
                    </div>
                    <div className='card-help'>
                        <FormItensAdmin form={form} config={config} />
                        <TypographyGr1d style={{ marginBottom: 8 }} component='bodySM' color='colorPrimitive500'>
                            Anexo de arquivo (opcional)
                        </TypographyGr1d>
                        <Upload customRequest={upload} onChange={handleUploadChange} multiple={true}>
                            <ButtonGr1d type='primitive' background='white' icon={arrow}>
                                Selecione um arquivo
                            </ButtonGr1d>
                        </Upload>
                        <Checkbox defaultChecked={true} style={{ background: 'white', marginTop: 8 }} checked={notification} onChange={onChange}>Desejo receber promoções, novidades e informações da Gr1d</Checkbox>
                        <ButtonGr1d loading={loading} disabled={fileList.some(item => item.status === 'uploading')} htmlType='submit' style={{ marginTop: 32 }} background='white' width='100%'>
                            Enviar
                        </ButtonGr1d>
                    </div>
                </div>
            </FormGr1d>
        </HelpFormStyled>;
    } else {
        return <></>;
    }
}

export default HelpForm;