import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const PolicyChangedModal = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>
export const PolicyChangedModalStyled = styled(PolicyChangedModal)`
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    border-radius: 8px;
    > div:nth-child(1) {
        a {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            text-decoration: underline;
        }
    }
`