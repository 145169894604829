import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { AdminLayoutStyled } from './index.styled';
import AdminLayoutMenu from './menu';
import { auth } from 'utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useAction } from 'store';
import { appActions } from 'store/app/action';

const { Content } = Layout;

const AdminTemplate: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const actions = useAction(appActions());
    const [child, setChild] = useState<React.ReactNode>(props.children);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        if (!auth.isAuthenticated())
            dispatch(actions.goTo('/login'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setChild(props.children);
    }, [props.updateChild, props.children])

    return <AdminLayoutStyled style={{ flexDirection: grid !== 'sm' ? 'row' : 'column' }}>
        <AdminLayoutMenu />
        <Layout style={{ height: grid !== 'sm' ? '100vh' : 'calc(100vh - 60px)' }} className='layout-admin'>
            <Content className='g-content-admin'>
                {child}
            </Content>
        </Layout>
    </AdminLayoutStyled>;
}

export default AdminTemplate;
