import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { notification } from "antd";
import { UploadFileAdminStyled } from "./file.styled";
import TypographyGr1d from "components/portal/typography";
import Icon, { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as pdf } from "assets/svg/admin/components/pdf.svg";
import moment, { Moment } from "moment";
import { FormContextValues } from "react-hook-form";

type Props = {
    height?: number,
    form: FormContextValues<any>,
    name: string
}

const UploadFileAdmin: React.FC<Props> = (props) => {
    const [file, setFile] = useState<{
        file: any,
        name: string,
        format: string,
        size: number,
        date: Moment
    } | undefined>();

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const beforeUpload = (file: any, files: any[]) => {
        if (file != null) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
                file.type !== 'application/msword' && file.type !== 'application/pdf') {
                notification.warning({
                    message: 'Arquivo em formato inválido',
                    placement: 'bottomLeft'
                })
            } else {
                const fReader: FileReader = new FileReader();
                fReader.onload = async function (event) {
                    const fileTemp = await getBase64(file);
                    const newFile = {
                        file: fileTemp,
                        name: file.name,
                        format: file.type,
                        size: file.size,
                        date: moment()
                    };
                    setFile(newFile);
                    props.form.setValue(props.name, newFile);
                }

                fReader.readAsText(file);
            }
        }
        return false;
    }

    function formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return <UploadFileAdminStyled>
        {file == null ? <Dragger showUploadList={false} beforeUpload={beforeUpload}>
            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                Arraste e solte ou clique para anexar arquivo
            </TypographyGr1d>
        </Dragger> : <div className='file'>
            <Icon component={pdf} />
            <div className='info'>
                <TypographyGr1d component='bodySM' color='colorPrimitive700'>{file.name}</TypographyGr1d>
                <TypographyGr1d component='caption' color='colorPrimitive500'>{file.date.format('DD MMM yyyy') + ' ás ' + file.date.format('hh:mm')} • {formatBytes(file.size)}</TypographyGr1d>
            </div>
            <a download={file.name} href={file.file}>
                <DownloadOutlined />
            </a>
            <div>
                <DeleteOutlined onClick={() => setFile(undefined)} />
            </div>
        </div>}
    </UploadFileAdminStyled>;
}

export default UploadFileAdmin;