import { ApiSavedModel } from "models/savedApi";
import { createApiSavedService } from "store/admin/savedApis/service";

export enum AdminApiSavedActionType {
    GET_SAVED_APIS = '[ADMIN_SAVED_APIS] Get Saved Apis',
    GET_SAVED_APIS_SUCCESS = '[ADMIN_SAVED_APIS] Get Saved Apis Success',
    GET_SAVED_APIS_ERROR = '[ADMIN_SAVED_APIS] Get Saved Apis Error',
    REMOVE_SAVED_API = '[ADMIN_SAVED_APIS] Remove Saved Apis',
    REMOVE_SAVED_API_SUCCESS = '[ADMIN_SAVED_APIS] Remove Saved Apis Success',
    REMOVE_SAVED_API_ERROR = '[ADMIN_SAVED_APIS] Remove Saved Apis Error',
    SAVE_API = '[ADMIN_SAVED_APIS] Save Api',
    SAVE_API_SUCCESS = '[ADMIN_SAVED_APIS] Save Api Success',
    SAVE_API_ERROR = '[ADMIN_SAVED_APIS] Save Api Error',
    SAVE_API_REMOVE = '[ADMIN_SAVED_APIS] Save Api Remove',
}

export interface adminApiSavedAction {
    getSavedApis(): void,
    removeSavedApi(id: string): void,
    saveApi(id: string): void,
    saveApiRemove(id: number): void
}

export const adminApiSavedActions = ((): adminApiSavedAction => {
    const apiSavedService = createApiSavedService();

    const actions = {
        getSavedApis: (): any => {
            return { type: AdminApiSavedActionType.GET_SAVED_APIS }
        },
        getSavedApiSuccess: (data: ApiSavedModel[]): any => {
            return { type: AdminApiSavedActionType.GET_SAVED_APIS_SUCCESS, payload: data }
        },
        getSavedApiError: (): any => {
            return { type: AdminApiSavedActionType.GET_SAVED_APIS_ERROR }
        },
        removeSavedApi: (): any => {
            return { type: AdminApiSavedActionType.REMOVE_SAVED_API }
        },
        removeSavedApiSuccess: (): any => {
            return { type: AdminApiSavedActionType.REMOVE_SAVED_API_SUCCESS }
        },
        removeSavedApiError: (): any => {
            return { type: AdminApiSavedActionType.REMOVE_SAVED_API_ERROR }
        },
        saveApi: (): any => {
            return { type: AdminApiSavedActionType.SAVE_API }
        },
        saveApiSuccess: (): any => {
            return { type: AdminApiSavedActionType.SAVE_API_SUCCESS }
        },
        saveApiError: (): any => {
            return { type: AdminApiSavedActionType.SAVE_API_ERROR }
        },
        saveApiRemove: (id: number): any => {
            return { type: AdminApiSavedActionType.SAVE_API_REMOVE, payload: id }
        },
    }

    function getSavedApis() {
        return (dispatch: any) => {
            dispatch(actions.getSavedApis());

            apiSavedService.getSavedApis().then((x: any) => {
                dispatch(actions.getSavedApiSuccess(x));
            }).catch((x: any) => {
                dispatch(actions.getSavedApiError());
            })
        }
    }

    function removeSavedApi(id: string) {
        return (dispatch: any) => {
            dispatch(actions.removeSavedApi());

            apiSavedService.removeApi(id).then(() => {
                dispatch(actions.removeSavedApiSuccess());
                dispatch(getSavedApis())
            }).catch(() => {
                dispatch(actions.removeSavedApiError());
            })
        }
    }

    function saveApi(id: string) {
        return (dispatch: any) => {
            dispatch(actions.saveApi());

            apiSavedService.saveApi(id).then(() => {
                dispatch(actions.saveApiSuccess());
            }).catch(() => {
                dispatch(actions.saveApiError());
            })
        }
    }

    function saveApiRemove(id: number) {
        return (dispatch: any) => {
            dispatch(actions.saveApiRemove(id));
        }
    }

    return { getSavedApis, removeSavedApi, saveApi, saveApiRemove }
})