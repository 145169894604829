import styled from 'styled-components';

const MultiSelectAdmin = (props: any) => {
    return <div className={props.className}>
        {props.children}
    </div>
}

export const MultiSelectAdminStyled = styled(MultiSelectAdmin)`
  
`;
