import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"
import home1 from "assets/jpg/home1.jpg";

const Home = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const HomeStyled = styled(Home)`
    @media only screen and (min-width:768px) {
        .productive {
            display: flex;
            align-items: center;
        }

        .advertiser-gr1d {
            display: flex;
            align-items: center;

            .txt {
                padding-left: 96px !important;
            }
        }
    }
    
    .productive-wrapper {
        background-image: url(${home1});
        background-color: #30184A;
        // background-size: 1280px auto;
        background-image: linear-gradient(to right, #30184A 50%, rgb(35, 19, 41) 51%);
        // background-repeat: no-repeat;
        // background-position: center;
        // border-radius: 0 0 200px 0;
        height: 407px;
        .productive-image {
            padding-top: 88px;
            width: 100%;
            height: 100%;
            background-size: 1380px auto;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(${home1});
        }
        .div-1380 {
            max-width: 1380px;
            margin: auto;
            // width: 1480px !important;
            position: relative;
            height: 100%;
        }
        .gradientLeft {
            left: -60px;
            top: -88px;
            height: calc(100% + 88px);
            width: 140px;
            // background-color: black;
            background-image: linear-gradient(to right, #30184A 60%, rgba(48, 24, 74, 0));
            position: absolute;
        }
        .gradientRight {
            right: -60px;
            top: -88px;
            height: calc(100% + 88px);
            width: 140px;
            // background-color: black;
            background-image: linear-gradient(to left, rgb(35, 19, 41) 60%, rgba(39, 20, 55, 0));
            position: absolute;
        }
        @media only screen and (max-width: 1380px) {
            .gradientLeft {
                display: none;
            }
            .gradientRight {
                display: none;
            }
        }
        .productive {
            > div {
                align-items: center;
            }
            
            .description {
                flex: 1;
                font-size: 16px;
            }

            .productivity {
                padding: 0px 2px;
                color: white;
                position: relative;
                display: inline-block;
                span {
                    z-index: 1;
                    position: relative;
                }
                .background-text {
                    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
                    position: absolute;
                    bottom: 10px;
                    left: -1%;
                    height: 25px;
                    width: 102%;
                    z-index: 0;
                }
            }

            .title {
                color: #14161F;
            }

            .worker {
                max-width: 100%;
            }

            .search {
                color: #727272;
            }
        }
    }

    .filter {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        div {
            cursor: pointer;
            line-height: 0;
        }

        div:first-child {
            cursor: initial;
        }
    }

    .high {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .all-txt {
            flex: 1;
        }

        .txt {
            font-size: 32px;
        }

        .sub-txt {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
        }

        .see-all {
            cursor: pointer;
        }
    }

    .home-sm .advertiser-gr1d {
        .ant-col {
            display: flex;
            justify-content: center;

            .txt {
                text-align: center;

                .white {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .advertiser-gr1d {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        width: 100%;
        padding: 40px 100px;

        > div {
            // align-items: center;
        }

        .div-1280 {
            width: 100%;
            height: 100%;
            > div {
                height: 100%;
                img {
                    width: 356px;
                    border-radius: 150px 150px 150px 50px;
                }
            }
        }

        img {
            flex: 1;
            width: 100%;
        }

        .txt {
            padding-left: 0px;
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            
            .title {
                color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
            }

            .subtitle {
                color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            }

            .api-tag {
                margin-top: 10px;
            }
        }
    }

    .use-cases {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .assign {
        position: relative;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        height: 232px;
        display: flex;

        .txt {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            
            .title {
                color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            }

            .subtitle {
                color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            }

            .fields {
                > form {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                }

                > form div:first-child {
                    width: 100%;
                }
            }
        }

        .newsletter {
            margin-left: 100px;
            display: flex;
            align-items: center;

            > div {
                flex: 1%;
            }
        }

        .dots {
            position: absolute;
            left: 20px;
            bottom: -30px;
        }
    }

    .home-sm.assign {
        .newsletter {
            margin-left: 0px;
            display: initial;
        }

        .fields {
            margin-top: 8px;
        }
    }

    .ant-card.ant-card-bordered {
        border: 1px solid #DBDDE5;
        border-radius: 8px;
    }

    .worker-div {
        display: flex;
        justify-content: flex-end;
    }

    .newsletter-assign {
        display: flex;
        justify-content: center;

        > span {
            margin-right: 14px;
        }
    }

    .home-sm {
        .cards {
            justify-content: center;
        }
        .use-cases {
            justify-content: center;
        }
    }
`