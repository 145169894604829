import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { formatCurrency } from "utils/utils";
import { PropsPrepaid } from ".";
import Icon from '@ant-design/icons';
import { ReactComponent as error } from "assets/svg/error.svg";

type Props = {
  price: number,
  calls: any,
  setCalls: React.Dispatch<React.SetStateAction<number>>,
  setShowReviewModal: React.Dispatch<React.SetStateAction<boolean>>
}

const PrepaidConsumption: React.FC<Props & PropsPrepaid> = (props) => {
  const history = useHistory();
  const { handleSubmit } = props.form;
  const [totalCall, setTotalCall] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();

  useEffect(() => {
    props.setCalls(0);
    props.setShowReviewModal(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    props.setNextStep('Próximo: Método de pagamento');
    props.setTitle('Calcule o consumo da API');
    props.setSubTitle('Insira a quantidade de chamadas que deseja para esta API.');
  }, [props])

  const formConfigData: ConfigFormItem[] = [
    {
      name: 'calls',
      typeComponent: 'number',
      max: 1000000000,
      min: 1,
      label: 'Quantidade de chamadas mensais',
      placeholder: 'Escreva a quantidade',
      helpText: '*Válido por 12 meses após a confirmação do pagamento.',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }
      }
    },
  ]

  const calculate = (data: any) => {
    setTotalCall(data.calls);
    setTotalPrice(data.calls * props.price);
  }

  const save = () => {
    props.setCurrentStep(x => x! + 1);
    props.setCalls(totalCall!);
    history.push(history.location.pathname.replace('/consumption', '/payment'));
  }

  return <div className='prepaid-consumption'>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(calculate)}>
      <div style={{ display: "flex", gap: 12 }}>
        <FormItensAdmin style={{ flex: 1 }} form={props.form} config={formConfigData} />
        <ButtonGr1d htmlType='submit' style={{ position: 'relative', top: 33 }} type='primitive'>Calcular</ButtonGr1d>
      </div>
      {totalPrice && <div className='price'>
        <TypographyGr1d fontWeight='400' fontSize='14px' color='colorPrimitive500'>Valor total</TypographyGr1d>
        {totalPrice >= 1000 ?
          <TypographyGr1d style={{ fontSize: 18 }} fontWeight='700' color='colorPrimitiveBlack'>{formatCurrency.format(totalPrice / 100)}</TypographyGr1d> :
          <TypographyGr1d style={{ fontSize: 18 }} fontWeight='700' color='colorFeedbackNegative300'>{formatCurrency.format(totalPrice / 100)}</TypographyGr1d>}
        <TypographyGr1d fontWeight='300' fontSize='12px' color='colorPrimitive500'>Qtd. de chamadas: {totalCall}</TypographyGr1d>
        <TypographyGr1d fontWeight='300' fontSize='14px' color='colorPrimitive500'>Valor por chamada: {formatCurrency.format(props.price / 100)}</TypographyGr1d>
        {totalPrice < 1000 &&
          <TypographyGr1d className='error' style={{ fontSize: 12 }} fontWeight='300' color='colorFeedbackNegative500'><Icon component={error}></Icon>Valor mínimo para a compra é de R$ 10,00.</TypographyGr1d>}
      </div>}
      <div style={{ justifyContent: 'flex-end', display: 'flex', paddingTop: 12 }} className="buttons">
        <ButtonGr1d disabled={totalPrice == null || totalPrice < 1000} onClick={save}>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
  </div>;
}

export default PrepaidConsumption;