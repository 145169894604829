import { createApiService } from "service";

export const createAppService = () => {
    const service = createApiService('/core/api/v1');

    const getCookiesTextVersion = async (): Promise<number> => {
        const terms = await service.get('/terms-policies');
        try {
            const lastCookies = (terms as any).reverse().filter((text: any) => text.type === 'cookies')
            return lastCookies[0].version
        } catch(e) {
            return 0
        }
        
    }

    return { getCookiesTextVersion }
}