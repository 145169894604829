import styled from "styled-components";
import { IGlobalStyle } from "theme/global.styles";

const OrgMembers: React.FC = (props: any) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

export const OrgMembersStyled = styled(OrgMembers)`
.header {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 33px;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    .headingLGBase {
        font-size: 38px;
        font-weight: 500;
    }
}
.content {
    padding: 32px;
    padding-top: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .description {
        .headingLGBase {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            font-size: 20px;
            font-weight: 500;
        }
    }
    .usersList {
        width: 60%;
        .ant-table-wrapper {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            border-radius: 7px;
            .ant-table-thead {
                .ant-table-cell:before {
                    display: none;
                }
                .admtitle {
                    display: flex;
                    justify-content: center;
                    .adminpermissionsinfo {
                        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
                    }
                }
                .deletetitle {
                    display: flex;
                    justify-content: center;
                }
            }
            .ant-table-tbody {
                .ant-table-cell {
                    padding: 8px 16px !important;
                }
                .ant-table-row:last-child {
                    .ant-table-cell {
                        border-bottom: unset !important;
                    }
                }
            }
        }
        .userCell {
            display: flex;
            .userphoto {
                img {
                    width: 40px;
                }
            }
            .userinfo {
                padding-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        .switch {
            display: flex;
            justify-content: center;
            width: 100%;
            .ant-switch-checked {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            }
        }
        .deleteuser {
            display: flex;
            justify-content: center;
            width: 100%;
            img {
                cursor: pointer;
            }
        }
    }
}

.deletemodalcontent {
    line-height: 24px;
    margin-top: 16px !important;
    span {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }
}
`