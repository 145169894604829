import { ApiSavedModel } from "models/savedApi";
import { AdminApiSavedActionType } from "./action";

export interface AdminApiSavedState {
    savedApis?: ApiSavedModel[];
    savedApisLoading: boolean;
    savingApi: boolean;
    removingSavedApi: boolean;
}

export const INITIAL_STATE = {
    itemLoading: false,
    savedApisLoading: false,
    savingApi: false,
    removingSavedApi: false
}
export function adminApiSavedReducer(state: AdminApiSavedState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AdminApiSavedActionType.GET_SAVED_APIS:
            state = { ...state, savedApisLoading: true }
            break;
        case AdminApiSavedActionType.GET_SAVED_APIS_SUCCESS:
            state = { ...state, savedApisLoading: false, savedApis: action.payload }
            break;
        case AdminApiSavedActionType.GET_SAVED_APIS_ERROR:
            state = { ...state, savedApisLoading: false }
            break;
        case AdminApiSavedActionType.REMOVE_SAVED_API:
            state = { ...state, removingSavedApi: true }
            break;
        case AdminApiSavedActionType.REMOVE_SAVED_API_SUCCESS:
            state = { ...state, removingSavedApi: false }
            break;
        case AdminApiSavedActionType.REMOVE_SAVED_API_ERROR:
            state = { ...state, removingSavedApi: false }
            break;
        case AdminApiSavedActionType.SAVE_API:
            state = { ...state, savingApi: true }
            break;
        case AdminApiSavedActionType.SAVE_API_SUCCESS:
            state = { ...state, savingApi: false }
            break;
        case AdminApiSavedActionType.SAVE_API_ERROR:
            state = { ...state, savingApi: false }
            break;
        case AdminApiSavedActionType.SAVE_API_REMOVE:
            const newSavedApi = state.savedApis?.filter(x => x.apiId.id !== action.payload);
            state = { ...state, savedApis: newSavedApi }
            break;
    }

    return state;
}