import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { UploadImageAdminStyled } from "./image.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as imageUpload } from "assets/svg/admin/components/image-upload.svg";
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import { notification, Spin } from "antd";

type Props = {
    height?: number,
    image?: any,
    setImage: (data: any) => void
}

const UploadImageAdmin: React.FC<Props> = (props) => {
    const [image, setImage] = useState<any>(props.image);
    const [uploading, setUploading] = useState(false);

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const beforeUpload = (file: any, files: any[]) => {
        setUploading(true);
        if (file != null) {
            if (file.size > 512000) {
                notification.warning({
                    message: <div>Tamanhos maximo da imagem é 512kb</div>,
                    placement: 'bottomLeft'
                })
                setUploading(false);
            } else if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                notification.warning({
                    message: <div>Arquivo em formato inválido <br />Formatos aceitos: jpg e png</div>,
                    placement: 'bottomLeft'
                })
                setUploading(false);
            } else {
                const fReader: FileReader = new FileReader();
                fReader.onload = async function (event) {
                    const imageTemp = await getBase64(file);
                    setImage(imageTemp);
                    props.setImage(imageTemp);
                    setUploading(false);
                }

                fReader.readAsText(file);
            }
        }
        return false;
    }

    return <UploadImageAdminStyled>
        <Spin spinning={uploading}>
            <Dragger showUploadList={false} beforeUpload={beforeUpload}
                style={{ backgroundImage: image ? `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)) ,url("${image}")` : '', backgroundSize: '100% 100%' }}>
                {image == null ? <><Icon component={imageUpload} />
                    <TypographyGr1d style={{ paddingBottom: 32 }} component='caption' color='colorPrimitive400'>
                        Resolução mín. de 600 x 600 pixels
                    </TypographyGr1d>
                    <TypographyGr1d style={{ padding: '0 24px' }} component='bodySM' color='colorPrimitive600'>
                        Arraste para dentro ou <span className='click'>clique</span> para selecionar sua imagem
                    </TypographyGr1d>
                    <ButtonGr1d style={{ position: 'absolute', bottom: '-20px' }}>
                        Selecionar imagem
                    </ButtonGr1d></> : <>
                    <ButtonGr1d style={{ position: 'absolute', bottom: '-20px' }}>
                        Editar
                    </ButtonGr1d>
                </>}
            </Dragger>
        </Spin>
    </UploadImageAdminStyled>;
}

export default UploadImageAdmin;