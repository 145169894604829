import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Input = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const InputStyled = styled(Input)`
    position: relative;

    .text {
        display: flex;
        margin-bottom: 8px;

        .point {
            cursor: pointer;
        }

        > div {
            flex: 1;
        }

        .helper-link {
            display: flex;
            justify-content: flex-end;
            font-family: DM Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};

            > div {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .text.disabled {
        opacity: 0.3;
        .label {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
        }
    }

    & .btn {
        transition: all 0s;

        > div {
            width: 100%;
        }

        span {
            transition: all 0s;
        }

        input {
            color: ${(props) => props.theme[props.color]};
            transition: all 0s;
        }
    }

    & .ant-input {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    .ant-input-disabled {
        opacity: 0.2;
    }

    & .brand > .ant-input {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        height: ${(props) => (props.size)};
        border: none;
        border-radius: 4px;
        padding: 10px 16px;

        input {
            height: ${(props) => (props.size)};
        }
    }

    & .error-field .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative50};
    }

    & .btn {
        > span {
            border: 2px solid transparent;
            height: ${(props) => (props.size)};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
            border-radius: 4px;

            input {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
            }

            input:-webkit-autofill {
                box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} inset;
            }
        }

        > span:hover {
            input:-webkit-autofill {
                box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorPrimitive100} inset !important;
            }
        }

        > span.brand:hover {
            input:-webkit-autofill {
                box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600} inset !important;
            }
        }

        > span.brand {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};

            input {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            }

            input:-webkit-autofill {
                box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} inset;
            }

            -webkit-text-fill-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};

            input:-webkit-autofill,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                color: white !important;
                box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} inset;
            }
            input:-webkit-autofill:hover {
                
            }
        }
    }

    & .btn.background-dynamic > span {
        height: ${(props) => props.height};
        background-color: ${(props) => props.theme[props.background]};

        input {
            background-color: ${(props) => props.theme[props.background]};
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0 30px ${(props) => props.theme[props.background]} inset;
        }
    }

    & .btn > span:hover {
        border: 2px solid transparent;
        border-right-width: 2px !important;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};

        > input {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }
    }

    & .btn.error-field > textarea:hover {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    & .btn > span.brand:hover {
        border: 2px solid transparent;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};

        > input {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }
    }

    & .ant-input {
        background-color: ${(props) => props.theme[props.background]};
        transition: all 0s;
    }

    & .ant-input:hover {
        background-color: ${(props) => props.theme[props.background]};
        transition: all 0s;
    }

    & .ant-input-affix-wrapper {
        padding: 8px 16px;
    }

    & .ant-input-password {
        background-color: ${(props) => props.theme[props.background]};
    }

    & .ant-select-selection-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
        display: flex;
        align-items: center;
    }

    & .ant-select-selector:hover > .ant-select-selection-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .ant-input:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .ant-input::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .brand .ant-input::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:hover::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:hover:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:hover::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .ant-input:hover::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input:hover:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input:hover::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input:focus::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input:focus:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .ant-input:focus::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .brand .ant-input:focus::placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:focus:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .brand .ant-input:focus::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }
    
    & .btn > span.ant-input-affix-wrapper-focused {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        box-sizing: border-box;
        box-shadow: none;
        border-right-width: 2px !important;
    }

    & .btn > span.brand.ant-input-affix-wrapper-focused {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        box-sizing: border-box;
        box-shadow: none;
        border-right-width: 2px !important;
    }

    .ant-input-password-icon > svg {
        width: 20px;
        height: 20px;
    }

    & .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        box-sizing: border-box;
        box-shadow: none;
        border-right-width: 2px !important;
        transition: all 0s;
    }

    & textarea.ant-input:focus, .ant-input-focused {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        box-sizing: border-box;
        box-shadow: none;
        border-right-width: 2px !important;
        transition: all 0s;
    }


    & textarea.ant-input {
        border: none;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        transition: all 0s;
        border-radius: 4px;
        padding: 10px 16px;
        height: 72px;
    }

    & .error-field textarea.ant-input {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative50};
    }

    .error-field > span {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative50};

        input {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative50};
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative50} inset;
        }
    }

    .error-field > span.brand {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};

        input {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0 30px ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} inset;
        }
    }

    .error-msg {
        margin-top: 8px;
        position: absolute;
        color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
    }

    .error-msg.brand {
        color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative100};
    }

    /* & .btn > span.ant-input-affix-wrapper-focused {
        animation-duration: 0s !important;
    } */

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 24px;
    }
`