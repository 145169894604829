import { CreditCardInfoStyled } from "./creditCardInfo.styled"
import Cards from 'react-credit-cards';
import { FieldValues, FormContextValues } from "react-hook-form";

type props = {
  form: FormContextValues<FieldValues>;
}

const CreditCard: React.FC<props> = ({ form }) => {
  return (
    <CreditCardInfoStyled>
      <div className="vertical-flex">
        {/* <TypographyGr1d fontSize="24px" font="Manrope" fontWeight="600">
          teste
        </TypographyGr1d> */}
        <Cards
          cvc={form.getValues().cvc || ''}
          expiry={form.getValues().valid || ''}
          name={form.getValues().name || ''}
          number={form.getValues().number || ''}
          placeholders={{ name: 'Nome impresso no cartão' }}
          locale={{ valid: 'Validade' }}
        />
      </div>
    </CreditCardInfoStyled>
  )
}

export const CreditCardInfo = CreditCard;