import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography"
import { FieldValues, FormContextValues } from "react-hook-form";

type props = {
  form: FormContextValues<FieldValues>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  edit: boolean;
  showBackButton: boolean;
  goToReview: () => void;
};

const ConsumptionInfo: React.FC<props> = (props) => {
  const { handleSubmit } = props.form;

  const save = (data: any) => {
    if (props.edit) {
      props.goToReview();
    } else {
      props.setCurrentStep(c => c + 1);
    }
  }

  const formConfigData: ConfigFormItem[] = [
    {
      name: 'calls',
      typeComponent: 'number',
      label: 'Quantidade de chamadas mensais',
      placeholder: 'Escreva a quantidade',
      helpText: 'Caso o consumo mensal ultrapasse em 25% a estimativa, o fornecimento será interrompido e entraremos em contato com você.',
      rules: { required: { value: true, message: 'Campo obrigatório' }, 
      min: {
        value: 1,
        message: "A quantidade mínima é 1"
      } }
    },
  ]

  return <div>
    <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope" color="colorPrimitiveBlack">Estime o consumo mensal da API</TypographyGr1d>
    <TypographyGr1d fontSize="14px" fontWeight="400" font="Manrope" color="colorPrimitive500" style={{ marginTop: "16px" }}>Utilizaremos esta estimativa para identificar qualquer comportamento anormal na utilização da API.</TypographyGr1d>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={props.form} config={formConfigData} />
      <div className="buttons">
        {props.showBackButton && <ButtonGr1d type="tertiary" onClick={() => { props.setCurrentStep(c => c - 1) }}>Voltar</ButtonGr1d>}
        {!props.showBackButton && <div></div>}
        <ButtonGr1d htmlType='submit'>{props.edit ? 'Alterar' : 'Continuar'}</ButtonGr1d>
      </div>
    </FormAdmin>
  </div>
}

export default ConsumptionInfo