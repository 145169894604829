
import { AuthModel } from 'models/auth';
import { local } from './storage';

const authServiceStorage = () => {
    const AUTH_GR1D = "authGr1d";

    const isAuthenticated = () => {
        return local.get(AUTH_GR1D) != null;
    };

    const getAuth = (): AuthModel => {
        return local.get(AUTH_GR1D) ?? {} as AuthModel;
    }

    const setAuth = (auth: AuthModel): void => {
        local.set(AUTH_GR1D, auth);
    };

    const logout = () => {
        local.set(AUTH_GR1D, null);
    };

    return { isAuthenticated, getAuth, setAuth, logout };
}

export const auth = authServiceStorage();
