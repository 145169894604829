import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Api = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const ApiCardStyled = styled(Api)`
.apiCard {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    .cardHeader {
        flex-direction: column;
        align-items: flex-start;
        img {
            max-height: 56px;
            object-fit: scale-down;
        }

        .txt {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        padding-bottom: 16px;
        width: 100%;
    }
    .cardBody {
        width: 100%;
        .tag-active {
            margin: 0;
            border: 0;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }
        .tag-testing {
            margin: 0;
            border: 0;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
        }
    }
}
`