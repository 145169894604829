import { useEffect, useRef, useState } from 'react'
import TypographyGr1d from '../typography'
import { CategoriesStyled } from './index.styled'

type category = {
    name: string,
    id: number
}

type props = {
    categories: category[]
}

const categoriesTag: React.FC<props> = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [expand, setExpand] = useState(false)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const wrapperRef = useRef(null)

    function handleClick (e: any) {
        if (
            wrapperRef.current &&
            !(wrapperRef.current as any).contains(e.target)
          ) {
            setExpand(false);
          } else {
            e.preventDefault()
            e.stopPropagation()
            return false
          }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        window.addEventListener('mousedown', handleClick)
        return () => {
            window.removeEventListener('mousedown', handleClick)
        }
    }, [])

    function handlePlusClick(e: any) {
        e.preventDefault()
        e.stopPropagation()
        setExpand(!expand)
        return false
    }

    return (
        <CategoriesStyled>
            {props.categories.length > 0 && <div ref={wrapperRef} className={'tag ' + (expand ? 'showMore' : '')}>
                <div className="tagAbsolute">
                    <TypographyGr1d font="Manrope" color="colorBrandPrimary600" fontSize="12px" fontWeight="300">
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="tagItem">
                                <a href={`/search/category/${props.categories[0].name.toLocaleLowerCase()}/${props.categories[0].id}`}>
                                    <span>{props.categories[0].name}</span>
                                </a>
                            </div>
                            {props.categories.length > 1 && 
                                <div className="tagItem" style={{marginLeft: '4px', cursor: 'pointer'}} onClick={handlePlusClick}>
                                    <span className="moreInfo"> {props.categories.length - 1}+</span>
                                </div>
                            }
                        </div>
                        {props.categories.length > 1 && (
                            <div className="taglist">
                                <ul>
                                    {props.categories.slice(1).map((category, i) => (
                                        <li key={i}>
                                            <div className="tagItem tagItemMore">
                                                <a href={`/search/category/${category.name.toLocaleLowerCase()}/${category.id}`}>
                                                    {category.name}
                                                </a>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </TypographyGr1d>
                </div>
            </div>}
        </CategoriesStyled>
    )
}

export default categoriesTag