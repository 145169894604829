import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const RegistrationDrawer: React.FC = (props: any) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

export const RegistrationDrawerStyled = styled(RegistrationDrawer)`
height: 100%;
.newmembercontainer {
    display: flex;
    min-height: 100%;
    width: 100%;
    // background-color: green;
    flex-direction: column;
    justify-content: space-between;
    .adminswitchercontainer {
        border-radius: 8px;
        bordeR: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        padding: 24px;
        margin-top: 35px;
        > div:nth-child(1) {
            display: flex;
            > div:nth-child(2) {
                margin-left: 16px;
                line-height: 100%;
            }
        }
        .anticon-info-circle {
            display: inline !important;
        }
        .ant-switch-checked {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }
    }
}
`