import PurchaseReviewTemplate from "views/layout/portal/purchaseReview/template"
import UserInfo from "./userInfo"
import ApiInfo from "./apiInfo"
import { Progress } from 'antd';
import useTheme from "theme/useTheme";
import styled from "styled-components";
import PurchaseReviewStyled from "./index.styled";
import { useForm } from "react-hook-form";
import TypographyGr1d from "components/portal/typography";
import CompanyInfo from "./companyInfo";
import { useEffect, useRef, useState } from "react";
import ConsumptionInfo from "./consumption";
import { CreditCardInfo } from "./creditCardInfo";
import { AddCreditCard } from "./addCreditCard";
import { CreditCard } from "./creditCard";
import { CreditCardPostModel } from "models/company";
import { Review } from "./review";
import { useDispatch, useSelector } from "react-redux";
import { CNPJModelResponse } from "models/cnpj";
import { useAction } from "store";
import { companyActions } from "store/admin/company/action";
import { UserModel } from "models/user";
import { PortalState as CompanyState } from "store/admin/company/reducer";
import { PortalState } from "store/portal/reducer"
import Modal from "components/portal/modal";
import ButtonGr1d from "components/portal/button";
import { useHistory } from "react-router";

const PurchasePreview: React.FC<any> = () => {
  const user: UserModel & {orgId: string} = useSelector((state: any) => state.login?.user);
  const dispatch = useDispatch();

  const theme = useTheme();
  const formUser = useForm();
  const [userFormData, setUserFormData] = useState<any>(null);
  const formCompany = useForm();
  const formConsumption = useForm();
  const formCreditCard = useForm();
  const [creditCardAdded, setCreditCardAdded] = useState<CreditCardPostModel | null>(null);

  const history = useHistory()

  const [userCPFRequested, setUserCPFRequested] = useState(false);
  const [userCompanyRequested, setUserCompanyRequested] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [stepName, setStepName] = useState<string>('');;
  const [totalSteps, setTotalSteps] = useState(5);

  const [creditCardStep, setCreditCardStep] = useState(false);
  const [, setForceUpdate] = useState(false);

  const [userAndCompanyInfoSaved, setUserAndCompanyInfoSaved] = useState(false);
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (user && !user.cpf && !userCPFRequested) {
      setUserCPFRequested(true)
    } else if (user && user.cpf) {
      setTotalSteps(s => s - 1)
    }
    if (user && !user.orgId && !userCompanyRequested) {
      setUserCompanyRequested(true)
    } else if (user && user.orgId) {
      setTotalSteps(s => s - 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // function goToLast() {
  //   setCurrentStep(totalSteps);
  // }

  function showUserInfoPage() {
    return currentStep === 1 - (5 - totalSteps) + (!userCompanyRequested ? 1 : 0) && !creditCardStep;
  }

  function showCompanyInfoPage() {
    return currentStep === 2 - (5 - totalSteps) - (!userCompanyRequested ? 1 : 0) && !creditCardStep;
  }

  function showConsuptionInfoPage() {
    return currentStep === 3 - (5 - totalSteps) && !creditCardStep;
  }

  function showPaymentInfoPage() {
    return currentStep === 4 - (5 - totalSteps) && !creditCardStep;
  }

  function showReviewInfoPage() {
    return currentStep === 5 - (5 - totalSteps) && !creditCardStep;
  }

  useEffect(() => {
    if (currentStep === 1 - (5 - totalSteps)) {
      setStepName('Próximo: Dados da empresa');
    } else if (currentStep === 2 - (5 - totalSteps)) {
      setStepName('Próximo: Estimativa de uso');
    } else if (currentStep === 3 - (5 - totalSteps)) {
      setStepName('Próximo: Método de pagamento');
    } else if (currentStep === 4 - (5 - totalSteps)) {
      setStepName('Revisão dos dados');
    } else if (currentStep === 4 - (5 - totalSteps)) {
      setStepName('Chegamos na última etapa');
    }
  }, [currentStep, totalSteps])

  const cnpj: CNPJModelResponse | undefined = useSelector((state: any) => state.company?.cnpj);
  const saveSuccess: boolean = useSelector((state: any) => state.company?.saveSuccess);
  const action = useAction(companyActions());
  const [loadingRequest, setLoadingRequest] = useState(false);

  function saveCompanyData() {
    dispatch(action.saveCompany({
      cnpj: cnpj!.cnpj.replaceAll(/_|-|[/]|[.]/g, ''),
      name: cnpj!.nome,
      endereco: {
        bairro: cnpj!.bairro,
        cep: cnpj!.cep,
        cidade: cnpj!.municipio,
        complemento: cnpj!.complemento,
        nomeRua: cnpj!.logradouro,
        numero: cnpj!.numero,
        uf: cnpj!.uf
      },
      razaoSocial: cnpj?.nome,
      telefone: cnpj!.telefone,
      id: undefined,
      cpfResponsavel: user.cpf ? user.cpf : formUser.getValues().cpf.replace(/\./g, '').replace('-', ''),
    }, userCPFRequested ? formUser.getValues().cpf.replace(/\./g, '').replace('-', '') : undefined, 
    userCPFRequested ? formUser.getValues().phone.replace(/[\(\)-\s]/g, '') : undefined));
  }

  useEffect(() => {
    if (saveSuccess) {
      setCurrentStep(c => c + 1)
      setUserAndCompanyInfoSaved(true)
    }
  }, [saveSuccess])

  const creditCardState: CompanyState = useSelector((state: any) => state.company);
  const promiseSavingCardRef = useRef<{resolve: any, reject: any} | null>(null);
  const promiseSavingConsumeRef = useRef<{resolve: any, reject: any} | null>(null);

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  async function saveData() {
    const valid = creditCardAdded!.expirationDate.split('/')
    const promiseCreditCard = new Promise((resolve, reject) => {
      promiseSavingCardRef.current = {
        resolve,
        reject,
      }
    });

    const promiseConsume = new Promise((resolve, reject) => {
      promiseSavingConsumeRef.current = {
        resolve,
        reject,
      }
    });


    setLoadingRequest(true)

    dispatch(action.postCreditCard({
      cardNumber: creditCardAdded!.cardNumber.replace(/\s/g, ''),
      nickname: creditCardAdded!.nickname,
      expirationDate: `${valid[0]}/20${valid[1]}`,
      holder: creditCardAdded!.holder,
      securityCode: creditCardAdded!.securityCode
    }, user.orgId, 'recaptchaResponse' as string))

    try {
      await promiseCreditCard;
      saveConsumeData()
      await promiseConsume;
      setShowModalSuccess(true);
    } finally {
      setLoadingRequest(false)
    }
  }

  const apiState: PortalState = useSelector((state: any) => state.portal);

  function saveConsumeData() {
    dispatch(action.requestConsume(apiState.apiToActivate!.id + '', formConsumption.getValues().calls))
  }

  useEffect(() => {
    if (promiseSavingConsumeRef.current === null) {
      return;
    }
  
    if (creditCardState.requestApiConsumeSuccess) {
      promiseSavingConsumeRef.current.resolve()
    } else if (creditCardState.requestApiConsumeError) {
      promiseSavingConsumeRef.current.reject()
    }

  }, [creditCardState.requestApiConsumeSuccess, creditCardState.requestApiConsumeError])

  useEffect(() => {
    if (promiseSavingCardRef.current === null) {
      return;
    }
  
    if (creditCardState.creditCardSavingSuccess) {
      promiseSavingCardRef.current.resolve()
      saveConsumeData()
    } else if (creditCardState.creditCardSavingError) {
      promiseSavingCardRef.current.reject()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardState.creditCardSavingError, creditCardState.creditCardSavingSuccess])

  return <PurchaseReviewTemplate
    finished={showModalSuccess}
    childrenLeft={
      <PurchaseReviewStyled>
        {!creditCardStep && <div className="progress">
          <Progress type="circle" width={32} strokeColor={theme.currentTheme.colorBrandPrimary400} percent={Math.round(currentStep / totalSteps * 100)} format={percent => ``} />
          <div className="ProgressText">
            <TypographyGr1d fontSize="12px" color="colorBrandPrimary400">Passo {currentStep} de {totalSteps}</TypographyGr1d>
            <span className="currentStep"></span>
            <TypographyGr1d fontSize="12px" color="colorPrimitive700">{stepName}</TypographyGr1d>
          </div>
        </div>}
        <div style={{ display: showUserInfoPage() ? 'block' : 'none'}}>
          <UserInfo form={formUser} userFormData={userFormData} next={() => setCurrentStep((c) => c + 1)} setUserInfo={setUserFormData} />
        </div>
        <div style={{ display: showCompanyInfoPage() ? 'block' : 'none'}}>
          <CompanyInfo form={formCompany} userForm={formUser} setCurrentStep={setCurrentStep} onConfirm={saveCompanyData} showBackButton={currentStep !== 1} />
        </div>
        <div style={{ display: showConsuptionInfoPage() ? 'block' : 'none'}}>
          <ConsumptionInfo form={formConsumption} setCurrentStep={setCurrentStep} edit={editInfo} goToReview={() => {setCurrentStep(totalSteps)}} showBackButton={currentStep !== 1 && !userAndCompanyInfoSaved} />
        </div>
        <div style={{ display: showPaymentInfoPage() ? 'block' : 'none'}}>
          <CreditCard back={() => setCurrentStep((c) => c - 1)} next={() => setCurrentStep((c) => c + 1)} showAddCreditCard={() => { setCreditCardStep(true) }} creditCardAdded={creditCardAdded} editCard={() => {setCreditCardStep(true)}} edit={editInfo} goToReview={() => {setCurrentStep(totalSteps)}} />
        </div>
        <div style={{ display: showReviewInfoPage() ? 'block' : 'none'}}>
          <Review goToCompanyStep={() => setCurrentStep(2 - (5 - totalSteps))} goToConsumeStep={() => {setEditInfo(true); setCurrentStep(3 - (5 - totalSteps))}} goToPaymentStep={() => {setEditInfo(true); setCurrentStep(4 - (5 - totalSteps))}} formCompany={formCompany} creditCardData={creditCardAdded} formConsumption={formConsumption} userCompanyRequested={userCompanyRequested} />
        </div>
        { creditCardStep && <AddCreditCard form={formCreditCard} onUpdate={() => {setForceUpdate(v => !v)}} onCancel={() => { setCreditCardStep(false) }} creditCardAdded={(creditCard: CreditCardPostModel) => {setCreditCardAdded(creditCard); setCreditCardStep(false) }}  creditCard={creditCardAdded} /> }
        <Modal width="480px" show={showModalSuccess} modalActions={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonGr1d type="secondary" onClick={() => {
              history.push('/')
            }}>Voltar à página inicial</ButtonGr1d>
            <ButtonGr1d type="primary" onClick={() => {
              history.push('/admin/api/consumed')
            }}>Ir para minhas API's</ButtonGr1d>
          </div>
        }>
          <TypographyGr1d fontSize="20px" color="colorPrimitiveBlack" font="Manrope">Estamos quase lá, agora é com a gente!</TypographyGr1d>
          <TypographyGr1d style={{ marginTop: "16px" }} fontSize="14px" color="colorPrimitive600" font="Manrope">Sua solicitação já está sendo processada, enviaremos atualizações para o seu e-mail. Este processo geralmente leva 2 dias úteis.</TypographyGr1d>
        </Modal>
      </PurchaseReviewStyled>
    }

    childrenRight={
      <div style={{ height: "100%" }}>
        {!creditCardStep && <ApiInfo fullCard={showReviewInfoPage()} onActivate={() => {saveData()}} loadingRequest={loadingRequest} />}
        {creditCardStep && <CreditCardInfo form={formCreditCard} />}
      </div>
    }
  />
}

const PurchasePreviewEx = styled(PurchasePreview)``

export default PurchasePreviewEx