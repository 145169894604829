import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const UploadImageAdmin = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const UploadImageAdminStyled = styled(UploadImageAdmin)`
    .ant-upload {
        width: 250px;
        height: 250px;
        position: relative;
    }

    .ant-upload.ant-upload-drag {
        border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    .click {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        font-weight: bold;
    }

    .anticon {
        font-size: 60px;
        padding-bottom: 14.5px;
        padding-top: 40px;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0;
    }

    .ant-upload.ant-upload-drag .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`