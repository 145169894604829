import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const UploadFileAdmin = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const UploadFileAdminStyled = styled(UploadFileAdmin)`
    .ant-upload {
        width: 100%;
        position: relative;
        padding: 8px;
    }

    .ant-upload.ant-upload-drag {
        border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    .click {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        font-weight: bold;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0;
    }

    .ant-upload.ant-upload-drag .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .file {
        display: flex;
        align-items: center;
        gap: 18px;

        .info {
            flex: 1;
        }

        .anticon {
            font-size: 32px;
        }

        .anticon-download {
            font-size: 18px;
        }

        .anticon-delete {
            cursor: pointer;
            font-size: 18px;
            color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative400};
        }
    }
`