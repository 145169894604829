import TypographyGr1d from "components/portal/typography";
import React from "react";
import { useSelector } from "react-redux";
import { ApiState } from "store/portal/reducer";
import { GeneralApiStyled } from "./general.styled";
import YoutubeEmbed from "../../../utils/youtube"

type Props = {
    title: string;
}

const GeneralApi: React.FC<Props> = (props) => {
    const apiState: ApiState = useSelector((state: any) => state.portal?.api);
    // const iFrame = {__html: '<iframe width="853" height="480" src="https://www.youtube.com/embed/x-0_z4DdQpg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'}
    return <GeneralApiStyled>
        <div className='general-description'>
            <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                {props.title}
            </TypographyGr1d>
            <TypographyGr1d style={{ marginBottom: 32 }} component='bodyLG' color='colorPrimitive600'>
                {!apiState.loading && apiState.item?.detailedDescription}
            </TypographyGr1d>
            {!apiState.loading && apiState.item?.id === 1183 && (
                <div>
                <YoutubeEmbed embedId="x-0_z4DdQpg" />
                </div>
            )}
            {!apiState.loading && apiState.item?.benefits && (
                <div>
                    <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                        A solução
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 32 }} component='bodyLG' color='colorPrimitive600'>
                        {apiState.item?.benefits}
                    </TypographyGr1d>
                </div>
            )}
            {!apiState.loading && apiState.item?.target && (
                <div>
                    <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                        Público-alvo
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 48 }} component='bodyLG' color='colorPrimitive600'>
                        {apiState.item?.target}
                    </TypographyGr1d>
                </div>
            )}
            
        </div>
    </GeneralApiStyled>;
}

export default GeneralApi;