import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography"
import { FieldValues, FormContextValues } from "react-hook-form";
import { companyActions } from "store/admin/company/action";
import { useAction } from "store";
import { useDispatch, useSelector } from "react-redux";
import { CNPJModelResponse } from "models/cnpj";
import { useEffect, useState } from "react";
import { CompanyInfoStyled } from "./companyInfo.styled";
import { ConfirmUserData } from "./confirmUserCompanyDataModal";

type props = {
  form: FormContextValues<FieldValues>;
  userForm: FormContextValues<FieldValues>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  onConfirm: () => void;
  showBackButton: boolean;
};

const UserInfo: React.FC<props> = ({ form, userForm, showBackButton, setCurrentStep, onConfirm }) => {
  const action = useAction(companyActions());

  const dispatch = useDispatch();

  const getCnpjInfo = (v: any) => {
    if (v?.target?.value != null) {
      const cnpj: string = v.target.value.replaceAll(/_|-|[/]|[.]/g, '');
      if (cnpj.length === 14) {
        dispatch(action.getCnpjInfo(cnpj));
      }
    }
  }

  const cnpj: CNPJModelResponse | undefined = useSelector((state: any) => state.company?.cnpj);

  useEffect(() => {
    if (cnpj?.message != null) {
      form.setError('cnpj', 'focus', cnpj.message);
    } else if (cnpj != null) {
      form.setValue('companyname', cnpj.fantasia);
      form.setValue('phone', cnpj.telefone.split('/')[0]);
      form.setValue('address', `
        ${cnpj.logradouro}, ${cnpj.numero}
        ${cnpj.bairro}, ${cnpj.municipio} - ${cnpj.uf}
        ${cnpj.cep}
      `)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj])

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const save = (data: any) => {
    setShowConfirmationModal(true)
    // setCurrentStep(s => s + 1);
  }

  const { handleSubmit } = form;

  const formConfigData: ConfigFormItem[] = [
    {
      typeComponent: "input",
      id: 'companyname',
      name: 'companyname',
      label: 'Nome fantasia',
      disabled: true,
      // rules: {
      //   required: { value: true, message: 'Campo obrigatório' },
      // },
    },
    {
      typeComponent: "input",
      id: 'phone',
      name: 'phone',
      label: 'Telefone da empresa',
      disabled: true,
      maxWidth: 200,
      // rules: {
      //   required: { value: true, message: 'Campo obrigatório' },
      // },
    },
    {
      typeComponent: "masked-input",
      id: 'cnpj',
      name: 'cnpj',
      label: 'CNPJ',
      maxWidth: 200,
      mask: "11.111.111/1111-11",
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
      },
      onChange: (v) => {
        getCnpjInfo(v);
        form.clearError('cnpj');
        // setUpdate(x => !x);
      },
    },
    {
      typeComponent: "textarea",
      id: "address",
      name: "address",
      disabled: true,
      textAreaPadding: "0",
      textAreaOverflow: "hidden",
      textAreaHeight: "110px",
    }
  ]

  return <CompanyInfoStyled>
    <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope" color="colorPrimitiveBlack">Forneça os dados da sua empresa</TypographyGr1d>
    <TypographyGr1d fontSize="14px" fontWeight="400" font="Manrope" color="colorPrimitive500" style={{ marginTop: "20px" }}>Para realizar a ativação da API, é necessário fornecer as informações da empresa.</TypographyGr1d>
    <div className="user-data-form">
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={form} config={formConfigData} />
      <div style={{ justifyContent: 'space-between' }} className="buttons">
      {showBackButton && <ButtonGr1d type="tertiary" onClick={() => { setCurrentStep(s => s - 1) }}>Voltar</ButtonGr1d> }
      {!showBackButton && <div></div>}
        <ButtonGr1d htmlType='submit'>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
    </div>
    <ConfirmUserData show={showConfirmationModal} formCompanyData={form} formUserData={userForm} onCancel={() => setShowConfirmationModal(false)} onConfirm={onConfirm} />
  </CompanyInfoStyled>
}

export default UserInfo

