import { createAccountService } from "store/admin/account/service";

export enum AccountActionType {
    UPDATE_ACCOUNT = '[ADMIN_ACCOUNT] Update Account',
    UPDATE_ACCOUNT_SUCCESS = '[ADMIN_ACCOUNT] Update Account Success',
    UPDATE_ACCOUNT_ERROR = '[ADMIN_ACCOUNT] Update Account Error',
}

export interface AccountAction {
    updateAccount(firstName: string, lastName: string, phone: string): void,
}

export const accountSavedActions = ((): AccountAction => {
    const accountService = createAccountService();

    const actions = {
        updateAccount: (): any => {
            return { type: AccountActionType.UPDATE_ACCOUNT }
        },
        updateAccountSuccess: (): any => {
            return { type: AccountActionType.UPDATE_ACCOUNT_SUCCESS }
        },
        updateAccountError: (): any => {
            return { type: AccountActionType.UPDATE_ACCOUNT_ERROR }
        },
    }

    function updateAccount(firstName: string | undefined, lastName: string | undefined, telephone: string | undefined) {
        return (dispatch: any) => {
            dispatch(actions.updateAccount());

            accountService.updateAccount({
                cpf: undefined,
                firstName,
                lastName,
                telephone
            }).then(() => {
                dispatch(actions.updateAccountSuccess());
            }).catch((x: any) => {
                dispatch(actions.updateAccountError());
            })
        }
    }

    return { updateAccount }
})