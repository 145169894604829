import styled from 'styled-components';

const DrawerAdmin = (props: any) => {
    return <div className={props.className}>
        {props.children}
    </div>
}

export const DrawerAdminStyled = styled(DrawerAdmin)`
    height: 100%;

    > div {
        height: 100%;

        .ant-form.ant-form-vertical {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .drawer-content {
        flex: 1;
    }

    .drawer-footer {
        padding-bottom: 48px;
    }

    .ant-drawer-body {
        
    }
`;
