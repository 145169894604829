import Cookies from 'js-cookie';
import { createAppService } from './service';

export enum AppActionType {
    SHOW_QUESTION = '[APP] Show Question',
    CHANGE_GRID = '[APP] Change Grid',
    GO_TO = '[APP] Go To',
    CLOSE_COOKIES = '[APP] Close Cookies',
    SHOW_COOKIES = '[APP] Show Cookies',
    CHANGE_MENU_ID = '[APP] Change MenuId',
    CHANGE_MODAL_PERMISSION_SHOW = '[APP] Change Modal Permission Show'
}

export interface navigatorAction {
    showQuestion(show: number): void,
    changeGrid(grid: 'sm' | 'md' | 'lg'): void,
    goTo(path: string, state?: any): void,
    acceptCookies(): void,
    closeCookies(): void,
    verifyCookies(): void,
    changeMenuId(id?: string): void,
    changeModalPermission(active: boolean): void
}

export const appActions = ((): navigatorAction => {
    const actions = {
        showQuestion: (show: number): any => {
            return { type: AppActionType.SHOW_QUESTION, payload: show }
        },
        changeGrid: (grid: string): any => {
            return { type: AppActionType.CHANGE_GRID, payload: grid }
        },
        goTo: (path: string, state: any): any => {
            return { type: AppActionType.GO_TO, payload: { path, state } }
        },
        showCookies: (): any => {
            return { type: AppActionType.SHOW_COOKIES }
        },
        closeCookies: (): any => {
            return { type: AppActionType.CLOSE_COOKIES }
        },
        changeMenuId: (id?: string): any => {
            return { type: AppActionType.CHANGE_MENU_ID, payload: id }
        },
        changeModalPermission: (active: boolean): any => {
            return { type: AppActionType.CHANGE_MODAL_PERMISSION_SHOW, payload: active }
        },
    }

    function showQuestion(show: number) {
        return (dispath: any) => {
            dispath(actions.showQuestion(show));
        }
    }

    function changeGrid(grid: string) {
        return (dispath: any) => {
            dispath(actions.changeGrid(grid));
        }
    }

    function goTo(path: string, state?: any) {
        return (dispath: any) => {
            dispath(actions.goTo(path, state));
        }
    }

    function acceptCookies() {
        return async (dispath: any) => {
            const appService = createAppService()
            const lastCookiesVersion = await appService.getCookiesTextVersion()
            Cookies.set('cookiesPolicy', `accepted-${lastCookiesVersion}`, {
                expires: new Date('2199-01-01'),
            })
            dispath(actions.closeCookies());
        }
    }

    function closeCookies() {
        return (dispath: any) => {
            dispath(actions.closeCookies());
        }
    }

    function verifyCookies() {
        return async (dispath: any) => {
            const appService = createAppService()
            const lastCookiesVersion = await appService.getCookiesTextVersion()
            if (!Cookies.get('cookiesPolicy') || Cookies.get('cookiesPolicy') !== `accepted-${lastCookiesVersion}`) {
                dispath(actions.showCookies());
            }
        }
    }

    function changeMenuId(menuId?: string) {
        return (dispath: any) => {
            dispath(actions.changeMenuId(menuId));
        }
    }

    function changeModalPermission(active: boolean) {
        return (dispath: any) => {
            dispath(actions.changeModalPermission(active));
        }
    }

    return { showQuestion, changeGrid, goTo, acceptCookies, closeCookies, verifyCookies, changeMenuId, changeModalPermission }
})