import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiConsumed = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiConsumedStyled = styled(AdminApiConsumed)`
.header {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 33px;
    border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    .headingLGBase {
        font-size: 38px;
        font-weight: 500;
    }
    .ant-tag {
        border: unset;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    }
}
.content {
    padding: 32px;
    padding-top: 16px;
    .filters {
        display: flex;
        justify-content: space-between;
        .inputs {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
    .mode {
        .anticon {
            padding-right: 0 !important;
        }
    }
    .apilist {
        width: 100%;
        .cards {
            width: 100%;
            display: block;
            .cardrowgrid {
                max-width: 100%;
                width: 100%;
                padding-top: 32px;
                gap: 32px;
                display: flex;
                flex-wrap: wrap;
                > div {
                    width: 309px;
                }
            }
        }
    }
    .pagination {
        padding-top: 33px;
        display: flex;
        justify-content: flex-end;
    }
}
`