import { AppActionType } from "./action";

export interface AppState {
    showQuestion: number;
    grid: string;
    goTo?: string;
    stateHistory?: any;
    goToRefresh: boolean;
    showCookiesAlert: boolean;
    menuId?: string;
    modalPermission: boolean;
}

const verifyWidth = (): string => {
    if (window.innerWidth < 768)
        return 'sm'
    else if (window.innerWidth < 1280)
        return 'md'
    else
        return 'lg'
}

export const INITIAL_STATE = {
    showQuestion: 1, grid: verifyWidth(), goToRefresh: false, showCookiesAlert: false,
    modalPermission: false
}
export function appReducer(state: AppState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AppActionType.SHOW_QUESTION:
            state = { ...state, showQuestion: action.payload }
            break;
        case AppActionType.CHANGE_GRID:
            state = { ...state, grid: action.payload }
            break;
        case AppActionType.GO_TO:
            state = {
                ...state, goTo: action.payload.path, goToRefresh: !state.goToRefresh, stateHistory: action.payload.state
            }
            break;
        case AppActionType.SHOW_COOKIES:
            state = { ...state, showCookiesAlert: true }
            break;
        case AppActionType.CLOSE_COOKIES:
            state = { ...state, showCookiesAlert: false }
            break;
        case AppActionType.CHANGE_MENU_ID:
            state = { ...state, menuId: action.payload }
            break;
        case AppActionType.CHANGE_MODAL_PERMISSION_SHOW:
            state = { ...state, modalPermission: action.payload }
            break;
    }

    return state;
}