import moment from "moment";
import { DependencyList, useCallback, useEffect } from "react";

export const useEffectDisabled = <T extends (...args: any[]) => any>(callback: T, deps: DependencyList) => {
    const callbackAux = useCallback(callback, deps) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        callbackAux()
    }, [callbackAux]) // eslint-disable-line react-hooks/exhaustive-deps
}


const numbers: string[] = [
    "", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove",
    "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezesete", "dezoito", "dezenove",
    "vinte", "vinte e um", "vinte e dois", "vinte e três", "vinte e quatro", "vinte e cinco", "vinte e seis", "vinte e sete", "vinte e oito", "vinte e nove",
    "trinta", "trinta e um", "trinta e dois", "trinta e três", "trinta e quatro", "trinta e cinco", "trinta e seis", "trinta e sete", "trinta e oito", "trinta e nove",
    "quarenta", "quarenta e um", "quarenta e dois", "quarenta e três", "quarenta e quatro", "quarenta e cinco", "quarenta e seis", "quarenta e sete", "quarenta e oito", "quarenta e nove",
    "cinquenta", "cinquenta e um", "cinquenta e dois", "cinquenta e três", "cinquenta e quatro", "cinquenta e cinco", "cinquenta e seis", "cinquenta e sete", "cinquenta e oito", "cinquenta e nove",
    "sessenta", "sessenta e um", "sessenta e dois", "sessenta e três", "sessenta e quatro", "sessenta e cinco", "sessenta e seis", "sessenta e sete", "sessenta e oito", "sessenta e nove",
]

export const getDateStr = (dateStr: string) => {
    const today = moment();
    const date = moment(dateStr);
    const year = today.diff(date, "year");
    const month = today.diff(date, "month");
    const week = today.diff(date, "week");
    const day = today.diff(date, "day");
    const hour = today.diff(date, "hour");
    const minute = today.diff(date, "minute");

    if (year > 0) {
        if (year > 1)
            return `há ${numbers[year]} anos`
        else
            return `há ${numbers[year]} ano`
    } else if (month) {
        if (month > 1)
            return `há ${numbers[month]} meses`
        else
            return `há ${numbers[month]} mês`
    } else if (week) {
        if (week > 1)
            return `há ${numbers[week]} semanas`
        else
            return `há ${numbers[week]} semana`
    } else if (day) {
        if (day > 1)
            return `há ${numbers[day]} dias`
        else
            return `há ${numbers[day]} dia`
    } else if (hour) {
        if (hour > 1)
            return `há ${numbers[hour]} horas`
        else
            return `há ${numbers[hour]} hora`
    } else if (minute) {
        if (minute > 1)
            return `há ${numbers[minute]} minutos`
        else
            return `há ${numbers[minute]} minuto`
    } else
        return `agora`
}

export const getJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export const isValidPhoneNumber = (value: string) => {
    let newValue = value.replaceAll(/_|-|[)]|[(]/g, '').replace(' ', '');
    if (newValue.length < 10)
        return 'Telefone inválido';
}

export const isValidCellPhoneNumber = (value: string) => {
    let newValue = value.replaceAll(/_|-|[)]|[(]/g, '').replace(' ', '');
    if (newValue.length < 11)
        return 'Telefone inválido';
}

export const isValidCpf = (value: string) => {
    var sum = 0;
    var rest;
    var inputCPF = value.replaceAll('.', '').replaceAll('-', '');

    if (inputCPF === '00000000000') return 'CPF inválido';
    for (let i = 1; i <= 9; i++) sum = sum + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(inputCPF.substring(9, 10))) return 'CPF inválido';

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(inputCPF.substring(10, 11))) return 'CPF inválido';
}

export const isValidCnpj = (cnpj: string) => {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return 'Cnpj inválido';

    if (cnpj.length !== 14)
        return false;

    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return 'Cnpj inválido';

    let height = cnpj.length - 2
    let numbers: any = cnpj.substring(0, height);
    let digits = cnpj.substring(height);
    let sum = 0;
    let pos = height - 7;
    for (let i = height; i >= 1; i--) {
        sum += numbers.charAt(height - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result.toString() !== digits.charAt(0))
        return 'Cnpj inválido';

    height = height + 1;
    numbers = cnpj.substring(0, height);
    sum = 0;
    pos = height - 7;
    for (let i = height; i >= 1; i--) {
        sum += numbers.charAt(height - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result.toString() !== digits.charAt(1))
        return 'Cnpj inválido';

    return true;
}

export const isEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const formatCurrency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const formatText = (value?: string, pattern?: string) => {
    if (value != null && pattern != null) {
        var i = 0,
            v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    } else return '';
}