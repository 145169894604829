import { Form, Input, Radio, Tabs } from "antd";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import UploadFileAdmin from "components/admin/upload/file";
import ButtonGr1d from "components/portal/button";
import React, { useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import { AdminApiConfigStyled } from "./config.styled";
import TypographyGr1d from "components/portal/typography";

const { TabPane } = Tabs;

const AdminApiConfig: React.FC = () => {
    const form = useForm();
    const [type, setType] = useState<string>('file');
    const [screen, setScreen] = useState<string>('prod');

    const formConfig: ConfigFormItem[] = [
        {
            name: 'format',
            label: 'Formato suportado',
            typeComponent: 'radio',
            defaultValue: 'rest',
            data: [{
                title: 'OpenAPI (REST)',
                value: 'rest'
            }, {
                title: 'WSDL (SOAP)',
                value: 'wsdl'
            }]
        },
        {
            name: 'doc',
            label: 'Tipo',
            typeComponent: 'custom',
            element: <div>
                <Radio.Group style={{ paddingBottom: 16 }} value={type} onChange={(v) => setType(v.target.value)}>
                    <Radio value='file'>Arquivo</Radio>
                    <Radio value='link'>Link</Radio>
                </Radio.Group>
                {type === 'file' ?
                    <UploadFileAdmin form={form} name='file' /> :
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Input className='input-form bodySMBase' size='middle' placeholder='Título do documento' />
                    </Form.Item>}
            </div>
        },
    ]

    const formConfigEndpoint: ConfigFormItem[] = [
        {
            name: 'tab',
            label: '',
            typeComponent: 'custom',
            element: <Tabs defaultValue={screen} onChange={(v) => setScreen(v)}>
                <TabPane tab="Produção" key="prod" />
                <TabPane tab="Sandbox" key="sand" />
            </Tabs>
        },
        {
            name: `endpoint-${screen}`,
            label: 'Endpoint de produção',
            placeholder: 'Endpoint de produção',
            typeComponent: 'input'
        },
        {
            name: `type-${screen}`,
            label: 'Tipo de autenticação',
            placeholder: 'Selecionar tipo de autenticação',
            typeComponent: 'select'
        }
    ]

    const formConfigOperations: ConfigFormItem[] = [
        {
            name: 'search',
            label: '',
            typeComponent: 'input',
            placeholder: 'Pesquise por nome do endpoint ou rota',
            suffix: <SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} />
        },
        {
            name: 'get',
            typeComponent: 'input',
            label: 'Nome do endpoint',
            elementBefore: <Get />,
            placeholder: 'Nome do endpoint'
        }
    ]

    return <AdminApiConfigStyled>
        <FormAdmin padding='30px 0px 0px 0px'>
            <FormItensAdmin id='' form={form} config={formConfig} labelTitle={{ title: 'Versão da API', subtitle: 'Defina as características de versionamento da sua API.' }} />
            <div className='hr' />
            <FormItensAdmin id='' form={form} config={formConfigEndpoint} labelTitle={{ title: 'Endpoints e rotas', subtitle: 'Especifique aqui os endpoints de produção assim como seus métodos de autenticação.' }} />
            <div className='hr' />
            <FormItensAdmin id='' form={form} config={formConfigOperations} labelTitle={{ title: 'Operações', subtitle: 'Fornecer boas descrições das operações realizadas por endpoint ajuda o usuário a entender se a API se encaixa na sua necessidade.' }} />
            <div className='hr-final' />
            <div className='btn-footer'>
                <ButtonGr1d style={{ flex: 1 }} type='danger'>
                    Descartar alterações
                </ButtonGr1d>
                <ButtonGr1d type='feedback'>
                    Salvar mudanças
                </ButtonGr1d>
            </div>
        </FormAdmin>

        {/* <Form.Item label='Tipo'>
            <Radio.Group value={type} onChange={(v) => setType(v.target.value)}>
                <Radio value='file'>Arquivo</Radio>
                <Radio value='link'>Link</Radio>
            </Radio.Group>
        </Form.Item> */}
    </AdminApiConfigStyled>;
}

const Get: React.FC = () => {
    return <TypographyGr1d className='get' component='subtitleSM' color='colorPrimitiveBlack'>
        GET
    </TypographyGr1d>
}

// const Post: React.FC = () => {
//     return <TypographyGr1d className='get' font='subtitleSM' color='colorPrimitiveBlack'>
//         Post
//     </TypographyGr1d>
// }

export default AdminApiConfig;