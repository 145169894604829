import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Api = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const ApiStyled = styled(Api)`
    position: relative;

    .white-screen {
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        right: 0;
        border-left: 0.5px solid #e0e0e0;
    }

    .anchor {
        position: sticky;
        top: 0px;
        padding-top: 40px;

        .logo {
            position: relative;
            right: 52px;
        }

        > div {
            display: flex;
            
            img {
                margin-top: 16px;
            }
        }

        .assing-api {
            width: 100%;
        }
    }

    .api-header {
        position: relative;
        max-width: 938px;
        padding: 0 20px;
        padding-right: 55px;
        margin-left: auto;
        display: flex;
        margin-top: 40px;
        margin-bottom: 24px;

        .title-logo.sm {
            justify-content: initial;

            .title {
                margin-left: 20px;
            }
        }

        .title-logo {
            display: flex;
            justify-content: center;

            img {
                object-fit: scale-down;
                max-width: 370px;
            }
        }

        .raking {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;

            .average {
                display: flex;
                align-items: baseline;
                justify-content: flex-end;
            }
        }
    }

    .dots {
        position: absolute;
        right: 20px;
        top: 30px;
    }

    .description {
        max-width: 938px;
        padding: 0 20px;
        padding-right: 55px;
        margin-left: auto;
        position: relative;
    }

    .logo {
        height: 100%;
    }

    .tabs {
        margin-bottom: 48px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};

        .div-1280 {
            max-width: 938px;
            padding-right: 55px;
            margin: 0;
            margin-left: auto;
        }
    }

    .options {
        display: flex;
        align-items: center;

        .tab-options {
            flex: 1;
        }

        .buttons-options {
            flex: 0.5;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                margin-right: 13px;
            }
        }

        .buttons-options.sm {
            margin-bottom: 10px;
        }
    }

    
    &.sm .options {
        display: initial;
        overflow: auto;
    }

    &.sm .api-header {
        display: initial;
    }

    .carousel-card {
        display: flex !important;
    }

    .slick-dots-bottom {
        bottom: -24px;
    }

    .div-carousel-card {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        gap: 16px;

        .cards {
            gap: 16px;

            > div {
                width: 360px;
            }
        }

        .ant-carousel {
            width: 100%;
            max-width: 1116px;
        }
    }

    .apis-title {
        display: flex;
    }

    .inline {
        display: inline-block !important;
    }
`