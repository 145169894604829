import styled from "styled-components"

const DocumentationApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const DocumentationApiStyled = styled(DocumentationApi)`
    padding-top: 48px;
    padding-bottom: 48px;
`