import { ApiModel, ApiPaginationModel } from "models/api";
import { CategoryListModel } from "models/category";
import { ApiDocument } from "models/document";
import { CommentModel } from "models/comment";
import { HomePageModel } from "models/homePage";
import { PaginationModel } from "models/pagination";
import { PolicyModel } from "models/policy";
import { UseCaseModel } from "models/useCase";
import { createApiService } from "service";
import { ApiKeyModel } from "models/apiKey";
import qs from "qs";
import { ApiBusinessModel } from "models/apiBusinessModel";
import { ApiPriceModel } from "models/prepaid";

export const createPortalService = () => {
    const service = createApiService("/core/api/v1");
    const serviceBilling = createApiService("/billing/api/v1");

    const getHomePage = (): Promise<HomePageModel[]> => {
        return service.get('/home');
    }

    const getAllCategory = (): Promise<CategoryListModel> => {
        return service.get(`/category?activeApis=true`);
    }

    const getApis = (fulltext: string, page: number, size: number, categories?: string[], log?: boolean): Promise<ApiPaginationModel> => {
        let obj: any = {
            page,
            size,
            fulltext,
            categorys: categories,
            activeApis: true,
            noImage: true,
            log
        }
        return service.get('/apis', {
            params: obj, paramsSerializer: params => {
                return qs.stringify(params)
            }
        });
    }

    const getUseCases = (): Promise<UseCaseModel[]> => {
        return service.get('/use-case/active', { params: { activeApis: true } });
    }

    const getUseCase = (id: number): Promise<UseCaseModel> => {
        return service.get('/use-case/' + id);
    }

    const getApi = (id: number, log?: boolean): Promise<ApiModel> => {
        return service.get('/apis/' + id, { params: { noImage: true, log } });
    }

    const getApiDocuments = (apiId: number): Promise<ApiDocument[]> => {
        return service.get('/apis/' + apiId + '/documents');
    }

    const getApiDefinition = (apiId: number): Promise<string> => {
        return service.get('/apis/' + apiId + '/api-definition');
    }

    const getPolicies = (): Promise<PolicyModel[]> => {
        return service.get('/terms-policies/');
    }

    const getApiComment = (apiId: number, page: number, size: number, q?: string): Promise<PaginationModel<CommentModel>> => {
        let obj: any = {
            page,
            size,
            q
        }
        return service.get(`/apis/${apiId}/comments`, { params: obj });
    }

    const getApiAnswer = (apiId: number, commentId: number): Promise<PaginationModel<CommentModel>> => {
        let obj: any = {
            page: 1,
            size: 10000
        }
        return service.get(`/apis/${apiId}/comments/${commentId}/replies`, { params: obj });
    }

    const postApiComment = (apiId: number, value: string, parentId?: number): Promise<CommentModel> => {
        let obj: any = {
            value,
            parentId
        }
        return service.post(`/apis/${apiId}/comments/`, obj);
    }

    const getApiKey = (apiId: number, type: string): Promise<ApiKeyModel> => {
        let obj: any = {
            apiId,
            keyType: type
        }
        return service.get(`/api-keys/key`, { params: obj });
    }

    const postApiKey = (apiId: any, type: string): Promise<ApiKeyModel> => {
        let obj: any = {
            apiId: parseInt(apiId)
        }
        return service.post(`/api-keys/${type}`, obj);
    }

    const postSubscription = (apiId: any, userId: string): Promise<ApiKeyModel> => {
        let obj: any = {
            userId,
            apiId,
            status: 'TEST'
        }
        return service.post(`/apis-subscription/`, obj);
    }

    const getBusinessModelApi = (apiId: number): Promise<ApiBusinessModel[]> => {
        return service.get('/business-model/', { params: { apiId: apiId } });
    }

    const getPrepaid = (id: number): Promise<ApiPriceModel> => {
        return serviceBilling.get(`/payments/${id}/pricing`);
    }

    return {
        getHomePage, getAllCategory, getApis, getUseCases, getUseCase, getApi, getApiComment, getApiAnswer, postApiComment,
        getApiDocuments, getPolicies, getApiKey, postApiKey, postSubscription, getApiDefinition, getBusinessModelApi, getPrepaid
    };
}