import { notification } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import ButtonGr1d from 'components/portal/button'
import TypographyGr1d from 'components/portal/typography'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useAction } from 'store'
import { accountSavedActions } from 'store/admin/account/action'
import { loginActions } from 'store/login/action'
import { AccountSettingsStyled } from './accountSettings.styled'
import { useHistory } from "react-router-dom";
import FormItensAdmin, { ConfigFormItem } from 'components/admin/form/formItens'
import FormAdmin from 'components/admin/form'
import { UserModel } from 'models/user'

type props = {
}

const AccountSettings: React.FC<props> = (props) => {
    const form = useForm()
    const { handleSubmit } = form;
    const dispatch = useDispatch();
    const action = useAction(accountSavedActions());
    const loginAction = useAction(loginActions())
    const history = useHistory()
    const [sendingForm, setSendingForm] = useState(false)
    const sendingFormRedux: boolean = useSelector((state: any) => state.account.updatingAccount);
    const sendingFormError: boolean = useSelector((state: any) => state.account.updatingAccountError);
    const user: UserModel = useSelector((state: any) => state.login?.user);

    function changeInfo() {
        dispatch(action.updateAccount(
            (form.getValues().firstName === '' || form.getValues().firstName === undefined) ? undefined : form.getValues().firstName.trim(),
            (form.getValues().lastName === '' || form.getValues().lastName === undefined) ? undefined : form.getValues().lastName.trim(),
            (form.getValues().phone === '' || form.getValues().phone === undefined) ? undefined : form.getValues().phone.replace(/\s/g, '').replace(/[\(\)\-]/g, ''),
        ))
    }

    useEffect(() => { 
        if (sendingFormRedux === true && sendingForm === false) {
            setSendingForm(true)
        } else if (sendingFormRedux === false && sendingForm === true) {
            setSendingForm(false)

            if (!sendingFormError) {
                notification.success({
                    message: 'Dados pessoais salvos com sucesso',
                    placement: 'bottomLeft'
                })
                dispatch(loginAction.forceRefreshToken())
            } else {
                notification.error({
                    message: 'Erro ao atualizar dados pessoais',
                    placement: 'bottomLeft'
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendingFormRedux])

    useEffect(() => {
        if (user != null) {
            setTimeout(() => {
                form.setValue('firstName', user.firstName);
                form.setValue('lastName', user.lastName);
                form.setValue('phone', user.telephone);
                form.setValue('email', user.email);
                form.setValue('cpf', user.cpf);
            }, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    //                     <InputGr1d type="masked-input" tabIndex={1} form={{ form: form, name: 'phone', validation: { isRequired: true } }} style={{ marginBottom: 20 }} defaultValue={user.telephone} placeholder='Digite telefone' mask="(11) 11111-1111" label='Telefone' />
    //                     <InputGr1d disabled tabIndex={1} form={{ form: form, name: 'email', validation: { isRequired: true } }} style={{ marginBottom: 20 }} defaultValue={user.email} placeholder='Digite seu e-mail' label='E-mail' />

    const formConfig: ConfigFormItem[] = [
        {
            id: 'firstName',
            name: 'firstName',
            typeComponent: 'input',
            maxLength: 40,
            label: 'Nome',
            placeholder: 'Digite seu nome',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'lastName',
            name: 'lastName',
            typeComponent: 'input',
            maxLength: 40,
            label: 'Sobrenome',
            placeholder: 'Digite seu sobrenome',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'phone',
            name: 'phone',
            typeComponent: 'masked-input',
            label: 'Telefone',
            placeholder: 'Digite telefone',
            mask: "(11) 11111-1111",
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        },
        {
            id: 'email',
            name: 'email',
            typeComponent: 'input',
            label: 'E-mail',
            disabled: true
        },
        {
            id: 'cpf',
            name: 'cpf',
            typeComponent: 'masked-input',
            mask: "111.111.111-11",
            label: 'CPF',
            disabled: true
        },
    ]

    return (
        <AccountSettingsStyled>
            <Content>
                <div className="header">
                    <TypographyGr1d font="Manrope" component="headingLG">
                        Minha conta
                    </TypographyGr1d>
                </div>
                <div className="content">
                    <div className="description">
                        <TypographyGr1d font="Manrope" component="headingLG">
                            Dados pessoais
                        </TypographyGr1d>
                    </div>
                    {user && <div className="form">
                        <FormAdmin onSubmit={handleSubmit(changeInfo)}>
                            <FormItensAdmin form={form} config={formConfig} />
                            <div className="formoptions">
                                <ButtonGr1d type="secondary" onClick={() => { history.goBack() }}>Cancelar</ButtonGr1d>
                                <ButtonGr1d loading={sendingFormRedux} htmlType='submit'>Salvar</ButtonGr1d>
                            </div>
                        </FormAdmin>
                    </div>}
                </div>
            </Content>
        </AccountSettingsStyled>
    )
}

export default AccountSettings
