import { ApiCardStyled } from './api.styled'
import { CSSProperties } from 'react'
import TypographyGr1d from 'components/portal/typography'
import { Tag } from 'antd'
import noImage from "assets/svg/no-image.svg"
import { useDispatch } from 'react-redux'
import { appActions } from 'store/app/action'
import { useAction } from 'store'

type props = {
    style?: CSSProperties,
    id: number,
    name: string,
    author: string,
    version: string,
    type: 'TEST' | 'ACTIVE'
}

const ApiCard: React.FC<props> = (props) => {
    const dispatch = useDispatch();
    const appAction = useAction(appActions());

    return (
        <ApiCardStyled>
            <div onClick={() => dispatch(appAction.goTo(`/admin/api/consumed/${props.id}`))} className='apiCard' style={{ ...props.style }}>
                <div className='cardHeader'>
                    <img onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} alt={props.name} src={`/core/api/v1/apis/${props.id}/image`} />
                    <TypographyGr1d className='oneLine' style={{ marginTop: '20px', display: '-webkit-box' }} color="colorPrimitive700" font="Manrope" fontSize="16px" fontWeight="700">
                        {props.name}
                    </TypographyGr1d>
                    <TypographyGr1d className='oneLine' style={{ marginTop: '0px', display: '-webkit-box' }} color="colorPrimitive600" font="Manrope" fontSize="14px" fontWeight="400">
                        por {props.author}
                    </TypographyGr1d>
                </div>
                <div className='cardBody'>
                    <TypographyGr1d style={{ width: '100%', marginTop: '16px', display: 'flex', justifyContent: 'space-between' }} color="colorPrimitive600" font="Manrope" fontSize="14px" fontWeight="400">
                        <span>Versão</span>
                        <span>{props.version}</span>
                    </TypographyGr1d>
                    <TypographyGr1d style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="colorPrimitive600" font="Manrope" fontSize="14px" fontWeight="400">
                        <span>Status</span>
                        <Tag style={{ marginBottom: 8 }} className={props.type === 'ACTIVE' ? 'tag-active' : 'tag-testing'}>
                            {props.type === 'ACTIVE' ? 'Inscrição ativa' : 'Período de teste'}
                        </Tag>
                    </TypographyGr1d>
                </div>
            </div>
        </ApiCardStyled>
    )
}

export default ApiCard;