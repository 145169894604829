import { Breadcrumb, Col, Popover, Progress, Row, Select, Spin, Table, Tabs, Tag } from "antd";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { ApiModel } from "models/api";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Icon, { SendOutlined } from '@ant-design/icons';
import { ReactComponent as caretDown } from "assets/svg/navegation/caret/down.svg";
import { ReactComponent as invite } from "assets/svg/admin/api/invite.svg";
import { useHistory } from "react-router-dom";
import { AdminApiFormStyled } from "./form.styled";
import AdminApiMarketplace from "./marketplace";
import DrawerAdmin from "components/admin/drawer";
import FormAdmin from "components/admin/form";
import { MemberModel } from "models/member";
import moment from "moment";
import AdminApiConfig from "./config";
import { HashLink } from "react-router-hash-link";
import ApiPreview from "./preview";

const { TabPane } = Tabs;

const AdminApiForm: React.FC = () => {
    const history = useHistory();
    const api: ApiModel | undefined = useSelector((state: any) => state.adminApi.item);
    const loading: boolean = useSelector((state: any) => state.adminApi.itemLoading);
    const members: MemberModel[] | undefined = useSelector((state: any) => state.adminApi.memberList);
    const membersLoading: boolean = useSelector((state: any) => state.adminApi.memberLoading);
    const [screen, setScreen] = useState<string>('1');
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [selectMemberId, setSelectMemberId] = useState<string | undefined>(undefined);
    const [selectMembers, setSelectMembers] = useState<MemberModel[]>([]);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const goTo = (link: string) => {
        history.push(link);
    }

    const columns = [
        {
            title: 'Usuário',
            dataIndex: 'firstName',
            width: '60%',
            key: 'firstName',
            render: (value: string, row: MemberModel) => {
                return <div>{`${row.firstName} ${row.lastName} / ${row.email}`}</div>
            }
        },
        {
            title: 'Data',
            dataIndex: 'createdTimestamp',
            width: '20%',
            key: 'createdTimestamp',
            render: (value: number) => {
                return <div>{moment(value).format('DD/MM/YYYY')}</div>
            }
        },
        {
            title: () => <div style={{ textAlign: 'right' }}>Ações</div>,
            dataIndex: 'id',
            width: '20%',
            key: 'acoes',
            render: () => {
                return <div style={{ textAlign: 'right' }} className='action-send' >Reenviar</div>
            }
        },
    ]

    const addUser = () => {
        const member = members!.find(x => x.id === selectMemberId)!;
        setSelectMembers([...selectMembers, { ...member, createdTimestamp: new Date() }]);
        setSelectMemberId(undefined);
    }

    return <AdminApiFormStyled>
        <Spin spinning={loading}>
            <DrawerAdmin visible={showDrawer} setVisible={setShowDrawer} title='Solicitar cadastro de informações técnicas'>
                <FormAdmin padding='0'>
                    <div className='drawer-content'>
                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 32 }}>
                            <TypographyGr1d component='headingMD' color='colorPrimitive700'>
                                teste
                            </TypographyGr1d>
                            <Select style={{ width: '100%' }} className='select-form' showSearch loading={membersLoading}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectMemberId}
                                onChange={(v) => setSelectMemberId(v)}
                                placeholder='Pesquise na sua empresa por e-mail ou nome'>
                                {/* {_.map(members?.filter(x => !selectMembers.map(x => x.id).includes(x.id)), x => <Option value={x.id} key={x.id}>{`${x.firstName} ${x.lastName} / ${x.email}`}</Option>)} */}
                            </Select>
                            <ButtonGr1d onClick={() => addUser()} disabled={selectMemberId === undefined} type='tertiary'>
                                <SendOutlined />
                            </ButtonGr1d>
                        </div>
                        <TypographyGr1d style={{ paddingBottom: 24 }} component='subtitleSM' color='colorPrimitive500'>
                            Solicitações enviadas
                        </TypographyGr1d>
                        <Table columns={columns} dataSource={selectMembers} pagination={false}>

                        </Table>
                    </div>
                </FormAdmin>
            </DrawerAdmin>
            <div className='header'>
                <TypographyGr1d component='action' color='colorPrimitive600' className='invite'>
                    <div className='description'>
                        Caso necessário, solicite a colegas de equipe o cadastro das informações técnicas da API.
                    </div>
                    <ButtonGr1d onClick={() => { setShowDrawer(true); setSelectMemberId(undefined) }} type='tertiary' icon={<Icon component={invite} />}>
                        Convidar pessoas
                    </ButtonGr1d>
                </TypographyGr1d>
                <TypographyGr1d component='bodySM' style={{ padding: '32px 32px 0px 32px' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => goTo('/admin/api')}>Minhas API's</Breadcrumb.Item>
                        <Breadcrumb.Item>Consumidas</Breadcrumb.Item>
                    </Breadcrumb>
                </TypographyGr1d>
                <div className='header-flex'>
                    <TypographyGr1d style={{ flex: 1, display: 'flex', alignItems: 'center' }} component='headingMD' color='colorPrimitive700'>
                        {api?.name}&nbsp;<Tag style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>{api?.version}&nbsp;&nbsp;<Icon style={{ position: 'relative', top: 5 }} width={5} component={caretDown} /></Tag>
                    </TypographyGr1d>
                    <Popover placement='bottom' trigger='click' content={<div style={{ width: 600 }}>
                        <TypographyGr1d component='caption' color='colorPrimitive700'>
                            <Tag style={{ width: '100%', textAlign: 'left' }}>
                                Preencha todas as informações necessárias para publicação.
                            </Tag>
                            <Row style={{ paddingTop: 24 }}>
                                <Col span={8}>
                                    <TypographyGr1d component='bodyLG' color='colorPrimitive700'>
                                        Geral
                                    </TypographyGr1d>
                                    <TypographyGr1d className='hash-link' style={{ paddingTop: 8 }} component='bodySM' color='colorPrimitive700'>
                                        <HashLink to="/admin/api/form#marketplace-image">Imagem</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-category">Categoria</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-description">Descrição resumida</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-detailedDescription">Descrição detalhada</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-benefit">Vantagens (opcional)</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-target">Público-alvo (opcional)</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-keyword">Palavras-chave</HashLink>
                                        <HashLink to="/admin/api/form#marketplace-doc">Documentação</HashLink>
                                    </TypographyGr1d>
                                </Col>
                                <Col span={8}>
                                    <TypographyGr1d component='bodyLG' color='colorPrimitive700'>
                                        Informações técnicas
                                    </TypographyGr1d>
                                    <TypographyGr1d className='hash-link' style={{ paddingTop: 8 }} component='bodySM' color='colorPrimitive700'>
                                        <HashLink to="/admin/api/form#config-api">Arquivo da API</HashLink>
                                        <HashLink to="/admin/api/form#config-endpoints">Endpoints</HashLink>
                                        <HashLink to="/admin/api/form#config-auth">Transporte e autenticação</HashLink>
                                    </TypographyGr1d>
                                </Col>
                                <Col span={8}>
                                    <TypographyGr1d component='bodyLG' color='colorPrimitive700'>
                                        Modelo de negócio
                                    </TypographyGr1d>
                                    <TypographyGr1d className='hash-link' style={{ paddingTop: 8 }} component='bodySM' color='colorPrimitive700'>
                                        <HashLink to="/admin/api/form#businessModel-model">Selecionar modelo</HashLink>
                                        <HashLink to="/admin/api/form#businessModel-endpoints">Configurar endpoints</HashLink>
                                    </TypographyGr1d>
                                </Col>
                            </Row>
                        </TypographyGr1d>
                    </div>}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', cursor: 'pointer' }}>
                            <Progress style={{ paddingRight: 12 }} format={() => ''} type="circle" percent={25} width={24} />
                            <TypographyGr1d component='subtitleSM'>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingRight: 12 }}>
                                        Criando
                                    </div>
                                    <Icon style={{ position: 'relative', top: 5 }} width={5} component={caretDown} />
                                </div>
                            </TypographyGr1d>
                        </div>
                    </Popover>
                    <ButtonGr1d onClick={() => setShowPreview(v => !v)} style={{ paddingRight: 16 }}>
                        {!showPreview ? 'Pré-visualizar' : 'Voltar para cadastro'}
                    </ButtonGr1d>
                    <ButtonGr1d disabled={true} type='brand'>
                        Solicitar publicação
                    </ButtonGr1d>
                </div>
                <TypographyGr1d style={{ display: !showPreview ? 'block' : 'none' }} component='bodyLG' color='colorPrimitiveBlack'>
                    <Tabs defaultValue={screen} onChange={(v) => setScreen(v)}>
                        <TabPane tab="Marketplace" key="1" />
                        <TabPane tab="Configurações da API" key="2" />
                        <TabPane tab="Modelo de negócio" key="3" />
                        <TabPane tab="Contrato" key="4" />
                        <TabPane tab="Ciclo de vida" key="5" />
                    </Tabs>
                </TypographyGr1d>
            </div>
            <div style={{ display: !showPreview ? 'block' : 'none' }} className='content'>
                {screen === '1' && <AdminApiMarketplace />}
                {screen === '2' && <AdminApiConfig />}
            </div>
            <div style={{ display: showPreview ? 'block' : 'none' }}>
                <ApiPreview />
            </div>
        </Spin>
    </AdminApiFormStyled>;
}

export default AdminApiForm;