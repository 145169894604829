import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Footer = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const FooterLayoutStyled = styled(Footer)`
    > .footer-white {
        position: relative;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        padding: 40px 0px;
    }

    > .footer-white > div {
        margin: auto;
        max-width: 1280px;
        padding: 0 20px;
    }

    @media only screen and (min-width:768px) {
        > .footer-white > div > div {
            display: flex;
        }

        .copyright {
            display: flex;
        }

        .question {
            display: flex;
            border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            align-items: center;
            > div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 50px;
                .txt {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    display: initial;
                }

                .img > img {
                    /* width: initial !important; */
                }
            }
        }
    }

    .question {
        position: relative;

        .question-dots {
            position: absolute;
            bottom: 40px;
            left: 20px;
        }

        img {
            width: 296px;
        }
    }

    > .footer-white > div > div > div {
        flex: 1;
    }

    > .footer-white > div > div > div:first-child {
        flex: 2;
    }

    .logo {
        margin-bottom: 32px;

        > img {
            height: 26px;
            width: 152px;
        }
    }

    .apis {
        display: flex;
        flex-direction: column;

        > div {
            padding-top: 16px;
        }

        > div:first-child {
            padding: 0;
        }
    }

    .apis.sm {
        display: none;
    }

    .feed {
        display: flex;
        flex-direction: column;

        > div {
            padding-top: 16px;
        }

        > div:first-child {
            padding: 0;
        }
    }

    .feed.sm {
        display: none;
    }

    .bold {
        font-weight: 600;
    }
    
    .phone {
        padding: 5px 0px;
    }

    .social.sm {
        display: none;
    }

    .network {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        > div {
            width: 39px;
            height: 39px;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            border-radius: 6px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            > img {
                width: 16px;
            }
        }

        > div:hover {
            border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200};
        }
    }

    hr {
        width: 100%;
        color: #B5B5B5;
    }

    .copyright {
        color: #757575;

        .copyright-gri1d {
            margin-bottom: 4px;
        }

        > div {
            flex: 1;
        }

        .privacity {
            display: flex;
            text-align: right;
            align-items: center;
        }
    }

    .question.question-sm {
        padding: 40px 20px;
    }

    .question.question-sm {
        .txt {
            text-align: center;
        }

        .btn {
            display: flex;
            justify-content: center;
            margin-bottom: 8px;
        }

        .img {
            justify-content: center;
            img {
                min-width: 320px;
                max-width: 375px;
            }
        }   
    }

    .question {
        padding: 40px 100px;

        .title {
            flex: 1;
            color: #5A25F0;
            margin-bottom: 10px;
        }

        .img {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }

    .border-top {
        position: absolute;
        background: linear-gradient(45deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} 50%);
        top: 0;
        right: 0;
        height: 40px;
    }
`