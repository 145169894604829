import { Breadcrumb, Tag } from "antd";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import moment, { Moment } from "moment";
import { useForm } from "react-hook-form";
import FormAdmin from "components/admin/form";
import Icon from '@ant-design/icons';
import { ReactComponent as arrow } from "assets/svg/navegation/arrow/left.svg";
import { useDispatch } from "react-redux";
import { useAction } from "store";
import { appActions } from "store/app/action";
import { PaymentPayableBillStyled } from "./payableBill.styled";

const PaymentPayableBill: React.FC = () => {
  const form = useForm({ defaultValues: { date: '0', status: '0' } });
  const dispatch = useDispatch();
  const appAction = useAction(appActions());

  const formConfig: ConfigFormItem[] = [
    {
      name: 'date',
      noLabel: true,
      typeComponent: 'select',
      data: [{
        value: '0',
        title: 'Últimos 12 meses'
      }],
      span: 24,
      sm: 12,
      md: 8,
      lg: 6
    },
    {
      name: 'status',
      noLabel: true,
      typeComponent: 'select',
      data: [{
        value: '0',
        title: 'Todos status'
      }],
      span: 24,
      sm: 12,
      md: 8,
      lg: 6
    },
  ]


  return <PaymentPayableBillStyled>
    <div className='header'>
      <TypographyGr1d component='bodySM'>
        <Breadcrumb>
          <Breadcrumb.Item>Página Inicial</Breadcrumb.Item>
          <Breadcrumb.Item>Pagamentos</Breadcrumb.Item>
          <Breadcrumb.Item>A pagar</Breadcrumb.Item>
          <Breadcrumb.Item>Todas faturas</Breadcrumb.Item>
        </Breadcrumb>
      </TypographyGr1d>
      <div className='header-title'>
        <TypographyGr1d component='headingMD' color='colorPrimitive700'>
          Todas faturas
        </TypographyGr1d>
      </div>
    </div>
    <div className='body'>
      <ButtonGr1d type='tertiary' style={{ paddingTop: 46, marginBottom: 38, paddingLeft: 32 }} onClick={() => dispatch(appAction.goTo('/admin/payment/payable'))}>
        <Icon component={arrow} />&nbsp;&nbsp;Voltar
      </ButtonGr1d>
      <div className='search'>
        <FormAdmin padding='0px'>
          <FormItensAdmin form={form} config={formConfig} />
        </FormAdmin>
      </div>
      <div className='payable-bill-cards'>
        <PaymentPayableBillCard id='1' startDate={moment('2021-08-01T06:00:00-03:00')} endDate={moment('2021-08-30T06:00:00-03:00')}
          dueDate={moment('2021-09-10T06:00:00-03:00')} status={1} value={583.72} />
        <PaymentPayableBillCard id='2' startDate={moment('2021-07-01T06:00:00-03:00')} endDate={moment('2021-07-30T06:00:00-03:00')}
          dueDate={moment('2021-08-10T06:00:00-03:00')} status={2} value={687.25} />
        <PaymentPayableBillCard id='3' startDate={moment('2021-06-01T06:00:00-03:00')} endDate={moment('2021-06-30T06:00:00-03:00')}
          dueDate={moment('2021-07-10T06:00:00-03:00')} status={3} value={429.44} />
        <PaymentPayableBillCard id='4' startDate={moment('2021-05-01T06:00:00-03:00')} endDate={moment('2021-05-30T06:00:00-03:00')}
          dueDate={moment('2021-06-10T06:00:00-03:00')} status={4} value={744.44} />
      </div>
    </div>
  </PaymentPayableBillStyled>
}

type PropsCard = {
  id?: string,
  startDate: Moment,
  endDate: Moment,
  dueDate: Moment,
  status: number,
  value: number
}

export const PaymentPayableBillCard: React.FC<PropsCard> = (props) => {
  const dispatch = useDispatch();
  const appAction = useAction(appActions());

  let status = 'Em aberto';

  if (props.status === 2)
    status = 'Fechada';
  else if (props.status === 3)
    status = 'Paga';
  else if (props.status === 4)
    status = 'Vencida';

  return <div onClick={() => { if (props.id !== undefined) dispatch(appAction.goTo(`/admin/payment/payable/bill/${props.id}`)) }}
    className='payable-card' style={{cursor: props.id !== undefined ? 'pointer' : 'initial'}}>
    <div>
      <Tag className={`status-${props.status}`}>
        <TypographyGr1d fontWeight='400' fontSize='12px' font='Manrope'>
          {status}
        </TypographyGr1d>
      </Tag>
    </div>
    <div className='title'>
      <div>
        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
          Consumo
        </TypographyGr1d>
        <TypographyGr1d component='subtitleSM' fontWeight='600' color='colorPrimitive600'>
          {props.startDate.format('DD/MM')} a {props.endDate.format('DD/MM')}
        </TypographyGr1d>
      </div>
    </div>
    <div className='footer'>
      <div>
        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
          Vencimento
        </TypographyGr1d>
        <TypographyGr1d fontWeight='600' component='subtitleSM' color='colorPrimitive600'>
          {props.dueDate.format('DD/MM/YYYY')}
        </TypographyGr1d>
      </div>
    </div>
    <div className='footer-value'>
      <TypographyGr1d component='bodySM' color='colorPrimitive600'>
        Valor total
      </TypographyGr1d>
      <TypographyGr1d fontWeight='600' component='subtitleSM' color='colorPrimitive600'>
        R$ {props.value}
      </TypographyGr1d>
    </div>
  </div>;
}

export default PaymentPayableBill;