import styled from "styled-components"

const Typography = (props: any) => {
    if (props.componentType === 'h1') {
        return <h1 onClick={props.onClick} style={props.style} className={props.className}>
            {props.children}
        </h1>
    }
    else if (props.componentType === 'h2') {
        return <h2 onClick={props.onClick} style={props.style} className={props.className}>
            {props.children}
        </h2>
    }
    else if (props.componentType === 'p') {
        return <p onClick={props.onClick} style={props.style} className={props.className}>
            {props.children}
        </p>
    } else if (props.componentType === 'span') {
        return <span onClick={props.onClick} style={props.style} className={props.className}>
            {props.children}
        </span>
    } else {
        return <div onClick={props.onClick} style={props.style} className={props.className}>
            {props.children}
        </div>
    }
}

export const TypographyStyled = styled(Typography)`
    color: ${(props) => props.theme[props.color]};
    font-family: ${(props) => props.theme[props.font]};
    font-weight: ${(props) => props.theme[props.fontWeight]} !important;
    font-size: ${(props) => props.theme[props.fontSize]} !important;
    line-height: ${(props) => props.theme[props.lineHeight]};
    font-style: ${(props) => props.theme[props.fontStyle]};

    .hash-link {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`