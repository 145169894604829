import React, { useState } from 'react'
import TypographyGr1d from "components/portal/typography";
import Icon from "@ant-design/icons";
import { ReactComponent as check } from "assets/svg/home/check.svg";
import { Form, notification } from "antd";
import InputGr1d from "components/portal/input";
import ButtonGr1d from "components/portal/button";
import { useForm } from "react-hook-form";
import { useEffect } from 'react';

const Newsletter:React.FC = () => {
    const form = useForm();
    const { handleSubmit } = form;

    const [isSigned, setIsSigned] = useState<boolean>(false);
    const [teste, setTeste] = useState<string>('');

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://d335luupugsy2.cloudfront.net/js/loader-scripts/2b609f30-b28f-4fa9-b4f9-363e3230d4b2-loader.js";
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        setTeste('valor novo')
    }, [teste])

    const handleError = () => {
        notification.error({
            placement: 'bottomLeft',
            message: 'Erro ao se inscrever no newsletter',
            description:
                'Houve um erro ao tentar se inscrever no newsletter. Tente novamente!',
        })
    }

    const handleSuccess = () => {
        setIsSigned(true)
    }

    return <div className='newsletter'>
        <div>
            <TypographyGr1d style={{ marginBottom: 8 }} component='headingMD' color='colorPrimitiveWhite' >
                Assine a nossa newsletter!
            </TypographyGr1d>
            <TypographyGr1d component='subtitleSM' color='colorPrimitiveWhite' >
                Seja o primeiro a receber nossas promoções e novidades
            </TypographyGr1d>
        </div>
        <div className='fields'>
            { isSigned ? <TypographyGr1d className='newsletter-assign' component='subtitleSM' color='colorBrandPrimary200'>
                <Icon className='logo' component={check} /> Newsletter assinada
            </TypographyGr1d> :
            <Form name='newsletter' style={{ width: '100%' }} onSubmitCapture={handleSubmit(handleSuccess)} onFinishFailed={handleError}>
                <InputGr1d form={{form: form, name: 'email', validation: { isRequired: true, isEmail: true } }} size='40px' colorBrand={true} placeholder='Digite seu e-mail' />
                <ButtonGr1d type="secondary" htmlType='submit' background='white'>
                    Assinar
                </ButtonGr1d>
            </Form> }
        </div>
    </div>
}

export default Newsletter