import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useEffect, useState } from "react";
import { FieldValues, FormContextValues } from "react-hook-form";
import { AddCreditCardStyled } from "./addCreditCard.styled";
import arrowLeft from "assets/svg/arrowLeft.svg"
import { CreditCardPostModel } from "models/company";

type props = {
  form: FormContextValues<FieldValues>;
  onUpdate: () => void;
  onCancel: () => void;
  creditCardAdded: (data: CreditCardPostModel) => void;
  creditCard: CreditCardPostModel | null;
}

const AddCreditCardComponent: React.FC<props> = ({ form, onUpdate, onCancel, creditCardAdded, creditCard }) => {
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [, setFocus] = useState<string | undefined>('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [, setUpdate] = useState(false);
  const [nickname] = useState(creditCard ? creditCard.nickname : '');
  const { handleSubmit } = form;

  function reset() {
    
  }

  function save(data: any) {
    // exemplo de form
    // cvc: "333"
    // name: "tee"
    // nickname: "teste"
    // number: "4234 2342 3432 4234"
    // valid: "12/21"

    const creditCardData: CreditCardPostModel = {
      cardNumber: data.number,
      expirationDate: data.valid,
      holder: data.name,
      nickname: data.nickname,
      securityCode: data.cvc,
    }
    creditCardAdded(creditCardData);
  }

  useEffect(() => {
    onUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvc, expiry, name, number])

  useEffect(() => {
    if (!creditCard) {
      return;
    }

    window.setTimeout(() => {
      form.setValue('nickname', creditCard ? creditCard.nickname : '')
      form.setValue('number', creditCard ? creditCard.cardNumber : '')
      form.setValue('name', creditCard ? creditCard.holder : '')
      form.setValue('valid', creditCard ? creditCard.expirationDate : '')
      form.setValue('cvc', creditCard ? creditCard.securityCode : '')
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCard])

  const formConfigForm: ConfigFormItem[] = [
    {
      name: 'nickname',
      label: 'Apelido do cartão',
      typeComponent: 'input',
      defaultValue: nickname,
      placeholder: 'Digite o apelido do cartão',
      helpText: 'Essa é uma forma de identificar o cartão na sua Wallet.',
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'number',
      label: 'Número do cartão',
      typeComponent: 'masked-input',
      placeholder: 'Digite o número do cartão',
      mask: '1111 1111 1111 1111',
      onChange: (v) => setNumber(v.target.value),
      onFocus: () => setFocus('number'),
      onBlur: () => setFocus(undefined),
      defaultValue: (creditCard || {} as any).cardNumber,
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: any) => {
          let newValue = value;
          newValue = newValue.replaceAll('_', '');
          newValue = newValue.replaceAll(' ', '');
          if (newValue.length < 16)
            return 'Campo inválido';
        }
      }
    },
    {
      name: 'name',
      label: 'Nome impresso no cartão',
      typeComponent: 'input',
      placeholder: 'Digite o nome impresso no cartão',
      onChange: (v) => setName(v.target.value),
      onFocus: () => setFocus('name'),
      onBlur: () => setFocus(undefined),
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'valid',
      label: 'Validade',
      typeComponent: 'masked-input',
      placeholder: 'MM/AA',
      mask: '11/11',
      onChange: (v) => setExpiry(v.target.value),
      onFocus: () => setFocus('expiry'),
      onBlur: () => setFocus(undefined),
      span: 16,
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'cvc',
      label: <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Chave de segurança
        </div>
        <TypographyGr1d onClick={() => window.open('https://support.skype.com/pt-br/faq/FA354/o-que-e-o-codigo-de-verificacao-de-cartao-cvc', '_blank')} style={{ cursor: 'pointer' }} font='Manrope' fontWeight='400' fontSize='12px' color='colorBrandPrimary400'>
          O que é
        </TypographyGr1d>
      </div>,
      onChange: (v) => setCvc(v.target.value),
      onFocus: () => { setFocus('cvc'); setUpdate(x => !x) },
      onBlur: () => { setFocus(undefined); setUpdate(x => !x) },
      typeComponent: 'masked-input',
      placeholder: 'CVC',
      mask: '111',
      span: 8,
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'btn',
      typeComponent: 'custom',
      element: <div style={{ display: 'flex', justifyContent: "flex-end" }}>
        {/* <ButtonGr1d htmlType='reset' style={{ flex: 1 }} type='primitive'>
          Cancelar
        </ButtonGr1d> */}
        <ButtonGr1d htmlType='submit' type='primary'>
          Salvar cartão
        </ButtonGr1d>
      </div>
    }
  ]

  return (
    <AddCreditCardStyled>
      <img alt="voltar" src={arrowLeft} style={{ marginBottom: "30px", cursor: "pointer" }} onClick={onCancel} />
      <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope">
        Adicionar cartão de crédito
      </TypographyGr1d>
      <FormAdmin onSubmit={handleSubmit(save)} onReset={reset}>
        <FormItensAdmin classNameCol='credit-div' form={form} config={formConfigForm} />
      </FormAdmin>
    </AddCreditCardStyled>
  )
}

export const AddCreditCard = AddCreditCardComponent;