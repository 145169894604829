import { AppActionType } from "./action";

export interface AppState {
    loadingLogin: boolean,
    loadingRegister: boolean,
    loadingForgotPassword: boolean,
    loadingVerifyEmail: boolean,
    verifyEmail: string,
    confirmRegister: boolean,
    screen: string;
    userEmail: string,
    loadingUserEmail: boolean,
    verifyTerms: boolean,
    verifyTermsTypes: string,
    loadingAcceptTerms: boolean,
    termsAccepted: boolean,
    recaptcha: boolean,
    updateRecaptcha: boolean,
    user?: {
        name: string,
        email: string,
        firstName: string,
        lastName: string,
        telephone: string,
        cpf: string | undefined,
        orgId: string,
        isAdmin: boolean
    }
}

export const INITIAL_STATE = {
    loadingLogin: false, loadingRegister: false, confirmRegister: false, loadingForgotPassword: false, loadingVerifyEmail: false,
    screen: 'login', verifyEmail: '', loadingUserEmail: false, userEmail: '', verifyTerms: false, verifyTermsTypes: '', loadingAcceptTerms: false,
    termsAccepted: false, recaptcha: false, updateRecaptcha: false
}
export function loginReducer(state: AppState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AppActionType.POST_REGISTER:
            state = { ...state, loadingRegister: true, confirmRegister: false }
            break;
        case AppActionType.POST_REGISTER_SUCCESS:
            state = { ...state, loadingRegister: false, confirmRegister: true }
            break;
        case AppActionType.POST_REGISTER_ERROR:
            state = { ...state, loadingRegister: false }
            break;
        case AppActionType.POST_FORGOT_PASSWORD:
            state = { ...state, loadingForgotPassword: true }
            break;
        case AppActionType.POST_FORGOT_PASSWORD_SUCCESS:
            state = { ...state, loadingForgotPassword: false }
            break;
        case AppActionType.POST_FORGOT_PASSWORD_ERROR:
            state = { ...state, loadingForgotPassword: false }
            break;
        case AppActionType.POST_VERIFY_EMAIL:
            state = { ...state, loadingVerifyEmail: true }
            break;
        case AppActionType.POST_VERIFY_EMAIL_SUCCESS:
            state = { ...state, loadingVerifyEmail: false }
            break;
        case AppActionType.POST_VERIFY_EMAIL_ERROR:
            state = { ...state, loadingVerifyEmail: false }
            break;
        case AppActionType.CONFIRM_REGISTER:
            state = { ...state, confirmRegister: false }
            break;
        case AppActionType.POST_LOGIN:
            state = { ...state, loadingLogin: true }
            break;
        case AppActionType.POST_LOGIN_SUCCESS:
        case AppActionType.POST_LOGIN_ERROR:
            state = { ...state, loadingLogin: false }
            break;
        case AppActionType.CHANGE_SCREEN:
            state = { ...state, screen: action.payload }
            break;
        case AppActionType.CHANGE_VERIFY_EMAIL:
            state = { ...state, verifyEmail: action.payload }
            break;
        case AppActionType.GET_USER_EMAIL:
            state = { ...state, loadingUserEmail: true, userEmail: '' }
            break;
        case AppActionType.GET_USER_EMAIL_SUCCESS:
            state = { ...state, loadingUserEmail: false, userEmail: action.payload }
            break;
        case AppActionType.GET_USER_EMAIL_ERROR:
            state = { ...state, loadingUserEmail: false }
            break;
        case AppActionType.CHANGE_VERIFY_TERMS:
            state = { ...state, verifyTerms: true, verifyTermsTypes: action.payload }
            break;
        case AppActionType.ACCEPT_POLICY_CHANGE:
            state = { ...state, loadingAcceptTerms: true }
            break;
        case AppActionType.ACCEPT_POLICY_CHANGE_ERROR:
            state = { ...state, loadingAcceptTerms: false }
            break;
        case AppActionType.ACCEPT_POLICY_CHANGE_SUCCESS:
            state = { ...state, loadingAcceptTerms: false, verifyTerms: false, termsAccepted: true }
            break;
        case AppActionType.SET_USER:
            state = { ...state, user: action.payload }
            break;
        case AppActionType.CLEAR_USER:
            state = { ...state, user: undefined }
            break;
        case AppActionType.CHANGE_RECAPTCHA:
            state = { ...state, recaptcha: action.payload }
            break;
        case AppActionType.UPDATE_RECAPTCHA:
            state = { ...state, updateRecaptcha: !state.updateRecaptcha }
            break;
    }


    return state;
}