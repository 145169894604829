import { ApiSavedModel } from "models/savedApi";
import { createApiService } from "service";

export const createApiSavedService = () => {
    const service = createApiService('/core/api/v1');

    const getSavedApis = (): Promise<ApiSavedModel[]> => {
        return service.get(`/apis/save/user`);
    }

    const removeApi = (id: string): Promise<void> => {
        return service.delete(`/apis/save/${id}`);
    }

    const saveApi = (id: string): Promise<void> => {
        return service.post(`/apis/save`, {
            "apiId": id
        });
    }

    const apiIsSaved = async (id: string): Promise<boolean> => {
        const res = await service.get(`/apis/save/user/${id}`)
        return (res as unknown as boolean)
    } 

    return { getSavedApis, removeApi, saveApi, apiIsSaved };
}