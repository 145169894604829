import { Switch } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import ButtonGr1d from 'components/portal/button'
import TypographyGr1d from 'components/portal/typography'
import { useEffect } from 'react'
import { RegistrationDrawerStyled } from './registrationDrawerStyled'
import { companyActions } from 'store/admin/company/action'
import { useAction } from 'store'
import { UserModel } from 'models/user'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from "react-hook-form";
import { memberSchema } from "./schemas";
import FormItensAdmin, {ConfigFormItem} from "../../../components/admin/form/formItens";
import FormAdmin from "../../../components/admin/form";

type props = {
    onClose: () => void,
    openedStatus: boolean,
}
const RegistrationDrawer: React.FC<props> = (props) => {
    const user: UserModel & {orgId: string} = useSelector((state: any) => state.login?.user);
    const dispatch = useDispatch();

    const memberForm = useForm({
        validationSchema: memberSchema,
        defaultValues: {
            name: '',
            email: '',
            isAdmin: false,
            cpf: undefined
        }
    });

    const addMemberErrorCode: number & {orgId: string} = useSelector((state: any) => state.company?.addOrgMemberErrorCode);
    useEffect(() => {
        if (addMemberErrorCode === 1062) {
            console.log('Error')
        }
        if (addMemberErrorCode === 2032) {
            console.log('Error')
        }
    }, [addMemberErrorCode])

    const loadingAddMember = useSelector((state: any) => state.company?.addOrgMembersLoading);

    const action = useAction(companyActions());

    const addMember = (data: any) => {
        dispatch(action.addOrgMember(user.orgId, data.name, data.email.toLowerCase(), data.cpf))
    };

    useEffect(() => {
        if (props.openedStatus === false) {
            memberForm.reset()
        }
    }, [props.openedStatus])

    function close() {
        memberForm.reset()
        props.onClose()
    }

    const formConfigData: ConfigFormItem[] = [
        {
            name: 'name',
            label: 'Nome',
            typeComponent: 'input'
        },
        {
            name: 'email',
            typeComponent: 'input',
            label: 'Email',
        },
    ]

    const isAdmin = memberForm.watch('isAdmin')

    if (isAdmin) {
        formConfigData.push({
            name: 'cpf',
            typeComponent: 'masked-input',
            mask: "111.111.111-11",
            label: 'CPF'
        })
    }

    return (
        <RegistrationDrawerStyled>
            <div className="newmembercontainer">
                <FormAdmin padding='32px 0' onSubmit={memberForm.handleSubmit(addMember)}>
                    <div className="content">
                        <TypographyGr1d fontSize="24px" font="Manrope" fontWeight="600" color="colorPrimitiveBlack">
                            Adicionar membro
                        </TypographyGr1d>
                        <FormItensAdmin form={memberForm} config={formConfigData} />
                        <div className="adminswitchercontainer">
                            <div>
                                <Controller
                                    as={<Switch defaultChecked />}
                                    name='isAdmin'
                                    control={memberForm.control}
                                />

                                <TypographyGr1d fontSize="20px" font="Manrope" fontWeight="700" color="colorPrimitive600">
                                    Administrador
                                </TypographyGr1d>
                            </div>
                            { isAdmin &&
                            <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive400" style={{marginTop: '14px'}}>
                                Para realizar a ação, é necessário cadastrar o CPF deste usuário.
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.75)', marginLeft: '9px' }} />
                            </TypographyGr1d>
                            }
                            <TypographyGr1d font="Manrope" fontSize="16px" color="colorPrimitive500" style={{marginTop: '24px'}}>
                                Somente usuários com a função de administrador podem:
                                <ul>
                                    <li>
                                        Visualizar e editar informações de faturamento das API’s.
                                    </li>
                                    <li>
                                        Gerenciar membros da empresa.
                                    </li>
                                    <li>
                                        Contratar novas API’s.
                                    </li>
                                </ul>
                            </TypographyGr1d>
                        </div>
                    </div>
                    <div className="footer" style={{marginTop: '16px'}}>
                        <ButtonGr1d htmlType='submit' width="100%" type="primary" style={{marginBottom: '16px'}} loading={loadingAddMember}>
                            Salvar membro
                        </ButtonGr1d>
                        <ButtonGr1d onClick={close} width="100%" type="secondary">
                            Voltar
                        </ButtonGr1d>
                    </div>
                </FormAdmin>
            </div>
        </RegistrationDrawerStyled>
    )
}

export default RegistrationDrawer
