import { DatePicker } from "antd";
import { FormAdminStyled } from "../form/index.styled";

type Props = {
  format?: string,
  picker?: 'date' | 'month' | 'quarter' | 'time' | 'week' | 'year'
}

const DatePickerGr1d: React.FC<Props> = (props) => {
  return <FormAdminStyled>
    <DatePicker dropdownClassName='gr1d-date' className='input-form input-form-suffix' picker={props.picker} format={props.format} />
  </FormAdminStyled>
}

export default DatePickerGr1d;