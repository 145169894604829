import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Categories = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const CategoriesStyled = styled(Categories)`
    // width: 100%;
    .tag {
        .tagItem {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
            white-space: nowrap;
            padding: 4px 8px;
            border-radius: 24px;
        }
        .tagItemMore {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }
        a {
            color: unset;
        }
        position: relative;
        .tagAbsolute {
            position: absolute !important;
            top: 100%;
            left: 0;
            position: relative;
            // padding: 4px 8px;
            // transition: border-radius 0.40s ease-in;
            // background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            // white-space: nowrap; 
        }
        .taglist {
            background-color: white;
            box-shadow: 0px 4px 8px rgba(20, 22, 31, 0.08);
            min-width: 100%;
            overflow-y: hidden;
            max-height: 0;
            transition: max-height 0.25s ease-in;
            border-radius: 4px;
            margin-left: -8px;
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                padding-top: 4px;
                padding: 8px;
                li {
                    padding: 4px 0px 4px 0px;
                    white-space: nowrap;
                    overflow: hidden;
                    width: fit-content;
                }
            }
        }
    }
    .showMore {
        .tagAbsolute {
            border-radius: 12px 12px 12px 12px;
        }
        .taglist {
            max-height: 250px;
            transition-delay: 1s;
            transition: max-height 0.25s 0.15s ease-in;
            z-index: 1;
            position: relative;
        }
    }
`