import { Breadcrumb, Table } from "antd";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import moment from "moment";
import Icon from '@ant-design/icons';
import { ReactComponent as pdf } from "assets/svg/admin/components/pdf2.svg";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { useAction } from "store";
import { appActions } from "store/app/action";
import PayableDetail from "./payableDetail";
import { useState } from "react";
import { PaymentPayableBillCard } from "./payableBill";
import { ReactComponent as arrow } from "assets/svg/navegation/arrow/left.svg";
import { match } from "react-router-dom";
import { PayableBillResumeStyled } from "./payableBillResume.styled";

type Props = {
  match: match,
}

const PaymentPayableBillResume: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const appAction = useAction(appActions());
  const [visible, setVisible] = useState<boolean>(false);
  const [apiSelect, setApiSelect] = useState<string>('');

  const columns: ColumnsType<any> = [
    {
      title: 'API',
      dataIndex: 'api',
      width: 472,
      key: 'api',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.api - b.api,
      render: (value: string, row: any) => {
        return <div>
          <TypographyGr1d color='colorPrimitive700' component='bodySM'>
            {row.api}
          </TypographyGr1d>
          <TypographyGr1d color='colorPrimitive700' component='caption'>
            {row.provider}
          </TypographyGr1d>
        </div>
      }
    },
    {
      title: <>Chamadas&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'calls',
      width: 163,
      key: 'calls',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.calls - b.calls,
    },
    {
      title: <>Consumo total&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'consume',
      width: 178,
      key: 'consume',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.consume - b.consume,
    },
    {
      title: 'Ações',
      align: 'right',
      dataIndex: 'actions',
      width: 147,
      key: 'actions',
      render: (value: any, row: any) => <TypographyGr1d color='colorBrandPrimary400' font='Manrope' fontWeight='600' fontSize='14px'>
        <span onClick={() => {
          setApiSelect(row);
          setVisible(true);
        }} style={{ cursor: 'pointer' }}>Ver detalhes</span>
      </TypographyGr1d>
    }
  ]

  const data = [{
    api: 'Facematch - Big ID',
    provider: 'Big Data Corp',
    calls: 3459,
    consume: 274.12
  }]

  return <PayableBillResumeStyled>
    <div className='header'>
      <TypographyGr1d component='bodySM'>
        <Breadcrumb>
          <Breadcrumb.Item>Página Inicial</Breadcrumb.Item>
          <Breadcrumb.Item>Pagamentos</Breadcrumb.Item>
          <Breadcrumb.Item>A pagar</Breadcrumb.Item>
          <Breadcrumb.Item>Todas faturas</Breadcrumb.Item>
        </Breadcrumb>
      </TypographyGr1d>
      <div className='header-title'>
        <TypographyGr1d component='headingMD' color='colorPrimitive700'>
          Todas faturas
        </TypographyGr1d>
      </div>
    </div>
    <div className='body'>
      <ButtonGr1d type='tertiary' style={{ paddingTop: 46, marginBottom: 38, paddingLeft: 32 }} onClick={() => dispatch(appAction.goTo('/admin/payment/payable/bill'))}>
        <Icon component={arrow} />&nbsp;&nbsp;Voltar
      </ButtonGr1d>
      <div className='payable-bill-cards'>
        <PaymentPayableBillCard startDate={moment('2021-07-01T06:00:00-03:00')} endDate={moment('2021-07-30T06:00:00-03:00')}
          dueDate={moment('2021-08-10T06:00:00-03:00')} status={1} value={687.25} />
      </div>
    </div>
    <div className='search'>
      <TypographyGr1d style={{ flex: 1 }} component='subtitleMD' color='colorPrimitive700'>
        Consumo detalhado
      </TypographyGr1d>
      <ButtonGr1d type='primitive'>
        <Icon style={{ paddingRight: 12 }} component={pdf} />Exportar
      </ButtonGr1d>
    </div>
    <div className='table'>
      <Table pagination={false} columns={columns} dataSource={data} />
    </div>
    <PayableDetail setVisible={setVisible} visible={visible} api={apiSelect} />
  </PayableBillResumeStyled>
}

export default PaymentPayableBillResume;