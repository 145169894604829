import { Card, Select, Space } from "antd";
import TypographyGr1d from "components/portal/typography";
import React from "react";
import { CardApiStyled } from "./api.styled";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import noImage from 'assets/svg/no-image.svg';
import { CategoryModel } from "models/category";
import SaveApiFlag from 'components/portal/saveApiFlag'
import { useEffect } from "react";
import { useRef } from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as star } from "assets/svg/star.svg";
import { Tooltip } from 'antd';

type CardProps = {
    id?: number;
    title: string;
    subTitle?: string;
    description: string;
    gateway: string;
    img?: string;
    categories?: CategoryModel[];
    height?: string;
    maxWidth?: number;
    maxWidthTitle?: number;
}

const CardApiGr1d: React.FC<CardProps> = (props) => {
    const history = useHistory();

    const txtRef = useRef(null)

    useEffect(() => {
        window.setTimeout(() => {
            if (txtRef.current === null) {
                return
            }
            const txtRefNotNull: any = txtRef.current
            if ((txtRefNotNull.firstChild as any).offsetWidth < (txtRefNotNull.firstChild as any).scrollWidth) {
                if (txtRefNotNull.firstChild === null) {
                    return
                }
                const spanTitle = txtRefNotNull.firstChild.firstChild
                if (spanTitle === null) {
                    return
                }
                if (!(spanTitle as any).getAttribute('title')) {
                    (spanTitle as any).setAttribute('title', props.title)
                }
            }
        }, 500)
    }, [props.title])

    const selectProps = {
        mode: 'multiple' as const,
        style: { width: '100%' },
        value: props.categories?.map(x => x.name),
        maxTagCount: 'responsive' as const,
    };

    return <CardApiStyled>
        <Link to={{ pathname: `/api/${props.id}/general`, state: { log: true } }}>
            <Card bodyStyle={{ padding: 16 }} style={{ width: '100%', maxWidth: props.maxWidth ?? 400, height: props.height }}>
                <div className="card-wrapper">
                    <div className="card-content">
                        <div className='card-logo'>
                            <img src={`/core/api/v1/apis/${props.id}/image`} alt='logo' onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} />
                            <div ref={txtRef} className='txt'>
                                <div style={{ display: 'flex' }}>
                                    <TypographyGr1d style={{ marginBottom: 4, maxWidth: props.maxWidthTitle ?? 250 }} fontSize="20px" fontWeight="700" color='colorPrimitiveBlack'>
                                        <span>{props.title}</span>
                                    </TypographyGr1d>
                                    {props.gateway === 'WSO2' && <Tooltip overlayInnerStyle={{ width: 275 }} placement="topLeft"
                                        title={<TypographyGr1d style={{ width: 275 }} fontSize='12px' fontWeight='600'>
                                            As APIs com este selo já contam com o novo processo de&nbsp;
                                            <TypographyGr1d style={{ display: 'inline-block' }} fontSize='12px' fontWeight='800'>assinatura simplificada</TypographyGr1d>
                                            &nbsp;da Gr1d.
                                        </TypographyGr1d>}>
                                        <Icon style={{ fontSize: 16, paddingLeft: 11 }} component={star} />
                                    </Tooltip>}
                                </div>
                                <TypographyGr1d component='subtitleSM' color='colorPrimitive400'>
                                    {props.subTitle != null ? 'por ' + props.subTitle : ''}
                                </TypographyGr1d>
                            </div>
                        </div>
                        <div className='card-txt'>
                            <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                                {props.description}
                            </TypographyGr1d>
                        </div>
                    </div>
                    <div className='category-tag pointer'>
                        <div>
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Select disabled={true} {...selectProps} />
                            </Space>
                        </div>
                        <SaveApiFlag apiId={props.id as number} />
                    </div>
                </div>
            </Card>
        </Link>
    </CardApiStyled>
};

export default CardApiGr1d;