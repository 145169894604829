import React, { useEffect, useRef, useState } from "react";
import { LoginPasswordStyled, LoginStyled } from "./index.styled";
import logo from "assets/svg/logo.svg"
// import google from "assets/svg/logon/google.svg"
// import facebook from "assets/svg/logon/facebook.svg"
// import github from "assets/svg/logon/github.svg"
import TypographyGr1d from "components/portal/typography";
import InputGr1d from "components/portal/input";
import ButtonGr1d from "components/portal/button";
import { Form } from "antd";
import { useHistory } from "react-router-dom";
import Icon from '@ant-design/icons';
import { ReactComponent as arrow } from "assets/svg/navegation/arrow/left.svg";
import { ReactComponent as mail } from "assets/svg/logon/mail.svg";
import { useForm } from "react-hook-form";
import { loginActions } from "store/login/action";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "utils/auth";
import { useAction } from "store";
import PolicyModal from "components/portal/modal/policy"
import ReCAPTCHA from "react-google-recaptcha";

const Login: React.FC = () => {
    let history = useHistory();
    const loadingLogin: boolean = useSelector((state: any) => state.login?.loadingLogin);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        if (auth.isAuthenticated()) {
            history.push('/');
        }
    }, [history, loadingLogin]);


    const dispatch = useDispatch();
    const action = useAction(loginActions());
    const screenStr: string = useSelector((state: any) => state.login.screen);

    const [screen, setScreen] = useState<JSX.Element>(<></>);

    useEffect(() => {
        if (screenStr === 'login')
            setScreen(<LoginScreen />);
        else if (screenStr === 'forgotPassword')
            setScreen(<LoginForgotPassword />);
        else if (screenStr === 'sendPassword')
            setScreen(<LoginSendPassword />);
        else if (screenStr === 'newPassword')
            setScreen(<LoginNewPassword />);
        else if (screenStr === 'verifyEmail')
            setScreen(<LoginVerifyEmail />);
    }, [screenStr]);

    useEffect(() => {
        return () => {
            dispatch(action.changeScreen('login'));
        }
    }, [dispatch, action])

    return <LoginStyled>
        <div className={`content-white ${grid}`}>
            <div className='border-top' />
            <div className='border-bottom' />
            {screen}
            {/* <LoginForgotPassword />
            <LoginSendPassword />
            <LoginNewPassword /> */}
        </div>
        <div className='dots' />
    </LoginStyled>;
}

const LoginScreen: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const form = useForm();
    const { handleSubmit } = form;
    const action = loginActions();
    const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
    const loadingLogin: boolean = useSelector((state: any) => state.login?.loadingLogin);
    const needAgreeTerms: boolean = useSelector((state: any) => state.login?.verifyTerms);
    const termsAccepted: boolean = useSelector((state: any) => state.login?.termsAccepted);
    const recaptcha: boolean = useSelector((state: any) => state.login?.recaptcha);
    const updateRecaptcha: boolean = useSelector((state: any) => state.login?.updateRecaptcha);
    const [recaptchaResponse, setRecaptchaResponse] = useState<String | null>('');
    const refCaptcha = useRef<ReCAPTCHA>(null);

    const login = (data: any) => {
        refInputEmail.current?.blur();
        refInputPassword.current?.blur();
        if ((history.location.state as any)?.type !== 'ActionApi')
            dispatch(action.login({ ...data, token: recaptchaResponse }));
        else
            dispatch(action.login({ ...data, token: recaptchaResponse }, (history.location.state as any)?.path));
    }

    const refInputEmail = useRef<any>(null);
    const refInputPassword = useRef<any>(null);

    useEffect(() => {
        if (recaptcha === true) {
            setShowRecaptcha(true);
            setRecaptchaResponse(null);
        }
    }, [recaptcha])

    useEffect(() => {
        if (showRecaptcha) {
            refCaptcha.current?.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRecaptcha])

    useEffect(() => {
        if (termsAccepted) {
            login({
                'username': (refInputEmail.current as any).props.value,
                'password': (refInputPassword.current as any).props.value
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [termsAccepted])

    return <div className='all-screen'>
        <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

        <div className='content'>
            {needAgreeTerms && <PolicyModal onAccept={() => { dispatch(action.acceptPolicyChange((refInputEmail.current as any).props.value)) }} />}
            <div className='login'>
                <Form onSubmitCapture={handleSubmit(login)}>
                    <div className='welcome'>
                        <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                            Bem-vindo de volta
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            Aqui o seu tempo rende mais
                        </TypographyGr1d>
                    </div>
                    <InputGr1d inputRef={refInputEmail} form={{ form: form, name: 'username', validation: { isRequired: true } }} style={{ marginBottom: 24 }} placeholder='Digite seu e-mail' label='E-mail' />
                    <InputGr1d inputRef={refInputPassword} form={{ form: form, name: 'password', validation: { isRequired: true } }} style={{ marginBottom: 16 }} type='password' placeholder='Digite sua senha' label='Senha' />
                    <div className='forgot-password'>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <span onClick={() => dispatch(action.changeScreen('forgotPassword'))}>Esqueci minha senha</span>
                        </TypographyGr1d>
                    </div>
                    <div className='recaptcha'>
                        {showRecaptcha && <ReCAPTCHA
                            ref={refCaptcha}
                            sitekey="6LcfeRAcAAAAAMKL5uACKBZ79CugFDPowFrD0hCM"
                            onChange={setRecaptchaResponse}
                            onExpired={() => setRecaptchaResponse(null)}
                        />}
                    </div>
                    <ButtonGr1d disabled={recaptchaResponse == null && showRecaptcha} htmlType="submit" loading={loadingLogin} type='primary' background='white' width={'100%'}>
                        Entrar
                    </ButtonGr1d>
                    {/* <Divider className='captionBase divider'>
                        ou faça login com
                    </Divider>
                    <div className='login-social'>
                        <img alt='google' src={google} />
                        <img alt='facebook' src={facebook} />
                        <img alt='github' src={github} />
                    </div> */}
                </Form>
                <div className='without-account'>
                    <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                        Não tem conta?
                    </TypographyGr1d>
                    &nbsp;
                    <div onClick={() => history.push('/register')}>
                        <TypographyGr1d style={{ cursor: 'pointer' }} component='bodySM' color='colorBrandPrimary500'>
                            Cadastre-se
                        </TypographyGr1d>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const LoginForgotPassword: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const action = loginActions();
    const loadingForgotPassword: boolean = useSelector((state: any) => state.login?.loadingForgotPassword);
    const form = useForm();
    const { handleSubmit } = form;

    const forgotPassword = (data: any) => {
        dispatch(action.forgotPassword(data.email));
    }

    return <LoginPasswordStyled>
        <Form className='all-screen' onSubmitCapture={handleSubmit(forgotPassword)}>
            <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

            <div className='content'>
                <div className='password-screen'>
                    <ButtonGr1d type='primitive' onClick={() => dispatch(action.changeScreen('login'))} style={{ marginBottom: 44 }}>
                        <Icon component={arrow} />&nbsp;&nbsp;Voltar
                    </ButtonGr1d>
                    <TypographyGr1d style={{ marginBottom: 8 }} color='colorPrimitiveBlack' component='headingSM'>
                        Esqueceu sua senha?
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 24 }} color='colorPrimitive500' component='bodySM'>
                        Vamos ajudá-lo a redefinir a senha
                    </TypographyGr1d>
                    <InputGr1d form={{ form: form, name: 'email', validation: { isRequired: true, isEmail: true } }} style={{ marginBottom: 24 }} width='100%' label='E-mail' placeholder='Digite seu e-mail' />
                    <ButtonGr1d loading={loadingForgotPassword} htmlType='submit' type='brand' width='100%'>
                        Continuar
                    </ButtonGr1d>
                </div>
            </div>
        </Form>
    </LoginPasswordStyled>;
}

const LoginVerifyEmail: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const action = loginActions();
    const loadingVerifyEmail: boolean = useSelector((state: any) => state.login?.loadingVerifyEmail);
    const verifyEmail: string = useSelector((state: any) => state.login?.verifyEmail);
    const form = useForm({ defaultValues: { email: verifyEmail } });
    const { handleSubmit } = form;

    const postVerifyEmail = (data: any) => {
        dispatch(action.verifyEmail(data.email));
    }

    return <LoginPasswordStyled>
        <Form className='all-screen' onSubmitCapture={handleSubmit(postVerifyEmail)}>
            <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

            <div className='content'>
                <div className='password-screen'>
                    <ButtonGr1d type='primitive' onClick={() => dispatch(action.changeScreen('login'))} style={{ marginBottom: 44 }}>
                        <Icon component={arrow} />&nbsp;&nbsp;Voltar
                    </ButtonGr1d>
                    <TypographyGr1d style={{ marginBottom: 8 }} color='colorPrimitiveBlack' component='headingSM'>
                        Usuário não confirmado
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 24 }} color='colorPrimitive500' component='bodySM'>
                        Desejar reenviar o e-mail de confirmação?
                    </TypographyGr1d>
                    <InputGr1d form={{ form: form, name: 'email', validation: { isRequired: true, isEmail: true } }} style={{ marginBottom: 24 }} width='100%' label='E-mail' placeholder='Digite seu e-mail' />
                    <ButtonGr1d loading={loadingVerifyEmail} htmlType='submit' type='brand' width='100%'>
                        Enviar
                    </ButtonGr1d>
                </div>
            </div>
        </Form>
    </LoginPasswordStyled>;
}

const LoginSendPassword: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const action = loginActions();

    return <LoginPasswordStyled>
        <div className='all-screen'>
            <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

            <div className='content'>
                <div className='send-password-screen'>
                    <Icon style={{ marginBottom: 32 }} className='mail' component={mail} />
                    <TypographyGr1d style={{ marginBottom: 8 }} color='colorPrimitiveBlack' component='headingXS'>
                        Pronto, instruções enviadas!
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 60, textAlign: 'center' }} color='colorPrimitive500' component='bodySM'>
                        Verifique seu e-mail gr1d.test@example.com <br /> e siga as instruções.
                    </TypographyGr1d>
                    <ButtonGr1d onClick={() => dispatch(action.changeScreen('login'))} style={{ width: '80%' }} type='brand' width='100%'>
                        Ir para o login
                    </ButtonGr1d>
                </div>
            </div>
        </div>
    </LoginPasswordStyled>;
}

const LoginNewPassword: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const action = loginActions();

    return <LoginPasswordStyled>
        <div className='all-screen'>
            <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

            <div className='content'>
                <div className='password-screen'>
                    <TypographyGr1d style={{ marginBottom: 24 }} color='colorPrimitiveBlack' component='headingSM'>
                        Crie sua nova senha
                    </TypographyGr1d>
                    <InputGr1d type='password' style={{ marginBottom: 20 }} width='100%' label='Nova senha' placeholder='Digite sua nova senha' />
                    <InputGr1d type='password' style={{ marginBottom: 24 }} width='100%' label='Repetir nova senha' placeholder='Digite novamente sua nova senha' />
                    <ButtonGr1d onClick={() => dispatch(action.changeScreen('login'))} type='brand' width='100%'>
                        Continuar
                    </ButtonGr1d>
                </div>
            </div>
        </div>
    </LoginPasswordStyled>;
}

export default Login;