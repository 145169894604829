import { createApiService } from "service";

export const createAccountService = () => {
    const service = createApiService('/core/api/v1');

    type accountInfo = {
        firstName: string | undefined,
        lastName: string | undefined,
        telephone: string | undefined,
        cpf: string | undefined;
    }
    const updateAccount = (accountInfo: accountInfo): Promise<void> => {
        return service.put(`/user`, accountInfo);
    }

    return { updateAccount };
}