import { Breadcrumb, Table, Tag } from "antd";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import moment, { Moment } from "moment";
import { PaymentPayableStyled } from "./payable.styled";
import { SearchOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import FormAdmin from "components/admin/form";
import DatePickerGr1d from "components/admin/datepicker";
import Icon from '@ant-design/icons';
import { ReactComponent as pdf } from "assets/svg/admin/components/pdf2.svg";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { useAction } from "store";
import { appActions } from "store/app/action";
import PayableDetail from "./payableDetail";
import { useState } from "react";

const PaymentPayable: React.FC = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const appAction = useAction(appActions());
  const [visible, setVisible] = useState<boolean>(false);
  const [apiSelect, setApiSelect] = useState<string>('');

  const formConfig: ConfigFormItem[] = [
    {
      name: 'search',
      noLabel: true,
      typeComponent: 'input',
      placeholder: 'Pesquisar por nome da API',
      suffix: <SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} />,
      span: 24,
      sm: 14,
      md: 10
    },
    {
      name: 'date',
      noLabel: true,
      typeComponent: 'custom',
      element: <div style={{ display: 'flex', gap: 8 }}>
        <DatePickerGr1d format='MMMM/YYYY' picker='month' />
        <ButtonGr1d type='primitive'>
          <Icon style={{ paddingRight: 12 }} component={pdf} />Exportar
        </ButtonGr1d>
      </div>,
      span: 24,
      sm: 10,
      md: 14
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: 'API',
      dataIndex: 'api',
      width: 472,
      key: 'api',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.api - b.api,
      render: (value: string, row: any) => {
        return <div>
          <TypographyGr1d color='colorPrimitive700' component='bodySM'>
            {row.api}
          </TypographyGr1d>
          <TypographyGr1d color='colorPrimitive700' component='caption'>
            {row.provider}
          </TypographyGr1d>
        </div>
      }
    },
    {
      title: <>Chamadas&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'calls',
      width: 163,
      key: 'calls',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.calls - b.calls,
    },
    {
      title: <>Consumo total&nbsp;&nbsp;</>,
      align: 'right',
      dataIndex: 'consume',
      width: 178,
      key: 'consume',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.consume - b.consume,
    },
    {
      title: 'Ações',
      align: 'right',
      dataIndex: 'actions',
      width: 147,
      key: 'actions',
      render: (value: any, row: any) => <TypographyGr1d color='colorBrandPrimary400' font='Manrope' fontWeight='600' fontSize='14px'>
        <span onClick={() => {
          setApiSelect(row);
          setVisible(true);
        }} style={{ cursor: 'pointer' }}>Ver detalhes</span>
      </TypographyGr1d>
    }
  ]

  const data = [{
    api: 'Facematch - Big ID',
    provider: 'Big Data Corp',
    calls: 3459,
    consume: 274.12
  }]

  return <PaymentPayableStyled>
    <div className='header'>
      <TypographyGr1d component='bodySM'>
        <Breadcrumb>
          <Breadcrumb.Item>Página Inicial</Breadcrumb.Item>
          <Breadcrumb.Item>Pagamentos</Breadcrumb.Item>
          <Breadcrumb.Item>A pagar</Breadcrumb.Item>
        </Breadcrumb>
      </TypographyGr1d>
      <div className='header-title'>
        <TypographyGr1d component='headingMD' color='colorPrimitive700'>
          A pagar
        </TypographyGr1d>
      </div>
    </div>
    <div className='body'>
      <div className='last-invoice'>
        <TypographyGr1d style={{ flex: 1 }} component='subtitleMD' color='colorPrimitive700'>
          Últimas faturas
        </TypographyGr1d>
        <ButtonGr1d onClick={() => dispatch(appAction.goTo('/admin/payment/payable/bill'))} type='primitive'>
          Ver todas
        </ButtonGr1d>
      </div>
      <div className='payable-cards'>
        <PaymentPayableCard startDate={moment('2021-08-01T06:00:00-03:00')} endDate={moment('2021-08-30T06:00:00-03:00')}
          dueDate={moment('2021-09-10T06:00:00-03:00')} status={1} value={583.72} />
        <PaymentPayableCard startDate={moment('2021-07-01T06:00:00-03:00')} endDate={moment('2021-07-30T06:00:00-03:00')}
          dueDate={moment('2021-08-10T06:00:00-03:00')} status={2} value={687.25} />
        <PaymentPayableCard startDate={moment('2021-06-01T06:00:00-03:00')} endDate={moment('2021-06-30T06:00:00-03:00')}
          dueDate={moment('2021-07-10T06:00:00-03:00')} status={3} value={429.44} />
      </div>
      <div className='search'>
        <TypographyGr1d style={{ paddingBottom: 32 }} component='subtitleMD' color='colorPrimitive700'>
          Consumo detalhado
        </TypographyGr1d>
        <FormAdmin padding='0px'>
          <FormItensAdmin form={form} config={formConfig} />
        </FormAdmin>
      </div>
      <div className='table'>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
    <PayableDetail setVisible={setVisible} visible={visible} api={apiSelect} />
  </PaymentPayableStyled>
}

type PropsCard = {
  startDate: Moment,
  endDate: Moment,
  dueDate: Moment,
  status: number,
  value: number
}

const PaymentPayableCard: React.FC<PropsCard> = (props) => {
  let status = 'Em aberto';

  if (props.status === 2)
    status = 'Fechada';
  else if (props.status === 3)
    status = 'Paga';

  return <div className='payable-card'>
    <div className='title'>
      <TypographyGr1d style={{ flex: 1 }} component='caption' color='colorPrimitiveBlack'>
        Consumo de {props.startDate.format('DD/MM')} a {props.endDate.format('DD/MM')}
      </TypographyGr1d>
      <Tag className={`status-${props.status}`}>
        <TypographyGr1d fontWeight='400' fontSize='12px' font='Manrope'>
          {status}
        </TypographyGr1d>
      </Tag>
    </div>
    <div className='footer'>
      <TypographyGr1d style={{ flex: 1 }} component='bodySM' color='colorPrimitive600'>
        Vencimento
      </TypographyGr1d>
      <TypographyGr1d component='bodySM' color='colorPrimitive600'>
        Valor total
      </TypographyGr1d>
    </div>
    <div className='footer-value'>
      <TypographyGr1d style={{ flex: 1 }} fontWeight='600' component='subtitleSM' color='colorPrimitive600'>
        {props.dueDate.format('DD/MM/YYYY')}
      </TypographyGr1d>
      <TypographyGr1d fontWeight='600' component='subtitleSM' color='colorPrimitive600'>
        R$ {props.value}
      </TypographyGr1d>
    </div>
  </div>;
}

export default PaymentPayable;