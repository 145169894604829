import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const PaymentPayable = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const PaymentPayableBillStyled = styled(PaymentPayable)`
    .header {
        padding: 32px 32px 0px 32px;
    }

    .header-title {
        padding-top: 16px;
        padding-bottom: 32px;
    }

    .body {
        border-top: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};

        .last-invoice {
            padding: 32px 32px 36px 32px;
            display: flex;
            flex-wrap: wrap;
        }

        .search {
            padding: 0px 32px 40px 32px;
        }

        .table {
            padding: 0px 32px 36px 32px;

            .ant-table-thead {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
            }

            .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
                padding: 8px 16px;
            }
        }
    }

    .payable-bill-cards {
        display: flex;
        gap: 16px;
        padding: 0px 32px 40px 32px;
        flex-wrap: wrap;

        .payable-card:hover {
            border: none;
            box-shadow: 0px 16px 32px rgba(20, 22, 31, 0.08);
        }

        .payable-card {
            width: 100%;
            height: 72px;
            padding: 12px 24px;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            align-items: center;

            > div {
                flex: 1;
            }

            .status-1 {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
                color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
                padding: 1px 8px;
            }

            .status-2 {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive50};
                color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive600};
                padding: 1px 8px;
            }

            .status-3 {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
                color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
                padding: 1px 8px;
            }

            .status-4 {
                background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative100};
                color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
                padding: 1px 8px;
            }

            .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .footer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .footer-value {
                text-align: right;
            }
        }
    }
`