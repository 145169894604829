export enum fontEnum {
  Manrope = 'fontFamilyBase',
  DMMono = "fontFamilyVariant",
}

export enum fontWeightEnum {
  W800 = 'fontWeightExtraBold',
  W700 = "fontWeightBold",
  W600 = "fontWeightSemiBold",
  W500 = "fontWeightMedium",
  W400 = "fontWeightRegular",
  W300 = "fontWeightLight",
  W200 = "fontWeightExtraLight",
}

export enum fontSizeEnum {
  "10px" = "fontSizeTiny",
  "12px" = "fontSizeXXXS",
  "14px" = "fontSizeXXS",
  "16px" = "fontSizeXS",
  "18px" = "fontSizeX",
  "20px" = "fontSizeSM",
  "24px" = "fontSizeMD",
  "32px" = "fontSizeLG",
  "40px" = "fontSizeXL",
  "48px" = "fontSizeXXL",
  "56px" = "fontSizeXXXL",
  "64px" = "fontSizeDisplay",
  "80px" = "fontSizeGiant",
  "96px" = "fontSizeHuge"
}

export enum lineHeightEnum {
  "100%" = "lineHeightDefault",
  "115%" = "lineHeightTiny",
  "120%" = "lineHeightSmall",
  "133%" = "lineHeightMedium",
  "150%" = "lineHeightLarge",
  "170%" = "lineHeightDistant"
}

export enum fontStyleEnum {
  "Default" = "fontStyleDefault",
  "Italic" = "fontStyleItalic"
}