import { fontEnum, fontSizeEnum, fontStyleEnum, fontWeightEnum, lineHeightEnum } from "enums/theme";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { TypographyStyled } from "./index.styled";

export type TypographyGr1dProps = {
    children: ReactNode;
    style?: CSSProperties,
    className?: string,
    classCustom?: string,
    color?: 'colorPrimitiveBlack' | 'colorPrimitive700' | 'colorPrimitive600' | 'colorPrimitive500' | 'colorPrimitive400' | 'colorPrimitive300' | 'colorPrimitive200' |
    'colorPrimitive100' | 'colorPrimitive50' | 'colorPrimitiveWhite' | 'colorFeedbackPositive700' | 'colorFeedbackPositive600' | 'colorFeedbackPositive500' |
    'colorFeedbackPositive400' | 'colorFeedbackPositive300' | 'colorFeedbackPositive200' | 'colorFeedbackPositive100' | 'colorFeedbackPositiv50' |
    'colorFeedbackNegative700' | 'colorFeedbackNegative600' | 'colorFeedbackNegative500' | 'colorFeedbackNegative400' | 'colorFeedbackNegative300' |
    'colorFeedbackNegative200' | 'colorFeedbackNegative100' | 'colorFeedbackNegative50' |
    'colorBrandPrimary700' | 'colorBrandPrimary600' | 'colorBrandPrimary500' | 'colorBrandPrimary400' | 'colorBrandPrimary300' | 'colorBrandPrimary200' |
    'colorBrandPrimary100' | 'colorBrandPrimary50',
    type?: 'Base' | 'Variant',
    component?: 'headingXXL' | 'headingXL' | 'headingLG' | 'headingMD' | 'headingSM' | 'headingXS' | 'subtitleLG' | 'subtitleMD' | 'subtitleSM' | 'paragraph' | 'bodyLG' | 'bodySM' |
    'caption' | 'overline' | 'action',
    font?: 'Manrope' | 'DMMono',
    fontWeight?: '800' | '700' | '600' | '500' | '400' | '300' | '200',
    fontSize?: '10px'| '12px' | '14px' | '16px' | '20px' | '24px' | '32px' | '40px' | '48px' | '56px' | '64px' | '80px' | '96px',
    lineHeight?: '100%' | '115%' | '120%' | '133%' | '150%' | '170%',
    fontStyle?: 'Default' | 'Italic',
    componentType?: 'div' | 'span' | 'h1' | 'h2' | 'p',
    onClick?: () => void,
}

const TypographyGr1d: React.FC<TypographyGr1dProps> = (props) => {
    const [font, setFont] = useState<string>();
    const [fontWeight, setFontWeight] = useState<string>();
    const [fontSize, setFontSize] = useState<string>();
    const [lineHeight, setLineHeight] = useState<string>();
    const [fontStyle, setFontStyle] = useState<string>();

    useEffect(() => {
        if (props.font != null) {
            setFont(fontEnum[props.font]);
        } else {
            setFont(fontEnum.Manrope);
        }
    }, [props.font])

    useEffect(() => {
        if (props.fontWeight != null) {
            setFontWeight((fontWeightEnum as any)[`W${props.fontWeight}`]);
        }
    }, [props.fontWeight])

    useEffect(() => {
        if (props.fontSize != null) {
            setFontSize(fontSizeEnum[props.fontSize]);
        }
    }, [props.fontSize])

    useEffect(() => {
        if (props.lineHeight != null) {
            setLineHeight(lineHeightEnum[props.lineHeight]);
        }
    }, [props.lineHeight])

    useEffect(() => {
        if (props.fontStyle != null) {
            setFontStyle(fontStyleEnum[props.fontStyle])
        }
    }, [props.fontStyle])

    let type = props.type != null ? props.type : 'Base';
    return <TypographyStyled onClick={props.onClick} componentType={props.componentType} className={props.classCustom + ' ' + ((props.className + ' ' ?? '')) + ((props.component && type) && props.component + type)}
        style={{ ...props.style }} color={props.color} font={font} fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight} fontStyle={fontStyle}>
        {props.children}
    </TypographyStyled>;
}

export default TypographyGr1d;