import styled from 'styled-components';
import { IGlobalStyle } from 'theme/global.styles';

const Form = (props: any) => {
    return <div className={props.className}>
        {props.children}
    </div>
}

export const FormAdminStyled = styled(Form)`
  .error-field {
      color: #E10700;
  }

  .ant-checkbox {
      top: 0;
  }

  .ant-transfer-list-checkbox > .ant-checkbox {
    position: relative;
    top: 3px;
  }

  .ant-form-item-control-input {
      min-height: 0;
  }

  .ant-transfer-list {
      flex: 1;
  }

  .ant-picker.input-form-suffix:hover input {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  }

  .ant-picker.input-form-suffix input {
    padding-right: 10px;
  }
  
  .input-form-suffix .ant-picker-suffix {
    color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
  }

  .input-form-suffix.ant-input-affix-wrapper {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
  }

  & .input-form-suffix.ant-picker-focused:hover {
    border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
  }

  .input-form-suffix.ant-input-affix-wrapper:hover input {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  }

  .input-form-suffix.input-form input {
      background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
  }

    .input-form input {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        border-radius: 4px;
        border: 2px solid #ffffff00;
    }

    .input-form-suffix.input-form {
        padding: 7px 16px;
    }

    .input-form-before input,
    .input-form {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        border-radius: 4px;
        padding: 10px 16px;
        border: 2px solid #ffffff00;
    }

    .select-form .ant-select-selector{
        height: 45px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        border-radius: 4px;
        border: 2px solid #ffffff00;
        padding: 0 16px;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

    .input-form input:hover,
    .input-form-before input:hover,
    .input-form:hover,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-input-affix-wrapper {
        border: 2px solid #ffffff00;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .ant-input-group-addon {
        background-color: initial;
        border: none;
    }

    & .input-form-before input::placeholder,
    & .input-form::placeholder,
    & .input-form input::placeholder,
    & .ant-select-selection-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .input-form-before input:-ms-input-placeholder,
    & .input-form:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .input-form-before input::-ms-input-placeholder,
    & .input-form::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    & .input-form-before input:hover::placeholder,
    & .input-form:hover::placeholder,
    & .input-form input:hover::placeholder,
    & .select-form:hover .ant-select-selection-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:hover:-ms-input-placeholder,
    & .input-form:hover:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:hover::-ms-input-placeholder,
    & .input-form:hover::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:focus::placeholder,
    & .input-form:focus::placeholder,
    & .ant-select-focused .ant-select-selection-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:focus:-ms-input-placeholder,
    & .input-form:focus:-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:focus::-ms-input-placeholder,
    & .input-form:focus::-ms-input-placeholder {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
    }

    & .input-form-before input:focus,
    & .input-form:focus,
    & .ant-input-affix-wrapper-focused.input-form,
    & .ant-input-number-focused,
    & .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
    & span.ant-select-focused > input,
    & .ant-picker-focused {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        box-sizing: border-box;
        box-shadow: none;
        border-right-width: 2px !important;
        transition: all 0s;
    }

    & .ant-input-number-focused {
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
    }

    .ant-select-multiple .ant-select-selection-item {
        border-radius: 10px;
        border: none;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
    }

    .element-before {
        display: flex;
        align-items: center;
        gap: 16px;

        > .ant-row {
            flex: 1;
        }
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-radio-inner::after {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .form-admin > div > label {
        width: 100%;

        > div {
            width: 100%;
        }
    }

    .ant-input-number-input {
        padding: 0;
    }

    .ant-input-number.input-form {
        padding: 5.5px 16px;
    }

    .ant-input-number.input-form:hover input {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }
`;
