import React, { ReactNode, useEffect, useState } from "react";
import TypographyGr1d from "components/portal/typography";
import { Drawer } from "antd";
import { DrawerAdminStyled } from "./index.styled";

type Props = {
    title: string,
    visible: boolean,
    footer?: ReactNode,
    setVisible: (check: boolean) => void,
    className?: string
}

const DrawerAdmin: React.FC<Props> = (props) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <Drawer destroyOnClose={true} className={`${props.className} drawer-admin`} visible={props.visible} onClose={() => props.setVisible(false)}
        title={<TypographyGr1d component='headingSM' color='colorPrimitiveBlack'>{props.title}</TypographyGr1d>}
        width={width < 760 ? '80%' : '748px'}
    >
        <DrawerAdminStyled>
            {props.children}
        </DrawerAdminStyled>
    </Drawer>
}

export default DrawerAdmin;