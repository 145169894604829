import Modal from "./index";
import React, { CSSProperties } from "react";
import ButtonGr1d from "../button";
import TypographyGr1d from "../typography";
import { PolicyChangedModalStyled } from "./policy.styled";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

type Props = {
    style?: CSSProperties,
    onAccept?: () => void
}

const PolicyChangedModal: React.FC<Props> = (props) => {
    const termosTypes: string = useSelector((state: any) => state.login?.verifyTermsTypes);

    return (
        <Modal width="394px" bottom={32} right={32} modalActions={
            (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <a href="/helpform">
                        <ButtonGr1d type="secondary">
                            Entrar em contato
                        </ButtonGr1d>
                    </a>
                    <ButtonGr1d type="primary" onClick={props.onAccept}>
                        Eu concordo
                    </ButtonGr1d>
                </div>
            )
        }>
            <PolicyChangedModalStyled style={props.style}>
                <div className="modalPolicyText">
                    <TypographyGr1d font="Manrope" fontWeight="500" fontSize="20px" lineHeight="150%">
                        { termosTypes.indexOf('termo') >= 0 && termosTypes.indexOf('politica') >= 0 &&
                            'Mudanças nos Termos de Uso e Política de Privacidade da GR1D'
                        }
                        { termosTypes.indexOf('termo') >= 0 && termosTypes.indexOf('politica') < 0 &&
                            'Mudanças nos Termos de Uso da GR1D'
                        }
                        { termosTypes.indexOf('politica') >= 0 && termosTypes.indexOf('termo') < 0 &&
                            'Mudanças na Política de Privacidade da GR1D'
                        }
                    </TypographyGr1d>
                    <TypographyGr1d style={{marginTop: '16px'}} font="Manrope" fontWeight="400" fontSize="14px">
                        { termosTypes.indexOf('termo') >= 0 && termosTypes.indexOf('politica') >= 0 &&
                            <>
                                <p>
                                Olá, estamos passando para informar que nós atualizamos os nossos <Link target='_blank' to='/terms'>Termos de uso</Link> e <Link target='_blank' to='/privacy'>Política de privacidade</Link> para esclarecer suas disposições e dar transparência aos nossos usuários.
                                </p>
                                <p>
                                Nós encorajamos e pedimos que leia com atenção nossos termos para continuar utilizando nossos serviços, qualquer dúvida você pode entrar em contato com o nosso time.
                                </p>
                            </>
                        }
                        
                        { termosTypes.indexOf('termo') >= 0 && termosTypes.indexOf('politica') < 0 &&
                            <>
                                <p>
                                Olá, estamos passando para informar que nós atualizamos os nossos <Link target='_blank' to='/terms'>Termos de uso</Link> para esclarecer suas disposições e dar transparência aos nossos usuários.
                                </p>
                                <p>
                                Nós encorajamos e pedimos que leia com atenção nossos termos para continuar utilizando nossos serviços, qualquer dúvida você pode entrar em contato com o nosso time.
                                </p>
                            </>
                        }

                        { termosTypes.indexOf('politica') >= 0 && termosTypes.indexOf('termo') < 0 &&
                            <>
                                <p>
                                Olá, estamos passando para informar que nós atualizamos a nossa <Link target='_blank' to='/privacy'>Política de privacidade</Link> para esclarecer suas disposições e dar transparência aos nossos usuários.
                                </p>
                                <p>
                                Nós encorajamos e pedimos que leia com atenção nossos termos para continuar utilizando nossos serviços, qualquer dúvida você pode entrar em contato com o nosso time.
                                </p>
                            </>
                        }
                    </TypographyGr1d>
                </div>
            </PolicyChangedModalStyled>
        </Modal>
    )
}

export default PolicyChangedModal