import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'theme/global.styles'
import useTheme from './useTheme';

export const Theme: React.FC = (props) => {
    const { loadTheme } = useTheme();

    return (
        <div>
            <ThemeProvider theme={loadTheme()}>
                <GlobalStyles />
                {props.children}
            </ThemeProvider>
        </div>
    );
}
