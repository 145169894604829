import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { pti18n } from "./index";

const resources = {
    pt: {
        translation: pti18n
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "pt",
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
