import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelpFormStyled } from "./index.styled";
import dots from "assets/svg/helpForm/dots.svg";
import FormGr1d from "components/admin/form";
import Icon from '@ant-design/icons';
import { ReactComponent as mail } from "assets/svg/logon/mail.svg";
import { useAction } from "store";
import { appActions } from "store/app/action";
import { match } from "react-router-dom";

type Props = {
    match: match,
}

const HelpSuccessForm: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const appAction = useAction(appActions());
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        dispatch(appAction.showQuestion(0));
        return () => {
            dispatch(appAction.showQuestion(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <HelpFormStyled className={grid} style={{ margin: grid !== 'sm' ? '48px 0' : '10px 0' }}>
        <FormGr1d padding={grid !== 'sm' ? '30px' : '0'}>
            <div className='div-1280 all-screen'>
                <div className='help-txt'>
                    {(props.match?.params as any).apiId == null ?
                        <div>
                            <TypographyGr1d style={{ marginBottom: 24 }} component='headingLG' color='colorPrimitiveBlack' >
                                <>Olá! Estamos ansiosos <br /> para te ajudar.</>
                            </TypographyGr1d>
                            <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive500' >
                                Você só precisa nos enviar uma mensagem!
                            </TypographyGr1d>
                        </div> :
                        <div>
                            <TypographyGr1d style={{ marginBottom: 24 }} component='headingLG' color='colorPrimitiveBlack' >
                                <>Olá! A assinatura desta API <br /> está disponível sob consulta.</>
                            </TypographyGr1d>
                            <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive500' >
                                Envie-nos uma mensagem e entraremos em contato!
                            </TypographyGr1d>
                        </div>
                    }
                    <div className='dots'>
                        <img width={200} alt='dots' src={dots} />
                    </div>
                </div>
                <div className='card-help'>
                    <div className='send-message'>
                        <Icon style={{ marginBottom: 32 }} className='mail' component={mail} />
                        <TypographyGr1d style={{ marginBottom: 8 }} color='colorPrimitiveBlack' component='headingXS'>
                            Mensagem enviada<br />com sucesso!
                        </TypographyGr1d>
                    </div>
                </div>
            </div>
        </FormGr1d>
    </HelpFormStyled>;
}

export default HelpSuccessForm;