import styled from "styled-components";
import { IGlobalStyle } from "theme/global.styles";

const Review: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export const ReviewStyled = styled(Review)`
  .cardTitleContainer {
    display: flex;
    align-items: center;
    .cardIconContainer {
      background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
      border-radius: 50%;
      margin-right: 16px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-card {
    margin-bottom: 15px;
  }
`;
