import { Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FormContextValues } from 'react-hook-form';
import { MultiSelectAdminStyled } from './index.styled';

type Props = {
    mode: 'multiple' | 'tags',
    data: { value: string, title: string }[],
    form: FormContextValues<any>,
    name: string,
    placeholder?: string
}

const { Option } = Select;

const MultiSelectAdmin: React.FC<Props> = (props) => {
    const onChange = (value: any[]) => {
        if (value.length > 0) {
            props.form.setValue(props.name, value);
            props.form.clearError(props.name);
        } else {
            props.form.setValue(props.name, undefined);
        }
    }

    return <MultiSelectAdminStyled>
        <Select className='select-form' placeholder={props.placeholder} onChange={onChange} mode={props.mode} style={{ width: '100%' }} 
        tokenSeparators={[',']} optionLabelProp="label">
            {_.map(props.data, x => <Option label={x.title} value={x.value} key={x.value}>{x.title}</Option>)}
        </Select>
    </MultiSelectAdminStyled>;
}

export default MultiSelectAdmin;
