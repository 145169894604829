import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const HelpForm = (props: any) =>
    <div className={props.className} style={props.style}>
        {props.children}
    </div>

export const HelpFormStyled = styled(HelpForm)`
    .pp-label-text {
        background-color: red;
    }

    .ant-upload {
        width: 100%;
    }

    &.sm .all-screen {
        flex-direction: column;
        position: relative;

        > div {
            width: 100%;
        }

        .dots {
            position: absolute;
            top: 50px;
        }

        .card-help {
            padding: 20px;
        }
    }

    .all-screen {
        display: flex;
        position: relative;

        > div {
            width: 50%;
        }

        .card-help {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            border-radius: 8px;
            box-sizing: border-box;
            padding: 48px 102px;
        }
    }

    .attach {
        position: absolute;
        display: flex;
        height: 100%;
        align-items: center;
        top: 0;
        width: 40%;
        justify-content: flex-end;
    }

    .help-txt {
        display: flex;
        flex-direction: column;

        > div {
            flex: 1;
        }
    }

    .dots {
        display: flex;
        align-items: flex-end;
    }

    .help-you {
        background: linear-gradient(180deg, transparent 65%, ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200} 35%);
        padding: 0px 2px;
        width: 100%;
    }

    .send-message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .mail {
        width: 190px;
        height: 190px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        border-radius: 100px;
        justify-content: center;
        margin-bottom: 32px;

        > svg {
            width: 60px;
            height: 48px;
        }
    }
`