import { notification } from "antd";
import i18n from "assets/i18n/i18next";
import { CEPModel } from "models/cep";
import { CompanyContactModel, CompanyModel, CreditCardModel, CreditCardPostModel } from "models/company";
import { MemberModel } from "models/member";
import { loginActions } from "store/login/action";
import { createLoginService } from "store/login/service";
import { auth } from "utils/auth";
import { createAccountService } from "../account/service";
import { createCompanyService } from "./service";

export enum PortalActionType {
    GET_CNPJ = '[COMPANY] Get CNPJ',
    GET_CNPJ_SUCCESS = '[COMPANY] Get CNPJ Success',
    GET_CNPJ_ERROR = '[COMPANY] Get CNPJ Error',
    GET_CEP = '[COMPANY] Get CEP',
    GET_CEP_SUCCESS = '[COMPANY] Get CEP Success',
    GET_CEP_ERROR = '[COMPANY] Get CEP Error',
    GET_COMPANY = '[COMPANY] Get Company',
    GET_COMPANY_SUCCESS = '[COMPANY] Get Company Success',
    GET_COMPANY_ERROR = '[COMPANY] Get Company Error',
    GET_CONTACTS = '[COMPANY] Get Contacts',
    GET_CONTACTS_SUCCESS = '[COMPANY] Get Contacts Success',
    GET_CONTACTS_ERROR = '[COMPANY] Get Contacts Error',
    POST_CONTACTS = '[COMPANY] Post Contacts',
    POST_CONTACTS_SUCCESS = '[COMPANY] Post Contacts Success',
    POST_CONTACTS_ERROR = '[COMPANY] Post Contacts Error',
    POST_COMPANY = '[COMPANY] Post Company',
    POST_COMPANY_SUCCESS = '[COMPANY] Post Company Success',
    POST_COMPANY_ERROR = '[COMPANY] Post Company Error',
    UPDATE_COMPANY = '[COMPANY] Update Company',
    UPDATE_COMPANY_SUCCESS = '[COMPANY] Update Company Success',
    UPDATE_COMPANY_ERROR = '[COMPANY] Update Company Error',
    UPDATE_CONTACTS = '[COMPANY] Update Contacts',
    UPDATE_CONTACTS_SUCCESS = '[COMPANY] Update Contacts Success',
    UPDATE_CONTACTS_ERROR = '[COMPANY] Update Contacts Error',
    POST_CREDIT_CARD = '[COMPANY] Post Credit Card',
    POST_CREDIT_CARD_SUCCESS = '[COMPANY] Post Credit Card Success',
    POST_CREDIT_CARD_ERROR = '[COMPANY] Post Credit Card Error',
    GET_CREDIT_CARD = '[COMPANY] Get Credit Card',
    GET_CREDIT_CARD_SUCCESS = '[COMPANY] Get Credit Success',
    GET_MEMBERS = '[ORG] Get Org Members',
    GET_MEMBERS_SUCCESS = '[ORG] Get Org Members Success',
    GET_MEMBERS_ERROR = '[ORG] Get Org Members Error',
    ADD_ORG_MEMBER = '[ORG] Add Org Member',
    ADD_ORG_MEMBER_SUCCESS = '[ORG] Add Org Member Success',
    ADD_ORG_MEMBER_ERROR = '[ORG] Add Org Member Error',
    UPDATE_ORG_MEMBER = '[ORG] Update Org Member',
    UPDATE_ORG_MEMBER_SUCCESS = '[ORG] Update Org Member Success',
    UPDATE_ORG_MEMBER_ERROR = '[ORG] Update Org Member Error',
    REMOVE_MEMBER = '[ORG] Remove Member',
    REMOVE_MEMBER_SUCCESS = '[ORG] Remove Member Success',
    REMOVE_MEMBER_ERROR = '[ORG] Remove Member Error',
    SET_PAGE_REDIRECT_AFTER_CREATE_COMPANY = '[ORG] Set Page To Redirect After Create Company',
    REQUEST_COMPANY_API_CONSUME = '[ORG] Request Company Api Consume',
    REQUEST_COMPANY_API_CONSUME_SUCCESS = '[ORG] Request Company Api Consume Success',
    REQUEST_COMPANY_API_CONSUME_ERROR = '[ORG] Request Company Api Consume Error'
}

export interface portalAction {
    getCnpjInfo(cnpj: string): void;
    getCepInfo(cep: string): void;
    getCompany(): void;
    getContacts(): void;
    saveContacts(contacts: CompanyContactModel[]): void;
    saveCompany(cnpj: CompanyModel, userCpf?: string, phone?: string): void;
    requestConsume(apiId: string, consume: number): void;
    postCreditCard(creditCard: CreditCardPostModel, orgId: string, recaptchaResponse: string): void;
    getCreditCard(orgId: string): void;
    getOrgMembers(orgId?: string): void;
    addOrgMember(orgId: string, name: string, memberEmail: string, memberCpf: string | undefined): void;
    updateOrgMember(orgId: string, memberId: string, isAdmin: boolean, memberCpf: string | undefined): void;
    removeOrgMember(orgId: string, userId: string): void;
    setPageToRedirectAfterCreateCompany(page: string | undefined): void;
}

export const companyActions = ((): portalAction => {
    const service = createCompanyService();
    const loginService = createLoginService();
    const loginAction = loginActions();
    const accountService = createAccountService();

    const actions = {
        getCnpj: (): any => {
            return { type: PortalActionType.GET_CNPJ }
        },
        getCnpjSuccess: (data: any): any => {
            return { type: PortalActionType.GET_CNPJ_SUCCESS, payload: data }
        },
        getCnpjError: (): any => {
            return { type: PortalActionType.GET_CNPJ_ERROR }
        },
        getCep: (): any => {
            return { type: PortalActionType.GET_CEP }
        },
        getCepSuccess: (data: CEPModel): any => {
            return { type: PortalActionType.GET_CEP_SUCCESS, payload: data }
        },
        getCepError: (): any => {
            return { type: PortalActionType.GET_CEP_ERROR }
        },
        getCompany: (): any => {
            return { type: PortalActionType.GET_COMPANY }
        },
        getCompanySuccess: (data: CompanyModel): any => {
            return { type: PortalActionType.GET_COMPANY_SUCCESS, payload: data }
        },
        getCompanyError: (): any => {
            return { type: PortalActionType.GET_COMPANY_ERROR }
        },
        getContacts: (): any => {
            return { type: PortalActionType.GET_CONTACTS }
        },
        getContactsSuccess: (data: CompanyContactModel[]): any => {
            return { type: PortalActionType.GET_CONTACTS_SUCCESS, payload: data }
        },
        getContactsError: (): any => {
            return { type: PortalActionType.GET_CONTACTS_ERROR }
        },
        postCompany: (): any => {
            return { type: PortalActionType.POST_COMPANY }
        },
        postCompanySuccess: (data: CompanyModel): any => {
            return { type: PortalActionType.POST_COMPANY_SUCCESS, payload: data }
        },
        postCompanyError: (): any => {
            return { type: PortalActionType.POST_COMPANY_ERROR }
        },
        postContacts: (): any => {
            return { type: PortalActionType.POST_CONTACTS }
        },
        postContactsSuccess: (data: CompanyContactModel[]): any => {
            return { type: PortalActionType.POST_CONTACTS_SUCCESS, payload: data }
        },
        postContactsError: (): any => {
            return { type: PortalActionType.POST_CONTACTS_ERROR }
        },
        updateCompany: (): any => {
            return { type: PortalActionType.UPDATE_COMPANY }
        },
        updateCompanySuccess: (data: CompanyModel): any => {
            return { type: PortalActionType.UPDATE_COMPANY_SUCCESS, payload: data }
        },
        updateCompanyError: (): any => {
            return { type: PortalActionType.UPDATE_COMPANY_ERROR }
        },
        updateContacts: (): any => {
            return { type: PortalActionType.UPDATE_CONTACTS }
        },
        updateContactsSuccess: (data: CompanyContactModel[]): any => {
            return { type: PortalActionType.UPDATE_CONTACTS_SUCCESS, payload: data }
        },
        updateContactsError: (): any => {
            return { type: PortalActionType.UPDATE_CONTACTS_ERROR }
        },
        postCreditCard: (): any => {
            return { type: PortalActionType.POST_CREDIT_CARD }
        },
        postCreditCardSuccess: (data: CreditCardModel): any => {
            return { type: PortalActionType.POST_CREDIT_CARD_SUCCESS, payload: data }
        },
        postCreditCardError: (): any => {
            return { type: PortalActionType.POST_CREDIT_CARD_ERROR }
        },
        getCreditCard: (): any => {
            return { type: PortalActionType.GET_CREDIT_CARD }
        },
        getCreditCardSuccess: (data: CreditCardModel): any => {
            return { type: PortalActionType.GET_CREDIT_CARD_SUCCESS, payload: data }
        },
        getMembers: (): any => {
            return { type: PortalActionType.GET_MEMBERS }
        },
        getMembersSuccess: (members: MemberModel[]): any => {
            return { type: PortalActionType.GET_MEMBERS_SUCCESS, payload: members }
        },
        getMembersError: (): any => {
            return { type: PortalActionType.GET_MEMBERS_ERROR }
        },
        addMember: (): any => {
            return { type: PortalActionType.ADD_ORG_MEMBER }
        },
        addMemberSuccess: (): any => {
            return { type: PortalActionType.ADD_ORG_MEMBER_SUCCESS }
        },
        addMemberError: (errorCode: number): any => {
            return { type: PortalActionType.ADD_ORG_MEMBER_ERROR, payload: errorCode }
        },
        updateOrgMember: (): any => {
            return { type: PortalActionType.UPDATE_ORG_MEMBER }
        },
        updateOrgMemberSuccess: (): any => {
            return { type: PortalActionType.UPDATE_ORG_MEMBER_SUCCESS }
        },
        updateOrgMemberError: (): any => {
            return { type: PortalActionType.UPDATE_ORG_MEMBER_ERROR }
        },
        removeOrgMember: (): any => {
            return { type: PortalActionType.REMOVE_MEMBER }
        },
        removeOrgMemberSuccess: (): any => {
            return { type: PortalActionType.REMOVE_MEMBER_SUCCESS }
        },
        removeOrgMemberError: (): any => {
            return { type: PortalActionType.REMOVE_MEMBER_ERROR }
        },
        setPageToRedirectAfterCreateCompany: (page: string | undefined): any => {
            return { type: PortalActionType.SET_PAGE_REDIRECT_AFTER_CREATE_COMPANY, payload: page }
        },
        requestConsume: () => {
            return { type: PortalActionType.REQUEST_COMPANY_API_CONSUME }
        },
        requestConsumeError: () => {
            return { type: PortalActionType.REQUEST_COMPANY_API_CONSUME_ERROR }
        },
        requestConsumeSuccess: () => {
            return { type: PortalActionType.REQUEST_COMPANY_API_CONSUME_SUCCESS }
        }
    }

    function getOrgMembers(orgId?: string) {
        return (dispatch: any) => {
            if (orgId != null) {
                dispatch(actions.getMembers())
                service.getOrgMembers(orgId).then((members) => {
                    dispatch(actions.getMembersSuccess(members))
                }).catch(e => {
                    dispatch(actions.getMembersError())
                })
            }
        }
    }

    function getCnpjInfo(cnpj: string) {
        return (dispath: any) => {
            dispath(actions.getCnpj());

            service.getCnpjInfo(cnpj).then(x => {
                if (x.situacao === 'ATIVA') {
                    dispath(actions.getCnpjSuccess(x));
                } else if (x.situacao === 'JÁ CADASTRADA') {
                    dispath(actions.getCnpjSuccess({ message: 'CNPJ Já Cadastrado' }));
                } else {
                    dispath(actions.getCnpjSuccess({ message: 'CNPJ inválido' }));
                }
            }).catch(x => {
                if (x.response.data.code === 1008)
                    notification.error({
                        message: i18n.t(`resource.error.1008.generic`),
                        placement: 'bottomLeft'
                    })
                dispath(actions.getCnpjError());
            })
        }
    }

    function getCepInfo(cep: string) {
        return (dispath: any) => {
            dispath(actions.getCep());

            service.getCepInfo(cep).then(x => {
                dispath(actions.getCepSuccess(x));
            }).catch(x => {
                dispath(actions.getCepError());
            })
        }
    }

    function getCompany() {
        return (dispath: any, getState: any) => {
            dispath(actions.getCompany());
            let state = getState();
            if (state.login?.user != null) {
                if (state.login.user.orgId != null) {
                    service.getCompany(state.login.user.orgId).then((company: CompanyModel) => {
                        dispath(actions.getCompanySuccess(company))
                    }).catch(x => {
                        dispath(actions.getCompanyError());
                    })
                } else {
                    dispath(actions.getCompanyError());
                }
            } else {
                setTimeout(() => {
                    dispath(getCompany());
                }, 1000)
            }
        }
    }

    function getContacts() {
        return (dispath: any, getState: any) => {
            dispath(actions.getContacts());
            let state = getState();
            if (state.login?.user != null) {
                if (state.login.user.orgId != null) {
                    service.getContacts(state.login.user.orgId).then((contacts) => {
                        dispath(actions.getContactsSuccess(contacts))
                    }).catch(x => {
                        dispath(actions.getContactsError());
                    })
                }
            } else {
                setTimeout(() => {
                    dispath(getContacts());
                }, 1000)
            }
        }
    }

    function saveCompany(company: CompanyModel, userCpf?: string, telephone?: string) {
        return async (dispath: any) => {
            if (userCpf !== undefined) {
                await accountService.updateAccount({
                    cpf: userCpf,
                    firstName: undefined,
                    lastName: undefined,
                    telephone: telephone
                });
            }
            if (company.id == null) {
                dispath(actions.postCompany());

                service.postCompany(company).then(x => {
                    if (!x) { // também não entendo o por que o erro 400 do axios cai no then (p/ empresa já existente)
                        dispath(actions.postCompanyError());
                        return;
                    }
                    dispath(actions.postCompanySuccess(x));
                    const authUser = auth.getAuth();
                    let date = new Date();
                    date.setSeconds(authUser.refresh_expires_in);
                    dispath(loginAction.refreshToken(authUser.refresh_token, date));
                    notification.success({
                        message: 'Cadastro salvo com sucesso!'
                    })
                }).catch(x => {
                    dispath(actions.postCompanyError());
                })
            } else {
                dispath(actions.updateCompany());

                service.updateCompany(company).then(x => {
                    dispath(actions.updateCompanySuccess(x));
                    notification.success({
                        message: 'Alterações salvas com sucesso!'
                    })
                }).catch(x => {
                    dispath(actions.updateCompanyError());
                })
            }
        }
    }

    function saveContacts(contacts: CompanyContactModel[]) {
        return async (dispath: any, getState: any) => {
            dispath(actions.postContacts());
            service.postContact(contacts).then(x => {
                dispath(actions.postContactsSuccess(x));
                notification.success({
                    message: 'Cadastro de contatos salvo com sucesso!'
                })
            }).catch(() => {
                dispath(actions.postContactsError());
            })
        }
    }

    function postCreditCard(creditCard: CreditCardPostModel, orgId: string, recaptchaResponse: string) {
        return (dispatch: any) => {
            dispatch(actions.postCreditCard());
            service.updateCreditCard(creditCard, orgId, recaptchaResponse).then((card: any) => {
                dispatch(actions.postCreditCardSuccess(card));
                notification.success({
                    message: 'Cartão salvo com sucesso!',
                    placement: 'bottomLeft'
                })
            }).catch(e => {
                notification.error({
                    message: 'Cartão de crédito inválido',
                    placement: 'bottomLeft'
                })
                dispatch(actions.postCreditCardError())
            })
        }
    }

    function getCreditCard(orgId: string) {
        return (dispath: any) => {
            service.getCreditCard(orgId).then((creditCard: CreditCardModel[]) => {
                dispath(actions.getCreditCardSuccess(creditCard[0]))
            })
        }
    }

    function addOrgMember(orgId: string, name: string, memberEmail: string, memberCpf: string | undefined) {
        return async (dispatch: any) => {
            try {
                dispatch(actions.addMember())
                const newCpf = memberCpf ? memberCpf.replaceAll('.', '').replaceAll('-', '') : undefined;
                await registerUserIfItDoesntExists(name, memberEmail, newCpf);
                await service.addOrgMember(orgId, memberEmail, newCpf);
                dispatch(actions.addMemberSuccess())
                notification['success']({
                    message: 'Novo membro adicionado com sucesso',
                    placement: 'bottomLeft'
                });
            } catch (e: any) {
                dispatch(actions.addMemberError(e.response.data.code))
            }
        }
    }

    function updateOrgMember(orgId: string, memberId: string, isAdmin: boolean, memberCpf: string | undefined) {
        return (dispatch: any) => {
            dispatch(actions.updateOrgMember())
            service.updateOrgMember(orgId, memberId, isAdmin, memberCpf).then(() => {
                dispatch(actions.updateOrgMemberSuccess())
                notification.success({
                    message: 'Permissões de membro atualizadas',
                    placement: 'bottomLeft'
                })
            }).catch(e => {
                dispatch(actions.updateOrgMemberError())
            })
        }
    }

    async function registerUserIfItDoesntExists(name: string, userEmail: string, cpf?: string) {
        try {
            await loginService.addMember({
                email: userEmail,
                firstName: name,
                cpf,
                role: "marketplace_admin",
            })
        } catch (e: any) {
            const USER_ALREADY_REGISTERED_CODE = 2002
            if (e.response.data.code !== USER_ALREADY_REGISTERED_CODE) {
                throw e;
            }
        }
    }

    function removeOrgMember(orgId: string, userId: string) {
        return async (dispatch: any) => {
            try {
                dispatch(actions.removeOrgMember())
                await service.removeOrgMember(orgId, userId)
                dispatch(actions.removeOrgMemberSuccess())
                notification.success({
                    message: 'Usuário removido',
                    placement: 'bottomLeft'
                })
            } catch (e) {
                dispatch(actions.removeOrgMemberError())
            }
        }
    }

    function setPageToRedirectAfterCreateCompany(page: string | undefined) {
        return async (dispatch: any) => {
            dispatch(actions.setPageToRedirectAfterCreateCompany(page))
        }
    }

    function requestConsume(apiId: string, consume: number) {
        return async (dispatch: any) => {
            dispatch(actions.requestConsume())
            service.requestApiConsume(apiId, consume).then(() => {
                dispatch(actions.requestConsumeSuccess())
            }).catch(() => {
                dispatch(actions.requestConsumeError())
            })
        }
    }

    return { getCnpjInfo, getCepInfo, saveCompany, postCreditCard, getCreditCard, getOrgMembers, addOrgMember, updateOrgMember, removeOrgMember, getCompany, getContacts, saveContacts, setPageToRedirectAfterCreateCompany, requestConsume }
})