import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const confirmUserCompanyData: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export const ConfirmUserCompanyDataStyled = styled(confirmUserCompanyData)`
  .alertDataChange {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    margin-top: 13px;
  }
  .dataSection {
    margin-top: 20px;
    .dataRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      > div:nth-child(2) {
        width: 60%;
      }
    }
  }
`