import { Tabs } from "antd";
import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { PolicyStyled } from './index.styled'
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "store/app/action";
import { useHistory } from "react-router-dom";
import { portalActions } from "store/portal/action";
import { useAction } from "store";
import { PoliciesState } from "store/portal/reducer";
import moment from "moment";

moment.updateLocale('pt', {
    months : [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
});

const { TabPane } = Tabs;

type Props = {
    pageTab: 'terms' | 'privacy' | 'cookies'
}

const pageKeys = {
    'terms': '1',
    'privacy': '2',
    'cookies': '3'
}

const Terms: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const actions = appActions();
    const actionsPortal = useAction(portalActions());
    const policiesState: PoliciesState = useSelector((state: any) => state.portal?.policies);
    const history = useHistory();
    const [tabValue] = useState(pageKeys[props.pageTab]);
    const [textChangeTimestamp, setTextChangeTimestamp] = useState('')
    const [htmlText, setHtmlText] = useState('');
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {

        dispatch(actions.showQuestion(0));
        return () => {
            dispatch(actions.showQuestion(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function changeTab(tabKey: '1' | '2' | '3') {
        history.push({
            '1': 'terms',
            '2': 'privacy',
            '3': 'cookies'
        }[tabKey])
    }

    useEffect(() => {
        dispatch(actionsPortal.getPolicies());
    }, [actionsPortal, dispatch])

    useEffect(() => {
        if (policiesState.loading || policiesState.policies === undefined) {
            return
        }

        switch(tabValue) {
            case '1':
                const termoPolicy = (policiesState.policies?.reverse().find((policy) => policy.type === 'termo'))
                if(termoPolicy !== undefined) {
                    setHtmlText(termoPolicy.text)
                    setTextChangeTimestamp(termoPolicy.creation)
                }
                break;
            case '2':
                const politicaPolicy = (policiesState.policies?.reverse().find((policy) => policy.type === 'politica'))
                if(politicaPolicy !== undefined) {
                    setHtmlText(politicaPolicy.text)
                    setTextChangeTimestamp(politicaPolicy.creation)
                }
                break;
            case '3':
                const cookiesPolicy = (policiesState.policies?.reverse().find((policy) => policy.type === 'cookies'))
                if(cookiesPolicy !== undefined) {
                    setHtmlText(cookiesPolicy.text)
                    setTextChangeTimestamp(cookiesPolicy.creation)
                }
                break;
        }
    }, [policiesState, tabValue])
    
    return (
        <PolicyStyled>
            <div className='policy-header'>
                <div className='content'>
                    <div className={`title`}>
                        <TypographyGr1d color='colorPrimitiveWhite' component={grid !== 'sm' ? 'headingXXL' : 'headingLG'} >
                            {tabValue === '1' && 'Termos de uso'}
                            {tabValue === '2' && 'Política de privacidade'}
                            {tabValue === '3' && 'Cookies'}
                        </TypographyGr1d>
                        <br />
                        <TypographyGr1d fontSize="14px" color='colorPrimitive200' >
                            Atualizado pela última vez em  {textChangeTimestamp !== '' && moment(textChangeTimestamp).format('DD [de] MMMM [de] YYYY')}
                        </TypographyGr1d>
                    </div>
                    <div className='options'>
                        <div className='tab-options'>
                            <TypographyGr1d font="Manrope" component='bodyLG' color='colorPrimitiveWhite'>
                                <Tabs defaultActiveKey={pageKeys[props.pageTab]} onChange={(v) => changeTab(v as any)} color="#FFF">
                                    <TabPane tab="Termos de uso" key="1" />
                                    <TabPane tab="Política de privacidade" key="2" />
                                    <TabPane tab="Política de Cookies" key="3" />
                                </Tabs>
                            </TypographyGr1d>
                        </div>
                    </div>
                </div>
            </div>
            <div className='policy-content'>
                <TypographyGr1d font="Manrope" color='colorPrimitiveBlack'>
                    <span dangerouslySetInnerHTML={{__html: htmlText}}>
                    
                    </span>
                </TypographyGr1d>
            </div>
        </PolicyStyled>
    )
}

export default Terms;