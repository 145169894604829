import { AccountActionType } from "./action";

export interface AccountState {
    updatingAccount: boolean;
    updatingAccountError: boolean;
}

export const INITIAL_STATE = {
    updatingAccount: false,
    updatingAccountError: false
}
export function accountReducer(state: AccountState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AccountActionType.UPDATE_ACCOUNT:
            state = { ...state, updatingAccount: true, updatingAccountError: false }
            break;
        case AccountActionType.UPDATE_ACCOUNT_SUCCESS:
            state = { ...state, updatingAccount: false}
            break;
        case AccountActionType.UPDATE_ACCOUNT_ERROR:
            state = { ...state, updatingAccount: false, updatingAccountError: true }
            break;
    }

    return state;
}