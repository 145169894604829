import { Pagination } from "antd";
import React, { CSSProperties, useState } from "react";
import { PaginationStyled } from "./index.styled";

export type PaginationGr1dProps = {
    style?: CSSProperties,
    total?: number,
    position?: 'left' | 'right',
    onChange?: (event: any) => void,
    page?: number,
    pageSize: number
}

const PaginationGr1d: React.FC<PaginationGr1dProps> = (props) => {
    const position = props.position ?? 'right';
    const [page, setPage] = useState(props.page ?? 1);

    const onChange = (event: any) => {
        setPage(event);
        if (props.onChange)
            props.onChange(event);
    }

    return <PaginationStyled className={position} style={props.style}>
        <Pagination current={page} onChange={onChange} showSizeChanger={false} pageSize={props.pageSize} total={props.total}>

        </Pagination>
    </PaginationStyled>;
}

export default PaginationGr1d;