import { ApiCardStyled } from './savedApi.styled'
import noImage from "assets/svg/no-image.svg"
import TypographyGr1d from 'components/portal/typography'
import CategoriesTag from 'components/portal/categoriesTag'
import SaveApiFlag from 'components/portal/saveApiFlag'
import { ApiSavedModel } from 'models/savedApi';

type props = {
    api: ApiSavedModel
}

const SavedApiCard: React.FC<props> = (props) => {
    function handleLinkClick(e: any) {
        if (e.nativeEvent.path.some((el: any) => el.classList && el.classList.contains('modalWrapper'))) {
            e.preventDefault()
            e.stopPropagation()
            return false
        }
    }

    return (
        <ApiCardStyled>
            <a href={`/api/${props.api.apiId.id}`} onClick={handleLinkClick}>
                <div className='apiCard'>
                    <div className="apiCardContent">
                    <div className='apiCardHeader'>
                    <img onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} alt={props.api.apiId.name} src={`/core/api/v1/apis/${props.api.apiId.id}/image`} />
                        <div className='apiCardTitle'>
                            <div>
                                <TypographyGr1d className='oneLine' font="Manrope" fontSize="20px" fontWeight="700" color="colorPrimitiveBlack">{ props.api.apiId.name }</TypographyGr1d>
                                <TypographyGr1d className='oneLine' font="Manrope" fontWeight="500" color="colorPrimitive400" style={{fontSize: '15px'}}>por { props.api.apiId.provider }</TypographyGr1d>
                            </div>
                        </div>
                    </div>
                    
                        <div className='apiCardDescription'>
                            <TypographyGr1d fontSize="14px" fontWeight="400" font="Manrope" color="colorPrimitive600">
                                { props.api.apiId.description }
                            </TypographyGr1d>
                        </div>
                    </div>
                    <div className='apiCardFooter'>
                        <CategoriesTag categories={props.api.apiId.categories.map(({id, name}) => ({id, name}))} />
                        <SaveApiFlag apiId={props.api.apiId.id as unknown as number} />
                    </div>
                </div>
            </a>
        </ApiCardStyled>
    )
}

export default SavedApiCard