import { ReactNode } from "react"
import { RowStyled } from "./row.styled"

type RowGr1dProps = {
    children?: ReactNode,
    gap?: number
}

const RowGr1d: React.FC<RowGr1dProps> = (props) => {
    return <RowStyled style={{ gap: props.gap, flexWrap: props.gap != null ? 'row' : 'row-wrap' }}>
        {props.children}
    </RowStyled>
}

export default RowGr1d;