import { ApiModel } from "models/api";
import { CategoryModel } from "models/category";
import { MemberModel } from "models/member";
import { AdminApiActionType } from "./action";

export interface AdminApiState {
    list: ApiModel[];
    total: number;
    loading: boolean;
    categoryList: CategoryModel[];
    categoryLoading: boolean;
    memberList: MemberModel[];
    memberLoading: boolean;
    item?: ApiModel;
    itemLoading: boolean;
    saveLoading: boolean;
}

export const INITIAL_STATE = {
    loading: false, list: [], total: 0, itemLoading: false, saveLoading: false, categoryList: [], categoryLoading: false,
    memberList: [], memberLoading: false
}
export function adminApiReducer(state: AdminApiState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AdminApiActionType.GET_APIS:
            state = { ...state, loading: true, total: 0 }
            break;
        case AdminApiActionType.GET_APIS_SUCCESS:
            state = { ...state, loading: false, list: action.payload.list, total: action.payload.total }
            break;
        case AdminApiActionType.GET_APIS_ERROR:
            state = { ...state, loading: false }
            break;
        case AdminApiActionType.POST_REGISTER_API:
            state = { ...state, saveLoading: true, item: undefined }
            break;
        case AdminApiActionType.POST_REGISTER_API_SUCCESS:
            state = { ...state, saveLoading: false, item: action.payload, list: [...state.list, action.payload], total: state.total + 1 }
            break;
        case AdminApiActionType.POST_REGISTER_API_ERROR:
            state = { ...state, saveLoading: false }
            break;
        case AdminApiActionType.GET_API:
            state = { ...state, itemLoading: true, item: undefined }
            break;
        case AdminApiActionType.GET_API_SUCCESS:
            state = { ...state, itemLoading: false, item: action.payload }
            break;
        case AdminApiActionType.GET_API_ERROR:
            state = { ...state, itemLoading: false }
            break;
        case AdminApiActionType.GET_CATEGORIES:
            state = { ...state, categoryLoading: true }
            break;
        case AdminApiActionType.GET_CATEGORIES_SUCCESS:
            state = { ...state, categoryLoading: false, categoryList: action.payload }
            break;
        case AdminApiActionType.GET_CATEGORIES_ERROR:
            state = { ...state, categoryLoading: false }
            break;
        case AdminApiActionType.GET_MEMBERS:
            state = { ...state, memberLoading: true }
            break;
        case AdminApiActionType.GET_MEMBERS_SUCCESS:
            state = { ...state, memberLoading: false, memberList: action.payload }
            break;
        case AdminApiActionType.GET_MEMBERS_ERROR:
            state = { ...state, memberLoading: false }
            break;
        case AdminApiActionType.CLEAR_API:
            state = { ...state, itemLoading: false, item: undefined }
            break;
    }

    return state;
}