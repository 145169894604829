import { createGlobalStyle } from 'styled-components';

export interface IGlobalStyle {
    //Global Color
    colorPrimitiveBlack: string;
    colorPrimitive700: string;
    colorPrimitive600: string;
    colorPrimitive500: string;
    colorPrimitive400: string;
    colorPrimitive300: string;
    colorPrimitive200: string;
    colorPrimitive100: string;
    colorPrimitive50: string;
    colorPrimitiveWhite: string;

    colorFeedbackPositive700: string;
    colorFeedbackPositive600: string;
    colorFeedbackPositive500: string;
    colorFeedbackPositive400: string;
    colorFeedbackPositive300: string;
    colorFeedbackPositive200: string;
    colorFeedbackPositive100: string;
    colorFeedbackPositive50: string;

    colorFeedbackNegative700: string;
    colorFeedbackNegative600: string;
    colorFeedbackNegative500: string;
    colorFeedbackNegative400: string;
    colorFeedbackNegative300: string;
    colorFeedbackNegative200: string;
    colorFeedbackNegative100: string;
    colorFeedbackNegative50: string;

    //Whitelabel Color
    colorBrandPrimary700: string;
    colorBrandPrimary600: string;
    colorBrandPrimary500: string;
    colorBrandPrimary400: string;
    colorBrandPrimary300: string;
    colorBrandPrimary200: string;
    colorBrandPrimary100: string;
    colorBrandPrimary50: string;

    //Typography
    fontFamilyBase: string;
    fontFamilyVariant: string;

    fontWeightExtraBold: string;
    fontWeightBold: string;
    fontWeightSemiBold: string;
    fontWeightMedium: string;
    fontWeightRegular: string;
    fontWeightLight: string;
    fontWeightExtraLight: string;

    fontSizeTiny: string;
    fontSizeXXXS: string;
    fontSizeXXS: string;
    fontSizeXS: string;
    fontSizeSM: string;
    fontSizeMD: string;
    fontSizeLG: string;
    fontSizeXL: string;
    fontSizeXXL: string;
    fontSizeXXXL: string;
    fontSizeDisplay: string;
    fontSizeGiant: string;
    fontSizeHuge: string;

    lineHeightDefault: string;
    lineHeightTiny: string;
    lineHeightSmall: string;
    lineHeightMedium: string;
    lineHeightLarge: string;
    lineHeightDistant: string;

    letterSpacingDefault: string;
    letterSpacingSmall: string;
    letterSpacingLarge: string;

    fontStyleDefault: string;
    fontStyleItalic: string;

    //Border Styles
    borderRadiusNone: string;
    borderRadiusXS: string;
    borderRadiusSM: string;
    borderRadiusMD: string;
    borderRadiusLG: string;
    borderRadiusPill: string;
    borderRadiusCircle: string;

    borderWidthNone: string;
    borderWidthHairline: string;
    borderWidthThin: string;
    borderWidthThick: string;
    borderWidthHeavy: string;

    //Effect Styles
    opacityLevelSemiTransparent: string;
    opacityLevelLight: string;
    opacityLevelMedium: string;
    opacityLevelIntense: string;
    opacityLevelSemiOpaque: string;

    shadowLevel01: string;
    shadowLevel02: string;
    shadowLevel03: string;
    shadowLevel04: string;
    shadowLevel05: string;

    //Spacing
    spacingInsetQuark: string;
    spacingInsetNano: string;
    spacingInsetXXXS: string;
    spacingInsetXXS: string;
    spacingInsetXS: string;
    spacingInsetSM: string;
    spacingInsetMD: string;
    spacingInsetLG: string;
    spacingInsetXL: string;

    spacingSquishQuark: string;
    spacingSquishNano: string;
    spacingSquishXXXS: string;
    spacingSquishXXS: string;
    spacingSquishXS: string;
    spacingSquishSM: string;
}

export const GlobalStyles = createGlobalStyle`
    .header-btn-drawer {
        button {
            margin-left: 15px;
            margin-top: 15px;
        }

        div {
            margin-left: 15px;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
            cursor: pointer;
            margin-top: 15px;
        }

        div:hover {
            border-bottom: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }
    }

    .ant-tag {
        border-radius: 24px;
        padding: 4px 8px;
    }

    .ant-btn-primary {
        background: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-btn.ant-btn-primary:hover {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        background: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
    }

    .ant-btn:hover, .ant-btn:focus {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }
        
    .input-form-suffix.ant-input-affix-wrapper-focused:hover {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
    }

    .input-form-suffix.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: #ffffff00;
    }

    .ant-card {
        border-radius: 5px;
    }

    .ant-btn {
        border-radius: 5px;
    }

    a {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
    }

    .actions-drawer {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }

    .headingXXLBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeDisplay};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingXLBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXL};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingLGBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXL};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingMDBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeLG};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingSMBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightSemiBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingXSBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeMD};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingXXSBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightSemiBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeMD};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .subtitleLGBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightSemiBold};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeLG};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .subtitleMDBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .subtitleSMBase {
        /* font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightSemiBold}; */
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .paragraphBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightExtraLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .bodyLGBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .bodySMBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .captionBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .overlineBase {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightExtraLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingLarge};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .actionBase {
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .headingXXLVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeDisplay};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .headingXLVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXL};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .headingLGVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXL};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .headingMDVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeLG};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .headingSMVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .headingXSVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeMD};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightSmall};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .subtitleLGVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeLG};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .subtitleMDVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .subtitleSMVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingDefault};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .saragraphVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeSM};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .bodyLGVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .bodySMVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .captionVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightMedium};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .overlineVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightLight};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightLarge};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingLarge};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .actionVariant {
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXS};
        line-height: ${(props) => (props.theme as IGlobalStyle).lineHeightDefault};
        letter-spacing: ${(props) => (props.theme as IGlobalStyle).letterSpacingSmall};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyVariant};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
    }

    .ant-checkbox-checked::after {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
    }

    .card-tag {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
    }

    .tag-rest-get {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
    }

    .tag-rest-post {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive50};
        color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive600};
    }

    .tag-rest-delete {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative100};
        color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;


        > div {
            width: 100%;
            max-width: 400px;
        }
    }

    & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        height: 70px;
        padding: 20px 20px 20px 44px;
        display: flex;
        align-items: center;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    & .ant-collapse {
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
    }

    & .ant-collapse > .ant-collapse-item {
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
    }

    & .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        top: initial;
        padding: initial;
    }

    & .ant-collapse-content-box {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
    }

    & .ant-tabs-tab:hover {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    }

    & .ant-tabs-ink-bar {
        background: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }
    
    & .ant-tabs-top > .ant-tabs-nav {
        margin: 0px;
    }

    & .ant-tabs-tab.ant-tabs-tab-active {
        padding: 16px;
    }

    & .ant-tabs-tab {
        padding: 16px;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }

    .div-1280 {
        margin: auto;
        max-width: 1280px;
        padding: 0 20px;
    }

    & .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: none;
    }

    & .anticon {
        display: flex !important;
        align-items: center;
    }

    & .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    & .ant-pagination-item-container {
        height: 100%;

        > span {
            height: 100%;
            width: 100%;
        }

        .anticon.anticon-double-right.ant-pagination-item-link-icon,
        .anticon.anticon-double-left.ant-pagination-item-link-icon {
            display: flex !important;
            justify-content: center;
        }
    }

    & .fullscreen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 999999999;
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50}
    }

    & .logout-link {
        cursor: pointer;
        display: flex;
        color: #0000EE;
    }

    & .link {
        cursor: pointer;
        color: #0000EE;
        text-decoration: underline;
        display: flex;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-menu-item:active {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    }

    .ant-menu-title-content {
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXS};
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
    }

    .ant-menu-item-active .ant-menu-title-content {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-menu-item-selected .ant-menu-title-content {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, 
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
    .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-menu-item:hover, .ant-menu-item-active {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-menu-vertical .ant-menu-item::after, 
    .ant-menu-vertical-left .ant-menu-item::after, 
    .ant-menu-vertical-right .ant-menu-item::after, 
    .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    & .ant-menu-submenu-selected .ant-menu-submenu-expand-icon, .ant-menu-submenu-selected  .ant-menu-submenu-arrow {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive400};
    }

    & .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive400};
    }

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive400};
    }

    .payable.drawer-admin {
        .ant-drawer-header {
            display: flex;
            border-bottom: none;
            padding: 48px 32px 32px 32px;

            > div {
                flex: 1;
            }
        }
    }

    .drawer-admin {

        .ant-drawer-header {
            display: flex;
            border-bottom: none;
            padding: 32px 32px 48px 32px;

            > div {
                flex: 1;
            }
        }

        .ant-drawer-body {
            padding: 0px 32px;
        }

        .ant-drawer-footer {
            padding: 0 32px 48px 32px;
            border-top: none;
        }
        
        .ant-drawer-close {
            position: relative;
            padding: 0;
        }
    }

    .layout-admin {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};

        .ant-menu-item {
            display: flex;
        }
    }

    .drawer-content .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        padding-top: 10px;
        padding-left: 4px;
    }

    .action-send {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        cursor: pointer;
    }

    .hr {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        height: 1px;
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .user-menu {
        > div {
            padding: 4px 16px;
            cursor: pointer;
        }
    }

    .admin-menu .ant-popover-inner-content {
        padding: 8px 0;
    }

    .hr-menu {
        padding: 0 !important;
        margin: 7.5px 0;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        height: 1px;
    }

    .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-modal-footer {
        padding: 16px 24px;
        border-radius: 0 0 8px 8px;
    }

    .ant-modal-header {
        padding: 16px 24px 0px 24px;
        border-bottom: none;
        border-radius: 8px 8px 0 0;
    }

    .ant-modal-body {
        padding: 0px 24px 16px 24px;
    }

    .title-toast {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXS};
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightSemiBold};
    }
    .dropdownContentProviderSelect {
        width: 200px;
        height: 200px;
        // background-color: #CCC;
        border-radius: 2px;
        width: 100%;
        padding: 16px;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        display: flex;
        .contentCollumn {
          display: flex;
          flex-direction: column;
          .options {
            display: flex;
            padding-top: 10px;
          }
        }
        justify-content: space-between;
      }
      .dropdownContentAPIStatusSelect {
        ul {
            padding: 0;
            margin: 0;
            li {
              list-style-type: none; 
              padding: 10px 16px;
              margin: 0;
            }
            cursor: pointer;
        }
        .active {
            background-color: #F4F0FF
        }
    }

    .category-tag.pointer {
        
        .ant-select-selection-item-remove {
            display: none;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
        }

        .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: initial;
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            border-radius: 24px;
        }
        
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            cursor: pointer;
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            cursor: pointer;
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            cursor: pointer;
        }
    }

    .category-tag {
        
        .ant-select-selection-item-remove {
            display: none;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
        }

        .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: initial;
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            border-radius: 24px;
        }
        
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            cursor: initial;
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            cursor: initial;
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
            cursor: initial;
        }
    }

    .logo-user {
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        border-radius: 40px;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-notification-notice-message {
        white-space: pre-line;
    }

    .oneLine {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .twoLine {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .select-no-select {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
            border-radius: 0;
        }

        .ant-select-selection-item {
            padding-right: 0;
        }

        .ant-select-arrow {
            display: none;
        }

        .ant-select-item-option {
            align-items: center;
        }
    }

    .select-no-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #ffffff;
        box-shadow: none;
    }

    .select-no-select.ant-select-single.ant-select-open .ant-select-selection-item {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
    }

    .select-no-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
    }

    .gr1d-date {
        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border: none;
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            text-decoration: underline;
            text-decoration-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            border-radius: 4px;
        }

        .ant-picker-header-view button:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }
    }

    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .welcome-modal {
        .ant-modal-content {
            border-radius: 9px;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};

            .ant-modal-close-x {
                height: 80px;
                width: 80px;
            }

            .modal-body {
                padding: 28px 34px;
            }

            img {
                border-top-left-radius: 9px;
                border-top-right-radius: 11px;
            }

            .ant-carousel .slick-dots-bottom {
                bottom: -42px;
            }

            .modal-body {
                a {
                    color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
                }
            }
        }
    }

    .slick-dots-bottom li button {
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitive400} !important;
    }

    .slick-dots-bottom .slick-active button {
        background: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
    }

    .ant-tooltip-inner {
        background: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700} !important;
    }

    .modal-prepaid {
        .ant-modal-header {
            padding: 28px 24px 0 24px;
        }
        .ant-modal-close {
            padding-top: 11px;
        }
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .modal-api {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .info-prepaid-tooltip {
        .ant-tooltip-arrow {
            left: 3px;
        }
        .ant-tooltip-inner {
            width: 310px;
        }
    }

    .error-flex {
        display: flex;
        gap: 6px;
        align-items: center;
        svg {
        fill: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative400};
        }
    }
`;
