import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import { UserModel } from "models/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { formatText, isValidCpf, isValidPhoneNumber } from "utils/utils";
import { PropsPrepaid } from ".";

type Props = {
  user: UserModel,
  userObj: any,
  setUserObj: React.Dispatch<React.SetStateAction<any>>,
}

const PrepaidUser: React.FC<Props & PropsPrepaid> = (props) => {
  const history = useHistory();
  const { handleSubmit } = props.form;
  const [phone, setPhone] = useState<string>('');
  const [lastPhone, setLastPhone] = useState<string>('');
  const [first, setFirst] = useState<boolean>(true);
  const [mask, setMask] = useState<string>(props.userObj?.phone == null ? '(11) 11111-1111' :
    props.userObj?.phone?.length !== 15 ? '(11) 1111-1111' : '(11) 11111-1111');
  const user: UserModel = useSelector((state: any) => state.login?.user);

  useEffect(() => {
    setTimeout(() => {
      props.form.setValue('cpf', props.userObj.cpf);
      props.form.setValue('phone', props.userObj.phone);
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!first) {
      if (lastPhone.length > phone.length) {
        setMask('(11) 1111-1111');
      } else if (phone.length < 10) {
        setMask('(11) 1111-1111');
      } else {
        setMask('(11) 11111-1111')
      }
    } else {
      setFirst(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, lastPhone])

  useEffect(() => {
    props.setNextStep('Próximo: Dados da empresa');
    props.setTitle('Completar cadastro');
    props.setSubTitle('Antes de prosseguir para a contratação da API, é preciso completar o seu cadastro de usuário.');
  }, [props])

  const formConfigData: ConfigFormItem[] = [
    {
      name: 'cpf',
      typeComponent: 'masked-input',
      mask: "111.111.111-11",
      label: 'CPF',
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidCpf(value);
        }
      },
    },
    {
      name: 'phone',
      typeComponent: 'masked-input',
      label: 'Número de celular',
      mask: mask,
      onFocus: () => {
        setMask('(11) 11111-1111');
      },
      onChange: (v) => {
        setLastPhone(phone);
        setPhone((v.target.value as string).replaceAll(/_|-|[)]|[(]/g, ''));
      },
      onBlur: (v) => {
        if (phone.length === 11) {
          setMask('(11) 1111-1111');
          setTimeout(() => {
            props.form.setValue('phone', phone);
            setMask('(11) 11111-1111');
          }, 0)
        }
      },
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: string) => {
          return isValidPhoneNumber(value);
        }
      }
    },
  ]

  const save = (data: any) => {
    props.setCurrentStep(x => x! + 1);
    let newPhone = data.phone;
    const phoneUnmask = data.phone.replaceAll(/_|-|[)]|[(]/g, '').replaceAll(' ', '');
    if (phoneUnmask.length === 10) {
      newPhone = formatText(phoneUnmask, '(##) ####-####');
    } else if (phoneUnmask.length === 11) {
      newPhone = formatText(phoneUnmask, '(##) #####-####');
    }
    props.setUserObj({ ...data, phone: newPhone });
    history.push(history.location.pathname.replace('/user', '/organization'));
  }

  return <div className='prepaid-user'>
    <FormAdmin padding='32px 0' onSubmit={handleSubmit(save)}>
      <FormItensAdmin form={props.form} config={formConfigData} />
      <div style={{ justifyContent: 'flex-end', display: 'flex', paddingTop: 12 }} className="buttons">
        <ButtonGr1d htmlType='submit'>Continuar</ButtonGr1d>
      </div>
    </FormAdmin>
  </div>;
}

export default PrepaidUser;