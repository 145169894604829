export interface ApiBusinessModel {
    id: number;
    apiId: number;
    type: ApiBusinessModelType;
    pricingLink?: string;
    pricingDescription?: string;
    priceRules: ApiPriceRule[];
    plans: ApiBusinessPlan[];
}

export enum ApiBusinessModelType {
    PER_REQUEST = 'PER_REQUEST',
    PLAN = 'PLAN',
    PER_PROVIDER = 'PER_PROVIDER',
    FREE = 'FREE',
}

export interface ApiPriceRule {
    id: number;
    franchise: boolean;
    freeTierQtty: number;
    endpoints: string[];
    businessModel: ApiBusinessModel;
    priceRange: ApiPriceRange[];
}


export interface ApiPriceRange {
    id: number;
    start: number;
    end: number;
    value: number;
    priceRule: ApiPriceRule;
}

export interface ApiBusinessPlan {
    id: number;
    name: string;
    requestsQtty: number;
    price: number;
    additionalReqPrice: number;
    businessModel?: ApiBusinessModel;
}
