import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Api = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const ApiPreviewStyled = styled(Api)`
    .api-header {
        max-width: 1280px;
        padding: 0 20px;
        margin: auto;
        display: flex;
        margin-top: 40px;
        margin-bottom: 24px;

        .title-logo {
            flex: 1;
            display: flex;
            align-items: center;

            .title {
                margin-left: 32px;
            }
        }

        .raking {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;

            .average {
                display: flex;
                align-items: baseline;
            }
        }
    }

    .description {
        max-width: 1280px;
        padding: 0 20px;
        margin: auto;
        position: relative;

        .dots {
            position: absolute;
            right: 20px;
            top: 0;
        }
    }

    .logo {
        height: 100%;
    }

    .tabs {
        margin-bottom: 48px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    .options {
        display: flex;
        align-items: center;

        .tab-options {
            flex: 1;
        }

        .buttons-options {
            flex: 0.5;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                position: relative;
                right: 13px;
            }
        }
    }

    
    &.sm .options {
        display: initial;
        overflow: auto;
    }

    &.sm .api-header {
        display: initial;
    }
`