import { ApiSubscriptionModel } from "models/apiSubscription";
import { createApiService } from "service";

export const createApiConsumedService = () => {
    const service = createApiService('/core/api/v1');

    const getApiSubscriptions = (userId: string): Promise<ApiSubscriptionModel[]> => {
        return service.get(`/apis-subscription/user`);
    }

    const deleteApiKey = (apiId: number, type: string): Promise<ApiSubscriptionModel[]> => {
        return service.delete(`/api-keys/${apiId}`);
    }

    return { getApiSubscriptions, deleteApiKey };
}