import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import './index.css';
import App from './App';
import history from './router/history'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-165689602-6');
    hotjar.initialize(2695116, 6);
    history.listen((location) => {
        hotjar.stateChange(location.pathname + location.search);
        ReactGA.pageview(location.pathname + location.search);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();