import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Layout = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const LayoutStyled = styled(Layout)`
    background: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};

    .content {
        overflow: auto;
    }

    .logo {
        cursor: pointer;
    }
`