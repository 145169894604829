import ButtonGr1d from "components/portal/button";
import InputGr1d from "components/portal/input";
import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { AdminApiStyled } from "./index.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as add } from "assets/svg/add.svg";
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { Table } from "antd";
import { adminApiActions } from "store/admin/api/action";
import { ApiModel } from "models/api";
import DrawerAdmin from "components/admin/drawer";
import { useForm } from "react-hook-form";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import { AdminApiState } from "store/admin/api/reducer";

const AdminApi: React.FC = () => {
    const dispatch = useDispatch();
    const apiState: AdminApiState = useSelector((state: any) => state.adminApi);
    const actions = useAction(adminApiActions());
    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    const form = useForm();
    const { handleSubmit } = form;

    const save = (data: any) => {
        dispatch(actions.postRegister({...data, basePath: 'http://grid.io/' + data.basePath, version: "1"}));
    }

    const reset = () => {
        setShowDrawer(false);
    }

    const callEdit = (id: number) => {
        dispatch(actions.getApi(id));
    }

    const callRegister = () => {
        setShowDrawer(true);
        form.reset();
    }

    useEffect(() => {
        dispatch(actions.getCategories());
        dispatch(actions.getApis('', 1));
        return () => {

        }
    }, [dispatch, actions]);

    const columns = [
        {
            title: 'API',
            dataIndex: 'name',
            width: '25%',
            key: 'name',
            ellipsis: true,
            render: (value: string, row: ApiModel) => {
                return <div className='link' onClick={() => callEdit(row.id!)}>{value}</div>
            }
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            width: '75%',
            key: 'description',
            ellipsis: true,
            render: (value: string, row: ApiModel) => {
                return <div className='link' onClick={() => callEdit(row.id!)}>{value}</div>
            }
        },
        {
            title: 'Excluir',
            dataIndex: 'id',
            width: '80px',
            key: 'id',
            render: (v: number) => <DeleteOutlined disabled={true} className='delete-use-case' style={{ color: 'red', justifyContent: 'center' }} />
        }
    ];

    const formConfig: ConfigFormItem[] = [{
        name: 'name',
        typeComponent: 'input',
        label: 'Qual é o nome da sua API?',
        placeholder: 'Em alta',
        rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
        name: 'description',
        typeComponent: 'textarea',
        labelTitle: {
            title: 'Descreva brevemente essa API.',
            subtitle: 'Tente algo como “Implemente, gerencie e faça o escalonamento de aplicativos em contêineres no Kubernetes, com a tecnologia X”.'
        },
        maxLength: 500,
        showMaxLength: true,
        placeholder: 'Descrição resumida',
        rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
        name: 'basePath',
        typeComponent: 'input',
        addonBefore: 'http://grid.io/',
        labelTitle: {
            title: 'Contexto',
            subtitle: 'É a terminação da URL da página da sua API no marketplace da GR1D.'
        },
        noSpace: true,
        placeholder: '_provedor_nome_da_api',
        rules: { required: { value: true, message: 'Campo obrigatório' } }
    }]

    return <AdminApiStyled>
        <div style={{ display: 'flex', height: '100%' }}>
            <div>
                <div>
                    <div className='api'>
                        <TypographyGr1d style={{ paddingTop: 72, marginBottom: 16 }} component='headingLG' color='colorPrimitiveBlack'>
                            APIS
                        </TypographyGr1d>
                        <TypographyGr1d style={{ marginBottom: 80 }} component='subtitleSM' color='colorPrimitive500'>
                            Gerencie aqui as suas apis.
                        </TypographyGr1d>
                    </div>
                    <div className='api-tab'>
                        <div style={{ marginBottom: 48 }} className='search'>
                            <InputGr1d className='input' placeholder='Pesquise por título ou descrição'
                                suffix={<SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} />} />
                            <ButtonGr1d onClick={() => callRegister()} type='brand'>
                                <Icon component={add} /> Cadastrar
                            </ButtonGr1d>
                        </div>
                        <Table bordered pagination={false} dataSource={apiState.list?.map((x, i) => { return { ...x, key: i } })} columns={columns} />
                    </div>
                    <DrawerAdmin visible={showDrawer} setVisible={setShowDrawer} title='Cadastrar API'>
                        <FormAdmin padding='0' onSubmit={handleSubmit(save)} onReset={reset}>
                            <div className='drawer-content'>
                                <FormItensAdmin form={form} config={formConfig} />
                            </div>
                            <div className='drawer-footer'>
                                <ButtonGr1d loading={apiState.saveLoading} htmlType='submit' type='brand' style={{ paddingBottom: 8 }} width='100%'>Salvar Cadastro</ButtonGr1d>
                                <ButtonGr1d htmlType='reset' width='100%'>Voltar</ButtonGr1d>
                            </div>
                        </FormAdmin>
                    </DrawerAdmin>
                </div>
            </div>
        </div>
    </AdminApiStyled>;
}

export default AdminApi;