import { ApiModel, ApiPaginationModel } from "models/api";
import { CategoryListModel } from "models/category";
import { CommentModel } from "models/comment";
import { HomePageModel } from "models/homePage";
import { PaginationModel } from "models/pagination";
import { PolicyModel } from "models/policy";
import { ApiKeyModel } from "models/apiKey";
import { UseCaseModel } from "models/useCase";
import { createPortalService } from "./service";
import { appActions } from "store/app/action";
import _ from "lodash";
import { ApiBusinessModel } from "models/apiBusinessModel";
import { ApiPriceModel } from "models/prepaid";

export enum PortalActionType {
    GET_HOME = '[PORTAL] Get HomePage',
    GET_HOME_SUCCESS = '[PORTAL] Get HomePage Success',
    GET_HOME_ERROR = '[PORTAL] Get HomePage Error',
    GET_ALL_CATEGORY = '[PORTAL] Get all Category',
    GET_ALL_CATEGORY_SUCCESS = '[PORTAL] Get all Category Success',
    GET_ALL_CATEGORY_ERROR = '[PORTAL] Get all Category Error',
    GET_APIS = '[PORTAL] Get Apis',
    GET_APIS_SUCCESS = '[PORTAL] Get Apis Success',
    GET_APIS_ERROR = '[PORTAL] Get Apis Error',
    GET_USE_CASES = '[PORTAL] Get UseCases',
    GET_USE_CASES_SUCCESS = '[PORTAL] Get UseCases Success',
    GET_USE_CASES_ERROR = '[PORTAL] Get UseCases Error',
    GET_USE_CASE = '[PORTAL] Get UseCase',
    GET_USE_CASE_SUCCESS = '[PORTAL] Get UseCase Success',
    GET_USE_CASE_ERROR = '[PORTAL] Get UseCase Error',
    GET_API = '[PORTAL] Get Api',
    GET_API_SUCCESS = '[PORTAL] Get Api Success',
    GET_API_ERROR = '[PORTAL] Get Api Error',
    GET_RELATED_API = '[PORTAL] Get Related Api',
    GET_RELATED_API_SUCCESS = '[PORTAL] Get Related Api Success',
    GET_RELATED_API_ERROR = '[PORTAL] Get Related Api Error',
    GET_RELATED_API_SEARCH = '[PORTAL] Get Related Api Search',
    GET_RELATED_API_SEARCH_SUCCESS = '[PORTAL] Get Related Api Search Success',
    GET_RELATED_API_SEARCH_ERROR = '[PORTAL] Get Related Api Search Error',
    GET_POLICIES = '[ADMIN_API] Get policies',
    GET_POLICIES_SUCCESS = '[ADMIN_API] Get policies Success',
    GET_POLICIES_ERROR = '[ADMIN_API] Get policies Error',
    GET_API_COMMENT = '[PORTAL] Get Api Comment',
    GET_API_COMMENT_SUCCESS = '[PORTAL] Get Api Comment Success',
    GET_API_COMMENT_ERROR = '[PORTAL] Get Api Comment Error',
    GET_API_ANSWER = '[PORTAL] Get Api Answer',
    GET_API_ANSWER_SUCCESS = '[PORTAL] Get Api Answer Success',
    GET_API_ANSWER_ERROR = '[PORTAL] Get Api Answer Error',
    POST_API_COMMENT = '[PORTAL] Post Api Comment',
    POST_API_COMMENT_SUCCESS = '[PORTAL] Post Api Comment Success',
    POST_API_COMMENT_ERROR = '[PORTAL] Post Api Comment Error',
    CHANGE_COMMENT_SCREEN = '[PORTAL] Change Comment Screen',
    GET_API_KEY = '[PORTAL] Get Api Key',
    GET_API_KEY_SUCCESS = '[PORTAL] Get Api Key Success',
    GET_API_KEY_ERROR = '[PORTAL] Get Api Key Error',
    POST_API_KEY = '[PORTAL] Post Api Key',
    POST_API_KEY_SUCCESS = '[PORTAL] Post Api Key Success',
    POST_API_KEY_ERROR = '[PORTAL] Post Api Key Error',
    POST_SUBSCRIPTION = '[PORTAL] Post Subscription',
    POST_SUBSCRIPTION_SUCCESS = '[PORTAL] Post Subscription Success',
    POST_SUBSCRIPTION_ERROR = '[PORTAL] Post Subscription Error',
    REMOVE_MODAL_API = '[PORTAL] Remove Modal API',
    GET_BUSINESS_MODEL_API = '[PORTAL] Get Business Model Api',
    GET_BUSINESS_MODEL_API_SUCCESS = '[PORTAL] Get Business Model Api Success',
    GET_BUSINESS_MODEL_API_ERROR = '[PORTAL] Get Business Model Api Error',
    SET_API_TO_ACTIVATE = '[PORTAL] Set Api to activate',
    GET_API_HELPFORM = '[PORTAL] Get Apis HelpForm',
    GET_API_HELPFORM_SUCCESS = '[PORTAL] Get Apis HelpForm Success',
    GET_API_HELPFORM_ERROR = '[PORTAL] Get Apis HelpForm Error',
    API_HELPFORM_DELETE = '[PORTAL] Apis HelpForm Delete',
    GET_API_PREPAID = '[PORTAL_PREPAID] Get Api Prepaid',
    GET_API_PREPAID_SUCCESS = '[PORTAL_PREPAID] Get Api Prepaid Success',
    GET_API_PREPAID_ERROR = '[PORTAL_PREPAID] Get Api Prepaid Error',
}

export interface portalAction {
    getHomePage(): void;
    getAllCategory(): void;
    getApis(keyword: string, offset: number, limit: number, categories?: string[], log?: boolean): void;
    getUseCases(): void,
    getUseCase(id: number, log?: boolean): void,
    getApi(id: number, log?: boolean): void,
    getApiComment(apiId: number, page: number, size: number, query?: string): void,
    getApiAnswer(apiId: number, commentId: number): void,
    postApiComment(apiId: number, value: string, parentId?: number, form?: any): void,
    changeCommentScreen(screen: number): void
    getPolicies(): void
    getApiComment(apiId: number, limit: number, offset: number): void,
    getApiKey(apiId: number, type: 'SANDBOX' | 'PRODUCTION'): void,
    getRelatedApiSearch(apiId: number, page: number, setTitle: (value: React.SetStateAction<string | undefined>) => void): void,
    getBusinessModelApi(apiId: number): void;
    setApiToActivate(api: ApiModel): void;
    postApiKey(apiId: number, type: 'SANDBOX' | 'PRODUCTION'): void;
    getPrepaid(apiId: number): void;
}

export const portalActions = ((): portalAction => {
    const portalService = createPortalService();
    const appAction = appActions();
    const actions = {
        getHomePage: (): any => {
            return { type: PortalActionType.GET_HOME }
        },
        getHomePageSuccess: (data: HomePageModel[]): any => {
            return { type: PortalActionType.GET_HOME_SUCCESS, payload: data }
        },
        getHomePageError: (): any => {
            return { type: PortalActionType.GET_HOME_ERROR }
        },
        getAllCategory: (): any => {
            return { type: PortalActionType.GET_ALL_CATEGORY }
        },
        getAllCategorySuccess: (data: CategoryListModel): any => {
            return { type: PortalActionType.GET_ALL_CATEGORY_SUCCESS, payload: data }
        },
        getAllCategoryError: (): any => {
            return { type: PortalActionType.GET_ALL_CATEGORY_ERROR }
        },
        getApis: (): any => {
            return { type: PortalActionType.GET_APIS }
        },
        getApisSuccess: (data: ApiPaginationModel): any => {
            return { type: PortalActionType.GET_APIS_SUCCESS, payload: data }
        },
        getApisError: (): any => {
            return { type: PortalActionType.GET_APIS_ERROR }
        },
        getUseCases: (): any => {
            return { type: PortalActionType.GET_USE_CASES }
        },
        getUseCasesSuccess: (data: UseCaseModel[]): any => {
            return { type: PortalActionType.GET_USE_CASES_SUCCESS, payload: data }
        },
        getUseCasesError: (): any => {
            return { type: PortalActionType.GET_USE_CASES_ERROR }
        },
        getUseCase: (): any => {
            return { type: PortalActionType.GET_USE_CASE }
        },
        getUseCaseSuccess: (data: UseCaseModel): any => {
            return { type: PortalActionType.GET_USE_CASE_SUCCESS, payload: data }
        },
        getUseCaseError: (): any => {
            return { type: PortalActionType.GET_USE_CASE_ERROR }
        },
        getApi: (): any => {
            return { type: PortalActionType.GET_API }
        },
        getApiSuccess: (data: ApiModel): any => {
            return { type: PortalActionType.GET_API_SUCCESS, payload: data }
        },
        getApiError: (): any => {
            return { type: PortalActionType.GET_API_ERROR }
        },
        getRelatedApi: (): any => {
            return { type: PortalActionType.GET_RELATED_API }
        },
        getRelatedApiSuccess: (data: ApiModel[]): any => {
            return { type: PortalActionType.GET_RELATED_API_SUCCESS, payload: data }
        },
        getRelatedApiError: (): any => {
            return { type: PortalActionType.GET_RELATED_API_ERROR }
        },
        getPolicies: (): any => {
            return { type: PortalActionType.GET_POLICIES }
        },
        getPoliciesSuccess: (data: PolicyModel[]): any => {
            return { type: PortalActionType.GET_POLICIES_SUCCESS, payload: data }
        },
        getPoliciesError: (): any => {
            return { type: PortalActionType.GET_POLICIES_ERROR }
        },
        getApiComment: (): any => {
            return { type: PortalActionType.GET_API_COMMENT }
        },
        getApiCommentSuccess: (data: PaginationModel<CommentModel>): any => {
            return { type: PortalActionType.GET_API_COMMENT_SUCCESS, payload: data }
        },
        getApiCommentError: (): any => {
            return { type: PortalActionType.GET_API_COMMENT_ERROR }
        },
        getApiAnswer: (commentId: number): any => {
            return { type: PortalActionType.GET_API_ANSWER, payload: commentId }
        },
        getApiAnswerSuccess: (data: CommentModel[], commentId: number): any => {
            return { type: PortalActionType.GET_API_ANSWER_SUCCESS, payload: { list: data, commentId } }
        },
        getApiAnswerError: (commentId: number): any => {
            return { type: PortalActionType.GET_API_ANSWER_ERROR, payload: commentId }
        },
        postApiComment: (): any => {
            return { type: PortalActionType.POST_API_COMMENT }
        },
        postApiCommentSuccess: (): any => {
            return { type: PortalActionType.POST_API_COMMENT_SUCCESS }
        },
        postApiCommentError: (): any => {
            return { type: PortalActionType.POST_API_COMMENT_ERROR }
        },
        changeCommentScreen: (screen: number): any => {
            return { type: PortalActionType.CHANGE_COMMENT_SCREEN, payload: screen }
        },
        getApiKey: (type: string): any => {
            return { type: PortalActionType.GET_API_KEY, payload: type }
        },
        getApiKeySuccess: (data: ApiKeyModel): any => {
            return { type: PortalActionType.GET_API_KEY_SUCCESS, payload: data }
        },
        getApiKeyError: (type: string): any => {
            return { type: PortalActionType.GET_API_KEY_ERROR, payload: type }
        },
        postApiKey: (type: string): any => {
            return { type: PortalActionType.POST_API_KEY, payload: type }
        },
        postApiKeySuccess: (data: ApiKeyModel): any => {
            return { type: PortalActionType.POST_API_KEY_SUCCESS, payload: data }
        },
        postApiKeyError: (type: string): any => {
            return { type: PortalActionType.POST_API_KEY_ERROR, payload: type }
        },
        postSubscription: (): any => {
            return { type: PortalActionType.POST_SUBSCRIPTION }
        },
        postSubscriptionSuccess: (): any => {
            return { type: PortalActionType.POST_SUBSCRIPTION_SUCCESS }
        },
        postSubscriptionError: (): any => {
            return { type: PortalActionType.POST_SUBSCRIPTION_ERROR }
        },
        removeModalApi: (): any => {
            return { type: PortalActionType.REMOVE_MODAL_API }
        },
        getRelatedApiSearch: (): any => {
            return { type: PortalActionType.GET_RELATED_API_SEARCH }
        },
        getRelatedApiSearchSuccess: (data: ApiPaginationModel): any => {
            return { type: PortalActionType.GET_RELATED_API_SEARCH_SUCCESS, payload: data }
        },
        getRelatedApiSearchError: (): any => {
            return { type: PortalActionType.GET_RELATED_API_SEARCH_ERROR }
        },
        getBusinessModelApi: (): any => {
            return { type: PortalActionType.GET_BUSINESS_MODEL_API }
        },
        getBusinessModelApiSuccess: (data?: ApiBusinessModel): any => {
            return { type: PortalActionType.GET_BUSINESS_MODEL_API_SUCCESS, payload: data }
        },
        getBusinessModelApiError: (): any => {
            return { type: PortalActionType.GET_BUSINESS_MODEL_API_ERROR }
        },
        setApiToContract: (api: ApiModel): any => {
            return { type: PortalActionType.SET_API_TO_ACTIVATE, payload: api }
        },
        getPrepaid: (): any => {
            return { type: PortalActionType.GET_API_PREPAID }
        },
        getPrepaidSuccess: (data: ApiPriceModel): any => {
            return { type: PortalActionType.GET_API_PREPAID_SUCCESS, payload: data }
        },
        getPrepaidError: (): any => {
            return { type: PortalActionType.GET_API_PREPAID_ERROR }
        },
    }

    function getHomePage() {
        return (dispath: any) => {
            dispath(actions.getHomePage());

            portalService.getHomePage().then(x => {
                dispath(actions.getHomePageSuccess(x));
            }).catch(x => {
                dispath(actions.getHomePageError());
            })
        }
    }

    function getAllCategory() {
        return (dispath: any) => {
            dispath(actions.getAllCategory());

            portalService.getAllCategory().then(x => {
                dispath(actions.getAllCategorySuccess(x));
            }).catch(x => {
                dispath(actions.getAllCategoryError());
            })
        }
    }

    function getApis(keyword: string, offset: number, limit: number, categories?: string[], log?: boolean) {
        return (dispath: any) => {
            dispath(actions.getApis());

            portalService.getApis(keyword, offset, limit, categories, log).then(x => {
                dispath(actions.getApisSuccess(x));
            }).catch(x => {
                dispath(actions.getApisError());
            })
        }
    }

    function getUseCases() {
        return (dispath: any) => {
            dispath(actions.getUseCases());

            portalService.getUseCases().then(x => {
                dispath(actions.getUseCasesSuccess(x));
            }).catch(x => {
                dispath(actions.getUseCasesError());
            })
        }
    }

    function getUseCase(id: number, log?: boolean) {
        return (dispath: any) => {
            dispath(actions.getUseCase());

            portalService.getUseCase(id).then(x => {
                dispath(actions.getUseCaseSuccess(x));
            }).catch(x => {
                dispath(actions.getUseCaseError());
            })
        }
    }

    function getApi(id: number, log?: boolean) {
        return async (dispath: any) => {
            dispath(actions.getApi());

            try {
                const api = await portalService.getApi(id, log)
                const documents = await portalService.getApiDocuments(id)
                try {
                    const definition = await portalService.getApiDefinition(id)
                    api.definitionFile = JSON.stringify(definition)
                } catch (e) {
                    api.definitionFile = undefined;
                }
                api.documents = documents
                dispath(getRelatedApi(api.relatedCategories.map(x => x.id.toString()), api.id!));
                dispath(actions.getApiSuccess(api));
            } catch (e) {
                dispath(actions.getApiError());
            }
        }
    }

    function getPolicies() {
        return (dispath: any) => {
            dispath(actions.getPolicies());

            portalService.getPolicies().then(x => {
                dispath(actions.getPoliciesSuccess(x));
            }).catch(x => {
                dispath(actions.getPoliciesError());
            })
        }
    }

    function getApiComment(apiId: number, page: number, size: number, query?: string) {
        return (dispath: any) => {
            dispath(actions.getApiComment());

            portalService.getApiComment(apiId, page, size, query).then(comment => {
                dispath(actions.getApiCommentSuccess(comment));
            }).catch(x => {
                dispath(actions.getApiCommentError());
            })
        }
    }

    function getApiAnswer(apiId: number, commentId: number) {
        return (dispath: any) => {
            dispath(actions.getApiAnswer(commentId));

            portalService.getApiAnswer(apiId, commentId).then(comments => {
                dispath(actions.getApiAnswerSuccess(comments.items, commentId));
            }).catch(x => {
                dispath(actions.getApiAnswerError(commentId));
            })
        }
    }

    function postApiComment(apiId: number, value: string, parentId?: number, form?: any) {
        return (dispath: any) => {
            dispath(actions.postApiComment());

            portalService.postApiComment(apiId, value, parentId).then(comment => {
                dispath(actions.postApiCommentSuccess());
                if (parentId == null) {
                    dispath(getApiComment(apiId, 1, 6, undefined));
                    dispath(changeCommentScreen(0));
                } else {
                    setTimeout(() => {
                        dispath(actions.postApiCommentSuccess());
                    }, 0)
                    form.setValue('answer', '');
                    dispath(getApiAnswer(apiId, parentId));
                }
            }).catch(x => {
                dispath(actions.postApiComment());
            })
        }
    }

    function changeCommentScreen(screen: number) {
        return (dispath: any) => {
            dispath(actions.changeCommentScreen(screen));
        }
    }

    function getApiKey(apiId: number, type: string) {
        return (dispath: any) => {
            dispath(actions.getApiKey(type));
            portalService.getApiKey(apiId, type).then(apiKey => {
                dispath(actions.getApiKeySuccess(apiKey));
            }).catch(x => {
                dispath(actions.getApiKeyError(type));
            })
        }
    }

    function postApiKey(apiId: number, type: string) {
        return (dispath: any) => {
            portalService.postApiKey(apiId, type).then(apiKey => {
                dispath(actions.postApiKeySuccess(apiKey));
                setTimeout(() => {
                    dispath(actions.removeModalApi());
                }, 100)
                if (apiKey.type === 'SANDBOX') {
                    dispath(appAction.goTo(`/api/${apiKey.apiId.id}/sandbox`));
                }
            }).catch(x => {
                dispath(actions.postApiKeyError(type));
            })
        }
    }

    function getRelatedApi(categories: string[], apiId: number) {
        return (dispath: any) => {
            dispath(actions.getRelatedApi());

            portalService.getApis('', 1, 1000, categories).then((x: any) => {
                dispath(actions.getRelatedApiSuccess(_.filter(x.items, x => x.id !== apiId)));
            }).catch(x => {
                dispath(actions.getRelatedApiError());
            })
        }
    }

    function getRelatedApiSearch(apiId: number, page: number,
        setTitle: (value: React.SetStateAction<string | undefined>) => void) {
        return async (dispath: any) => {
            dispath(actions.getRelatedApiSearch());

            portalService.getApi(apiId).then((api) => {
                portalService.getApis('', page, 12, api.relatedCategories.map(x => x.id.toString())).then((apis) => {
                    setTitle('APIs Realacionadas: ' + api.name);
                    let tempApis: any[] = _.filter(apis.items, x => x.id !== apiId);
                    const categories = api.relatedCategories.map(x => x.name);
                    tempApis.map(x => x.countApis = x.relatedCategories != null ? _.intersection(categories, x.relatedCategories.map((a: any) => a.name)).length : 0)
                    tempApis = _.orderBy(tempApis, ['countApis', 'name'], ['desc']);
                    let meta = apis.meta;
                    meta.totalItems = meta.totalItems - 1;

                    dispath(actions.getRelatedApiSearchSuccess({ ...apis, items: tempApis, meta }));
                }).catch(x => {
                    dispath(actions.getRelatedApiSearchError());
                })
            }).catch(x => {
                dispath(actions.getRelatedApiSearchError());
            });
        }
    }

    function getBusinessModelApi(apiId: number) {
        return async (dispath: any) => {
            dispath(actions.getBusinessModelApi());

            portalService.getBusinessModelApi(apiId).then((businessModel) => {
                dispath(actions.getBusinessModelApiSuccess(businessModel.length > 0 ? businessModel[0] : undefined));
            }).catch(() => {
                dispath(actions.getBusinessModelApiError());
            });
        }
    }

    function setApiToActivate(api: ApiModel) {
        return async (dispatch: any) => {
            dispatch(actions.setApiToContract(api))
        }
    }

    function getPrepaid(id: number) {
        return (dispath: any) => {
            dispath(actions.getPrepaid());

            portalService.getPrepaid(id).then(x => {
                dispath(actions.getPrepaidSuccess(x));
            }).catch(x => {
                dispath(actions.getPrepaidError());
            })
        }
    }

    return {
        getHomePage, getAllCategory, getApis, getUseCases, getUseCase, getApi, getApiComment, getApiAnswer, postApiComment,
        changeCommentScreen, getPolicies, getApiKey, getRelatedApiSearch, getBusinessModelApi, setApiToActivate,
        postApiKey, getPrepaid
    }
})