import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const CardApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const CardApiStyled = styled(CardApi)`
    cursor: pointer;

    .ant-card-body {
        height: 100%;
        .card-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }
    
    .card-logo {
        display: flex;
        align-items: center;

        img {
            width: 80px;
            height: 80px;
            object-fit: scale-down;
        }

        .txt {
            flex: 1;
            padding-left: 20px;
            width: calc(100% - 60px - 20px);

            > div {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: block;
                white-space: nowrap;

                > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: block;
                    white-space: nowrap;
                }
            }
        }
    }

    .card-txt {
        color: #332F40;
        margin: 16px 0px 20px 0px;

        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .tag {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            flex: 1;
        }
    }

    .card-tag {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
    }

    .category-tag {
        display: flex;
        align-items: center;

        > div {
            flex: 1;
        }
    }
`