import React, { useEffect, useState } from "react";
import { LoginStyled } from "./index.styled";
import logo from "assets/svg/logo.svg"
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import { match, useHistory } from "react-router-dom";
import Icon from '@ant-design/icons';
import { ReactComponent as mail } from "assets/svg/logon/mail.svg";
import { ReactComponent as arrow } from "assets/svg/navegation/arrow/left.svg";
import { ReactComponent as link } from "assets/svg/logon/link-expired.svg";
import { ReactComponent as like } from "assets/svg/logon/like.svg";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { loginActions } from "store/login/action";

type Props = {
    match: match
}

const LoginInfo: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const actions = useAction(loginActions());
    const email: string = useSelector((state: any) => state.login.userEmail);
    const [emailRegister, setEmailRegister] = useState<string>('');
    const [action, setAction] = useState<'SUCCESS' | 'VERIFY_EMAIL' | 'UPDATE_PASSWORD'>();
    const [error, setError] = useState<boolean>(false);
    const [element, setElement] = useState<JSX.Element>(<></>);

    useEffect(() => {
        if ((props.match.params as any) !== null) {
            const key = (atob((props.match.params as any).key)).split('&&');
            setError((key[0] as string) !== 'success');
            const action = key[1] as any;
            const id = key[2] as any;
            if (action === "SUCCESS")
                setEmailRegister(id);
            else
                dispatch(actions.getUserEmail(id))
            setAction(action);
        }
    }, [dispatch, actions, props.match.params]);

    useEffect(() => {
        if (email !== '') {
            if (action === 'VERIFY_EMAIL') {
                if (error)
                    setElement(<LoginExpired isPassword={false} email={email} />);
                else
                    setElement(<Success isPassword={false} />);
            } else if (action === 'UPDATE_PASSWORD') {
                if (error)
                    setElement(<LoginExpired isPassword={true} email={email} />);
                else
                    setElement(<Success isPassword={true} />);
            }
        } else if (emailRegister !== '')
            setElement(<Confirm email={emailRegister} />);
        else {
            setElement(<></>);
        }
    }, [action, error, email, emailRegister])

    return <LoginStyled>
        {element}
    </LoginStyled>;
}

type PropsLoginVerify = {
    email: string
}

const Confirm: React.FC<PropsLoginVerify> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const actions = useAction(loginActions());

    const postVerifyEmail = () => {
        dispatch(actions.verifyEmail(props.email));
    }

    return <LoginStyled>
        <div className='content-white'>
            <div className='border-top' />
            <div className='border-bottom' />
            <div className='all-screen'>
                <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

                <div className='content'>
                    <div className='resend-email-screen'>
                        <ButtonGr1d type='primitive' onClick={() => history.push('/login')} style={{ marginBottom: 40 }}>
                            <Icon component={arrow} />&nbsp;&nbsp;Voltar
                        </ButtonGr1d>
                        <div style={{ marginBottom: 24 }} className='icon-center'>
                            <Icon component={mail} />
                        </div>
                        <TypographyGr1d style={{ marginBottom: 18 }} color='colorPrimitiveBlack' component='headingXS'>
                            Link de confirmação enviado
                        </TypographyGr1d>
                        <TypographyGr1d style={{ textAlign: 'center', maxWidth: 310, margin: '0 auto' }} color='colorPrimitive500' component='bodySM'>
                            <div style={{ marginBottom: 40 }}>
                                Verifique seu e-mail {props.email} e siga as instruções. O link expira em 24h.
                            </div>
                            <div className='without-account'>
                                <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                                    Não recebeu o e-mail?
                                </TypographyGr1d>
                                &nbsp;
                                <div onClick={() => postVerifyEmail()}>
                                    <TypographyGr1d style={{ cursor: 'pointer' }} component='bodySM' color='colorBrandPrimary500'>
                                        Clique aqui pra reenviar.
                                    </TypographyGr1d>
                                </div>
                            </div>
                        </TypographyGr1d>
                    </div>
                </div>
            </div>
        </div>
        <div className='dots' />
    </LoginStyled>;
}

type PropsLoginExpired = {
    email: string,
    isPassword?: boolean
}

const LoginExpired: React.FC<PropsLoginExpired> = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const actions = useAction(loginActions());

    const action = () => {
        if (props.isPassword)
            dispatch(actions.forgotPassword(props.email));
        else
            dispatch(actions.verifyEmail(props.email));
    }

    return <LoginStyled>
        <div className='content-white'>
            <div className='border-top' />
            <div className='border-bottom' />
            <div className='all-screen'>
                <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

                <div className='content'>
                    <div className='resend-email-screen'>
                        <div style={{ marginBottom: 24 }} className='icon-center'>
                            <Icon component={link} />
                        </div>
                        <TypographyGr1d style={{ marginBottom: 18 }} color='colorPrimitiveBlack' component='headingXS'>
                            Link expirado!
                        </TypographyGr1d>
                        <TypographyGr1d style={{ textAlign: 'center', maxWidth: 310, margin: '0 auto 56px auto' }} color='colorPrimitive500' component='bodySM'>
                            {!props.isPassword ? `Faça uma nova solicitação de confirmação de email e verifique sua caixa de e-mail ou spam.` :
                                `Faça uma nova solicitação de troca de senha para ${props.email} e verifique sua caixa de e-mail ou spam.`}
                        </TypographyGr1d>
                        <ButtonGr1d onClick={() => action()} style={{ width: '100%' }} type='brand' width='100%'>
                            Reenviar e-mail
                        </ButtonGr1d>
                    </div>
                </div>
            </div>
        </div>
        <div className='dots' />
    </LoginStyled>;
}

type PropsSuccess = {
    isPassword: boolean
}

const Success: React.FC<PropsSuccess> = (props) => {
    let history = useHistory();

    return <LoginStyled>
        <div className='content-white'>
            <div className='border-top' />
            <div className='border-bottom' />
            <div className='all-screen'>
                <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

                <div className='content'>
                    <div className='register-success-screen'>
                        <div style={{ marginBottom: 24 }} className='icon-center'>
                            <Icon component={like} />
                        </div>
                        <TypographyGr1d style={{ marginBottom: 18 }} color='colorPrimitiveBlack' component='headingXS'>
                            {props.isPassword === true ? 'Senha alterada com sucesso!' : 'Cadastro realizado com sucesso!'}
                        </TypographyGr1d>
                        {props.isPassword !== true && <TypographyGr1d style={{ textAlign: 'center', maxWidth: 394, margin: '0 auto 56px auto' }} color='colorPrimitive500' component='bodySM'>
                            A GR1D lhe deseja boas-vindas! Conheça agora uma nova forma de consumir e comercializar suas APIs!
                        </TypographyGr1d>}
                        <ButtonGr1d onClick={() => history.push('/admin/api/consumed')} style={{ width: '100%' }} type='brand' width='100%'>
                            Acessar o Portal GR1D
                        </ButtonGr1d>
                    </div>
                </div>
            </div>
        </div>
        <div className='dots' />
    </LoginStyled>;
}

export default LoginInfo;