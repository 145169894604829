import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"
import dots from "assets/svg/logon/pattern-dots.svg"

const Login = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const LoginStyled = styled(Login)`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};

    & > .dots {
        background-repeat: repeat;
        background-image: url(${dots});
        height: 100%;
        width: 100%;
        position:absolute;
    }
    
    .content-white.sm {
        padding: 30px 20px;
    }

    .content-white {
        position: relative;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        max-width: 730px;
        width: 100%;
        height: 100vh;
        padding: 30px 135px;
        margin: auto 0;
        z-index: 1;
    }
    
    .border-top {
        position: absolute;
        background: linear-gradient(45deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} 50%);
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
    }

    .border-bottom {
        position: absolute;
        background: linear-gradient(135deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} 50%);
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
    }

    .content {
        display: flex;
        align-items: center;
    }

    .welcome {
        margin-bottom: 24px;
    }

    .login {
        width: 100%;
    }

    .divider {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    .login-social {
        display: flex;
        justify-content: center;
        gap: 16px;

        img {
            cursor: pointer;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            padding: 10px;
            border-radius: 100px;
        }

        img:hover {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200};
        }
    }

    .without-account {
        display: flex;
        margin-top: 24px;
    }

    .forgot-password {
        text-align: right;
        margin-bottom: 24px;
        
        span {
            cursor: pointer;
        }
    }

    .recaptcha > div {
        display: flex;
        justify-content: center;
        padding: 12px 0;
    }

    .all-screen {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
    }

    .logo {
        width: 110px;
        height: 20px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    
    .content {
        flex: 1;
    }

    .resend-email-screen {
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .register-success-screen {
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .confirmed-screen {
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .icon-center {
        display: flex;
        justify-content: center;

        > span {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            font-size: 80px;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
            width: 192px;
            height: 192px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
        }
    }
`


export const LoginPasswordStyled = styled(Login)`
    & {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
    }

    .password-screen {
        width: 100%;
    }

    .send-password-screen {
        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex;
    }

    .mail {
        width: 190px;
        height: 190px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        border-radius: 100px;
        justify-content: center;

        > svg {
            width: 60px;
            height: 48px;
        }
    }
    
`