import styled from "styled-components";

const AddCreditCard: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export const AddCreditCardStyled = styled(AddCreditCard)`
  form {
    margin-top: 32px;
    padding: 0 !important;
  }
`;
