import ButtonGr1d from "components/portal/button";
import InputGr1d from "components/portal/input";
import PaginationGr1d from "components/portal/pagination";
import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommentApiStyled } from "./comment.styled";
import Icon, { ExpandAltOutlined } from '@ant-design/icons';
import { ReactComponent as arrow } from "assets/svg/navegation/arrow/left.svg";
import { ReactComponent as dots } from "assets/svg/api/dot-comment.svg";
import { useForm } from "react-hook-form";
import { Collapse, Spin } from "antd";
import RowGr1d from "components/portal/grid/row";
import ColGr1d from "components/portal/grid/col";
import { useAction } from "store";
import { portalActions } from "store/portal/action";
import { CommentState } from "store/portal/reducer";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';
import { CommentModel } from "models/comment";
import { getDateStr } from "utils/utils";
import { AutoSizer, List } from "react-virtualized";
import Linkify from "react-linkify";
import { auth } from "utils/auth";
import { match } from "react-router-dom";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import FormAdmin from "components/admin/form";
import { appActions } from "store/app/action";

type PropsAnswers = {
    apiId: number,
    id: number,
    avatar: JSX.Element,
    name: string,
    time: string,
    description: string,
    borderBottom?: boolean,
    search: string,
    repliesCount: number,
    answers: number[],
    comment: CommentModel,
    setSelectComment: (value: CommentModel | undefined) => void,
    isExpand: boolean
}

type Props = {
    match: match,
}

const CommentApi: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const actions = useAction(portalActions());
    const appAction = useAction(appActions());
    const searchRef = useRef<string>('');
    const [search, setSearch] = useState<string>('');
    const commentState: CommentState = useSelector((state: any) => state.portal.comment);
    const screen: number = useSelector((state: any) => state.portal.comment.screen);
    const loadingSend: boolean = useSelector((state: any) => state.portal.comment.loadingSend);
    const [selectComment, setSelectComment] = useState<CommentModel | undefined>();
    const [isAuthenticated] = useState(auth.isAuthenticated());

    const form = useForm();
    const formReplies = useForm();
    const { handleSubmit } = form;

    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        const newSearch = search.trim() === '' ? undefined : search;
        dispatch(actions.getApiComment((props.match.params as any).id, page, 6, newSearch));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, actions, page, search])

    useEffect(() => {
        if (selectComment != null)
            dispatch(actions.changeCommentScreen(2));
        else
            dispatch(actions.changeCommentScreen(0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, actions, selectComment])

    const sendComment = (data: any) => {
        if (isAuthenticated)
            dispatch(actions.postApiComment((props.match.params as any).id, data.comment));
    }

    const sendAnwers = (data: any) => {
        if (isAuthenticated)
            dispatch(actions.postApiComment((props.match.params as any).id, data.answer, selectComment?.id, formReplies));
    }

    const enterSearch = (event: any) => {
        if ((searchRef.current != null && searchRef.current.trim() !== '') && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
            searchRef.current = event.target.value;
            setSearch(event.target.value)
        } else
            searchRef.current = event.target.value;
    }

    const formConfigAnswer: ConfigFormItem[] = [
        {
            id: 'answer',
            name: 'answer',
            typeComponent: 'textarea',
            label: 'Responder comentário',
            placeholder: 'Escreva aqui...',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        }
    ]

    const formConfigComment: ConfigFormItem[] = [
        {
            id: 'comment',
            name: 'comment',
            typeComponent: 'textarea',
            label: 'Adicione seu comentário',
            placeholder: 'Escreva aqui...',
            rules: { required: { value: true, message: 'Campo obrigatório' } }
        }
    ]

    const writeComment = () => {
        if (auth.isAuthenticated()) {
            dispatch(actions.changeCommentScreen(1))
        } else {
            dispatch(appAction.goTo('/login', { type: 'ActionApi', path: props.match.url }));
        }
    }

    return <CommentApiStyled>
        {screen === 0 && <>
            <Spin spinning={commentState.loading}>
                <div>
                    <div className={`title-comment ${grid}`}>
                        <TypographyGr1d style={{ marginBottom: 40 }} component='headingSM' color='colorPrimitiveBlack'>
                            {(commentState.item?.meta.totalItems !== 1 ? 'Comentários' : 'Comentário') + ` (${commentState.item?.meta.totalItems ?? '0'})`}
                            <InputGr1d onKeyDown={(v) => enterSearch(v)} style={{ maxWidth: 500, marginTop: 24 }}
                                suffix={<SearchOutlined style={{ cursor: 'pointer', zIndex: 2 }} onClick={() => enterSearch('Enter')} className='search' />}
                                placeholder='Pesquise comentários' size='40px' />
                        </TypographyGr1d>

                        <div className='btn'>
                            <div className='dots-comment'>
                                <Icon component={dots} />
                            </div>
                            <ButtonGr1d onClick={() => writeComment()} type='brand'>
                                Escrever novo comentário
                            </ButtonGr1d>
                        </div>
                    </div>
                    {commentState.item?.items.map(x => <Comment isExpand={false} answers={x.filteredReplies} repliesCount={x.replies} apiId={(props.match.params as any).id} id={x.id} search={search}
                        setSelectComment={setSelectComment} comment={x}
                        avatar={<div className='logo-user-comment'>
                            <img src={`https://ui-avatars.com/api/?name=${x?.userId}&background=random&rounded=true`} alt="User" width="35px" />
                        </div>} name={x.userId}
                        time={getDateStr(x.createdAt)} borderBottom={true} description={x.value} />
                    )}
                    {(commentState.item?.meta.totalItems == null || commentState.item!.meta.totalItems < 2) && <div style={{ height: 120 }}></div>}
                </div>
            </Spin>
            {commentState.item !== undefined && commentState.item?.meta.totalItems > 6 && <PaginationGr1d style={{ marginTop: 16, paddingBottom: 96 }} onChange={(v) => setPage(v)} position='left' pageSize={6} total={commentState.item?.meta.totalItems} />}
        </>}
        {screen === 1 &&
            <div style={{ position: 'relative' }}>
                <div className='dots-replies'>
                    <Icon component={dots} />
                </div>
                <div style={{ width: grid !== 'sm' ? '50%' : '100%', paddingBottom: 96 }}>
                    <ButtonGr1d type='primitive' style={{ paddingTop: 48, marginBottom: 32 }} onClick={() => dispatch(actions.changeCommentScreen(0))}>
                        <Icon component={arrow} />&nbsp;&nbsp;Voltar
                    </ButtonGr1d>
                    <div style={{ display: isAuthenticated ? 'block' : 'none' }}>
                        <FormAdmin onSubmit={handleSubmit(sendComment)} padding='30px 0px 0px 0px'>
                            <FormItensAdmin form={form} config={formConfigComment} />
                            <div className='btn-comment'>
                                <ButtonGr1d loading={loadingSend} htmlType='submit' type='brand'>
                                    Enviar
                                </ButtonGr1d>
                            </div>
                        </FormAdmin>
                        {/* <Form style={{ width: '100%' }} onSubmitCapture={handleSubmit(sendComment)}>
                            <InputGr1d form={{ form: form, name: 'comment', validation: { isRequired: true } }} className='add-comment' label='Adicione seu comentário' placeholder='Escreva aqui...' type='text-area' />
                            <div className='btn-comment'>
                                <ButtonGr1d loading={loadingSend} htmlType='submit' type='brand'>
                                    Enviar
                                </ButtonGr1d>
                            </div>
                        </Form> */}
                    </div>
                </div>
            </div>}
        {screen === 2 &&
            <div style={{ paddingBottom: 96, position: 'relative' }}>
                <div className='dots-replies'>
                    <Icon component={dots} />
                </div>
                <ButtonGr1d type='primitive' onClick={() => {
                    const newSearch = search.trim() === '' ? undefined : search;
                    dispatch(actions.getApiComment((props.match.params as any).id, page, 6, newSearch));
                    dispatch(actions.changeCommentScreen(0));
                }} style={{ paddingTop: 48, marginBottom: 32 }}>
                    <Icon component={arrow} />&nbsp;&nbsp;Voltar
                </ButtonGr1d>
                {selectComment != null && <Comment isExpand={true} answers={[]} repliesCount={selectComment.replies} apiId={(props.match.params as any).id} id={selectComment.id} search={search}
                    setSelectComment={setSelectComment} comment={selectComment}
                    avatar={<div className='logo-user-comment'>
                        <img src={`https://ui-avatars.com/api/?name=${selectComment.userId}&background=EDEEF2&color=404554&rounded=true`} alt="User" width="35px" />
                    </div>} name={selectComment.userId}
                    time={getDateStr(selectComment.createdAt)} borderBottom={true} description={selectComment.value} />}
                <div style={{ display: isAuthenticated ? 'block' : 'none' }}>
                    <FormAdmin onSubmit={formReplies.handleSubmit(sendAnwers)} padding='30px 0px 0px 0px'>
                        <FormItensAdmin form={formReplies} config={formConfigAnswer} />
                        <div className='btn-comment'>
                            <ButtonGr1d loading={loadingSend} htmlType='submit' type='brand'>
                                Enviar
                            </ButtonGr1d>
                        </div>
                    </FormAdmin>
                </div>
                {/* <Form style={{ width: '100%', marginTop: 16 }} onSubmitCapture={handleSubmit(sendAnwers)}>
                    <InputGr1d form={{ form: form, name: 'answer', validation: { isRequired: true } }} className='add-comment'
                        label='Responder comentário' placeholder='Escreva aqui...' type='text-area' />
                    <div className='btn-comment'>
                        <ButtonGr1d onClick={() => { form.setValue('answer', '') }} htmlType='reset' type='brand'>
                            Reset
                        </ButtonGr1d>
                        <ButtonGr1d htmlType='submit' type='brand'>
                            Enviar
                        </ButtonGr1d>
                    </div>
                </Form> */}
            </div>}
        <ColGr1d sm={12} md={6}>
            <div style={{ paddingRight: 12 }} id='comment-text' className='captionBase' />
        </ColGr1d>
    </CommentApiStyled >;
}

const Comment: React.FC<PropsAnswers> = (props) => {
    const dispatch = useDispatch();
    const actions = useAction(portalActions());
    const listRef = useRef<any>();
    const [collapse, setCollapse] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [height, setHeight] = useState<number>();
    const loadingState: number[] = useSelector((state: any) => state.portal.comment.loadingAnswers);
    const answersState: CommentModel[] | undefined = useSelector((state: any) => state.portal.comment?.answers[props.id]);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    useEffect(() => {
        if (props.isExpand || props.answers?.length > 0) {
            dispatch(actions.getApiAnswer(props.apiId, props.id));
            setCollapse(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isExpand, dispatch, actions])

    useEffect(() => {
        if (loadingState.includes(props.id))
            setLoading(true);
        else
            setLoading(false);
    }, [loadingState, props.id])

    useEffect(() => {
        if (answersState != null) {
            if (answersState.length < 5)
                setHeight(answersState.length * 100);
            else
                setHeight(500);
        }
    }, [answersState])

    useEffect(() => {
        if (!props.isExpand) {
            if (!loading && props.repliesCount > 0 && (answersState == null || answersState?.length === 0))
                dispatch(actions.getApiAnswer(props.apiId, props.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse])

    const rowRenderer = ({ index, key, style }: any) => {
        if (answersState != null) {
            const row = answersState[index];

            return (
                <div style={style} key={index} className={`comment anwers ${index !== props.answers?.length - 1 ? 'border-comment' : ''}`}>
                    <div>
                        <div className={`avatar-comment logo-user-comment ${grid}`}>
                            <img src={`https://ui-avatars.com/api/?name=${row.userId}&background=random&rounded=true`} alt="User" width="35px" />
                        </div>
                        <div className='description-comment'>
                            <TypographyGr1d style={{ marginBottom: 12, paddingRight: 12, position: 'relative' }} component='caption' color='colorPrimitive600'>
                                <Linkify>
                                    <div className='linkfy'>
                                        {row.value}
                                    </div>
                                </Linkify>
                                <Highlighter textToHighlight={row.value} searchWords={[props.search]} />
                            </TypographyGr1d>
                            <div className='info-comment'>
                                <TypographyGr1d component='caption' color='colorPrimitive600'>
                                    {row.userId}
                                </TypographyGr1d>
                                <TypographyGr1d component='caption' color='colorPrimitive600'>
                                    {getDateStr(row.createdAt)}
                                </TypographyGr1d>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const rowHeight = ({ index, key, style }: any) => {
        const row = answersState![index];

        var test = document.getElementById("comment-text")!;
        test.innerHTML = row.value;
        var height = (test.clientHeight) + 75;

        return height;
    };

    const resize = () => {
        listRef.current?.recomputeRowHeights()
    }

    return <RowGr1d>
        <ColGr1d sm={12} md={6}>
            <div className={`comment ${props.borderBottom === true ? 'border-comment' : ''}`}>
                <div>
                    <div className={`avatar-comment ${grid}`}>
                        {props.avatar}
                    </div>
                    <div className='description-comment'>
                        <TypographyGr1d style={{ marginBottom: 12, paddingRight: 12, position: 'relative' }} component='caption' color='colorPrimitive600'>
                            <Linkify>
                                <div className='linkfy'>
                                    {props.description}
                                </div>
                            </Linkify>
                            <Highlighter textToHighlight={props.description} searchWords={[props.search]} />
                        </TypographyGr1d>
                        <div className='info-comment'>
                            <TypographyGr1d component='caption' color='colorPrimitive600'>
                                {props.name}
                            </TypographyGr1d>
                            <div className='bar' />
                            <div onClick={() => {
                                if (!props.isExpand)
                                    setCollapse(x => !x)
                            }}>
                                <TypographyGr1d className='answer' component='caption' color='colorBrandPrimary500'>
                                    <Spin spinning={loading}>
                                        {!props.isExpand ? `${props.repliesCount} ${props.repliesCount !== 1 ? 'Respostas' : 'Resposta'}` :
                                            `${answersState?.length} ${answersState?.length !== 1 ? 'Respostas' : 'Resposta'}`}
                                    </Spin>
                                </TypographyGr1d>
                            </div>
                            <div className='bar' />
                            <TypographyGr1d component='caption' color='colorPrimitive600'>
                                {props.time}
                            </TypographyGr1d>
                            {!props.isExpand && <>
                                <div className='bar' />
                                <div onClick={() => props.setSelectComment(props.comment)}>
                                    <TypographyGr1d className='answer' component='caption' color='colorPrimitive600'>
                                        Expandir&nbsp;&nbsp;<ExpandAltOutlined />
                                    </TypographyGr1d>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            {(answersState != null && answersState.length > 0) &&
                <Collapse className='no-header' activeKey={[collapse ? '1' : '']}>
                    <Collapse.Panel key='1' header=''>
                        <div style={{ flex: '1 1 auto', height: height }}>
                            <AutoSizer onResize={resize}>
                                {({ width, height }) => (
                                    <List
                                        height={height}
                                        width={width}
                                        ref={listRef}
                                        rowCount={answersState?.length ?? 0}
                                        rowHeight={rowHeight}
                                        rowRenderer={rowRenderer}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            }
        </ColGr1d>
    </RowGr1d >;
}

export default CommentApi;