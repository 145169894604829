import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const PaymentCreditCard = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const PaymentCreditCardStyled = styled(PaymentCreditCard)`
    .header {
        padding: 32px 32px 0px 32px;
    }

    .credit-div {
        display: flex;
        flex-direction: column;
        padding: 24px 24px 0px 24px !important;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 40px;
    }

    .hr-account {
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .info {
        display: flex;
        justify-content: center;
        margin-top: 26px;
        margin-bottom: 40px;
        padding: 18px 34px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    }

    .info-icon {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        font-size: 20px;
    }

    .rccs__name {
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
        font-size: ${(props) => (props.theme as IGlobalStyle).fontSizeXXS};
        font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightMedium};
        left: 24px;
        width: 65%;
    }

    .rccs {
        width: 315px;
    }

    .rccs__card {
        width: 315px;
        height: 200px;
    }

    .rccs__number {
        left: 24px;
    }

    .rccs__expiry {
        bottom: 24px;
        right: 24px;
    }

    .rccs__chip {
        top: 24px;
        left: 24px;
    }

    .rccs__signature {
        width: 266px;
        left: 24px;
    }
`