import { ApiModel, ApiPaginationModel, ApiRegisterModel } from "models/api";
import { CategoryModel } from "models/category";
import { MemberModel } from "models/member";
import { appActions } from "store/app/action";
import { createAdminApiService } from "./service";

export enum AdminApiActionType {
    GET_APIS = '[ADMIN_API] Get Apis',
    GET_APIS_SUCCESS = '[ADMIN_API] Get Apis Success',
    GET_APIS_ERROR = '[ADMIN_API] Get Apis Error',
    GET_API = '[ADMIN_API] Get Api',
    GET_API_SUCCESS = '[ADMIN_API] Get Api Success',
    GET_API_ERROR = '[ADMIN_API] Get Api Error',
    GET_MEMBERS = '[ADMIN_API] Get Members',
    GET_MEMBERS_SUCCESS = '[ADMIN_API] Get Members Success',
    GET_MEMBERS_ERROR = '[ADMIN_API] Get Members Error',
    GET_CATEGORIES = '[ADMIN_API] Get Category',
    GET_CATEGORIES_SUCCESS = '[ADMIN_API] Get Category Success',
    GET_CATEGORIES_ERROR = '[ADMIN_API] Get Category Error',
    CLEAR_API = '[ADMIN_API] Clear Api',
    POST_REGISTER_API = '[ADMIN_API] Post register api',
    POST_REGISTER_API_SUCCESS = '[ADMIN_API] Post register api Success',
    POST_REGISTER_API_ERROR = '[ADMIN_API] Post register api Error',
}

export interface adminApiAction {
    getApis(keyword: string, offset: number): void,
    getCategories(): void,
    getMembers(): void,
    postRegister(api: ApiRegisterModel): void,
    getApi(id: number): void,
    clearApi(): void
}

export const adminApiActions = ((): adminApiAction => {
    const portalService = createAdminApiService();
    const appAction = appActions();

    const actions = {
        getApis: (): any => {
            return { type: AdminApiActionType.GET_APIS }
        },
        getApisSuccess: (data: ApiPaginationModel): any => {
            return { type: AdminApiActionType.GET_APIS_SUCCESS, payload: data }
        },
        getApisError: (): any => {
            return { type: AdminApiActionType.GET_APIS_ERROR }
        },
        getApi: (): any => {
            return { type: AdminApiActionType.GET_API }
        },
        getApiSuccess: (data: ApiModel): any => {
            return { type: AdminApiActionType.GET_API_SUCCESS, payload: data }
        },
        getApiError: (): any => {
            return { type: AdminApiActionType.GET_API_ERROR }
        },
        getCategories: (): any => {
            return { type: AdminApiActionType.GET_CATEGORIES }
        },
        getCategoriesSuccess: (data: CategoryModel[]): any => {
            return { type: AdminApiActionType.GET_CATEGORIES_SUCCESS, payload: data }
        },
        getCategoriesError: (): any => {
            return { type: AdminApiActionType.GET_CATEGORIES_ERROR }
        },
        getMembers: (): any => {
            return { type: AdminApiActionType.GET_MEMBERS }
        },
        getMembersSuccess: (data: MemberModel[]): any => {
            return { type: AdminApiActionType.GET_MEMBERS_SUCCESS, payload: data }
        },
        getMembersError: (): any => {
            return { type: AdminApiActionType.GET_MEMBERS_ERROR }
        },
        clearApi: (): any => {
            return { type: AdminApiActionType.CLEAR_API }
        },
        postRegister: (): any => {
            return { type: AdminApiActionType.POST_REGISTER_API }
        },
        postRegisterSuccess: (data: ApiModel): any => {
            return { type: AdminApiActionType.POST_REGISTER_API_SUCCESS, payload: data }
        },
        postRegisterError: (): any => {
            return { type: AdminApiActionType.POST_REGISTER_API_ERROR }
        },
    }

    function getApis(keyword: string, offset: number) {
        return (dispath: any) => {
            dispath(actions.getApis());

            portalService.getApis(offset).then(x => {
                dispath(actions.getApisSuccess(x));
            }).catch(x => {
                dispath(actions.getApisError());
            })
        }
    }

    function getApi(id: number) {
        return (dispath: any) => {
            dispath(actions.getApi());

            portalService.getApi(id).then(x => {
                dispath(actions.getApiSuccess(x));
                setTimeout(() => {
                    dispath(appAction.goTo('/admin/api/form'));
                }, 0);
            }).catch(x => {
                dispath(actions.getApiError());
            })
        }
    }

    function getCategories() {
        return (dispath: any) => {
            dispath(actions.getCategories());

            portalService.getCategories().then(x => {
                dispath(actions.getCategoriesSuccess(x));
            }).catch(x => {
                dispath(actions.getCategoriesError());
            })
        }
    }

    function getMembers() {
        return (dispath: any) => {
            dispath(actions.getMembers());

            portalService.getMembers().then(x => {
                dispath(actions.getMembersSuccess(x));
            }).catch(x => {
                dispath(actions.getMembersError());
            })
        }
    }

    function postRegister(api: ApiRegisterModel) {
        return (dispath: any) => {
            dispath(actions.postRegister());

            portalService.postRegister(api).then(x => {
                dispath(actions.postRegisterSuccess(x));
                dispath(appAction.goTo('/admin/api/form'));
            }).catch(x => {
                dispath(actions.postRegisterError());
            })
        }
    }

    function clearApi() {
        return (dispath: any) => {
            dispath(actions.clearApi());
        }
    }

    return { getApis, postRegister, getApi, clearApi, getCategories, getMembers }
})