import _ from "lodash";
import { ApiModel } from "models/api";
import { ApiKeyModel } from "models/apiKey";
import { ApiSubscriptionModel } from "models/apiSubscription";
import { createApiConsumedService } from "store/admin/apiConsumed/service";
import { appActions } from "store/app/action";
import { createPortalService } from "store/portal/service";

export enum AdminApiConsumedActionType {
    GET_API = '[ADMIN_API_CONSUMED] Get Api',
    GET_API_SUCCESS = '[ADMIN_API_CONSUMED] Get Api Success',
    GET_API_ERROR = '[ADMIN_API_CONSUMED] Get Api Error',
    GET_API_ID = '[ADMIN_API_ID_CONSUMED] Get Api',
    GET_API_ID_SUCCESS = '[ADMIN_API_ID_CONSUMED] Get Api Success',
    GET_API_ID_ERROR = '[ADMIN_API_ID_CONSUMED] Get Api Error',
    GET_API_SWAGGER = '[ADIMN_API_CONSUMED] Get Api Swagger',
    GET_API_SWAGGER_SUCCESS = '[ADIMN_API_CONSUMED] Get Api Swagger Success',
    GET_API_SWAGGER_ERROR = '[ADIMN_API_CONSUMED] Get Api Swagger Error',
    GET_API_KEY = '[ADMIN_API_CONSUMED] Get Api Key',
    GET_API_KEY_SUCCESS = '[ADMIN_API_CONSUMED] Get Api Key Success',
    GET_API_KEY_ERROR = '[ADMIN_API_CONSUMED] Get Api Key Error',
    DELETE_API_KEY = '[ADMIN_API_CONSUMED] Delete Api Key',
    DELETE_API_KEY_SUCCESS = '[ADMIN_API_CONSUMED] Delete Api Key Success',
    DELETE_API_KEY_ERROR = '[ADMIN_API_CONSUMED] Delete Api Key Error',
    DELETE_SWAGGER = '[ADMIN_API_CONSUMED] Delete Swagger',
}

export interface adminApiAction {
    getApi(id: string): void,
    getApiId(id: number, isToFetchSwagger?: boolean): void,
    getApiKey(apiId: number, type: 'SANDBOX' | 'PRODUCTION'): void,
    deleteApiKey(apiId: number, type: 'SANDBOX' | 'PRODUCTION'): void,
    getApiSwagger(apiId: number): void;
    deleteSwagger(): void;
}

export const adminApiConsumedActions = ((): adminApiAction => {
    const consumedService = createApiConsumedService();
    const portalService = createPortalService();
    const appAction = appActions();

    const actions = {
        getApi: (): any => {
            return { type: AdminApiConsumedActionType.GET_API }
        },
        getApiSuccess: (data: ApiSubscriptionModel[]): any => {
            return { type: AdminApiConsumedActionType.GET_API_SUCCESS, payload: data }
        },
        getApiError: (): any => {
            return { type: AdminApiConsumedActionType.GET_API_ERROR }
        },
        getApiId: (): any => {
            return { type: AdminApiConsumedActionType.GET_API_ID }
        },
        getApiIdSuccess: (data: ApiModel): any => {
            return { type: AdminApiConsumedActionType.GET_API_ID_SUCCESS, payload: data }
        },
        getApiIdError: (): any => {
            return { type: AdminApiConsumedActionType.GET_API_ID_ERROR }
        },
        getApiSwagger: (): any => {
            return { type: AdminApiConsumedActionType.GET_API_SWAGGER }
        },
        getApiSwaggerSuccess: (swagger: string): any => {
            return { type: AdminApiConsumedActionType.GET_API_SWAGGER_SUCCESS, payload: swagger }
        },
        getApiSwaggerError: (): any => {
            return { type: AdminApiConsumedActionType.GET_API_SWAGGER_ERROR }
        },
        getApiKey: (type: string): any => {
            return { type: AdminApiConsumedActionType.GET_API_KEY, payload: type }
        },
        getApiKeySuccess: (data: ApiKeyModel): any => {
            return { type: AdminApiConsumedActionType.GET_API_KEY_SUCCESS, payload: data }
        },
        getApiKeyError: (type: string): any => {
            return { type: AdminApiConsumedActionType.GET_API_KEY_ERROR, payload: type }
        },
        deleteApiKey: (type: string): any => {
            return { type: AdminApiConsumedActionType.DELETE_API_KEY, payload: type }
        },
        deleteApiKeySuccess: (type: string): any => {
            return { type: AdminApiConsumedActionType.DELETE_API_KEY_SUCCESS, payload: type }
        },
        deleteApiKeyError: (): any => {
            return { type: AdminApiConsumedActionType.DELETE_API_KEY_ERROR }
        },
        deleteSwagger: (): any => {
            return { type: AdminApiConsumedActionType.DELETE_SWAGGER }
        },
    }

    function getApi(userId: string) {
        return (dispatch: any) => {
            dispatch(actions.getApi());

            consumedService.getApiSubscriptions(userId).then(x => {
                const newApisConsumed = _.map(_.groupBy(_.orderBy(x, ['dateSubscription'], 'desc'), x => x.apiId.id),
                    (group: ApiSubscriptionModel[]) => {
                        return _.orderBy(group, 'type', 'asc')[0];
                    });
                dispatch(actions.getApiSuccess(newApisConsumed));
            }).catch(x => {
                dispatch(actions.getApiError());
            })
        }
    }

    function getApiId(id: number, isToFetchSwagger: boolean = true) {
        return async (dispatch: any) => {
            dispatch(actions.getApiId());

            try {
                const api = await portalService.getApi(id)
                if (isToFetchSwagger) {
                    const definition = await portalService.getApiDefinition(id)
                    api.definitionFile = JSON.stringify(definition)
                }
                dispatch(actions.getApiIdSuccess(api));
            } catch (e) {
                dispatch(actions.getApiIdError());
            }
        }
    }

    function getApiKey(apiId: number, type: string) {
        return (dispath: any) => {
            dispath(actions.getApiKey(type));
            portalService.getApiKey(apiId, type).then(apiKey => {
                dispath(actions.getApiKeySuccess(apiKey));
            }).catch(x => {
                dispath(actions.getApiKeyError(type));
            })
        }
    }

    function deleteApiKey(apiId: number, type: string) {
        return (dispath: any, getState: any) => {
            const state = getState();
            dispath(actions.deleteApiKey(type));
            consumedService.deleteApiKey(apiId, type).then(apiKey => {
                dispath(actions.deleteApiKeySuccess(type));
                if (type === 'SANDBOX')
                    if (state.adminApiConsumed?.apiKeyProd?.item?.apiKey == null) {
                        dispath(appAction.goTo('/admin/api/consumed'));
                    }
            }).catch(x => {
                dispath(actions.deleteApiKeyError());
            })
        }
    }

    function getApiSwagger(apiId: number) {
        return async (dispatch: any) => {
            try {
                dispatch(actions.getApiSwagger())
                await portalService.getApiDefinition(apiId).then((definition) => {
                    dispatch(actions.getApiSwaggerSuccess(definition))
                })
            } catch (e) {
                dispatch(actions.getApiSwaggerError())
            }
        }
    }

    function deleteSwagger() {
        return async (dispatch: any) => {
            dispatch(actions.deleteSwagger());
        }
    }

    return { getApi, getApiKey, getApiId, deleteApiKey, getApiSwagger, deleteSwagger }
})