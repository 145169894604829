import { combineReducers } from "redux";

type ReducerManager = {
    add(key: string, reducer: any): void,
    remove(key: any): void,
    reduce(state: any, action: any): any
}
export const createReducerManager = (initialReducers: any): ReducerManager => {
    let reducers = { ...initialReducers };
    let combinedReducer = combineReducers(reducers);

    const reduce = (state: any, action: any) => {
        return combinedReducer(state, action as never);
    };

    const add = <T extends any>(key: string, reducer: (state: T, action: any) => T) => {
        if (!key) {
            return
        }

        reducers[key] = reducer;
        combineReducers(reducers);

        combinedReducer = combineReducers(reducers)
    }

    const remove = (key: any) => {
        if (!key || !reducers[key]) {
            return
        }
        delete reducers[key]
        combinedReducer = combineReducers(reducers)
    };

    return { reduce, add, remove }
}
