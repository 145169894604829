export enum NavigatorActionType {
    CHANGE_ROUTE = '[NAVIGATOR] Change Route',
}

export interface navigatorAction {
    changeRoute(params: { path: string }): void,
}

export const navigatorActions = ((): navigatorAction => {
    const actions = {
        changeRoute: (path: string): any => {
            return { type: NavigatorActionType.CHANGE_ROUTE, payload: { path } }
        }
    }

    function changeRoute(params: { path: string }) {
        return (dispath: any) => {
            dispath(actions.changeRoute(params.path));
        }
    }

    return { changeRoute }
})