import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"
import dots from "assets/svg/logon/pattern-dots.svg"

const Register = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const RegisterStyled = styled(Register)`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
    
    .content-white.sm {
        padding: 30px 20px;
    }

    .content-white {
        position: relative;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        max-width: 730px;
        width: 100%;
        height: 100vh;
        padding: 30px 50px;
        margin: auto 0;
        z-index: 1;
    }
    
    .border-top {
        position: absolute;
        background: linear-gradient(45deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} 50%);
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
    }

    .border-bottom {
        background: linear-gradient(135deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500} 50%);
        right: 0;
        bottom: 0;
        width: 32px;
        height: 32px;
        position: absolute;
    }

    .content {
        display: flex;
        align-items: center;
    }

    .welcome {
        margin-bottom: 24px;
    }

    .login {
        width: 90%;
    }

    .divider {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
    }

    .login-social {
        display: flex;
        justify-content: center;
        gap: 16px;

        img {
            cursor: pointer;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            padding: 10px;
            border-radius: 100px;
        }

        img:hover {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200};
        }
    }

    .without-account {
        display: flex;
        margin-top: 24px;

        > .txt {
            flex: 1;
        }
    }

    .use-term {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        cursor: pointer;
    }

    & > .dots {
        background-repeat: repeat;
        background-image: url(${dots});
        height: 100%;
        width: 100%;
        position:absolute;
    }

    .link-register {
        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        cursor: pointer;
        z-index: 1;
    }

    .check-inline {
        display: flex;
        margin-bottom: 24px;
        z-index: 10;
        
        > div {
            margin-left: 10px;
        }
    }

    .all-screen {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
    }

    .logo {
        width: 110px;
        height: 20px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    
    .content {
        flex: 1;
    }
`