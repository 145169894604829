import styled from "styled-components"

const PurchaseReviewStyled: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(PurchaseReviewStyled)`
  .progress {
    padding-bottom: 32px;
    display: flex;
    .ProgressText {
      margin-left: 8px;
    }
  }
  .buttons {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
  }

  .btn-general {
      display: flex;
  }
`