import styled from "styled-components";
import { IGlobalStyle } from "theme/global.styles";

const CreditCard: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export const CreditCardStyled = styled(CreditCard)`
.paymentAlert {
  margin-top: 16px;
  display: flex;
  border-radius: 8px;
  padding: 16px;
  background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
  .alerticon {
    padding-right: 16px;
    img {
      width: 16px;
    }
  }
}
.addCard {
  cursor: pointer;
  align-items: center;
  padding: 8px 20px;
  margin-top: 32px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  display: flex;
  .ant-radio-inner::after {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
  }
}

.showCard {
  align-items: center;
  padding: 8px 20px;
  margin-top: 32px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
  display: flex;
  .twoCollums {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
`;
