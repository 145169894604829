import { ApiModel } from "models/api";
import { ApiBusinessModel } from "models/apiBusinessModel";
import { ApiKeyModel } from "models/apiKey";
import { CategoryModel } from "models/category";
import { CommentModel } from "models/comment";
import { HomePageModel } from "models/homePage";
import { PaginationModel } from "models/pagination";
import { PolicyModel } from "models/policy";
import { ApiPriceModel } from "models/prepaid";
import { UseCaseModel } from "models/useCase";
import { PortalActionType } from "./action";

export interface PortalState {
    homePage: HomePageState;
    category: CategoryState;
    api: ApiState;
    useCase: UseCaseState;
    policies: PoliciesState;
    comment: CommentState;
    apiKeyProd: ApiKeyState;
    apiKeySandbox: ApiKeyState;
    apiPrice: ApiPriceState;
    apiToActivate: ApiModel | null;
    prepaid?: ApiPriceModel
}

export interface HomePageState {
    loading: boolean;
    list: HomePageModel[];
}

export interface CategoryState {
    loading: boolean;
    list?: CategoryModel[];
}

export interface ApiState {
    loading: boolean;
    list?: ApiModel[];
    item?: ApiModel;
    total?: number;
    relatedAPIs?: ApiModel[]
}

export interface UseCaseState {
    loading: boolean;
    list?: UseCaseModel[];
    item?: UseCaseModel;
}

export interface ApiKeyState {
    loading: boolean;
    loadingSave: boolean;
    item?: ApiKeyModel;
    modal: boolean;
}

export interface CommentState {
    loading: boolean;
    item?: PaginationModel<CommentModel>,
    answers: { [id: number]: CommentModel[] },
    loadingAnswers: number[];
    loadingSend: boolean;
    screen: number;
}

export interface PoliciesState {
    loading: boolean;
    policies?: PolicyModel[];
}

export interface ApiPriceState {
    loading: boolean;
    item?: ApiBusinessModel;
}

export const INITIAL_STATE = {
    homePage: { loading: false, list: [] }, category: { loading: false, item: undefined }, api: { loading: false },
    useCase: { loading: false }, policies: { loading: false }, comment: { loading: false, loadingAnswers: [], answers: {}, loadingSend: false, screen: 0 },
    apiKeyProd: { loading: false, loadingSave: false, modal: false }, apiKeySandbox: { loading: false, loadingSave: false, modal: false },
    apiPrice: { loading: false }, apiToActivate: null
}
export function portalReducer(state: PortalState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case PortalActionType.GET_HOME:
            state = { ...state, homePage: { ...state.homePage, loading: true } }
            break;
        case PortalActionType.GET_HOME_SUCCESS:
            state = { ...state, homePage: { loading: false, list: action.payload } }
            break;
        case PortalActionType.GET_HOME_ERROR:
            state = { ...state, homePage: { ...state.homePage, loading: false } }
            break;
        case PortalActionType.GET_ALL_CATEGORY:
            state = { ...state, category: { ...state.category, loading: true } }
            break;
        case PortalActionType.GET_ALL_CATEGORY_SUCCESS:
            state = { ...state, category: { loading: false, list: action.payload } }
            break;
        case PortalActionType.GET_ALL_CATEGORY_ERROR:
            state = { ...state, category: { ...state.category, loading: false } }
            break;
        case PortalActionType.GET_APIS:
            state = { ...state, api: { ...state.api, loading: true, list: [] } }
            break;
        case PortalActionType.GET_APIS_SUCCESS:
            state = { ...state, api: { ...state.api, loading: false, list: action.payload.items, total: action.payload.meta.totalItems } }
            break;
        case PortalActionType.GET_APIS_ERROR:
            state = { ...state, api: { ...state.api, loading: false } }
            break;
        case PortalActionType.GET_RELATED_API:
            state = { ...state, api: { ...state.api, relatedAPIs: [] } }
            break;
        case PortalActionType.GET_RELATED_API_SUCCESS:
            state = { ...state, api: { ...state.api, relatedAPIs: action.payload } }
            break;
        case PortalActionType.GET_RELATED_API_ERROR:
            state = { ...state, api: { ...state.api, relatedAPIs: [] } }
            break;
        case PortalActionType.GET_USE_CASES:
            state = { ...state, useCase: { ...state.useCase, loading: true } }
            break;
        case PortalActionType.GET_USE_CASES_SUCCESS:
            state = { ...state, useCase: { loading: false, list: action.payload } }
            break;
        case PortalActionType.GET_USE_CASES_ERROR:
            state = { ...state, useCase: { ...state.useCase, loading: false } }
            break;
        case PortalActionType.GET_USE_CASE:
            state = { ...state, useCase: { ...state.useCase, loading: true, item: undefined } }
            break;
        case PortalActionType.GET_USE_CASE_SUCCESS:
            state = { ...state, useCase: { loading: false, item: action.payload } }
            break;
        case PortalActionType.GET_USE_CASE_ERROR:
            state = { ...state, useCase: { ...state.useCase, loading: false } }
            break;
        case PortalActionType.GET_API:
            state = { ...state, api: { ...state.api, loading: true, relatedAPIs: [] } }
            break;
        case PortalActionType.GET_API_SUCCESS:
            state = { ...state, api: { ...state.api, loading: false, item: action.payload } }
            break;
        case PortalActionType.GET_API_ERROR:
            state = { ...state, api: { ...state.api, loading: false } }
            break
        case PortalActionType.GET_API_COMMENT:
            state = { ...state, comment: { ...state.comment, loadingAnswers: [], loading: true } }
            break;
        case PortalActionType.GET_API_COMMENT_SUCCESS:
            state = { ...state, comment: { loading: false, loadingAnswers: [], item: action.payload, answers: {}, loadingSend: state.comment.loadingSend, screen: state.comment.screen } }
            break;
        case PortalActionType.GET_API_COMMENT_ERROR:
            state = { ...state, comment: { ...state.comment, loadingAnswers: [], loading: false } }
            break;
        case PortalActionType.POST_API_COMMENT:
            state = { ...state, comment: { ...state.comment, loadingSend: true } }
            break;
        case PortalActionType.POST_API_COMMENT_SUCCESS:
            state = { ...state, comment: { ...state.comment, loadingSend: false } }
            break;
        case PortalActionType.POST_API_COMMENT_ERROR:
            state = { ...state, comment: { ...state.comment, loadingSend: false } }
            break;
        case PortalActionType.GET_API_ANSWER:
            state = { ...state, comment: { ...state.comment, item: state.comment.item, answers: state.comment.answers, loadingAnswers: [...state.comment.loadingAnswers, action.payload] } }
            break;
        case PortalActionType.GET_API_ANSWER_SUCCESS:
            const newAnswers: CommentModel[] = action.payload.list;
            let answers = { ...state.comment.answers };
            answers[action.payload.commentId] = newAnswers;
            state = { ...state, comment: { loading: false, item: state.comment.item, loadingAnswers: [...state.comment.loadingAnswers.filter(x => x !== action.payload.commentId)], answers: answers, loadingSend: state.comment.loadingSend, screen: state.comment.screen } };
            break;
        case PortalActionType.GET_API_ANSWER_ERROR:
            state = { ...state, comment: { ...state.comment, item: state.comment.item, answers: state.comment.answers, loadingAnswers: [...state.comment.loadingAnswers.filter(x => x !== action.payload)] } }
            break;
        case PortalActionType.CHANGE_COMMENT_SCREEN:
            state = { ...state, comment: { ...state.comment, screen: action.payload } }
            break;
        case PortalActionType.GET_POLICIES:
            state = { ...state, policies: { ...state.policies, loading: true } }
            break;
        case PortalActionType.GET_POLICIES_SUCCESS:
            state = { ...state, policies: { policies: action.payload, loading: false } }
            break;
        case PortalActionType.GET_POLICIES_ERROR:
            state = { ...state, policies: { ...state.policies, loading: false } }
            break;
        case PortalActionType.GET_API_KEY:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: true } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: true } }
            break;
        case PortalActionType.GET_API_KEY_SUCCESS:
            if (action.payload.type === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: false, item: action.payload } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: false, item: action.payload } }
            break;
        case PortalActionType.GET_API_KEY_ERROR:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: false, item: undefined } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: false, item: undefined } }
            break;
        case PortalActionType.POST_SUBSCRIPTION:
        case PortalActionType.POST_API_KEY:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loadingSave: true, modal: false, item: undefined } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loadingSave: true, modal: false, item: undefined } }
            break;
        case PortalActionType.POST_API_KEY_SUCCESS:
            if (action.payload.type === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loadingSave: false, item: action.payload, modal: true } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loadingSave: false, item: action.payload, modal: true } }
            break;
        case PortalActionType.POST_SUBSCRIPTION_ERROR:
        case PortalActionType.POST_API_KEY_ERROR:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loadingSave: false } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loadingSave: false } }
            break;
        case PortalActionType.REMOVE_MODAL_API:
            state = { ...state, apiKeyProd: { ...state.apiKeyProd, modal: false }, apiKeySandbox: { ...state.apiKeySandbox, modal: false } }
            break;
        case PortalActionType.GET_RELATED_API_SEARCH:
            state = { ...state, api: { ...state.api, loading: true, list: [], total: undefined } }
            break;
        case PortalActionType.GET_RELATED_API_SEARCH_SUCCESS:
            state = { ...state, api: { ...state.api, loading: false, list: action.payload.items, total: action.payload.meta.totalItems } }
            break;
        case PortalActionType.GET_RELATED_API_SEARCH_ERROR:
            state = { ...state, api: { ...state.api, loading: false } }
            break;
        case PortalActionType.GET_BUSINESS_MODEL_API:
            state = { ...state, apiPrice: { loading: true, item: undefined } }
            break;
        case PortalActionType.GET_BUSINESS_MODEL_API_SUCCESS:
            state = { ...state, apiPrice: { loading: false, item: action.payload } }
            break;
        case PortalActionType.GET_BUSINESS_MODEL_API_ERROR:
            state = { ...state, apiPrice: { ...state.apiPrice, loading: false } }
            break
        case PortalActionType.SET_API_TO_ACTIVATE:
            state = { ...state, apiToActivate: action.payload }
            break
        case PortalActionType.GET_API_PREPAID:
            state = { ...state, prepaid: undefined }
            break;
        case PortalActionType.GET_API_PREPAID_SUCCESS:
            state = { ...state, prepaid: action.payload }
            break;
        case PortalActionType.GET_API_PREPAID_ERROR:
            state = { ...state, prepaid: undefined }
            break;
    }

    return state;
}