import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const CommentApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const CommentApiStyled = styled(CommentApi)`
    .linkfy {
        position: absolute;
        padding-right: 12px;

        > a {
            z-index: 2;
        }
    }

    #comment-text {
        /* position: absolute; */
        visibility: hidden;
        height: auto;
        width: auto;
        margin-left: 112px;
        margin-right: 17px;
        font-family: ${(props) => (props.theme as IGlobalStyle).fontFamilyBase};
    }

    .comment.anwers {
        width: calc(100% - 56px) !important;
    }

    .comment {
        mark {
            background-color: #fcffc0;
            padding: 0;
        }
        > div {
            display: flex;
        }

        padding-top: 24px;
        padding-bottom: 24px;

        .avatar-comment {
            margin-right: 20px;
        }

        .description-comment {
            flex: 1;
        }

        .info-comment {
            display: flex;
            gap: 12px;
        }

        .bar {
            border-left: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        }

        .answer {
            cursor: pointer;
            display: flex;
        }
    }

    .anwers {
        margin-left: 56px;
    }

    .title-comment {
        display: flex;
        align-items: center;
        z-index: 2;
        padding-top: 48px;

        > div {
            flex: 1;
        }

        .btn {
            display: flex;
            justify-content: flex-end;
        }
    }
    
    .title-comment.sm {
        display: initial;

        .btn {
            display: initial;
        }
    }

    .comment .avatar-comment.sm {
        margin-right: 5px
    }

    .border-comment {
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .btn-comment {
        display: flex;
        margin-top: 16px;
        justify-content: flex-end;
    }

    .animation {
        position: relative;
        overflow: hidden;
    }

    .animation > div {
        position: relative;
        z-index: 1;
    }

    .no-header.ant-collapse {
        border: none;
    }

    .no-header .ant-collapse-item {
        border-bottom: none;
    }

    .no-header > .ant-collapse-item > .ant-collapse-header {
        display: none;
    }

    .no-header .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .no-header .ant-collapse-content-box {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    }

    .dots-comment {
        position: absolute;

        svg {
            width: 200px;
            height: 200px;
        }
    }

    .dots-replies {
        position: absolute;
        right: 0px;
        top: 110px;

        svg {
            width: 200px;
            height: 200px;
        }
    }

    .logo-user-comment {
        border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        border-radius: 40px;
        height: 41px;
        width: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`