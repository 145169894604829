import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiMarketplace = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminApiFormStyled = styled(AdminApiMarketplace)`

    .header-flex {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
        padding: 16px 32px 32px 32px;
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke:  ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-progress-circle-trail {
        stroke:  ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200};
    }

    .ant-tabs-nav-list {
        padding: 8px 32px;
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        width: 100%;
    }

    .invite {
        display: flex;
        align-items: center;
        padding: 16px 32px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};

        .description {
            flex: 1;
        }
    }
`