import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { ApiStyled } from "./index.styled";
import Helmet from "react-helmet";
import dots from "assets/svg/api/dots.svg";
import { Carousel, Modal, notification, Select, Space, Spin, Tabs, Tooltip } from "antd";
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import GeneralApi from "./general";
import PriceApi from "./price";
import SandboxApi from "./sandbox";
import { useDispatch, useSelector } from "react-redux";
import DocumentationApi from "./documentation";
import CommentApi from "./comment";
import { match, Route, useHistory, Switch } from "react-router-dom";
import { useAction } from "store";
import { portalActions } from "store/portal/action";
import { Skeleton } from 'antd';
import { appActions } from "store/app/action";
import { auth } from "utils/auth";
import { ApiKeyState, ApiPriceState, ApiState } from "store/portal/reducer";
import noImage from "assets/svg/no-image.svg"
import SaveApiFlag from "components/portal/saveApiFlag";
import CardApiGr1d from "components/portal/card/api";
import _ from "lodash";
import { CarouselRef } from "antd/lib/carousel";
import Icon from '@ant-design/icons';
import { ReactComponent as left } from "assets/svg/navegation/chevron/left.svg";
import { ReactComponent as right } from "assets/svg/navegation/chevron/right.svg";
import { ReactComponent as star } from "assets/svg/star.svg";
import { ApiPriceModel } from "models/prepaid";
import { UserModel } from "models/user";
import { formatCurrency } from "utils/utils";

const { TabPane } = Tabs;

type Props = {
    match: match,
}

const Api: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const action = useAction(portalActions());
    const appAction = useAction(appActions());
    const apiState: ApiState = useSelector((state: any) => state.portal?.api);
    const [tabValue, setTabValue] = useState<string>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const carouselRef = useRef<CarouselRef>(null);
    const prepaid: ApiPriceModel | undefined = useSelector((state: any) => state.portal?.prepaid);
    const user: UserModel | undefined = useSelector((state: any) => state.login?.user);
    const [modalAdminVisible, setModalAdminVisible] = useState<boolean>(false);
    const elRef = useRef<HTMLDivElement>(null);
    const apiPrice: ApiPriceState = useSelector((state: any) => state.portal?.apiPrice);

    const carouselCard = useMemo(() => {
        if (apiState?.relatedAPIs != null && apiState?.item?.relatedCategories != null &&
            apiState?.item?.relatedCategories.length > 0) {
            let itens: ReactNode[] = [];
            let tempApis: any[] = [...apiState.relatedAPIs];
            const categories = apiState.item.relatedCategories.map(x => x.name);
            tempApis.map(x => x.countApis = x.relatedCategories != null ? _.intersection(categories, x.relatedCategories.map((a: any) => a.name)).length : 0)
            tempApis = _.orderBy(tempApis, ['countApis', 'name'], ['desc']);
            const groups = _.chunk(tempApis, 3);

            _.forEach(groups, (group, ig) => {
                itens.push(<div key={ig} className='cards carousel-card'>
                    {group.map((x, i) => {
                        return <CardApiGr1d maxWidth={360} maxWidthTitle={245} height="230px" img={x.image} categories={x.relatedCategories} key={i} id={x.id} title={x.name}
                            subTitle={x.provider} description={x.description} gateway={x.gateway} />
                    })}
                </div>)
            });

            return itens;
        } else {
            return [];
        }
    }, [apiState?.relatedAPIs, apiState?.item])

    const [messageCancel] = useState<JSX.Element>(<div>
        <TypographyGr1d classCustom='title-toast'>
            A API está pronta para ser testada
        </TypographyGr1d>
        <TypographyGr1d component='bodySM'>
            Você pode acessá-la em Portal GR1D {`>`} Minhas APIs {`>`} Consumidas
        </TypographyGr1d>
    </div>);
    const apiKeyProd: ApiKeyState = useSelector((state: any) => state.portal?.apiKeyProd);
    const apiKeySandbox: ApiKeyState = useSelector((state: any) => state.portal?.apiKeySandbox);

    useEffect(() => {
        if (auth.isAuthenticated()) {
            dispatch(action.getApiKey((props.match.params as any).id, 'SANDBOX'));
            dispatch(action.getApiKey((props.match.params as any).id, 'PRODUCTION'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (apiState.item?.id !== parseInt((props.match.params as any).id) && !apiState.loading) {
            dispatch(action.getApi((props.match.params as any).id, (history.location.state as any)?.log === true));
            history.location.state = null;
            dispatch(action.getBusinessModelApi((props.match.params as any).id));
            dispatch(action.getPrepaid((props.match.params as any).id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, action, props.match])

    useEffect(() => {
        const path = `/api/${(props.match.params as any).id}`;

        if (history.location.pathname.includes(path + '/general'))
            setTabValue('general');
        else if (history.location.pathname.includes(path + '/doc'))
            setTabValue('doc');
        else if (history.location.pathname.includes(path + '/price'))
            setTabValue('price');
        else if (history.location.pathname.includes(path + '/sandbox'))
            setTabValue('sandbox');
        else if (history.location.pathname.includes(path + '/comment'))
            setTabValue('comment');
        else
            setTabValue('general');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match])

    useEffect(() => {
        if (tabValue != null)
            history.replace(`/api/${(props.match.params as any).id}/${tabValue}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabValue])

    const activeApi = () => {
        if (prepaid?.price == null) {
            dispatch(appAction.goTo(`/helpform/${apiState.item?.id}`));
        } else {
            if (auth.isAuthenticated()) {
                if (user?.isAdmin) {
                    dispatch(appAction.goTo(`/prepaid/${apiState.item?.id}`));
                } else {
                    setModalAdminVisible(true);
                }
            } else {
                dispatch(appAction.goTo('/login', { type: 'ActionApi', path: props.match.url }));
            }
        }
        // if (auth.isAuthenticated()) {
        //     if (user.isAdmin) {
        //         dispatch(action.setApiToActivate(apiState.item!))
        //         history.push('/purchase-review')
        //         // dispatch(action.postSubscription((props.match.params as any).id, 'PRODUCTION'));
        //     } else {
        //         dispatch(appAction.changeModalPermission(true));
        //     }
        // } else {
        //     dispatch(appAction.goTo('/login', { type: 'ActionApi', path: props.match.url }));
        // }
    }

    const testApi = () => {
        if (auth.isAuthenticated() && apiKeySandbox.item != null) {
            dispatch(appAction.goTo(`/admin/api/consumed/${(props.match.params as any).id}`));
        } else if (auth.isAuthenticated()) {
            dispatch(action.postApiKey((props.match.params as any).id, 'SANDBOX'));
        } else {
            dispatch(appAction.goTo('/login', { type: 'ActionApi', path: props.match.url }));
        }
    }

    useEffect(() => {
        if (apiKeyProd.modal || apiKeySandbox.modal)
            setModalVisible(true);
    }, [apiKeyProd.modal, apiKeySandbox.modal])

    const cancelTestApi = () => {
        notification.success({
            message: messageCancel,
            placement: 'bottomLeft'
        });
        setModalVisible(false);
    }

    const goToApi = () => {
        dispatch(appAction.goTo(`/admin/api/consumed/${(props.match.params as any).id}`));
    }

    const selectProps = {
        mode: 'multiple' as const,
        style: { width: '100%' },
        value: apiState.item?.relatedCategories.map(x => x.name),
    };

    return <ApiStyled className={grid}>
        <div ref={elRef}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 0.64, maxWidth: '100%' }}>
                    <Helmet>
                        <title>{`${apiState.item?.name} - ${apiState.item?.provider}| GR1D`}</title>
                        <meta name="description" content={`${apiState.item?.description}`} />
                        <meta name="robots" content='nofollow' />
                        <meta name="keywords" content={apiState.item?.relatedCategories.map(value => value.name).join(', ')} />
                    </Helmet>
                    <div className='api-header'>
                        {/* <div className='dots'>
                <img alt='dot' src={dots} />
            </div> */}
                        <div style={{ flexDirection: grid !== 'sm' ? 'row' : 'column' }} className='title-logo'>
                            {grid === 'sm' && <img onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} style={{ height: '104px' }} className='logo' src={`/core/api/v1/apis/${(props.match.params as any).id}/image`} alt='logo-temp' />}
                            <div className='title'>
                                <div>
                                    <div className='category-tag'>
                                        <div>
                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                <Select disabled={true} {...selectProps} />
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                                <TypographyGr1d className='oneLine' componentType='h1' style={{ marginBottom: 4, display: 'block' }} component='headingLG' color='colorPrimitiveBlack'>
                                    {apiState.loading && <Skeleton paragraph={{ rows: 1 }} />}
                                    {!apiState.loading && apiState.item?.name}

                                </TypographyGr1d>
                                {!apiState.loading && apiState.item?.gateway === 'WSO2' && <Tooltip overlayInnerStyle={{ width: 275 }} placement="topLeft"
                                    title={<TypographyGr1d style={{ width: 275 }} fontSize='12px' fontWeight='600'>
                                        As APIs com este selo já contam com o novo processo de&nbsp;
                                        <TypographyGr1d style={{ display: 'inline-block' }} fontSize='12px' fontWeight='800'>assinatura simplificada</TypographyGr1d>
                                        &nbsp;da Gr1d.
                                    </TypographyGr1d>}>
                                    <Icon className='inline' style={{ fontSize: 32, paddingLeft: 3, marginLeft: 9 }} component={star} />
                                </Tooltip>}
                                <TypographyGr1d componentType="h2" component='subtitleSM' color='colorPrimitive700'>
                                    {!apiState.loading && `por ${apiState.item?.provider || ''}`}
                                </TypographyGr1d>
                            </div>
                        </div>
                        <div className='raking'>
                            <div style={{ minWidth: '200px' }}>
                                {/* {apiState.loading && <Skeleton paragraph={{ rows: 2 }} />}
                    {!apiState.loading &&
                        <div>
                            <TypographyGr1d font="Manrope" component='overline' color='colorPrimitiveBlack'>
                                Avaliação
                            </TypographyGr1d>
                            <div className='average'>
                                <TypographyGr1d component='headingLG' color='colorPrimitiveBlack'>
                                    {apiState.item?.ratings}
                                </TypographyGr1d>&nbsp;
                                <TypographyGr1d component='headingMD' color='colorPrimitive500'>
                                    / 5.0
                                </TypographyGr1d>
                            </div>
                            <Rate disabled={true} allowHalf={true} value={apiState.item?.ratings} />
                        </div>
                    } */}
                            </div>
                        </div>
                    </div>
                    <div className='description'>
                        <TypographyGr1d componentType='p' style={{ width: grid !== 'sm' ? '50%' : '100%', marginBottom: 24 }} component='bodyLG' color='colorPrimitive600'>
                            {!apiState.loading && apiState.item?.description}
                        </TypographyGr1d>
                        {apiState.loading === false && grid === 'sm' && <ButtonGr1d loading={apiKeyProd.loadingSave} onClick={() => activeApi()} style={{ marginBottom: 48 }} background='white'>
                            Assinar API
                        </ButtonGr1d>}
                        <div className='options'>
                            {grid === 'sm' && <div className={`buttons-options sm`}>
                                <TypographyGr1d style={{ marginRight: 32 }} component='action' color='colorPrimitive600'>
                                    {apiState.item && (
                                        <SaveApiFlag type="button" height={20} apiId={parseInt((props.match.params as any).id)} />
                                    )}
                                </TypographyGr1d>
                                <ButtonGr1d loading={apiKeySandbox.loadingSave} onClick={() => testApi()} type="secondary" background='white'>
                                    {apiKeySandbox.item != null ? 'Ver chave de sandbox' : 'Testar API'}
                                </ButtonGr1d>
                            </div>}
                            <div className='tab-options'>
                                <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack'>
                                    <Tabs activeKey={tabValue} onChange={(v) => setTabValue(v)}>
                                        <TabPane tab="Visão geral" key="general" />
                                        {apiState.item?.documents && apiState.item?.documents?.length > 0 && <TabPane tab="Documentação" key="doc" />}
                                        <TabPane tab="Preços" key="price" />
                                        <TabPane tab="Sandbox" key="sandbox" />
                                        <TabPane tab="Comentários" key="comment" />
                                    </Tabs>
                                </TypographyGr1d>
                            </div>
                            {grid !== 'sm' && <div className={`buttons-options`}>
                                <TypographyGr1d style={{ marginRight: 32 }} component='action' color='colorPrimitive600'>
                                    {apiState.item && (
                                        <SaveApiFlag type="button" height={20} apiId={parseInt((props.match.params as any).id)} />
                                    )}
                                </TypographyGr1d>
                                {/* <ButtonGr1d loading={apiKeySandbox.loadingSave} onClick={() => testApi()} type="secondary" background='white'>
                                    {apiKeySandbox.item != null ? 'Ver chave de sandbox' : 'Testar API'}
                                </ButtonGr1d> */}
                            </div>}
                        </div>
                    </div>
                    <div className='tabs'>
                        <div className='div-1280'>
                            <Switch>
                                <Route exact path="/api/:id/general">
                                    <GeneralApi title='Sobre' />
                                </Route>
                                <Route exact path="/api/:id/doc">
                                    <DocumentationApi />
                                </Route>
                                <Route exact path="/api/:id/price">
                                    <PriceApi />
                                </Route>
                                <Route exact path="/api/:id/sandbox">
                                    <SandboxApi />
                                </Route>
                                <Route exact path="/api/:id/comment/:page?/:query?/:responseId?" render={(propsRoute) => { return <CommentApi {...propsRoute} /> }} />
                            </Switch>
                        </div>
                    </div>
                </div>
                {grid !== 'sm' && <div style={{ flex: 0.36, minWidth: 342, marginBottom: 48 }} className="white-screen">
                    <div className="anchor">
                        <div style={{ maxWidth: 342, paddingRight: 46 }}>
                            <img onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} style={{ height: '104px' }} className='logo' src={`/core/api/v1/apis/${(props.match.params as any).id}/image`} alt={`Logo da API ${apiState.item?.name}, por ${apiState.item?.provider}`} />
                            <div className="assing-api">
                                <Spin spinning={apiPrice.loading}>
                                    {apiPrice.loading ? <div></div> :
                                        prepaid?.price == null ?
                                            apiPrice.item?.pricingDescription === 'Grátis.' ?
                                                <div>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 8, paddingTop: 45 }}>
                                                            <TypographyGr1d componentType='span' component='subtitleSM' fontWeight='400' fontSize="10px" style={{ color: '#757575', paddingRight: 10 }}>
                                                                requisição
                                                            </TypographyGr1d>
                                                            <TypographyGr1d style={{ lineHeight: '100%' }} fontWeight='500' fontSize='32px' color='colorPrimitiveBlack'>
                                                                Grátis
                                                            </TypographyGr1d>
                                                        </div>
                                                    </div>
                                                    <ButtonGr1d loading={apiKeyProd.loadingSave} onClick={() => activeApi()} style={{ paddingBottom: 16, paddingTop: 4 }} width='100%' type="primary">Assinar API</ButtonGr1d>
                                                    <ButtonGr1d loading={apiKeySandbox.loadingSave} onClick={() => testApi()} width='100%' type="primitive">
                                                        {apiKeySandbox.item != null ? 'Ver chave de sandbox' : 'Testar API'}
                                                    </ButtonGr1d>
                                                </div> :
                                                <div style={{ paddingTop: 18, width: '100%' }}>
                                                    <ButtonGr1d onClick={() => activeApi()} style={{ paddingBottom: 16, paddingTop: 4 }} width='100%' type="primary">Assinar API</ButtonGr1d>
                                                    <ButtonGr1d loading={apiKeySandbox.loadingSave} onClick={() => testApi()} width='100%' type="primitive">
                                                        {apiKeySandbox.item != null ? 'Ver chave de sandbox' : 'Testar API'}
                                                    </ButtonGr1d>
                                                </div>
                                            : <div>
                                                <TypographyGr1d fontWeight="600" fontSize="10px" style={{ color: '#616161' }}>A partir de</TypographyGr1d>
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 8 }}>
                                                        <TypographyGr1d style={{ lineHeight: '100%' }} fontWeight='500' fontSize='32px' color='colorPrimitiveBlack'>
                                                            {formatCurrency.format(prepaid.price / 100).replace('R$', '')}
                                                        </TypographyGr1d>
                                                        <TypographyGr1d componentType='span' component='subtitleSM' fontWeight='400' fontSize="10px" style={{ color: '#757575' }}>
                                                            /Por consulta
                                                        </TypographyGr1d>
                                                    </div>
                                                </div>
                                                <ButtonGr1d loading={apiKeyProd.loadingSave} onClick={() => activeApi()} style={{ paddingBottom: 16, paddingTop: 4 }}
                                                    width='100%' type="primary">Assinar API</ButtonGr1d>
                                                <ButtonGr1d loading={apiKeySandbox.loadingSave} onClick={() => testApi()} width='100%' type="primitive">
                                                    {apiKeySandbox.item != null ? 'Ver chave de sandbox' : 'Testar API'}
                                                </ButtonGr1d>
                                            </div>
                                    }
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {(apiState?.relatedAPIs != null && apiState?.relatedAPIs.length > 0) && <div className='div-1280'>
                <div className='apis-title'>
                    <TypographyGr1d style={{ marginBottom: 32, flex: 1 }} component='headingMD' color='colorPrimitiveBlack' >
                        APIs relacionadas
                    </TypographyGr1d>
                    <ButtonGr1d type="secondary" onClick={() => history.push(`/search/related-apis/${apiState.item?.id}/1`)} background='white'>Ver todos</ButtonGr1d>
                </div>
                {/* <div style={{ marginBottom: 48 }} className='cards'> */}
                <div className='div-carousel-card'>
                    <ButtonGr1d onClick={() => carouselRef.current?.prev()} type='tertiary'>
                        <Icon component={left} />
                    </ButtonGr1d>
                    <Carousel ref={carouselRef} dotPosition='bottom'>
                        {carouselCard}
                    </Carousel>
                    <ButtonGr1d onClick={() => carouselRef.current?.next()} type='tertiary'>
                        <Icon component={right} />
                    </ButtonGr1d>
                </div>
                {/* {apiState?.relatedAPIs?.map((x, i) => {
                    return <CardApiGr1d height="230px" img={x.image} categories={x.relatedCategories} key={i} id={x.id} title={x.name} subTitle={x.provider}
                        description={x.description} />
                })} */}
                {/* </div> */}
            </div>}
            <Modal width={480} centered onCancel={() => setModalVisible(false)} visible={modalVisible} title={<TypographyGr1d component='subtitleMD'
                color='colorPrimitiveBlack'>API pronta para teste</TypographyGr1d>} footer={<div style={{ display: 'flex' }}>
                    <ButtonGr1d onClick={() => cancelTestApi()} style={{ flex: 1 }} type='primitive'>Continuar procurando</ButtonGr1d>
                    <ButtonGr1d onClick={() => goToApi()} type='brand'>Ir para API</ButtonGr1d>
                </div>}>
                <TypographyGr1d style={{ paddingTop: 21 }} font='Manrope' fontWeight='700' fontSize='14px' color='colorPrimitive600'>{apiState.item?.name}
                    <TypographyGr1d componentType='span' fontWeight='400'> já foi adicionada à sua conta e está disponível para ser testada.</TypographyGr1d>
                </TypographyGr1d>
            </Modal>
            <Modal className="modal-api" width={480} centered onCancel={() => setModalAdminVisible(false)} visible={modalAdminVisible}
                title={<TypographyGr1d component='subtitleMD' style={{ lineHeight: '24px' }}
                    color='colorPrimitiveBlack'>Permissão de administrador necessária</TypographyGr1d>} footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonGr1d onClick={() => setModalAdminVisible(false)} type='brand'>Entendi!</ButtonGr1d>
                    </div>}>
                <TypographyGr1d style={{ paddingTop: 21 }} font='Manrope' fontWeight='700' fontSize='14px' color='colorPrimitive600'>
                    <TypographyGr1d componentType='span' fontWeight='400'>
                        Somente administradores da sua empresa podem assinar uma API. Solicite ao seu administrador que efetue a assinatura ou que
                        altere o seu perfil em "Minha empresa" {'>'} "Usuários e Permissões".
                    </TypographyGr1d>
                </TypographyGr1d>
            </Modal>
        </div>
    </ApiStyled>;
}

export default Api;