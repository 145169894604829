import ButtonGr1d from 'components/portal/button';
import TypographyGr1d from 'components/portal/typography';
import { CreditCardStyled } from './creditCard.styled'
import InfoCookies from 'assets/svg/infoCookies.svg'
import { Radio } from 'antd';
import CreditCardImage from 'assets/svg/creditcard.svg'
import Edit from 'assets/svg/edit.svg'
import { useState } from 'react';
import { CreditCardPostModel } from 'models/company';

type showCardProps = {
  creditCard: CreditCardPostModel;
  editCard: () => void;
}

const ShowCard: React.FC<showCardProps> = ({ creditCard, editCard }) => {
  return (
    <div className="showCard">
      <div style={{ paddingRight: "16px" }}>
        <img alt="cartão" src={CreditCardImage} />
      </div>
      <div className="twoCollums">
        <div className="cardDescription">
          <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive600">
            { creditCard.nickname }
            <br />
            **** { creditCard.cardNumber.substring(5, 9) } **** ****
          </TypographyGr1d>
        </div>
        <div className="editCard">
          <img onClick={editCard} alt="editar" src={Edit} style={{ cursor: "pointer" }} />
        </div>
      </div>
    </div>
  )
}

type addCardProps = {
  onSelect: () => void;
}

const AddCard: React.FC<addCardProps> = ({ onSelect }) => {
  const [selected, setSelected] = useState(false)

  return (
    <div className="addCard" onClick={() => {setSelected(true); onSelect()}}>
      <div style={{ paddingRight: "10px" }}>
        <Radio checked={selected} />
      </div>
      <div style={{ paddingRight: "16px" }}>
        <img alt='Cartão de Crédito' src={CreditCardImage} />
      </div>
      <div>
        <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive600">Adicionar novo cartão</TypographyGr1d>
      </div>
    </div>
  )
}

type props = {
  back: () => void;
  next: () => void;
  showAddCreditCard: () => void;
  creditCardAdded: CreditCardPostModel | null;
  editCard: () => void;
  edit: boolean;
  goToReview: () => void;
}

const CreditCardComponent: React.FC<props> = ({ back, showAddCreditCard, creditCardAdded, editCard, next, edit, goToReview }) => {
  const [addCreditCardSelected, setAddCreditCardSelected] = useState(false);

  function goToNextStep() {
    if (!edit) {
      next()
    } else {
      goToReview()
    }
  }

  return (
    <CreditCardStyled>
      <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope" color="colorPrimitiveBlack">Como você prefere pagar?</TypographyGr1d>
      <TypographyGr1d style={{ marginTop: "15px" }} color="colorPrimitive500" fontSize="14px" font="Manrope">Utilizaremos esta estimativa para identificar qualquer comportamento anormal na utilização da API.</TypographyGr1d>
      { creditCardAdded === null && <AddCard onSelect={() => {setAddCreditCardSelected(true)}} /> }
      { creditCardAdded !== null && <ShowCard creditCard={creditCardAdded} editCard={editCard} /> }
      <div className="paymentAlert">
        <div className="alerticon">
          <img alt='Cookies' src={InfoCookies} />
        </div>
        <div className="alerttext">
          Por enquanto aceitamos apenas cartões de crédito como método de pagamento. Em breve outras opções serão permitidas 😉
        </div>
      </div>
      <div className="buttons">
        <ButtonGr1d type="tertiary" onClick={() => { back() }}>Voltar</ButtonGr1d>
        <ButtonGr1d type="primary" onClick={() => { if (!creditCardAdded) {showAddCreditCard()} else {goToNextStep()} }} disabled={!addCreditCardSelected && !creditCardAdded}>{edit ? 'Alterar' : 'Continuar'}</ButtonGr1d>
      </div>
    </CreditCardStyled>
  )
}

export const CreditCard = CreditCardComponent;