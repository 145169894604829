import TypographyGr1d from "components/portal/typography"
import ApiInfoStyled from "./apiInfo.styled"
import SecurePayment from "assets/svg/securepayment.svg"
import ReviewPurchaseCard from "components/portal/card/reviewPurchaseCard"
import { useSelector } from "react-redux";
import { PortalState } from "store/portal/reducer";

type props = {
  fullCard: boolean;
  loadingRequest: boolean;
  onActivate: () => void;
}

const ApiInfo: React.FC<props> = ({ fullCard, loadingRequest, onActivate }) => {
  const apiState: PortalState = useSelector((state: any) => state.portal);

  return (
    <ApiInfoStyled>
      <div className="vertical-flex">
        <TypographyGr1d fontSize="24px" font="Manrope" fontWeight="600">
          Revise sua compra
        </TypographyGr1d>
        <div className="api-card-container">
          {apiState.apiToActivate && <ReviewPurchaseCard loadingRequest={loadingRequest} full={fullCard} onActivate={onActivate} image={apiState.apiToActivate!.image} provider={apiState.apiToActivate!.provider} version={apiState.apiToActivate!.version} /> }
        </div>
        <div className="payment-method">
          <img alt="Safe2Pay" src={SecurePayment} />
          <TypographyGr1d fontSize="14px" font="Manrope" fontWeight="400" color="colorPrimitive500"><span>Transação segura por Safe2Pay</span></TypographyGr1d>
        </div>
      </div>
    </ApiInfoStyled>
  )
}

export default ApiInfo