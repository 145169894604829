import { notification } from "antd";
import i18n from "assets/i18n/i18next";
import { ApiModel } from "models/api";
import { CompanyContactModel, CompanyModel } from "models/company";
import { PaymentIntentModel } from "models/prepaid";
import { appActions } from "store/app/action";
import { loginActions } from "store/login/action";
import { createPortaPrepaidService } from "./service";

export enum PortalPrepaidActionType {
    GET_API = '[PORTAL_PREPAID] Get Api',
    GET_API_SUCCESS = '[PORTAL_PREPAID] Get Api Success',
    GET_API_ERROR = '[PORTAL_PREPAID] Get Api Error',
    GET_API_PRICE = '[PORTAL_PREPAID] Get Api Price',
    GET_API_PRICE_SUCCESS = '[PORTAL_PREPAID] Get Api Price Success',
    GET_API_PRICE_ERROR = '[PORTAL_PREPAID] Get Api Price Error',
    GET_PAYMENT = '[PORTAL_PREPAID] Get Payment',
    GET_PAYMENT_SUCCESS = '[PORTAL_PREPAID] Get Payment Success',
    GET_PAYMENT_ERROR = '[PORTAL_PREPAID] Get Payment Error',
    UNLOCK_SCREEN = '[PORTAL_PREPAID] Unlock Screen',
    GET_CONTACTS = '[PORTAL_PREPAID] Get Contacts',
    GET_CONTACTS_SUCCESS = '[PORTAL_PREPAID] Get Contacts Success',
    GET_CONTACTS_ERROR = '[PORTAL_PREPAID] Get Contacts Error',
    GET_CNPJ = '[COMPANY] Get CNPJ',
    GET_CNPJ_SUCCESS = '[COMPANY] Get CNPJ Success',
    GET_CNPJ_ERROR = '[COMPANY] Get CNPJ Error',
    SAVING = '[COMPANY] Get Saving',
    SAVING_SUCCESS = '[COMPANY] Get Saving Success',
    SAVING_ERROR = '[COMPANY] Get Saving Error',
}

export interface adminApiAction {
    getApi(id: number): void,
    getApiPrice(id: number): void,
    unlockScreen(unlock: boolean): void,
    getContacts(): void,
    getCnpj(cnpj: string): void,
    saving(emailTec: string[], emailFin: string[], emailBusiness: string[],
        user?: {
            cpf: string,
            phone: string
        }, cnpj?: {
            nickname: string,
            cnpj: string,
            phone: string
        }): void,
    getPayment(quantity: number, apiId: number): void,
}

export const portalPrepaidActions = ((): adminApiAction => {
    const service = createPortaPrepaidService();
    const appAction = appActions();
    const loginAction = loginActions();

    const actions = {
        getApi: (): any => {
            return { type: PortalPrepaidActionType.GET_API }
        },
        getApiSuccess: (data: ApiModel): any => {
            return { type: PortalPrepaidActionType.GET_API_SUCCESS, payload: data }
        },
        getApiError: (): any => {
            return { type: PortalPrepaidActionType.GET_API_ERROR }
        },
        getApiPrice: (): any => {
            return { type: PortalPrepaidActionType.GET_API_PRICE }
        },
        getApiPriceSuccess: (data: number): any => {
            return { type: PortalPrepaidActionType.GET_API_PRICE_SUCCESS, payload: data }
        },
        getApiPriceError: (): any => {
            return { type: PortalPrepaidActionType.GET_API_PRICE_ERROR }
        },
        getPayment: (): any => {
            return { type: PortalPrepaidActionType.GET_PAYMENT }
        },
        getPaymentSuccess: (data: PaymentIntentModel): any => {
            return { type: PortalPrepaidActionType.GET_PAYMENT_SUCCESS, payload: data }
        },
        getPaymentError: (): any => {
            return { type: PortalPrepaidActionType.GET_PAYMENT_ERROR }
        },
        unlockScreen: (unlock: boolean): any => {
            return { type: PortalPrepaidActionType.UNLOCK_SCREEN, payload: unlock }
        },
        getContacts: (): any => {
            return { type: PortalPrepaidActionType.GET_CONTACTS }
        },
        getContactsSuccess: (data: CompanyContactModel[]): any => {
            return { type: PortalPrepaidActionType.GET_CONTACTS_SUCCESS, payload: data }
        },
        getContactsError: (): any => {
            return { type: PortalPrepaidActionType.GET_CONTACTS_ERROR }
        },
        getCnpj: (): any => {
            return { type: PortalPrepaidActionType.GET_CNPJ }
        },
        getCnpjSuccess: (data: any): any => {
            return { type: PortalPrepaidActionType.GET_CNPJ_SUCCESS, payload: data }
        },
        getCnpjError: (): any => {
            return { type: PortalPrepaidActionType.GET_CNPJ_ERROR }
        },
        saving: (): any => {
            return { type: PortalPrepaidActionType.SAVING }
        },
        savingSuccess: (): any => {
            return { type: PortalPrepaidActionType.SAVING_SUCCESS }
        },
        savingError: (): any => {
            return { type: PortalPrepaidActionType.SAVING_ERROR }
        },
    }

    function getApi(id: number) {
        return (dispath: any) => {
            dispath(actions.getApi());

            service.getApi(id).then(x => {
                dispath(actions.getApiSuccess(x));
            }).catch(x => {
                dispath(actions.getApiError());
            })
        }
    }

    function getApiPrice(id: number) {
        return (dispath: any) => {
            dispath(actions.getApiPrice());

            service.getApiPrice(id).then(x => {
                dispath(actions.getApiPriceSuccess(x.price));
            }).catch(x => {
                dispath(actions.getApiPriceError());
            })
        }
    }

    function unlockScreen(unlock: boolean) {
        return (dispath: any) => {
            dispath(actions.unlockScreen(unlock));
        }
    }

    function getContacts() {
        return (dispath: any, getState: any) => {
            let state = getState();
            dispath(actions.getContacts());
            service.getContacts(state.login.user.orgId).then(x => {
                dispath(actions.getContactsSuccess(x));
            }).catch(x => {
                dispath(actions.getContactsError());
            })
        }
    }

    function getCnpj(cnpj: string) {
        return (dispath: any) => {
            dispath(actions.getCnpj());

            service.getCnpj(cnpj).then(x => {
                if (x.situacao === 'ATIVA') {
                    dispath(actions.getCnpjSuccess(x));
                } else if (x.situacao === 'JÁ CADASTRADA') {
                    dispath(actions.getCnpjSuccess({ message: 'CNPJ Já Cadastrado' }));
                } else {
                    dispath(actions.getCnpjSuccess({ message: 'CNPJ inválido' }));
                }
            }).catch(x => {
                if (x.response.data.code === 1008)
                    notification.error({
                        message: i18n.t(`resource.error.1008.generic`),
                        placement: 'bottomLeft'
                    })
                dispath(actions.getCnpjError());
            })
        }
    }

    function saving(emailTec: string[],
        emailFin: string[],
        emailBusiness: string[],
        user?: {
            cpf: string,
            phone: string
        }, cnpj?: { nickname: string, cnpj: string, phone: string }) {
        return (dispatch: any, getState: any) => {
            const state = getState();
            dispatch(actions.saving());
            if (user != null) {
                dispatch(updateUser(user, cnpj!, emailTec, emailFin, emailBusiness));
            } else if (cnpj?.cnpj) {
                dispatch(createOrganization(state.login.user.cpf, cnpj, emailTec, emailFin, emailBusiness));
            } else {
                dispatch(createContacts(state.login.user.orgId, emailTec, emailFin, emailBusiness));
            }
        }
    }

    function updateUser(user: { cpf: string, phone: string }, cnpj: { nickname: string, cnpj: string, phone: string }, emailTec: string[], emailFin: string[], emailBusiness: string[]) {
        return (dispatch: any) => {
            service.updateUser(user).then((x) => {
                dispatch(createOrganization(user.cpf, cnpj, emailTec, emailFin, emailBusiness));
            }).catch(x => {
                dispatch(actions.savingError());
            })
        }
    }

    function createOrganization(cpf: string, cnpj: { nickname: string, cnpj: string, phone: string }, emailTec: string[], emailFin: string[], emailBusiness: string[]) {
        return (dispath: any) => {
            service.getCnpj(cnpj.cnpj.replaceAll(/_|-|[/]|[.]/g, '')).then((organization) => {
                const newOrganization: CompanyModel = {
                    cnpj: cnpj.cnpj.replaceAll(/_|-|[/]|[.]/g, ''),
                    name: cnpj.nickname,
                    endereco: {
                        bairro: organization.bairro,
                        cep: organization.cep,
                        cidade: organization.municipio,
                        complemento: organization.complemento,
                        nomeRua: organization.logradouro,
                        numero: organization.numero,
                        uf: organization.uf
                    },
                    razaoSocial: organization.nome,
                    telefone: cnpj.phone,
                    cpfResponsavel: cpf
                }
                service.postOrganization(newOrganization).then((x) => {
                    dispath(loginAction.forceRefreshToken());
                    setTimeout(() => {
                        dispath(createContacts(x.id!, emailTec, emailFin, emailBusiness));
                    }, 1000)
                }).catch(() => {
                    dispath(loginAction.forceRefreshToken());
                    dispath(actions.savingError());
                })
            }).catch(() => {
                dispath(actions.savingError());
            })
        }
    }

    function createContacts(orgId: string, emailTec: string[], emailFin: string[], emailBusiness: string[]) {
        return (dispath: any, getState: any) => {
            let contacts: CompanyContactModel[] = [];
            const state = getState();
            contacts.push({
                emails: emailTec,
                organization: orgId,
                type: 0
            });
            contacts.push({
                emails: emailFin,
                organization: orgId,
                type: 1
            });
            contacts.push({
                emails: emailBusiness,
                organization: orgId,
                type: 2
            });
            service.postContact(contacts).then((x) => {
                dispath(actions.savingSuccess());
                dispath(appAction.goTo(`/prepaid/${state.prepaid.api.id}/consumption`));
            }).catch(() => {
                dispath(actions.savingError());
            })
        }
    }

    function getPayment(quantity: number, apiId: number) {
        return (dispath: any) => {
            dispath(actions.getPayment());
            service.getPayment(quantity, apiId).then((x) => {
                dispath(actions.getPaymentSuccess(x));
            }).catch(() => {
                dispath(actions.getPaymentError());
            })
        }
    }

    return { getApi, getApiPrice, unlockScreen, getContacts, getCnpj, saving, getPayment }
})