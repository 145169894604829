import './App.css';
import Routes from './router';
import 'assets/i18n/i18next';
import { store } from 'store';
import { Provider } from "react-redux";
import 'antd/dist/antd.css';
import "swagger-ui/dist/swagger-ui.css";
import 'react-credit-cards/es/styles-compiled.css';
import Navigator from './views/watch/navigator';
import { Router } from 'react-router-dom';
import history from './router/history';
import CookiesAlert from 'components/portal/cookiesAlert';
import { Theme } from "theme";

function App() {
  return (
  <Provider store={store}>
    <Theme><CookiesAlert /></Theme>
    <Router history={history}>
      <Navigator />
      <Routes />
    </Router>
  </Provider>
)}

export default App;
