import { useEffect } from "react"
import { ModalStyled } from "./index.styled"

type Props = {
    show?: boolean;
    width?: string;
    modalActions?: React.ReactNode
    top?: number
    left?: number
    right?: number
    bottom?: number
}



const Modal: React.FC<Props> = (props) => {
    function shouldBeAbsolutePosition(): boolean {
        return props.top !== undefined ||
            props.bottom !== undefined ||
            props.left !== undefined ||
            props.right !== undefined
    }

    useEffect(() => {
        const elementsWithScroll = document.getElementsByClassName("ant-layout-content");

        if (props.show) {
            setTimeout(() => { // necessário quando fecha e abre outro modal no mesmo ciclo
                for (let i = 0; i < elementsWithScroll.length; i++) {
                    (elementsWithScroll[i] as any).style["overflow-y"] = 'hidden'
                }
                window.document.body.style.overflow = 'hidden';
            }, 20)
        } else {
            for (let i = 0; i < elementsWithScroll.length; i++) {
                (elementsWithScroll[i] as any).style["overflow-y"] = 'auto'
            }
            window.document.body.style.overflow = 'auto';
        }
    }, [props.show])

    return (
        <ModalStyled>
            {(props.show !== undefined ? props.show : true) &&
             <>
                <div className="modalBackground" />
                    <div className="modalWrapper">
                        <div className="modalPositioned" style={{width: props.width, top: props.top ? `${props.top}px` : 'unset !important', left: `${props.left}px`, right: `${props.right}px`, bottom: `${props.bottom}px`, position: shouldBeAbsolutePosition() ? 'absolute' : undefined }}>
                        <div className="modalContent">
                            {props.children}
                        </div>
                        {
                            props.modalActions !== undefined &&
                            <div className="modalActions">
                                {props.modalActions}
                            </div>
                        }
                    </div>
                </div>
            </>
            }
        </ModalStyled>
    )
}

export default Modal