import TypographyGr1d from "components/portal/typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderCategoryLayoutStyled } from "./category.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as hamburguer } from "assets/svg/hamburguer.svg"
import { useAction } from "store";
import { portalActions } from "store/portal/action";
import { CategoryState } from "store/portal/reducer";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import _ from "lodash";
import { CategoryModel } from "models/category";

const { Option } = Select;

const HeaderCategoryLayout: React.FC = () => {
    const dispatch = useDispatch();
    const [categoriesHeader, setCategoriesHeader] = useState<CategoryModel[]>([]);
    const actions = useAction(portalActions());
    const categoryState: CategoryState = useSelector((state: any) => state.portal?.category);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    let history = useHistory();

    useEffect(() => {
        if (categoryState.list == null) {
            dispatch(actions.getAllCategory());
        }
    }, [dispatch, actions, categoryState.list])

    useEffect(() => {
        if (categoryState.list != null) {
            if (process.env.REACT_APP_ENVIRONMENT !== 'DEV') {
                const category1Text = categoryState.list.find(x => x.id === 36)?.name ?? 'Consulta de Dados Cadastrais';
                const category2Text = categoryState.list.find(x => x.id === 18)?.name ?? 'Consulta Veicular';
                const category3Text = categoryState.list.find(x => x.id === 29)?.name ?? 'Serviços Assistenciais';
                const category4Text = categoryState.list.find(x => x.id === 23)?.name ?? 'Biometria Facial';
                const category5Text = categoryState.list.find(x => x.id === 33)?.name ?? 'Validação de Dados';
                setCategoriesHeader([{ description: category1Text, id: 36, name: category1Text },
                { description: category2Text, id: 18, name: category2Text },
                { description: category3Text, id: 29, name: category3Text },
                { description: category4Text, id: 23, name: category4Text },
                { description: category5Text, id: 33, name: category5Text }]);
            } else {
                setCategoriesHeader(categoryState.list);
            }
        }
    }, [categoryState.list])

    let distance = '48px';
    let fontSize = '';
    let categoryWidth = '160px';
    if (grid === 'md') {
        distance = '24px';
        fontSize = '14px';
        categoryWidth = '140px';
    }

    if (grid !== 'sm') {
        return <HeaderCategoryLayoutStyled categoryWidth={categoryWidth} distance={distance}>
            <TypographyGr1d style={{ height: '100%', fontSize: fontSize }} component='action' color='colorPrimitive600'>
                <div className='category'>
                    <Select getPopupContainer={trigger => trigger.parentNode} onChange={(event: any) => history.push(`/search/category/${event}/1`, { log: true })} className='select-no-select' value={-1}>
                        <Option style={{ display: 'none' }} value={-1}>
                            <div style={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                                <div className='hamburguer'>
                                    <Icon className='logo' component={hamburguer} />
                                </div>
                                <TypographyGr1d font="Manrope" color="colorPrimitive600" fontSize="14px" fontWeight="600" lineHeight="100%">
                                    <div className='all-categories'>Todas as categorias</div>
                                </TypographyGr1d>
                            </div>
                        </Option>
                        {_.orderBy(categoryState.list, x => x.name)?.map((x, i) => {
                            return <Option value={x.id} key={i}>
                                <TypographyGr1d style={{ height: 16 }} font="Manrope" color="colorPrimitive600" fontSize="14px" fontWeight="600" lineHeight="100%">
                                    {x.name}
                                </TypographyGr1d>
                            </Option>
                        })}
                    </Select>

                    <div className='all-categories-border'></div>
                    <div className='categories'>
                        {categoriesHeader.map((x, i) => {
                            return <span onClick={() => history.push(`/search/category/${x.id}/1`, { log: true })} key={i}>
                                <TypographyGr1d font="Manrope" color="colorPrimitive600" fontSize="14px" fontWeight="600" lineHeight="100%">
                                    {x.name}
                                </TypographyGr1d>
                            </span>
                        })}
                    </div>
                </div>
            </TypographyGr1d>
        </HeaderCategoryLayoutStyled>;
    } else {
        return <></>;
    }
}

export default HeaderCategoryLayout;