import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const UseCase = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const CardUseCaseStyled = styled(UseCase)`
    cursor: pointer;
    width: 100%; 
    max-width: 400px;

    > div {
        height: 100%;
    }

    img {
        width: 100%;
        max-height: 240px;
        object-fit: scale-down;
    }

    .title {
        color: #212121;
        padding-bottom: 8px;
        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .description {
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .ant-card-body {
        padding: 0px;
    }

    .body {
        padding: 0px 20px 20px 20px;
    }

    .ant-tag {
        margin-top: 20px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
    }

    .tag {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            flex: 1;
        }
    }
`