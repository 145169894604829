import TypographyGr1d from "components/portal/typography";
import { ReviewStyled } from "./review.styled"
import { Card } from 'antd';
import CompanyIcon from "assets/svg/company.svg"
import TagIcon from "assets/svg/tag-consume.svg"
import CardIcon from "assets/svg/card.svg"
import { FieldValues, FormContextValues } from "react-hook-form";

type props = {
  creditCardData: any;
  formCompany: FormContextValues<FieldValues>;
  formConsumption: FormContextValues<FieldValues>;
  userCompanyRequested: boolean;
  goToCompanyStep: () => void;
  goToConsumeStep: () => void;
  goToPaymentStep: () => void;
}

const ReviewComponent: React.FC<props> = ({ formCompany, formConsumption, creditCardData, userCompanyRequested, goToCompanyStep, goToConsumeStep, goToPaymentStep }) => {

  return (
    <ReviewStyled>
      <TypographyGr1d fontSize="24px" fontWeight="600" font="Manrope" color="colorPrimitiveBlack" style={{ marginBottom: "30px" }}>Revise e confirme sua assinatura</TypographyGr1d>
      {userCompanyRequested && <Card title={<div className="cardTitleContainer">
        <div className="cardIconContainer">
          <img alt="company" src={CompanyIcon} />
        </div>
        <TypographyGr1d fontSize="16px" fontWeight="500" font="Manrope" color="colorPrimitive700">Dados da empresa</TypographyGr1d>
      </div>}
      
      extra={<a href="/#"><TypographyGr1d fontSize="14px" fontWeight="600" font="Manrope" color="colorPrimitive600" onClick={goToCompanyStep}>{ /* 'Editar' */ }</TypographyGr1d></a>}>
        <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{formCompany.getValues().companyname}</TypographyGr1d>
        <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500" style={{ marginTop: "10px" }}>{formCompany.getValues().cnpj}</TypographyGr1d>
        <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500" style={{ whiteSpace: "pre-line", marginTop: "-10px" }}>{formCompany.getValues().address}</TypographyGr1d>
      </Card>}
      <Card title={<div className="cardTitleContainer">
        <div className="cardIconContainer">
          <img alt="company" src={TagIcon} />
        </div>
        <TypographyGr1d fontSize="16px" fontWeight="500" font="Manrope" color="colorPrimitive700">Estimativa de uso</TypographyGr1d>
      </div>}
      
      extra={<a href="/#"><TypographyGr1d fontSize="14px" fontWeight="600" font="Manrope" color="colorPrimitive600" onClick={goToConsumeStep}>Editar</TypographyGr1d></a>}>
        <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{ formConsumption.getValues().calls } consultas/mês</TypographyGr1d>
      </Card>
      <Card title={<div className="cardTitleContainer">
        <div className="cardIconContainer">
          <img alt="company" src={CardIcon} />
        </div>
        <TypographyGr1d fontSize="16px" fontWeight="500" font="Manrope" color="colorPrimitive700">Método de pagamento</TypographyGr1d>
      </div>}
      
      extra={<a href="/#"><TypographyGr1d fontSize="14px" fontWeight="600" font="Manrope" color="colorPrimitive600" onClick={goToPaymentStep}>Editar</TypographyGr1d></a>}>
        {creditCardData && <TypographyGr1d font="Manrope" fontSize="14px" color="colorPrimitive500">{ creditCardData.nickname }
            <br />
            **** { creditCardData.cardNumber.substring(5, 9) } **** ****
        </TypographyGr1d>}
      </Card>
    </ReviewStyled>
  )
}

export const Review = ReviewComponent;
