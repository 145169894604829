import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"
import dots from "assets/png/dots_policy.png"

const Api = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const PolicyStyled = styled(Api)`
.policy-header {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
    display: flex;
    align-items: flex-start;
    .content {
        width: 100%;
        max-width: 1080px;
        margin: auto;
        padding-top: 96px;
        background-image: url(${dots});
        background-repeat: no-repeat;
        background-position: right center;

        .title {
            padding: 0 20px;
            padding-bottom: 48px;
        }

        .ant-tabs-tab {
            font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightRegular};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .ant-tabs-tab:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }
    
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-weight: ${(props) => (props.theme as IGlobalStyle).fontWeightBold};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }
    }
}
.policy-content {
    line-height: 23.8px;
    width: 100%;
    max-width: 1080px;
    margin: auto;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 80px;
}
`