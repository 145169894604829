import { NavigatorActionType } from "./action";

export interface NavigatorState {
    path?: string;
}

export const INITIAL_STATE = { name: undefined, path: undefined }
export function navigatorReducer(state: NavigatorState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case NavigatorActionType.CHANGE_ROUTE:
            state = { ...state, path: action.payload?.path }
            break;
    }

    return state;
}