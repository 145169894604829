import styled from 'styled-components';
import { IGlobalStyle } from 'theme/global.styles';

const SelectAPIStatus = (props: any) => {
    return <div style={props.style} className={props.className}>
        {props.children}
    </div>
}

export const SelectAPIStatusStyled = styled(SelectAPIStatus)`
  .parent {
    .ant-select {
      width: 100%;
    }
  }
  .ant-select-selection-overflow {
    height: ${(props) => props.height}}
  }
  .ant-select-selection-overflow-item {
    width: 100%;
  }
  .ant-select {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
    svg {
      fill: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700} !important;
    }
  }
  .ant-select-selector {
    background-color: unset !important;
  }
  .ant-select-selection-overflow-item-suffix {
    display: none;
  }
  .hasItemSelected {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    svg {
      fill: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
    }
  }
  .ant-tag {
    padding: 2px 8px;
    color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400} !important;
  }
  .checkIcon {
    display: none !important;
        svg {
            margin-right: 8px;
        }
    }
  .hasItemSelected {
      .checkIcon {
        display: inline !important;
      }
  }
`;