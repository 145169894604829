import { useCallback, useState } from "react";

const useTheme = () => {
    const [themeState] = useState('gridTheme');

    const [optionsTheme] = useState({
        defaultTheme: {
            colorPrimitiveBlack: "#14161f",
            colorPrimitive700: "#252833",
            colorPrimitive600: "#404554",
            colorPrimitive500: "#5e6475",
            colorPrimitive400: "#9ea1b0",
            colorPrimitive300: "#bec0cc",
            colorPrimitive200: "#dbdde5",
            colorPrimitive100: "#edeef2",
            colorPrimitive50: "#f7f8fa",
            colorPrimitiveWhite: "#ffffff",
            colorFeedbackPositive700: "#10462d",
            colorFeedbackPositive600: "#156a41",
            colorFeedbackPositive500: "#09864a",
            colorFeedbackPositive400: "#05a357",
            colorFeedbackPositive300: "#06c167",
            colorFeedbackPositive200: "#66d18f",
            colorFeedbackPositive100: "#addc0",
            colorFeedbackPositive50: "#e6f2eb",
            colorFeedbackNegative700: "#5a0300",
            colorFeedbackNegative600: "#870400",
            colorFeedbackNegative500: "#ab0600",
            colorFeedbackNegative400: "#e10700",
            colorFeedbackNegative300: "#e84f4a",
            colorFeedbackNegative200: "#f1918e",
            colorFeedbackNegative100: "#fed3d2",
            colorFeedbackNegative50: "#ffeeed",
            colorBrandPrimary700: "#102c60",
            colorBrandPrimary600: "#174291",
            colorBrandPrimary500: "#1e54b7",
            colorBrandPrimary400: "#0670f9",
            colorBrandPrimary300: "#5b91f5",
            colorBrandPrimary200: "#a0bff8",
            colorBrandPrimary100: "#d4e2fc",
            colorBrandPrimary50: "#eff3fe",
            fontFamilyBase: "Manrope",
            fontFamilyVariant: "DM Mono",
            fontWeightExtraBold: "800",
            fontWeightBold: "700",
            fontWeightSemiBold: "600",
            fontWeightMedium: "500",
            fontWeightRegular: "400",
            fontWeightLight: "300",
            fontWeightExtraLight: "200",
            fontSizeTiny: "10px",
            fontSizeXXXS: "12px",
            fontSizeXXS: "14px",
            fontSizeXS: "16px",
            fontSizeSM: "20px",
            fontSizeMD: "24px",
            fontSizeLG: "32px",
            fontSizeXL: "40px",
            fontSizeXXL: "48px",
            fontSizeXXXL: "56px",
            fontSizeDisplay: "64px",
            fontSizeGiant: "80px",
            fontSizeHuge: "96px",
            lineHeightDefault: "100%",
            lineHeightTiny: "115%",
            lineHeightSmall: "120%",
            lineHeightMedium: "133%",
            lineHeightLarge: "150%",
            lineHeightDistant: "170%",
            letterSpacingDefault: "0%",
            letterSpacingSmall: "-3%",
            letterSpacingLarge: "8%",
            fontStyleDefault: "default",
            fontStyleItalic: "italic",
            borderRadiusNone: "0px",
            borderRadiusXS: "4px",
            borderRadiusSM: "8px",
            borderRadiusMD: "16px",
            borderRadiusLG: "24px",
            borderRadiusPill: "500px",
            borderRadiusCircle: "50%",
            borderWidthNone: "0px",
            borderWidthHairline: "1px",
            borderWidthThin: "2px",
            borderWidthThick: "4px",
            borderWidthHeavy: "8px",
            opacityLevelSemiTransparent: "0.08",
            opacityLevelLight: "0.16",
            opacityLevelMedium: "0.32",
            opacityLevelIntense: "0.64",
            opacityLevelSemiOpaque: "0.80",
            shadowLevel01: "0.08",
            shadowLevel02: "0.16",
            shadowLevel03: "0.32",
            shadowLevel04: "0.64",
            shadowLevel05: "0.80",
            spacingInsetQuark: "4px",
            spacingInsetNano: "8px",
            spacingInsetXXXS: "12px",
            spacingInsetXXS: "16px",
            spacingInsetXS: "24px",
            spacingInsetSM: "32px",
            spacingInsetMD: "40px",
            spacingInsetLG: "48px",
            spacingInsetXL: "64px",
            spacingSquishQuark: "4px 8px",
            spacingSquishNano: "8px 16px",
            spacingSquishXXXS: "16px 24px",
            spacingSquishXXS: "16px 32px",
            spacingSquishXS: "24px 48px",
            spacingSquishSM: "32px 64px"
        },
        gridTheme: {
            colorPrimitiveBlack: "#14161F",
            colorPrimitive700: "#252833",
            colorPrimitive600: "#404554",
            colorPrimitive500: "#5e6475",
            colorPrimitive400: "#9ea1b0",
            colorPrimitive300: "#BEC0CC",
            colorPrimitive200: "#DBDDE5",
            colorPrimitive100: "#EDEEF2",
            colorPrimitive50: "#f7f8fa",
            colorPrimitiveWhite: "#ffffff",
            colorFeedbackPositive700: "#10462d",
            colorFeedbackPositive600: "#156a41",
            colorFeedbackPositive500: "#09864a",
            colorFeedbackPositive400: "#05a357",
            colorFeedbackPositive300: "#06c167",
            colorFeedbackPositive200: "#66d18f",
            colorFeedbackPositive100: "#ADDEC0",
            colorFeedbackPositive50: "#e6f2eb",
            colorFeedbackNegative700: "#5a0300",
            colorFeedbackNegative600: "#870400",
            colorFeedbackNegative500: "#ab0600",
            colorFeedbackNegative400: "#e10700",
            colorFeedbackNegative300: "#e84f4a",
            colorFeedbackNegative200: "#f1918e",
            colorFeedbackNegative100: "#fed3d2",
            colorFeedbackNegative50: "#ffeeed",
            colorBrandPrimary700: "#2C1F66",
            colorBrandPrimary600: "#3C2A8C",
            colorBrandPrimary500: "#5232DB",
            colorBrandPrimary400: "#6E4CFF",
            colorBrandPrimary300: "#ac99ff",
            colorBrandPrimary200: "#d5ccff",
            colorBrandPrimary100: "#E4DBFF",
            colorBrandPrimary50: "#F4F0FF",
            fontFamilyBase: "Manrope",
            fontFamilyVariant: "DM Mono",
            fontWeightExtraBold: "800",
            fontWeightBold: "700",
            fontWeightSemiBold: "600",
            fontWeightMedium: "500",
            fontWeightRegular: "400",
            fontWeightLight: "300",
            fontWeightExtraLight: "200",
            fontSizeTiny: "10px",
            fontSizeXXXS: "12px",
            fontSizeXXS: "14px",
            fontSizeXS: "16px",
            fontSizeSM: "20px",
            fontSizeMD: "24px",
            fontSizeLG: "32px",
            fontSizeXL: "40px",
            fontSizeXXL: "48px",
            fontSizeXXXL: "56px",
            fontSizeDisplay: "64px",
            fontSizeGiant: "80px",
            fontSizeHuge: "96px",
            lineHeightDefault: "100%",
            lineHeightTiny: "115%",
            lineHeightSmall: "120%",
            lineHeightMedium: "133%",
            lineHeightLarge: "150%",
            lineHeightDistant: "170%",
            letterSpacingDefault: "0%",
            letterSpacingSmall: "-3%",
            letterSpacingLarge: "8%",
            fontStyleDefault: "default",
            fontStyleItalic: "italic",
            borderRadiusNone: "0px",
            borderRadiusXS: "4px",
            borderRadiusSM: "8px",
            borderRadiusMD: "16px",
            borderRadiusLG: "24px",
            borderRadiusPill: "500px",
            borderRadiusCircle: "50%",
            borderWidthNone: "0px",
            borderWidthHairline: "1px",
            borderWidthThin: "2px",
            borderWidthThick: "4px",
            borderWidthHeavy: "8px",
            opacityLevelSemiTransparent: "0.08",
            opacityLevelLight: "0.16",
            opacityLevelMedium: "0.32",
            opacityLevelIntense: "0.64",
            opacityLevelSemiOpaque: "0.80",
            shadowLevel01: "0.08",
            shadowLevel02: "0.16",
            shadowLevel03: "0.32",
            shadowLevel04: "0.64",
            shadowLevel05: "0.80",
            spacingInsetQuark: "4px",
            spacingInsetNano: "8px",
            spacingInsetXXXS: "12px",
            spacingInsetXXS: "16px",
            spacingInsetXS: "24px",
            spacingInsetSM: "32px",
            spacingInsetMD: "40px",
            spacingInsetLG: "48px",
            spacingInsetXL: "64px",
            spacingSquishQuark: "4px 8px",
            spacingSquishNano: "8px 16px",
            spacingSquishXXXS: "16px 24px",
            spacingSquishXXS: "16px 32px",
            spacingSquishXS: "24px 48px",
            spacingSquishSM: "32px 64px"
        }
    })

    const loadTheme = useCallback(
        () => {
            const { defaultTheme, gridTheme } = optionsTheme;
            switch (themeState) {
                case "gridTheme":
                    return gridTheme;
                default:
                    return defaultTheme;
            }
        }, [optionsTheme, themeState]
    )

    const currentTheme = loadTheme();

    return {
        loadTheme, currentTheme, optionsTheme
    }

}

export default useTheme;



