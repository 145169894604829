import { Form } from 'antd';
import React, { FormEventHandler } from 'react';
import { FormAdminStyled } from './index.styled';

export type PropsForm = {
    onSubmit?: FormEventHandler<any>;
    onReset?: FormEventHandler<any>;
    padding?: string,
    name?: string
}

const FormAdmin: React.FC<PropsForm> = (props) => {
    return <FormAdminStyled>
        <Form name={props.name} autoComplete='off' layout="vertical" onSubmitCapture={props.onSubmit} onReset={props.onReset} style={{ padding: props.padding ? props.padding : 30 }}>
            {props.children}
        </Form>
    </FormAdminStyled>;
}

export default FormAdmin;
