import { Spin } from "antd";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import ButtonGr1d from "components/portal/button";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Icon from '@ant-design/icons';
import { ReactComponent as edit } from "assets/svg/admin/company/edit.svg";
import { ReactComponent as deleteSvg } from "assets/svg/admin/company/delete.svg";
import _ from "lodash";
import { CompanyContactModel, CompanyModel } from "models/company";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "store";
import { companyActions } from "store/admin/company/action";
import {isEmail} from "../../../utils/utils";

const CompanyContact: React.FC = () => {
  const dispatch = useDispatch();
  const action = useAction(companyActions());
  const form = useForm();
  const { handleSubmit } = form;
  const [countTec, setCountTec] = useState<number>(0);
  const [listTec, setListTec] = useState<number[]>([0]);
  const [countFin, setCountFin] = useState<number>(0);
  const [listFin, setListFin] = useState<number[]>([0]);
  const [countBusiness, setCountBusiness] = useState<number>(0);
  const [listBusiness, setListBusiness] = useState<number[]>([0]);
  const company: CompanyModel | undefined = useSelector((state: any) => state.company?.item);
  const contacts: CompanyContactModel[] | undefined = useSelector((state: any) => state.company?.contacts);
  const loadingContacts: boolean | undefined = useSelector((state: any) => state.company?.loadingContacts);
  const loadingSaveContacts: boolean | undefined = useSelector((state: any) => state.company?.loadingSaveContacts);

  const [, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    dispatch(action.getContacts())
    dispatch(action.getCompany())
  }, [])

  useEffect(() => {
    _.forEach(contacts, (x) => {
      if (x.type === 0) {
        setListTec(_.range(x.emails.length));
        setCountTec(x.emails.length - 1);
        setTimeout(() => {
          _.forEach(x.emails, (email, index) => {
            form.setValue(`emailTec${index}`, email)
          })
        }, 0)
        return
      }
      if (x.type === 1) {
        setListFin(_.range(x.emails.length));
        setCountFin(x.emails.length - 1);
        setTimeout(() => {
          _.forEach(x.emails, (email, index) => {
            form.setValue(`emailFin${index}`, email)
          })
        }, 0)
        return
      }
      if (x.type === 2) {
        setListBusiness(_.range(x.emails.length));
        setCountBusiness(x.emails.length - 1);
        setTimeout(() => {
          _.forEach(x.emails, (email, index) => {
            form.setValue(`emailBusiness${index}`, email)
          })
        }, 0)
        return
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts])

  const save = (data: any) => {
    let emailsTec: string[] = [];
    let emailsFin: string[] = [];
    let emailsBusiness: string[] = [];
    _.forEach(data, (x, i) => {
      if (i.includes('emailTec'))
        emailsTec.push(x);
      else if (i.includes('emailFin'))
        emailsFin.push(x);
      else if (i.includes('emailBusiness'))
        emailsBusiness.push(x);
    });
    let newContacts: CompanyContactModel[] = [];
    if (contacts != null && contacts.length > 0) {
      const contactTec: CompanyContactModel = {
        emails: emailsTec,
        organization: company!.id!,
        type: 0,
      }
      newContacts.push(contactTec);
      const contactFin: CompanyContactModel = {
        emails: emailsFin,
        organization: company!.id!,
        type: 1,
      }
      newContacts.push(contactFin);
      const contactBusiness: CompanyContactModel = {
        emails: emailsBusiness,
        organization: company!.id!,
        type: 2,
      }
      newContacts.push(contactBusiness);
    } else {
      const contactTec: CompanyContactModel = {
        emails: emailsTec,
        organization: company!.id!,
        type: 0,
      }
      newContacts.push(contactTec);
      const contactFin: CompanyContactModel = {
        emails: emailsFin,
        organization: company!.id!,
        type: 1,
      }
      newContacts.push(contactFin);
      const contactBusiness: CompanyContactModel = {
        emails: emailsBusiness,
        organization: company!.id!,
        type: 2,
      }
      newContacts.push(contactBusiness);
    }
    dispatch(action.saveContacts(newContacts));
  }

  const reset = () => {
    form.reset();
    setUpdate(x => !x);
  }

  const deleteEmail = (type: string, id: number) => {
    if (type === 'emailTec') {
      setListTec(listTec.filter((x) => x !== id));
    } else if (type === 'emailFin') {
      setListFin(listFin.filter((x) => x !== id));
    } else if (type === 'emailBusiness') {
      setListBusiness(listBusiness.filter((x) => x !== id));
    }
  }

  const validateEmail = (type: string, email: string) => {
    form.clearError();
    if (isEmail(email)) {
      let cont = 0;
      _.forEach(form.getValues(), (x, i) => {
        if (i.includes(type) && x === email) {
          cont = cont + 1;
        }
      });
      if (cont > 1) {
        return 'Email já incluido'
      }
    }
  }

  const formConfigTec: ConfigFormItem[] = [...listTec.map((x) => {
    return {
      name: `emailTec${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailTec', value);
        }
      },
      elementAfter: listTec.length > 1 ? <Icon onClick={() => deleteEmail('emailTec', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailTecBtn`,
    typeComponent: 'custom',
    element: <div>
      <ButtonGr1d onClick={() => { setListTec([...listTec, countTec + 1]); setCountTec(countTec + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  const formConfigFin: ConfigFormItem[] = [...listFin.map((x) => {
    return {
      name: `emailFin${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailFin', value);
        }
      },
      elementAfter: listFin.length > 1 ? <Icon onClick={() => deleteEmail('emailFin', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailFinBtn`,
    typeComponent: 'custom',
    element: <div>
      <ButtonGr1d onClick={() => { setListFin([...listFin, countFin + 1]); setCountFin(countFin + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  const formConfigBusiness: ConfigFormItem[] = [...listBusiness.map((x) => {
    return {
      name: `emailBusiness${x}`,
      typeComponent: 'input',
      label: 'E-mail',
      rules: {
        required: { value: true, message: 'Campo obrigatório' }, pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Email Inválido'
        },
        validate: (value: string) => {
          return validateEmail('emailBusiness', value);
        }
      },
      elementAfter: listBusiness.length > 1 ? <Icon onClick={() => deleteEmail('emailBusiness', x)} className='delete' component={deleteSvg} /> : undefined
    } as ConfigFormItem
  }),
  {
    name: `emailBusinessBtn`,
    typeComponent: 'custom',
    element: <div>
      <ButtonGr1d onClick={() => { setListBusiness([...listBusiness, countBusiness + 1]); setCountBusiness(countBusiness + 1) }} width='100%' type='dashed'>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ fontSize: 20 }} component={edit} />&nbsp;&nbsp;Adicionar campo para contato</div>
      </ButtonGr1d>
    </div>
  }];

  return <div>
    <Spin spinning={loadingContacts}>
      <FormAdmin onSubmit={handleSubmit(save)} onReset={reset}>
        <FormItensAdmin form={form} config={formConfigTec} labelTitle={{ title: 'Contato técnico', subtitle: 'Recebe notificações de problemas técnicos encontrados nas APIs ou atualizações de versões nas APIs consumidas.' }} />
        <div style={{ marginTop: 44, marginBottom: 16 }} className='hr' />
        <FormItensAdmin form={form} config={formConfigFin} labelTitle={{ title: 'Contato financeiro', subtitle: 'Recebe notificações referentes a faturas de APIs consumidas ou informações de recebimentos de APIs fornecidas.' }} />
        <div style={{ marginTop: 44, marginBottom: 16 }} className='hr' />
        <FormItensAdmin form={form} config={formConfigBusiness} labelTitle={{ title: 'Contato de negócios', subtitle: 'Recebe notificações referentes a dúvidas do negócio e planos de cobrança.' }} />
        <div style={{ marginTop: 44, marginBottom: 16 }} className='hr' />
        <div className='btn-general'>
          <ButtonGr1d htmlType='reset' style={{ flex: 1 }} type='dangersecondary'>
            Descartar alterações
          </ButtonGr1d>
          <ButtonGr1d loading={loadingSaveContacts} htmlType='submit' type='feedback'>
            Salvar mudanças
          </ButtonGr1d>
        </div>
      </FormAdmin>
    </Spin>
  </div>;
}

export default CompanyContact;