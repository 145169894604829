import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAction } from "store";
import { appActions } from "store/app/action";
import { AppState } from "store/app/reducer";
import { loginActions } from "store/login/action";

type State = {
    app: AppState
}

const Navigator: React.FC = () => {
    const dispatch = useDispatch();
    const loginAction = useAction(loginActions());
    const history = useHistory();
    const goTo = useSelector((state: State) => state.app.goTo);
    const stateHistory = useSelector((state: State) => state.app.stateHistory);
    const goToRefresh = useSelector((state: State) => state.app.goToRefresh);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const actions = appActions();

        if (width <= 768) {
            dispatch(actions.changeGrid("sm"));
        } else if (width <= 1280) {
            dispatch(actions.changeGrid("md"));
        } else {
            dispatch(actions.changeGrid("lg"));
        }
    }, [width, dispatch]);

    useEffect(() => {
        dispatch(loginAction.getUserInfo())
    }, [dispatch, loginAction])

    useEffect(() => {
        if(history.action === 'POP')
            history.push(history.location.pathname, undefined);
    }, [history])

    useEffect(() => {
        if (goTo != null) {
            history.push(goTo, stateHistory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goTo, goToRefresh])

    return <></>;
}

export default Navigator;
