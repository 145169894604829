import styled from "styled-components"

const Col = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const ColStyled = styled(Col)`
    &.gr1d-col-1 {
        display: block;
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%;
    }

    &.gr1d-col-2 {
        display: block;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }

    &.gr1d-col-3 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }

    &.gr1d-col-4 {
        display: block;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }

    &.gr1d-col-5 {
        display: block;
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }

    &.gr1d-col-6 {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
    }

    &.gr1d-col-7 {
        display: block;
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }

    &.gr1d-col-8 {
        display: block;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }

    &.gr1d-col-9 {
        display: block;
        flex: 0 0 75%;
        max-width: 75%;
    }

    &.gr1d-col-10 {
        display: block;
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
    }

    &.gr1d-col-11 {
        display: block;
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
    }

    &.gr1d-col-12 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }
`