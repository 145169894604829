import React, { useEffect, useRef, useState } from "react";
import logo from "assets/svg/logo.svg";
// import google from "assets/svg/logon/google.svg"
// import facebook from "assets/svg/logon/facebook.svg"
// import github from "assets/svg/logon/github.svg"
import TypographyGr1d from "components/portal/typography";
import InputGr1d from "components/portal/input";
import ButtonGr1d from "components/portal/button";
import { Form, Popover } from "antd";
import { RegisterStyled } from "./register.styled";
import { Link, useHistory } from "react-router-dom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useForm } from "react-hook-form";
import { loginActions } from "store/login/action";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleOutlined } from '@ant-design/icons';

const Register: React.FC = () => {
    let history = useHistory();
    const [check, setCheck] = useState(false);
    const dispatch = useDispatch();
    const form = useForm();
    const { handleSubmit } = form;
    const action = loginActions();
    const loadingRegister: boolean = useSelector((state: any) => state.login?.loadingRegister);
    const confirmRegister: boolean = useSelector((state: any) => state.login?.confirmRegister);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);


    const register = (data: any) => {
        dispatch(action.register(data));
    }

    const firstNameRef = useRef(null)
    const lastNameRef = useRef(null)
    const emailRef = useRef(null)
    const passwordRef = useRef(null)
    const dispatchRegister = () => {
        register({
            'firstName': (firstNameRef.current as any).props.value,
            'lastName': (lastNameRef.current as any).props.value,
            'email': (emailRef.current as any).props.value,
            'password': (passwordRef.current as any).props.value,
        })
    }

    useEffect(() => {
        if (confirmRegister) {
            history.push(`/login/${btoa(`success&&SUCCESS&&${form.getValues().email}`)}`);
        }
    }, [confirmRegister, history, form])

    return <RegisterStyled>
        <div className={`content-white ${grid}`}>
            <div className='border-top' />
            <div className='border-bottom' />
            <div className='all-screen'>
                <img onClick={() => history.push('/')} className='logo' src={logo} alt='logo' />

                <div className='content'>
                    <div className='login'>
                        <Form onSubmitCapture={handleSubmit(dispatchRegister)} autoComplete='off'>
                            <div className='welcome'>
                                <TypographyGr1d style={{ marginBottom: 8 }} component='headingSM' color='colorPrimitiveBlack'>
                                    Bem-vindo ao nosso Marketplace
                                </TypographyGr1d>
                                <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                                    Aqui o seu tempo rende mais
                                </TypographyGr1d>
                            </div>
                            <InputGr1d maxLength={40} inputRef={firstNameRef} tabIndex={1} form={{ form: form, name: 'firstName', validation: { isRequired: true } }} style={{ marginBottom: 20 }} placeholder='Digite seu nome' label='Nome' />
                            <InputGr1d maxLength={40} inputRef={lastNameRef} tabIndex={2} form={{ form: form, name: 'lastName', validation: { isRequired: true } }} style={{ marginBottom: 20 }} placeholder='Digite seu sobrenome' label='Sobrenome' />
                            <InputGr1d inputRef={emailRef} tabIndex={3} form={{ form: form, name: 'email', validation: { isRequired: true, isEmail: true } }} style={{ marginBottom: 20 }} placeholder='Digite seu e-mail' label='E-mail' />
                            <InputGr1d autoComplete='new-password' maxLength={100} inputRef={passwordRef} tabIndex={4} form={{ form: form, name: 'password', validation: { isRequired: true, isPassword: true } }}
                                style={{ marginBottom: 36 }} type='password' placeholder='Digite sua senha' label={<div style={{ display: 'flex' }}>
                                    Senha&nbsp;&nbsp;&nbsp;
                                    <Popover placement='left' trigger={'hover'} title='A SENHA DEVE CONTER' content={<div>
                                        NO MÍNIMO OITO CARACTERES<br />
                                        NO MÁXIMO CEM CARACTERES<br />
                                        UMA LETRA MAIÚSCULA<br />
                                        UMA LETRA MINÚSCULA<br />
                                        UM NÚMERO<br />
                                        UM CARACTERE ESPECIAL ( ! ? @ : ; - , . % & # $ )
                                    </div>}>
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Popover>
                                </div>} />
                            <div className='check-inline'>
                                <Checkbox tabIndex={5} onClick={() => setCheck(!check)} checked={check} />
                                <div>
                                    <TypographyGr1d component='bodySM' color='colorPrimitiveBlack'>
                                        <>
                                            Eu li e concordo com os <Link className='link-register' target='_blank' to='/terms'>Termos de uso</Link> e
                                            <Link className='link-register' target='_blank' to='/privacy'> Política de privacidade.</Link>
                                        </>
                                    </TypographyGr1d>
                                </div>
                            </div>
                            <ButtonGr1d disabled={!check} htmlType='submit' loading={loadingRegister} type='primary' background='white' width={'100%'}>
                                Criar conta
                            </ButtonGr1d>
                            {/* <Divider className='captionBase divider'>
                                ou crie a conta com
                            </Divider>
                            <div className='login-social'>
                                <img alt='google' src={google} />
                                <img alt='facebook' src={facebook} />
                                <img alt='github' src={github} />
                            </div> */}
                            <div className='without-account'>
                                <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                                    Já possui conta?
                                </TypographyGr1d>
                                &nbsp;
                                <div onClick={() => history.push('/login')}>
                                    <TypographyGr1d style={{ cursor: 'pointer' }} component='bodySM' color='colorBrandPrimary500'>
                                        Entrar
                                    </TypographyGr1d>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        <div className='dots' />
    </RegisterStyled>;
}

export default Register;