import { Modal } from "antd";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PurchaseReviewTemplateStyled from "./template.styled"

type props = {
  childrenLeft?: React.ReactNode;
  childrenRight?: React.ReactNode;
  finished: boolean;
}

const PurchaseReviewTemplate: React.FC<props> = ({ childrenLeft, childrenRight, finished }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [path, setPath] = useState<string | undefined>(undefined);
  const unblock = useRef<any>();

  useEffect(() => {
    unblock.current = history.block((x) => {
      if (path == null) {
        setPath(x.pathname);
        setModalVisible(true);
        return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (finished) {
      unblock.current()
    }
  }, [finished])

  const cancelModal = () => {
    setPath(undefined);
    setModalVisible(false);
  }

  const descarteChange = () => {
    unblock.current();
    history.push(path!);
  }

  return (
    <PurchaseReviewTemplateStyled>
      <div style={{ display: "flex" }} className="">
        <div className={`content-white`}>
          <div className='border-top' />
          <div className='border-bottom' />
          <div className='new-content'>
            {childrenLeft}
          </div>
        </div>
        <div className="content-grey">{childrenRight}</div>
      </div>
      <Modal width={480} centered onCancel={() => cancelModal()} visible={modalVisible} title={<TypographyGr1d component='subtitleMD'
        color='colorPrimitiveBlack'>As informações serão descartadas</TypographyGr1d>} footer={<div style={{ display: 'flex' }}>
          <ButtonGr1d onClick={() => cancelModal()} style={{ flex: 1 }} type='primitive'>Voltar</ButtonGr1d>
          <ButtonGr1d onClick={() => descarteChange()} type='danger'>Descartar alterações e sair</ButtonGr1d>
        </div>}>
        <TypographyGr1d style={{ paddingTop: 21 }} font='Manrope' fontWeight='400' fontSize='14px' color='colorPrimitive600'>
          Ao sair desta página, as informações fornecidas até agora serão descartadas. Deseja continuar?
        </TypographyGr1d>
      </Modal>
    </PurchaseReviewTemplateStyled>
  )
}

export default PurchaseReviewTemplate;
