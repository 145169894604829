import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const SavedApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const ApiCardStyled = styled(SavedApi)`
    .apiCardTitle {
        width: calc(100% - 68px - 12px);
    }
    .apiCardTitle > div {
        width: 100%;
    }
    .apiCardTitle > div > div {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .apiCardDescription > div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .apiCard {
        display: flex;
        flex-direction: column !important;
        position: relative;
        height: 216px;
        width: 309px;
        padding: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
        .apiCardContent {
            flex-direction: column !important;
            display: flex;
            align-items: space-between;
            height: 100%;
        }
        .apiCardHeader {
            display: flex;
            img {
                width: 80px;
                padding-right: 12px;
                object-fit: scale-down;
            }
            .apiCardTitle {
                display: flex;
                align-items: flex-end;
            }
        }
        .apiCardDescription {
            margin-top: 16px;
        }
        .apiCardFooter {
            // margin-top: 16px;
            display: flex;
            justify-content: space-between;
        }
    }
`