import { ApiModel, ApiPaginationModel, ApiRegisterModel } from "models/api";
import { CategoryModel } from "models/category";
import { MemberModel } from "models/member";
import { createApiService } from "service";

export const createAdminApiService = () => {
    const service = createApiService('/core/api/v1');

    const getApis = (offset: number): Promise<ApiPaginationModel> => {
        return service.get('/apis/', { params: { page: offset, size: 10 } });
    }

    const getApi = (id: number): Promise<ApiModel> => {
        return service.get(`/apis/${id}`);
    }

    const getCategories = (): Promise<CategoryModel[]> => {
        return service.get(`/category/`);
    }

    const getMembers = (): Promise<MemberModel[]> => {
        return service.get(`/organization/${process.env.REACT_APP_ORGANIZATION}/members`);
    }

    const postRegister = (api: ApiRegisterModel): Promise<ApiModel> => {
        return service.post('/apis/', api);
    }

    return { getApis, postRegister, getApi, getCategories, getMembers };
}