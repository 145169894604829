// import { Props } from "react";
import { Select } from 'antd'
import TypographyGr1d from 'components/portal/typography'
import { CSSProperties, useState } from 'react'
import { SelectAPIStatusStyled } from './status.styled'
import Icon from '@ant-design/icons';
import { ReactComponent as arrow } from "assets/svg/input/arrow_down.svg";
import { ReactComponent as check } from "assets/svg/input/check.svg";

type status = {
    code: string,
    name: string
}

type Props = {
    style?: CSSProperties,
    height?: string,
    bordered: boolean,
    selectionChanged?: (status: string) => void,
    statuslist: status[]
}

const SelectAPIStatus: React.FC<Props> = (props) => {
    const statusList = [
        {
            'code': 'all',
            'name': 'Todos status'
        },
        ...props.statuslist
    ]
    const [currentSelectedStatus, setCurrentSelectedStatus] = useState(statusList[0])

    function changeSelected(status: status) {
        setCurrentSelectedStatus(status)
        if (props.selectionChanged) {
            props.selectionChanged(status.code)
        }
    }

    return (
        <SelectAPIStatusStyled style={{...props.style}} height={props.height}>
            <Select
                className={currentSelectedStatus.code !== 'all' ? 'hasItemSelected' : undefined}
                style={{ width: 260 }}
                placeholder="custom dropdown render"
                mode="multiple"
                showArrow
                bordered={props.bordered}
                showSearch={false}
                defaultValue={['']}
                suffixIcon={(<Icon component={arrow}></Icon>)}
                tagRender={() => (
                    <TypographyGr1d font="Manrope" fontWeight="600" fontSize="14px" color={currentSelectedStatus.code !== 'all' ? 'colorBrandPrimary400' : 'colorPrimitive700'}>
                        <span style={{ lineHeight: '30px', marginLeft: '16px' }}>
                            <Icon component={check} className="checkIcon"></Icon>
                            { currentSelectedStatus.name }
                        </span>
                    </TypographyGr1d>
                )}
                dropdownRender={(menu) => (
                        <div className="dropdownContentAPIStatusSelect">
                            <ul>
                                {
                                    statusList.map((status, i) => (
                                        <li key={i} onClick={() => {changeSelected(status)}} className={currentSelectedStatus.code === status.code ? 'active' : undefined}>
                                            <TypographyGr1d key={status.code} font="Manrope" fontWeight="400" fontSize="14px" color={currentSelectedStatus.code === status.code ? "colorBrandPrimary400" : "colorPrimitive600"}>
                                                { status.name }
                                            </TypographyGr1d>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )}
                />
        </SelectAPIStatusStyled>
    )
}

export default SelectAPIStatus