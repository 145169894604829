import { createHelpService } from './service'
import { UploadProgressEvent } from "rc-upload/lib/interface";
import { ApiModel } from 'models/api';
import { createPortalService } from 'store/portal/service';

export enum HelpActionType {
    GET_SUBJECTS = '[HELP] get subjects',
    SEND_FILE_SUCCESS = '[HELP] send file success',
    SEND_FILE_ERROR = '[HELP] send file error',
    GET_API_HELPFORM = '[PORTAL] Get Apis HelpForm',
    GET_API_HELPFORM_SUCCESS = '[PORTAL] Get Apis HelpForm Success',
    GET_API_HELPFORM_ERROR = '[PORTAL] Get Apis HelpForm Error',
    API_HELPFORM_DELETE = '[PORTAL] Apis HelpForm Delete',
}

export interface navigatorAction {
    getSubjects(): void
    sendFile(file: File, onProgress?: (event: UploadProgressEvent) => void): void
    createHelp(data: { name: string, email: string, subject: string, text: string, phone: string, organization: string, anexos?: string[], notification:boolean}): void,
    getApiHelpForm(id: number): void,
    apiHelpFormDelete(): void,
}

export const helpActions = ((): navigatorAction => {
    const service = createHelpService()
    const portalService = createPortalService();
    const actions = {
        getSubjects: (subjects: any): any => {
            return { type: HelpActionType.GET_SUBJECTS, payload: { subjects } }
        },
        sendFileSuccess: (info: { name: string, success: boolean }): any => {
            return { type: HelpActionType.SEND_FILE_SUCCESS, payload: info }
        },
        sendFileError: (info: { name: string, success: boolean }): any => {
            return { type: HelpActionType.SEND_FILE_ERROR, payload: info }
        },
        getApiHelpForm: (): any => {
            return { type: HelpActionType.GET_API_HELPFORM }
        },
        getApiHelpFormSuccess: (data: ApiModel): any => {
            return { type: HelpActionType.GET_API_HELPFORM_SUCCESS, payload: data }
        },
        getApiHelpFormError: (): any => {
            return { type: HelpActionType.GET_API_HELPFORM_ERROR }
        },
        apiHelpFormDelete: (): any => {
            return { type: HelpActionType.API_HELPFORM_DELETE }
        }
    }

    function getSubjects() {
        return async (dispath: any) => {
            const result = await service.getSubjects()
            dispath(actions.getSubjects(result));
        }
    }

    async function sendFile(file: File, onProgress: (event: UploadProgressEvent) => string) {
        return await service.sendFile(file, onProgress)
    }

    async function createHelp(data: { name: string, email: string, subject: string, text: string, phone: string, organization: string, anexos: string[], notification:boolean}) {
        return await service.createHelp(data)
    }

    function apiHelpFormDelete() {
        return async (dispatch: any) => {
            dispatch(actions.apiHelpFormDelete());
        }
    }

    function getApiHelpForm(id: number) {
        return async (dispath: any) => {
            dispath(actions.getApiHelpForm());
            portalService.getApi(id).then((api) => {
                dispath(actions.getApiHelpFormSuccess(api));
            }).catch(() => {
                dispath(actions.getApiHelpFormError());
            })
        }
    }

    return { getSubjects, sendFile, createHelp, apiHelpFormDelete, getApiHelpForm }
})