import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import HeaderCategoryLayout from "./category";
import FooterLayout from "./footer";
import HeaderLayout from "./header"
import { LayoutStyled } from "./index.styled";

const Layout: React.FC = (props) => {
    const [child, setChild] = useState<React.ReactNode>(props.children);
    const [top, setTop] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setChild(props.children);
    }, [props.children])

    useEffect(() => {
        const unlisten = history.listen(() => {
            document.getElementById('content')?.scrollTo(0, 0);
        });

        setTop(document.getElementById('content')!.offsetTop);
        const handleResize = () => setTop(document.getElementById('content')!.offsetTop);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            unlisten();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LayoutStyled>
        <HeaderLayout maxWidth={1280} padding='0 20px' />
        <div style={{ height: `calc(100vh - ${top}px)` }} id='content' className='content'>
            <HeaderCategoryLayout />
            {child}
            <FooterLayout />
        </div>
    </LayoutStyled>;
}

export default Layout;