import styled from "styled-components";

const CompanyInfo: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export const CompanyInfoStyled = styled(CompanyInfo)`
  .form-admin {
    
  }
`;
