import { Tag } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import InputGr1d from 'components/portal/input'
import TypographyGr1d from 'components/portal/typography'
import { AdminApiConsumedStyled } from './list.styled'
import { SearchOutlined } from '@ant-design/icons';
import ProviderSelect from 'components/admin/multiselect/provider'
import ApiCard from 'components/admin/card/api'
import PaginationGr1d from 'components/portal/pagination'
import TypeSelect from 'components/admin/multiselect/status'
import { useCallback, useEffect, useState } from 'react'
import { provider } from 'components/admin/multiselect/provider'
import { ApiSubscriptionModel } from 'models/apiSubscription'
import SavedApiCard from 'components/admin/card/savedApi'
import { ApiSavedModel } from 'models/savedApi'
import ApiListEmptyState from "./list-empty-state"

type searchIconProps = {
    refresh: boolean;
    onClick: () => void;
}

const SearchIcon: React.FC<searchIconProps> = ({ onClick, refresh }) => {
    return <div><SearchOutlined onClick={onClick} style={{ cursor: 'pointer', zIndex: 2 }} /> {refresh} </div>
}

type props = {
    apis: ApiSubscriptionModel[] | ApiSavedModel[],
    type: 'consumed' | 'saved'
}

const ConsumedApi: React.FC<props> = (props) => {
    const [providers, setProviders] = useState<provider[]>([])
    const [apiStatusFilter, setApiStatusFilter] = useState('all')
    const [apiNameFilter, setApiNameFilter] = useState('')
    const [providersFilter, setProvidersFilter] = useState<provider[]>([])
    const [SearchResult, setSearchResult] = useState<ApiSubscriptionModel[] | ApiSavedModel[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [forceRefreh, setForceRefresh] = useState(false);

    const PAGE_SIZE = 8

    useEffect(() => {
        setProviders([...props.apis.map((api: ApiSubscriptionModel | ApiSavedModel) => {
            return {
                'id': `${api.apiId.id}`,
                'name': api.apiId.provider,
                'selected': false
            }
        })])

        setProvidersFilter([...providers])

        searchApis()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apis])

    useEffect(() => {
        searchApis()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiStatusFilter, providersFilter])

    function nameFilter(apiSubscription: ApiSubscriptionModel | ApiSavedModel): boolean {
        return apiSubscription.apiId.name.toLowerCase().indexOf(apiNameFilter.toLocaleLowerCase()) > -1
    }

    function providerFilter(apiSubscription: ApiSubscriptionModel | ApiSavedModel): boolean {
        if (!providersFilter.some(provider => provider.selected === true)) { // nenhum provedor selecionado no filtro, ignora este filtro
            return true
        }
        return providersFilter.some(provider => provider.selected === true && provider.name === apiSubscription.apiId.provider)
    }

    function statusFilter(apiSubscription: ApiSubscriptionModel | ApiSavedModel): boolean {
        if (props.type === 'saved') {
            return true
        }
        switch (apiStatusFilter) {
            case 'test':
                return (apiSubscription as ApiSubscriptionModel).type === 'TEST';
            case 'active':
                return (apiSubscription as ApiSubscriptionModel).type === 'ACTIVE';
            default:
                return true
        }
    }

    const searchApis = useCallback(() => {
        setSearchResult((props.apis as any)
            .filter(nameFilter)
            .filter(providerFilter)
            .filter(statusFilter)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiNameFilter, providersFilter, apiStatusFilter])

    useEffect(() => {
        searchApis();
    }, [apiNameFilter, searchApis])

    return (
        <AdminApiConsumedStyled>
            <Content>
                <div className="header">
                    <TypographyGr1d font="Manrope" component="headingLG">
                        {props.type === 'consumed' && 'API\'s consumidas'}
                        {props.type === 'saved' && 'API\'s salvas'}
                    </TypographyGr1d>
                    <div style={{ marginLeft: '8px' }}>
                        <Tag>
                            <TypographyGr1d font="Manrope" fontWeight="400" fontSize="12px" color="colorBrandPrimary600">
                                total de {props.apis.length} API's
                            </TypographyGr1d>

                        </Tag>
                    </div>
                </div>
                {
                    props.apis.length > 0 && 
                    <div className="content">
                        <div className="filters">
                            <div className="inputs">
                                <InputGr1d className='input' onChange={(e) => {setApiNameFilter(e.target.value); setForceRefresh((f) => !f);}} 
                                placeholder='Pesquise por nome da API' style={{ width: '100%', maxWidth: '391px' }} />
                                <ProviderSelect height="40px" bordered={false} selectionChanged={setProvidersFilter} providers={providers} />
                                {props.type === 'consumed' && <TypeSelect height="40px" bordered={false} selectionChanged={setApiStatusFilter} statuslist={[{ 'code': 'active', 'name': 'Inscrição ativa' }, { 'code': 'test', 'name': 'Período de teste' }]} />}
                            </div>
                            <div className="mode">
                                {/* <Gr1dButton>
                                    <Icon component={gridview} className="gridViewIcon" />
                                </Gr1dButton> */}
                            </div>
                        </div>
                        {SearchResult.length === 0 &&
                            <TypographyGr1d font="Manrope" style={{ marginTop: '32px' }}>
                                Nenhuma API encontrada
                            </TypographyGr1d>
                        }
                        {SearchResult.length !== 0 &&
                            <div className="apilist">
                                <div className="cards">
                                    <div className="cardrowgrid">
                                        {
                                            SearchResult.slice((currentPage - 1) * PAGE_SIZE, ((currentPage - 1) * PAGE_SIZE) + PAGE_SIZE).map((card: ApiSavedModel | ApiSubscriptionModel, index: number) => (
                                                <div key={index}>
                                                    {props.type === 'consumed' && <ApiCard id={card.apiId.id} name={card.apiId.name} author={card.apiId.provider} type={(card as ApiSubscriptionModel).type} version={card.apiId.version} />}
                                                    {props.type === 'saved' && <SavedApiCard api={card as ApiSavedModel} />}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {SearchResult.length !== 0 && SearchResult.length > PAGE_SIZE &&
                            <div className="pagination">
                                <PaginationGr1d onChange={setCurrentPage} total={SearchResult.length} pageSize={8} />
                            </div>
                        }        
                    </div>
                }

                {
                    props.apis.length === 0 &&
                    <ApiListEmptyState />
                }
            </Content>
        </AdminApiConsumedStyled>
    )
}

export default ConsumedApi