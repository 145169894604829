import { HomeStyled } from "./index.styled";
// import worker from "assets/svg/home/worker.svg";
import home2 from "assets/png/home2.png";
// import home2 from "assets/svg/home/advertiser.svg";
import dots from "assets/svg/pattern-dots.svg";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourceHome } from "assets/i18n/resource";
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import CardApiGr1d from "components/portal/card/api";
import CardUseCaseGr1d from "components/portal/card/useCase";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { HomePageState } from "store/portal/reducer";
import { useAction } from "store";
import { portalActions } from "store/portal/action";
import { HomePageModel } from "models/homePage";
import Newsletter from "./Newsletter";
import _ from 'lodash';
import { Col, Row } from "antd";

const Home: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const i18n: ResourceHome = useMemo(() => t("resource.home", { returnObjects: true }), [t]);
    const history = useHistory();
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);
    const homePageState: HomePageState = useSelector((state: any) => state.portal?.homePage);
    const actions = useAction(portalActions());
    const [homePage, setHomePage] = useState<HomePageModel | undefined>(undefined);
    const [homePage1, setHomePage1] = useState<HomePageModel | undefined>(undefined);
    const [homePage2, setHomePage2] = useState<HomePageModel | undefined>(undefined);

    useEffect(() => {
        if (homePageState.list?.length === 0) {
            dispatch(actions.getHomePage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, actions])

    useEffect(() => {
        _.forEach(homePageState.list, (item) => {
            if (item.type === 0)
                setHomePage(item);
            else if (item.type === 1)
                setHomePage1(item);
            else if (item.type === 2)
                setHomePage2(item);
        })
    }, [homePageState.list])

    return <HomeStyled>
        <div className='productive-wrapper'>
            <div className='productive-image'>
                <div className='div-1380'>
                    <div className="gradientLeft"></div>
                    <div className="gradientRight"></div>
                    <div className='div-1280'>
                        <div className='productive'>
                            <Row>
                                <Col sm={14} md={14}>
                                    <div className='description'>
                                        <div className="title">
                                            <TypographyGr1d style={{ marginBottom: 8 }} component={grid === 'sm' ? 'headingLG' : 'headingXL'} color='colorPrimitiveWhite' type='Base'>
                                                {i18n.more} <span className='productivity'><div className='background-text'></div><span>{i18n.productivity}</span></span> {i18n.team}
                                            </TypographyGr1d>
                                        </div>

                                        <TypographyGr1d style={{ marginBottom: 32 }} component={grid === 'sm' ? 'subtitleSM' : 'subtitleMD'} color='colorPrimitiveWhite' type='Base'>
                                            Encontre e gerencie centenas de APIs em um só lugar.
                                        </TypographyGr1d>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='div-1280'>
            <div style={{ marginTop: 96 }} className='high'>
                <div className='all-txt'>
                    <TypographyGr1d style={{ marginBottom: 8 }} fontWeight='600' component='headingMD' color='colorPrimitiveBlack' type='Base'>
                        {homePage?.title}
                    </TypographyGr1d>
                    <TypographyGr1d component='subtitleSM' color='colorPrimitive500' type='Base'>
                        {homePage?.subtitle}
                    </TypographyGr1d>
                </div>
                <ButtonGr1d type="secondary" href={'/search/all-apis/all-apis/1'} background='white'>Ver todos</ButtonGr1d>
            </div>
            <div style={{ marginBottom: 96 }} className='cards'>
                {homePage?.relatedAPIs.map((x, i) => {
                    return <CardApiGr1d height="230px" img={x.image} categories={x.categories} key={i} id={x.id} title={x.name} subTitle={x.provider}
                        description={x.description} gateway={x.gateway} />
                })}
            </div>
        </div>
            <div className='advertiser-gr1d'>
                <div className='div-1280'>
                    <Row>
                        <Col sm={12} md={12}>
                            <img src={home2} alt='anunciante' />
                        </Col>
                        <Col sm={12} md={12}>
                            <div className='txt'>
                                <div>
                                    <TypographyGr1d component='headingLG' color='colorPrimitiveBlack'>
                                        Tem uma API?
                                    </TypographyGr1d>
                                    <TypographyGr1d style={{ marginBottom: 36 }} component='subtitleMD' color='colorPrimitive600'>
                                        Publique-a em nosso marketplace!
                                    </TypographyGr1d>
                                    <ButtonGr1d onClick={() => history.push('/helpform')} type='brand' background='white'>Quero ser um parceiro da GR1D</ButtonGr1d>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        <div className='div-1280'>
            <div style={{ marginTop: 96, marginBottom: 32 }} className='high'>
                <div className='all-txt'>
                    <TypographyGr1d style={{ marginBottom: 8 }} fontWeight='600' component='headingMD' color='colorPrimitiveBlack' >
                        {homePage1?.title}
                    </TypographyGr1d>
                    <TypographyGr1d component='subtitleSM' color='colorPrimitive500' >
                        {homePage1?.subtitle}
                    </TypographyGr1d>
                </div>
                <ButtonGr1d type="secondary" onClick={() => history.push('/search/all-useCase/useCase/1')} background='white'>Ver todos</ButtonGr1d>
            </div>
            <div className='use-cases'>
                {homePage1?.relatedUseCases.map((x, i) => {
                    return <CardUseCaseGr1d key={i} id={x.id} img={x.image} title={x.title} apiCount={x.relatedAPIs.length}
                        description={x.subtitle} />
                })}
            </div>
            <div style={{ marginTop: 96 }} className='high'>
                <div className='all-txt'>
                    <TypographyGr1d style={{ marginTop: 8, marginBottom: 8 }} fontWeight='600' component={grid === 'sm' ? 'headingSM' : 'headingMD'} color='colorPrimitiveBlack' >
                        {homePage2?.title}
                    </TypographyGr1d>
                    <TypographyGr1d component='subtitleSM' color='colorPrimitive500' >
                        {homePage2?.subtitle}
                    </TypographyGr1d>
                </div>
                <ButtonGr1d type="secondary" onClick={() => history.push('/search/all-apis/apis/1')} background='white'>Ver todos</ButtonGr1d>
            </div>
            <div className='cards'>
                {homePage2?.relatedAPIs.map((x, i) => {
                    return <CardApiGr1d height="230px" img={x.image} categories={x.categories} key={i} id={x.id} title={x.name} subTitle={x.provider}
                        description={x.description} gateway={x.gateway} />
                })}
            </div>
        </div>
        <div style={{ marginTop: 96 }} className={`home-${grid} assign`}>
            <div className='txt div-1280'>
                <Newsletter />
                <div className='dots'>
                    <img src={dots} alt='dots' />
                </div>
            </div>
        </div>
    </HomeStyled>;
}

export default Home;