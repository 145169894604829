import { ApiSubscriptionModel } from 'models/apiSubscription';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAction } from 'store';
import { AdminApiState } from "store/admin/apiConsumed/reducer";
import { adminApiConsumedActions } from 'store/admin/apiConsumed/action';
import { auth } from 'utils/auth';
import APIlist from 'components/admin/api/list'

const ConsumedApi: React.FC = () => {
    const dispatch = useDispatch();
    const action = useAction(adminApiConsumedActions());
    const consumedState: AdminApiState = useSelector((state: any) => state.adminApiConsumed);

    const [subscriptions, setSubscriptions] = useState<ApiSubscriptionModel[]>([])

    useEffect(() => {
        dispatch(action.getApi(auth.getAuth().userid))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, action])

    useEffect(() => {
        if (!consumedState.itemLoading && consumedState.item !== undefined) {
            setSubscriptions([...consumedState.item])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumedState.itemLoading])

    return  (
        <div>
            { APIlist({apis: subscriptions, type: "consumed"}) }
        </div>
    )
}

export default ConsumedApi