import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const Button = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const ButtonStyled = styled(Button)`
    .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0s all;
        font-weight: unset;
        font-size: unset;
        font-family: unset;
        line-height: unset;
    }

    /* .ant-btn:hover {
        color: ${(props) => props.theme[props.color]};
        background-color: ${(props) => props.theme[props.background]};
    }

    .ant-btn:focus {
        color: ${(props) => props.theme[props.color]};
        background-color: ${(props) => props.theme[props.background]};
    } */

    .white {
        a {
            padding-top: 6.4px !important;
        }
        .brand {
            border: none;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }

        .brand:hover {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        }

        .brand:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            box-sizing: border-box;
            box-shadow: inset 0px 0px 0px 2px #FFFFFF;
        }

        .brand:active {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }

        .brand:disabled,
        .brand[disabled]{
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .primary {
            border: none;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-shadow: none;
        }

        .primary:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        }

        .primary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            padding: 0;

            > span.ant-btn-loading-icon {
                display: none;
                border: none;
                border-radius: none;
                padding: none;
                height: initial;
                width: initial;
                transition: initial;
            }

            > span {
                display: block;
                border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
                border-radius: inherit;
                padding: 4px 12px;
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                justify-content: center;
            }
        }

        .ant-btn-loading.primary:focus {
            > span.ant-btn-loading-icon {
                display: block;
            }
        }

        .removeBorders:focus {
            span {
                padding-right: 0 !important;
            }
        }

        .removeBorders {
            span {
                border: 0 !important;
                width: unset !important;
            }
        }

        .primary:active {
            border: none;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            border: 4px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            padding: unset;
            > span {
                border: unset;
            }
        }

        .primary:disabled,
        .primary[disabled]{
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
        }

        .secondary {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .secondary:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }

        .secondary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }

        .secondary:active {
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
        }

        .secondary:disabled,
        .secondary[disabled]{
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .primitive {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .primitive:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .primitive:focus {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .primitive:active {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }

        .primitive:disabled,
        .primitive[disabled]{
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .danger {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative400};
        }

        .danger:hover {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
        }

        .danger:focus {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
        }

        .danger:active {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
        }

        .danger:disabled,
        .danger[disabled]{
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative200};
        }

        .dangersecondary {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .dangersecondary:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .dangersecondary:focus {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .dangersecondary:active {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }

        .dangersecondary:disabled,
        .dangersecondary[disabled]{
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
            color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .tertiary {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: initial;
            box-shadow: none;
        }

        .tertiary:hover {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .tertiary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .tertiary:active {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .tertiary:disabled,
        .tertiary[disabled]{
            border: unset;
            background-color: unset;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
        }

        .tertiary:disabled:hover,
        .tertiary[disabled]:hover{
            background-color: initial;
        }

        .feedback {
            border: none;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive400};
        }

        .feedback:hover {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive500};
        }

        .feedback:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive500};
            box-sizing: border-box;
            box-shadow: inset 0px 0px 0px 4px #FFFFFF;
        }

        .feedback:active {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackPositive600};
        }

        .feedback:disabled,
        .feedback[disabled]{
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }

        .dashed {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .dashed:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .dashed:focus {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
        }

        .dashed:active {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        }

        .dashed:disabled,
        .dashed[disabled]{
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary100};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        }
    }

    .black {
        .brand {
            border: none;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
        }

        .brand:hover {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
        }

        .brand:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            box-sizing: border-box;
            box-shadow: inset 0px 0px 0px 4px ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .brand:active {
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
        }

        .brand:disabled,
        .brand[disabled]{
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .primary {
            border: none;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
            box-shadow: none;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400}
        }

        .primary:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500}
        }

        .primary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            padding: 0;
            > span {
                display: block;
                border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
                border-radius: inherit;
                padding: 4px 12px;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }

        .primary:active {
            border: none;
            background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary600};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            > span {
                border: unset;
                padding: 4px 16px;
            }
        }

        .primary:disabled,
        .primary[disabled]{
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive500}
        }

        .secondary {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .secondary:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .secondary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .secondary:active {
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
        }

        .secondary:disabled,
        .secondary[disabled]{
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .primitive {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .primitive:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .primitive:focus {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .primitive:active {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
        }

        .primitive:disabled,
        .primitive[disabled]{
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: initial;
        }

        .tertiary {
            border: 1px solid transparent;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            background-color: initial;
            box-shadow: none;
        }

        .tertiary:hover {
            border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .tertiary:focus {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .tertiary:active {
            border: 2px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
        }

        .tertiary:disabled,
        .tertiary[disabled]{
            border: unset;
            background-color: unset;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive300};
        }

        .tertiary:disabled:hover,
        .tertiary[disabled]:hover{
            background-color: initial;
        }

        .dashed {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorPrimitive500};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveBlack};
        }

        .dashed:hover {
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .dashed:focus {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary300};
            box-sizing: border-box;
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
        }

        .dashed:active {
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary500};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
            background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
        }

        .dashed:disabled,
        .dashed[disabled]{
            border: 1px dashed ${(props) => (props.theme as IGlobalStyle).colorPrimitive700};
            color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive600};
            background-color: initial;
        }
    }
`