import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ButtonGr1d from 'components/portal/button';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Icon from '@ant-design/icons';
import { ReactComponent as info } from "assets/svg/info.svg";
import { notification, Spin } from 'antd';
import { createPortaPrepaidService } from 'store/portal/prepaid/service';
import { useSelector } from 'react-redux';
import { PaymentIntentModel } from 'models/prepaid';

type Props = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  apiId: number,
  unblock: React.MutableRefObject<any>
}

const PrepaidCheckout: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSuccess, setLoadingSuccess] = useState<boolean>(false);
  const payment: PaymentIntentModel | undefined = useSelector((state: any) => state.prepaid?.payment);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const handleSubmit = async (event: any) => {
    setLoadingSuccess(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href.replace(history.location.pathname, `/prepaid/${props.apiId}/success/`),
      },
      redirect: 'if_required'
    });

    if (result.error) {
      notification.error({
        message: result.error.message,
        placement: 'bottomLeft'
      });
      setLoadingSuccess(false);
    } else {
      const service = createPortaPrepaidService();
      service.getPaymentTransaction(props.apiId, payment!.id).then(x => {
        if (x.status === 'succeeded') {
          props.unblock.current();
          history.push(`/prepaid/${props.apiId}/success/`);
        }
        setLoadingSuccess(false);
      }).catch(() => {
        setLoadingSuccess(false);
      });
    }
  };

  const back = () => {
    props.setCurrentStep(x => x - 1);
    history.push(history.location.pathname.replace('/payment', '/consumption'))
  }

  return <div style={{ paddingTop: 32 }}>
    <Spin spinning={loading}>
      <div style={{ marginBottom: 32 }} className='info'>
        <Icon component={info}></Icon>Por enquanto aceitamos apenas cartões de crédito como método de pagamento. Em breve, outras opções serão permitidas 😉
      </div>
      <form onSubmit={handleSubmit}>
        <PaymentElement onReady={() => setLoading(false)} />
        <div style={{ justifyContent: 'space-between', display: 'flex', paddingTop: 12 }} className="buttons">
          <ButtonGr1d disabled={loadingSuccess} type="tertiary" onClick={back}>Voltar</ButtonGr1d>
          <ButtonGr1d loading={loadingSuccess} htmlType='submit'>Finalizar compra</ButtonGr1d>
        </div>
      </form>
    </Spin>
  </div>;
}

export default PrepaidCheckout;