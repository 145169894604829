import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import { UseCaseStyled } from "./index.styled";
import dots from "assets/svg/useCase/dots.svg";
import { Carousel, Tag } from "antd";
import TypographyGr1d from "components/portal/typography";
import { useDispatch, useSelector } from "react-redux";
import { portalActions } from "store/portal/action";
import { useAction } from "store";
import { match } from "react-router-dom";
import { UseCaseModel } from "models/useCase";
import Icon from '@ant-design/icons';
import { ReactComponent as left } from "assets/svg/navegation/chevron/left.svg";
import { ReactComponent as right } from "assets/svg/navegation/chevron/right.svg";
import noImage from 'assets/svg/no-image.svg';
import ButtonGr1d from "components/portal/button";
import { CarouselRef } from "antd/lib/carousel";
import CardApiGr1d from "components/portal/card/api";
import _ from "lodash";

type Props = {
    match: match,
}

const UseCase: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const action = useAction(portalActions());
    const useCase: UseCaseModel | undefined = useSelector((state: any) => state.portal?.useCase?.item);
    const carouselRef = useRef<CarouselRef>(null);

    useEffect(() => {
        dispatch(action.getUseCase((props.match.params as any).id));
    }, [dispatch, action, props.match])

    const carouselCard = useMemo(() => {
        if (useCase?.relatedAPIs != null) {
            let itens: ReactNode[] = [];
            let tempApis: any[] = [...useCase.relatedAPIs];
            const groups = _.chunk(tempApis, 3);

            _.forEach(groups, (group, ig) => {
                itens.push(<div key={ig} className='cards carousel-card'>
                    {group.map((x, i) => {
                        return <CardApiGr1d maxWidth={360} maxWidthTitle={245} height="230px" img={x.image} categories={x.relatedCategories} key={i} id={x.id} title={x.name}
                            subTitle={x.provider} description={x.description} gateway={x.gateway} />
                    })}
                </div>)
            });

            return itens;
        } else {
            return [];
        }

    }, [useCase?.relatedAPIs])

    return <UseCaseStyled>
        <div className='header'>
            <div className='description'>
                <Tag style={{ marginBottom: 16 }}>
                    <TypographyGr1d component='caption' color='colorPrimitive600' >
                        {useCase?.relatedAPIs?.length} APIs
                    </TypographyGr1d>
                </Tag>
                <TypographyGr1d style={{ marginBottom: 8 }} component='headingLG' color='colorPrimitiveBlack' >
                    {useCase?.title}
                </TypographyGr1d>
                <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive600' >
                    {useCase?.subtitle}
                </TypographyGr1d>
                <TypographyGr1d style={{ marginBottom: 60 }} component='bodyLG' color='colorPrimitive500' >
                    {useCase?.description}
                </TypographyGr1d>
            </div>
            <div className='image'>
                <img onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} alt='caso de uso' src={`/core/api/v1/use-case/${useCase?.id}/image`} />
            </div>
        </div>
        <div className='apis-title'>
            <TypographyGr1d style={{ marginBottom: 8 }} component='headingMD' color='colorPrimitiveBlack' >
                Nossa seleção de APIs
            </TypographyGr1d>
            <TypographyGr1d style={{ marginBottom: 32 }} component='subtitleSM' color='colorPrimitive500' >
                Essas soluções podem ajudar seu negócio
            </TypographyGr1d>
        </div>
        <div className='apis'>
            <div className='div-carousel-card'>
                <ButtonGr1d onClick={() => carouselRef.current?.prev()} type='tertiary'>
                    <Icon component={left} />
                </ButtonGr1d>
                <Carousel ref={carouselRef} dotPosition='bottom'>
                    {carouselCard}
                </Carousel>
                <ButtonGr1d onClick={() => carouselRef.current?.next()} type='tertiary'>
                    <Icon component={right} />
                </ButtonGr1d>
            </div>
            {/* <div style={{ marginBottom: 96 }} className='cards'>
                {useCase?.relatedAPIs?.map(x => <CardApiGr1d categories={x.relatedCategories} id={x.id} img={x.image} title={x.name} subTitle={x.provider ?? ''}
                    description={x.description} gateway={x.gateway} />)}
            </div> */}
        </div>
        <div className='questions'>
            <div className='dots'>
                <img alt='background' src={dots} />
            </div>
            {/* <TypographyGr1d style={{ marginBottom: 32 }} component='headingMD' color='colorPrimitiveBlack' >
                Perguntas Frequentes
            </TypographyGr1d>
            <TypographyGr1d style={{ maxWidth: 805 }} component='subtitleMD' color='colorPrimitiveBlack'>
                <Collapse className='collapse'>
                    <Panel header="Como funciona o processo de ativação das APIs?" key="1">
                        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                            Para ativar uma API basta ir na página da API desejada e clicar em ativar. O método de cobrança varia entre APIs, verifique as opções disponíveis e selecione aquele que melhor se encaixa com as suas necessidades.
                        </TypographyGr1d>
                    </Panel>
                    <Panel header="Quanto vou pagar pelo uso das APIs?" key="2">
                        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                            Para ativar uma API basta ir na página da API desejada e clicar em ativar. O método de cobrança varia entre APIs, verifique as opções disponíveis e selecione aquele que melhor se encaixa com as suas necessidades.
                        </TypographyGr1d>
                    </Panel>
                    <Panel header="Como faço para integrar as APIs na minha plataforma?" key="3">
                        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                            Para ativar uma API basta ir na página da API desejada e clicar em ativar. O método de cobrança varia entre APIs, verifique as opções disponíveis e selecione aquele que melhor se encaixa com as suas necessidades.
                        </TypographyGr1d>
                    </Panel>
                    <Panel header="Como faço para entrar em contato com a GR1D?" key="4">
                        <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                            Para ativar uma API basta ir na página da API desejada e clicar em ativar. O método de cobrança varia entre APIs, verifique as opções disponíveis e selecione aquele que melhor se encaixa com as suas necessidades.
                        </TypographyGr1d>
                    </Panel>
                </Collapse>
            </TypographyGr1d> */}
        </div>
    </UseCaseStyled>;
}

export default UseCase;