import { validateCPF } from "validations-br";
import * as Yup from "yup";

export const memberSchema = Yup.object({
    isAdmin: Yup.boolean(),
    email: Yup.string().email().required(`Campo é obrigatório`),
    name: Yup.string().required(`Campo é obrigatório`),
    cpf: Yup
        .string()
        .nullable()
        .when("isAdmin", {
            is: true,
            then: Yup.string().required("Campo é obrigatório")
                .test("is-cpf", "CPF inválido. Verifique se todos os dígitos foram inseridos corretamente.", (value) => validateCPF(value as string))
        })
})
