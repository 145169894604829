import ButtonGr1d from "../button";
import TypographyGr1d from "../typography";
import ReviewPurchaseCardStyled from "./reviewPurchaseCard.styled"
import noImage from "assets/svg/no-image.svg"

type props = {
  image?: string;
  version?: string;
  provider? :string;
  full: boolean;
  onActivate: () => void;
  loadingRequest: boolean;
}

const ReviewPurchaseCard: React.FC<props> = ({ image, version, provider, full, loadingRequest, onActivate }) => {
  return (
    <ReviewPurchaseCardStyled>
      <div className="cardcontainer">
        <div className="card-vertical">
          <div className="card-header">
            <img alt='review' src={image || ''} onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} />
            <div className="card-title-container">
              <TypographyGr1d fontSize="16px" fontWeight="600" font="Manrope" color="colorPrimitive700">Facematch - BigID</TypographyGr1d>
              <TypographyGr1d fontSize="14px" fontWeight="400" color="colorPrimitive600">por {provider}</TypographyGr1d>
            </div>
          </div>
          <div className="card-content">
            <div className="version">
              <TypographyGr1d>Versão</TypographyGr1d>
              <TypographyGr1d>{version}</TypographyGr1d>
            </div>
            <div className="paymentModel">
              <TypographyGr1d fontWeight="700" fontSize="14px" color="colorPrimitive700">Modelo de cobrança</TypographyGr1d>
            </div>
            <div className="payment-description">
              <TypographyGr1d fontWeight="400" fontSize="14px" color="colorPrimitive700">A cobrança é feita de acordo com com a <a href='/#'>Tabela de preços de Cashback.</a></TypographyGr1d>
            </div>
          </div>
          {full && <div className="activationInfo">
            <div className="activationText">
              <TypographyGr1d font="Manrope" fontSize="14px">Ao ativar a assinatura, você confirma que leu e concorda com os <a href="/terms" target="_blank">Termos e Condições</a> e <a href="/privacy" target="_blank">Política de Privacidade</a> da GR1D.</TypographyGr1d>
            </div>
            <ButtonGr1d loading={loadingRequest} type="primary" width="100%" style={{ marginTop: "24px" }} onClick={onActivate}>Ativar Assinatura</ButtonGr1d>
          </div>}
        </div>
      </div>
    </ReviewPurchaseCardStyled>
  )
}

export default ReviewPurchaseCard;