import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const SandboxApi = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const SandboxApiStyled = styled(SandboxApi)`
    padding-top: 48px;
    padding-bottom: 96px;

    .authorization__btn.unlocked {
        display: none;
    }

    .authorization__btn.locked {
        display: none;
    }

    .swagger {
        background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        padding: 20px;
        border-radius: 8px;
    }

    .swagger.sm {
        h2.title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .authorize {
            margin-top: 10px;
        }

        .wrapper {
            padding: 0;
        }

        .opblock-summary {
            display: flex;
        }
    }

    .sm .swagger-ui .opblock .opblock-summary-path {
        max-width: 100%;
    }

    .sm .swagger-ui .scheme-container .schemes {
        display: initial;
    }

    .sm .swagger-ui .opblock.opblock-get {
        padding: 10px;
    }

    .sm .swagger-ui .scheme-container {
        padding: 10px;
    }

    .sm .parameters-container {
        overflow: auto;
    }
    .sm .responses-inner {
        overflow: auto;
    }

    .no-try-out .try-out__btn {
        display: none;
    }

    .swagger-ui .auth-wrapper .authorize svg {
        display: block;
    }
`