import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles";

const Prepaid: React.FC<any> = ({ className, children, grid }) => {
  return <div className={className + ' ' + grid}>{children}</div>
}

const PrepaidStyled = styled(Prepaid)`
  display: flex;
  gap: 74px;
  min-height: calc(100vh - 64px);
  background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitive50};
  flex-wrap: wrap;

  .border-top {
    position: absolute;
    background: linear-gradient(45deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} 50%);
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }

  .border-bottom {
    position: absolute;
    background: linear-gradient(135deg, ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite} 50%, ${(props) => (props.theme as IGlobalStyle).colorPrimitive50} 50%);
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }

  &.sm {
    .step-screen {
      padding: 15px;
    }

    .review-screen {
      padding: 15px;
    }

    .api-card {
      padding: 10px;
    }
  } 

  .step-screen {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    padding: 56px 120px;
    max-width: 735px;
    width: 100%;
    position: relative;
  }´

  .review-screen {
    padding: 56px 5px 56px 0;
    width: 100%;
    max-width: 400px;
  }

  .api-card {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
    padding: 24px;
    border-radius: 8px;
    max-width: 395px;
    width: 395px;

    .api-card-header {
      display: flex;
      gap: 16px;

      img {
        object-fit: scale-down;
      }
    }

    .api-card-version {
      padding-top: 24px;
      display: flex;
      > div {
        flex: 1;
      }
    }
  }

  .steps {
    display: flex;
    gap: 8px;
  }

  .safe-prepaid {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 16px;
  }

  .info {
    background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
    padding: 16px;
    border-radius: 8px;
    display: flex;
    gap: 12px;

    .anticon svg {
      fill: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }
  }

  .price {
    padding-top: 32px;
  }

  .error {
    display: flex;
    gap: 6px;
    svg {
      fill: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative400};
    }
  }
`

export const PrepaidConfirmStyled = styled(Prepaid)`
  padding-top: 20px;

  .prepaid-modal-info {
    display: flex;
    padding-bottom: 8px;
  }

  .prepaid-modal-info-title {
    width: 150px;
  }

  .prepaid-modal-company {
    padding-top: 8px;
  }
`;

export default PrepaidStyled;