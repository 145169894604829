import { Modal } from "antd";
import ButtonGr1d from "components/portal/button";
import TypographyGr1d from "components/portal/typography";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAction } from "store";
import { appActions } from "store/app/action";

const PermissionModal: React.FC = () => {
  const dispatch = useDispatch();
  const action = useAction(appActions());
  let history = useHistory();
  const modalVisible: any = useSelector((state: any) => state.app?.modalPermission);

  return <Modal centered={true} visible={modalVisible} width="480px" onCancel={() => dispatch(action.changeModalPermission(false))}
    footer={
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonGr1d type="secondary" onClick={() => { history.push('/helpform'); dispatch(action.changeModalPermission(false)); }}>
          Tenho uma dúvida
        </ButtonGr1d>
        <ButtonGr1d type="primary" onClick={() => { dispatch(action.changeModalPermission(false)); }}>
          Entendi!
        </ButtonGr1d>
      </div>
    }>
    <TypographyGr1d style={{ paddingTop: 16, paddingBottom: 16 }} component='subtitleMD' color='colorPrimitiveBlack'>
      Permissão de administrador necessária
    </TypographyGr1d>
    <TypographyGr1d color='colorPrimitive600' fontWeight='400' fontSize='14px'>
      A assinatura somente pode realizada por Administradores da empresa. Solicite que um Administrador efetue a assinatura ou lhe atribua o cargo em “Minha empresa” {'>'} “Usuários e Permissões”.
    </TypographyGr1d>
  </Modal>;
}

export default PermissionModal;