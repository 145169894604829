import { Card, Tag } from "antd";
import TypographyGr1d from "components/portal/typography";
import React from "react";
import { useHistory } from "react-router";
import { CardUseCaseStyled } from "./useCase.styled";
import noImage from 'assets/svg/no-image.svg';

type UseCaseProps = {
    id: number;
    img: string;
    title: string;
    description: string;
    apiCount: number;
}

const CardUseCaseGr1d: React.FC<UseCaseProps> = (props) => {
    const history = useHistory();

    return <CardUseCaseStyled>
        <Card onClick={() => history.push(`/useCase/${props.id}`)} bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
            <div style={{ marginBottom: 28, flex: 1 }}>
                <img src={`/core/api/v1/use-case/${props.id}/image`} alt='logo' onError={(e: any) => { e.target.onerror = null; e.target.src = noImage }} />
            </div>
            <div className='body'>
                <div className='txt'>
                    <div className='title'>
                        <TypographyGr1d fontSize="20px" fontWeight="700" color='colorPrimitiveBlack'>
                            {props.title}
                        </TypographyGr1d>
                    </div>
                </div>
                <div className='bodyLGBase description'>
                    <TypographyGr1d component='bodySM' color='colorPrimitive600'>
                        {props.description}
                    </TypographyGr1d>
                </div>
                <div className='tag'>
                    <div>
                        <Tag className='captionBase card-tag'>
                            <TypographyGr1d component='caption' color='colorPrimitive600'>
                                <>{props.apiCount} Apis</>
                            </TypographyGr1d>
                        </Tag>
                    </div>
                </div>
            </div>
        </Card>
    </CardUseCaseStyled>
};

export default CardUseCaseGr1d;