import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const HeaderCategory = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const HeaderCategoryLayoutStyled = styled(HeaderCategory)`
    height: 56px;
    background-color: ${(props) => (props.theme as IGlobalStyle).colorPrimitiveWhite};
  
    > div {
        max-width: 1280px;
        margin: auto;
    }

    .category {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;

        .all-categories {
            font-size: 16px;
            padding-left: 11px;
            margin-right: 24px;
            cursor: pointer;
            min-width: ${(props) => props.categoryWidth};
            max-width: ${(props) => props.categoryWidth};
            width: 100%;
        }

        .all-categories-border {
            border-right: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive200};;
            height: calc(100% - 16px);
        }

        .hamburguer {
            font-size: 20px;
        }

        .categories {
            width: 100%;
            overflow: hidden;
            display: flex;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 100%;
            align-items: center;
            
            > span {
                cursor: pointer;
                margin-left: ${(props) => props.distance};
            }
        }
    }
`