import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const CookiesAlert = (props: any) =>
    <div style={props.style} className={props.className}>
        {props.children}
    </div>

export const CookiesAlertStyled = styled(CookiesAlert)`
.positionedAlert {
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    .cookiesBg {
        display: flex;
        justify-content: center;
        padding: 18px 34px;
        background-color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary50};
        .cookiesContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            max-width: 1240px;
            .cookiesContent {
                display: flex;
                .cookiesText {
                    line-height: 170%;
                    padding-left: 10px;
                    a {
                        text-decoration: underline;
                        color: ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
                    }
                }
            }
            .cookiesActions {
                display: flex;
                .acceptButton {
                    display: flex;
                    align-items: center;
                }
                .closeIcon {
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    img {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
`