import { CookiesAlertStyled } from './index.styled'
import InfoCookies from 'assets/svg/infoCookies.svg'
import CloseCookies from 'assets/svg/closeCookies.svg'
import TypographyGr1d from '../typography'
import ButtonGr1d from '../button'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from 'store/app/reducer'
import { appActions } from 'store/app/action'
import { useAction } from 'store'
import { useEffect } from 'react'

const CookiesAlert: React.FC = () => {
    const showAlert: boolean = useSelector((state: {app: AppState}) => state.app.showCookiesAlert);

    const dispatch = useDispatch();
    const action = useAction(appActions());

    useEffect(() => {
        dispatch(action.verifyCookies())
    }, [dispatch, action])

    function closeCookies() {
        dispatch(action.closeCookies())
    }

    function acceptCookies() {
        dispatch(action.acceptCookies())
    }

    return (
        <CookiesAlertStyled>
            <div className="positionedAlert" style={{display: !showAlert? 'none' : 'unset'}}>
                <div className="cookiesBg">
                    <div className="cookiesContainer">
                        <div className="cookiesContent">
                            <div className="cookiesIcon">
                                <img alt="Info" src={InfoCookies} />
                            </div>
                            <div className="cookiesText">
                                <TypographyGr1d color="colorPrimitive600" font="Manrope" fontWeight="600" fontSize="14px">Usamos cookies para melhorar sua experiência no nosso site e personalizar nossa comunicação.</TypographyGr1d>
                                <TypographyGr1d color="colorPrimitive600" font="Manrope" fontWeight="400" fontSize="14px">Ao clicar em “Continuar e fechar”, você concorda com a nossa <a target="_blank" href="/cookies">Política de Cookies</a>.</TypographyGr1d>
                            </div>
                        </div>
                        <div className="cookiesActions">
                            <div className="acceptButton">
                                <ButtonGr1d type="secondary" onClick={acceptCookies}>Continuar e fechar</ButtonGr1d>
                            </div>
                            <div className="closeIcon" onClick={closeCookies}>
                                <img alt="Info" src={CloseCookies} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CookiesAlertStyled>
    )
}

export default CookiesAlert