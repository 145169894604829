import { Breadcrumb, Modal, Spin } from "antd";
import FormAdmin from "components/admin/form";
import FormItensAdmin, { ConfigFormItem } from "components/admin/form/formItens";
import TypographyGr1d from "components/portal/typography";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PaymentCreditCardStyled } from "./creditCard.styled";
import Icon from '@ant-design/icons';
import { ReactComponent as add } from "assets/svg/add.svg";
import { ReactComponent as editPencil } from "assets/svg/edit-pencil.svg";
import ButtonGr1d from "components/portal/button";
import { ReactComponent as info } from "assets/svg/info.svg";
import Cards from 'react-credit-cards';
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from 'store/admin/company/action';
import { useAction } from "store";
import { PortalState } from "store/admin/company/reducer";
import { UserModel } from "models/user";


const PaymentCreditCard: React.FC = () => {
  const form = useForm();
  const { handleSubmit } = form;

  const [screen, setScreen] = useState<number>(3);
  const [elementForm, setElementForm] = useState<any>();
  const [focus, setFocus] = useState<"name" | "number" | "expiry" | "cvc" | undefined>(undefined);
  const [nickname, setNickname] = useState<string>('');
  const [cvc, setCvc] = useState<string>('');
  const [expiry, setExpiry] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [recaptchaResponse, setRecaptchaResponse] = useState<String | null>('');
  const [, setUpdate] = useState<boolean>(false);

  const dispatch = useDispatch();
  const action = useAction(companyActions());
  const user: UserModel & {orgId: string} = useSelector((state: any) => state.login?.user);
  const creditCardState: PortalState = useSelector((state: any) => state.company);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const save = (data: any) => {
    if (creditCardState.creditCardSaving) {
      return
    }
    const valid = data.valid.split('/')

    dispatch(action.postCreditCard({
      cardNumber: data.number.replace(/\s/g, ''),
      nickname: data.nickname,
      expirationDate: `${valid[0]}/20${valid[1]}`,
      holder: data.name,
      securityCode: data.cvc
    }, user.orgId, recaptchaResponse as string))
    setUpdate(x => !x);
  }

  useEffect(() => {
    if (user) {
      dispatch(action.getCreditCard(user.orgId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (creditCardState.creditCard) {
      setExpiry(creditCardState.creditCard.expirationDate);
      setName(creditCardState.creditCard.holder);
      setNickname(creditCardState.creditCard.nickname)
      setNumber(creditCardState.creditCard.cardNumber);
      form.setValue('nickname', creditCardState.creditCard.nickname)
      form.setValue('number', creditCardState.creditCard.cardNumber)
      form.setValue('name', creditCardState.creditCard.holder)
      form.setValue('valid', creditCardState.creditCard.expirationDate.split('/')[0] + creditCardState.creditCard.expirationDate.split('/')[1].substring(2))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardState.creditCard])

  const reset = () => {
    form.reset();
    setUpdate(x => !x);
    setNickname('');
    setCvc('');
    setExpiry('');
    setName('');
    setNumber('');
  }

  useEffect(() => {
    if (screen === 1) {
      setElementForm(formConfig);
    } else if (screen === 2) {
      setElementForm(formConfigForm);
    } else if (screen === 3) {
      setElementForm(formConfigAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  useEffect(() => {
    if (screen === 2) {
      setElementForm(formConfigForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, cvc, expiry, name, number, nickname, recaptchaResponse, creditCardState.creditCardSaving])

  const formConfig: ConfigFormItem[] = [
    {
      name: 'add',
      typeComponent: 'custom',
      noLabel: true,
      element: <div>
        <TypographyGr1d component='subtitleMD' color='colorPrimitive500' style={{ paddingBottom: 12 }}>
          Nenhum cartão cadastrado
        </TypographyGr1d>
        <ButtonGr1d onClick={() => setScreen(2)} style={{ display: 'flex', justifyContent: 'flex-end' }} type='primitive'>
          <Icon component={add} />&nbsp;&nbsp;Adicionar cartão
        </ButtonGr1d>
      </div>
    },
  ]

  const formConfigForm: ConfigFormItem[] = [
    {
      name: 'bankId',
      noLabel: true,
      typeComponent: 'custom',
      placeholder: 'Digite ou selecione o código do banco',
      element: <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
        placeholders={{ name: 'Nome impresso no cartão' }}
        locale={{ valid: 'Validade' }}
      />
    },
    {
      name: 'nickname',
      label: 'Apelido do cartão',
      typeComponent: 'input',
      defaultValue: nickname,
      placeholder: 'Digite o apelido do cartão',
      helpText: 'Essa é uma forma de identificar o cartão na sua Wallet.',
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'number',
      label: 'Número do cartão',
      typeComponent: 'masked-input',
      placeholder: 'Digite o número do cartão',
      mask: '1111 1111 1111 1111',
      onChange: (v) => setNumber(v.target.value),
      onFocus: () => setFocus('number'),
      onBlur: () => setFocus(undefined),
      rules: {
        required: { value: true, message: 'Campo obrigatório' },
        validate: (value: any) => {
          let newValue = value;
          newValue = newValue.replaceAll('_', '');
          newValue = newValue.replaceAll(' ', '');
          if (newValue.length < 16)
            return 'Campo inválido';
        }
      }
    },
    {
      name: 'name',
      label: 'Nome impresso no cartão',
      typeComponent: 'input',
      placeholder: 'Digite o nome impresso no cartão',
      onChange: (v) => setName(v.target.value),
      onFocus: () => setFocus('name'),
      onBlur: () => setFocus(undefined),
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'valid',
      label: 'Validade',
      typeComponent: 'masked-input',
      placeholder: 'MM/AA',
      mask: '11/11',
      onChange: (v) => setExpiry(v.target.value),
      onFocus: () => setFocus('expiry'),
      onBlur: () => setFocus(undefined),
      span: 16,
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'cvc',
      label: <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Chave de segurança
        </div>
        <TypographyGr1d onClick={() => window.open('https://support.skype.com/pt-br/faq/FA354/o-que-e-o-codigo-de-verificacao-de-cartao-cvc', '_blank')} style={{ cursor: 'pointer' }} font='Manrope' fontWeight='400' fontSize='12px' color='colorBrandPrimary400'>
          O que é
        </TypographyGr1d>
      </div>,
      onChange: (v) => setCvc(v.target.value),
      onFocus: () => { setFocus('cvc'); setUpdate(x => !x) },
      onBlur: () => { setFocus(undefined); setUpdate(x => !x) },
      typeComponent: 'masked-input',
      placeholder: 'CVC',
      mask: '111',
      span: 8,
      rules: { required: { value: true, message: 'Campo obrigatório' } }
    },
    {
      name: 'recaptcha',
      typeComponent: 'custom',
      element: <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReCAPTCHA
          sitekey="6LcfeRAcAAAAAMKL5uACKBZ79CugFDPowFrD0hCM"
          onChange={setRecaptchaResponse}
        />
      </div>
    },
    {
      name: 'btn',
      typeComponent: 'custom',
      element: <div style={{ display: 'flex' }}>
        <ButtonGr1d htmlType='reset' style={{ flex: 1 }} type='primitive'>
          Cancelar
        </ButtonGr1d>
        <ButtonGr1d loading={creditCardState.creditCardSaving} disabled={recaptchaResponse === '' || recaptchaResponse === null || creditCardState.creditCardSavingSuccess === true} htmlType='submit' type='primary'>
          Salvar cartão
        </ButtonGr1d>
      </div>
    }
  ]

  const formConfigAccount: ConfigFormItem[] = [
    {
      name: 'account',
      typeComponent: 'custom',
      noLabel: true,
      element: <div>
        <TypographyGr1d component='subtitleMD' color='colorBrandPrimary400' style={{ paddingBottom: 8 }}>
          01 - Banco do Brasil
        </TypographyGr1d>
        <TypographyGr1d component='subtitleSM' color='colorPrimitive400' style={{ paddingBottom: 8 }}>
          Conta Corrente
        </TypographyGr1d>
        <div style={{ marginTop: 0, marginBottom: 12 }} className='hr' />
        <div style={{ display: 'flex' }}>
          <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack' style={{ paddingRight: 32 }}>
            Agência: 9800-0
          </TypographyGr1d>
          <TypographyGr1d component='bodyLG' color='colorPrimitiveBlack'>
            Conta: 205190-1
          </TypographyGr1d>
        </div>
        <ButtonGr1d onClick={() => setVisibleModal(true)} style={{ display: 'flex', justifyContent: 'flex-end' }} type='primary'>
          <Icon component={editPencil} />&nbsp;&nbsp;Editar cartão
        </ButtonGr1d>
      </div>
    },
  ]

  return <PaymentCreditCardStyled>
    <Spin spinning={creditCardState.creditCardLoading}>
      <div className='header'>
        <TypographyGr1d component='bodySM'>
          <Breadcrumb>
            <Breadcrumb.Item>Pagamentos</Breadcrumb.Item>
            <Breadcrumb.Item>Cartão de crédito</Breadcrumb.Item>
          </Breadcrumb>
        </TypographyGr1d>
        <TypographyGr1d component='headingMD' style={{ paddingTop: 16 }}>
          Conta bancária
        </TypographyGr1d>
      </div>
      <div style={{ marginTop: 32, marginBottom: 0 }} className='hr' />
      <FormAdmin onSubmit={handleSubmit(save)} onReset={reset}>
        <div className='info'>
          <Icon className='info-icon' component={info} />&nbsp;&nbsp;<TypographyGr1d font='Manrope' fontWeight='600' fontSize='14px' color='colorPrimitive600'>Por enquanto aceitamos apenas cartões de crédito como método de pagamento. Em breve outras opções serão permitidas 😉</TypographyGr1d>
        </div>
        <FormItensAdmin classNameCol='credit-div' form={form} config={elementForm} labelTitle={{ title: 'Dados do cartão', subtitle: 'Para contratar APIs, é necessário ter um meio de pagamento cadastrado para pagar suas faturas.' }} />
      </FormAdmin>
    </Spin>
    <Modal centered={true} visible={visibleModal} width="480px" footer={
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonGr1d type="secondary" onClick={() => { setVisibleModal(false) }}>
          Voltar
        </ButtonGr1d>
        <ButtonGr1d type="danger" onClick={() => { setVisibleModal(false); setScreen(2) }}>
          Alterar
        </ButtonGr1d>
      </div>
    }>
      <TypographyGr1d style={{ paddingTop: 24, paddingBottom: 16 }} component='subtitleMD' color='colorPrimitiveBlack'>
        Tem certeza que deseja alterar o cartão de crédito?
      </TypographyGr1d>
      <TypographyGr1d color='colorPrimitive600' fontWeight='400' fontSize='14px'>
        O cartão atual não estará mais disponível para uso
      </TypographyGr1d>
    </Modal>
  </PaymentCreditCardStyled>;
}

export default PaymentCreditCard;