import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminApiMarketplace = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const CompanyStyled = styled(AdminApiMarketplace)`

    .header {
        padding: 32px 32px 0px 32px;
    }

    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke:  ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary400};
    }

    .ant-progress-circle-trail {
        stroke:  ${(props) => (props.theme as IGlobalStyle).colorBrandPrimary200};
    }

    .ant-tabs-nav-list {
        padding: 8px 32px;
        border-bottom: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }

    .btn-general {
        display: flex;
    }

    .delete {
        color: ${(props) => (props.theme as IGlobalStyle).colorFeedbackNegative400};
        font-size: 26px;
        cursor: pointer;
        position: relative;
        top: 2px;
    }
`