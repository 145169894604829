import styled from "styled-components"
import { IGlobalStyle } from "theme/global.styles"

const AdminAccount = (props: any) =>
    <div className={props.className}>
        {props.children}
    </div>

export const AdminAccountStyled = styled(AdminAccount)`
    .header {
        padding: 32px 32px 0px 32px;
    }

    .account-div {
        display: flex;
        flex-direction: column;
        padding: 24px 24px 0px 24px !important;
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
        box-sizing: border-box;
        border-radius: 8px;
    }

    .hr-account {
        border: 1px solid ${(props) => (props.theme as IGlobalStyle).colorPrimitive100};
    }
`