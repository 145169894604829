import React from "react";
import { FooterLayoutStyled } from "./footer.styled";
import logo from "assets/svg/logo.svg"
import facebook from "assets/svg/facebook.svg"
import instagram from "assets/svg/instagram.svg"
import linkedin from "assets/svg/linkedin.svg"
import youtube from "assets/svg/youtube.svg"
import TypographyGr1d from "components/portal/typography";
import ButtonGr1d from "components/portal/button";
import home3 from "assets/png/home-3.png";
import home4 from "assets/png/home-4.png";
import questionDots from "assets/svg/home/question-dots.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const FooterLayout: React.FC = () => {
    const history = useHistory();
    const showQuestion: number = useSelector((state: any) => state.app?.showQuestion);
    const grid: 'sm' | 'md' | 'lg' = useSelector((state: any) => state.app?.grid);

    return <FooterLayoutStyled>
        {showQuestion === 1 && <div className={`question question-${grid}`}>
            <div className="div-1280">
                <div className='txt'>
                    <div className='question-dots'>
                        <img src={questionDots} alt='duvidas' />
                    </div>
                    <TypographyGr1d style={{ marginBottom: 8 }} component={grid === 'sm' ? 'headingMD' : 'headingLG'} color='colorPrimitiveBlack' >
                        Está com alguma dúvida?
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 24 }} component={grid === 'sm' ? 'subtitleSM' : 'subtitleMD'} color='colorPrimitive600' >
                        Deixa a gente te ajudar!
                    </TypographyGr1d>
                    <div className='btn'>
                        <ButtonGr1d onClick={() => history.push('/helpform')} background='white' type='brand'>
                            Vamos conversar!
                        </ButtonGr1d>
                    </div>
                </div>
                <div className='img'>
                    <img src={home3} alt='duvidas' style={{transform: "scaleX(-1)"}} />
                </div>
            </div>
        </div>}
        {showQuestion === 2 && <div className={`question question-${grid}`}>
            <div className="div-1280">
                <div className='txt'>
                    <TypographyGr1d style={{ marginBottom: 8 }} component='headingLG' color='colorPrimitiveBlack' >
                        Não encontrou o que procura?
                    </TypographyGr1d>
                    <TypographyGr1d style={{ marginBottom: 24 }} component='subtitleMD' color='colorPrimitive600' >
                        Deixa a gente te ajudar!
                    </TypographyGr1d>
                    <div className='btn'>
                        <ButtonGr1d onClick={() => history.push('/helpform')} background='white' type='brand'>
                            Vamos conversar
                        </ButtonGr1d>
                    </div>
                </div>
                <div className='img'>
                    <img src={home4} alt='duvidas' style={{transform: "scaleX(-1)"}} />
                </div>
            </div>
        </div>}
        <div className='footer-white'>
            <div className='border-top' />
            <div>
                <div>
                    <div style={{ cursor: 'initial' }} className='logo'>
                        <img style={{ cursor: 'initial' }} className='logo' src={logo} alt='logo' />
                        <div className='description'>
                            <TypographyGr1d component='subtitleSM' color='colorPrimitive600'>
                                <>
                                    plataforma de negócios <br />
                                    marketplace de APIs
                                </>
                            </TypographyGr1d>
                        </div>
                    </div>
                    <div className={`apis ${grid}`}>
                        <TypographyGr1d component='subtitleMD' color='colorPrimitive600'>
                            GR1D
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <Link to='/search/all-apis/apis/1'>Todas as APIs</Link>
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <Link to='/search/all-useCase/useCase/1'>Casos de uso</Link>
                        </TypographyGr1d>
                    </div>
                    <div className={`feed ${grid}`}>
                        <TypographyGr1d component='subtitleMD' color='colorPrimitive600'>
                            Links úteis
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <Link target='_blank' to='/terms'>Termos de uso</Link>
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <Link target='_blank' to='/privacy'>Política de privacidade</Link>
                        </TypographyGr1d>
                        <TypographyGr1d component='bodySM' color='colorPrimitive500'>
                            <Link to='/helpform'>Fale conosco</Link>
                        </TypographyGr1d>
                    </div>
                    <div className={`social ${grid}`}>
                        <TypographyGr1d style={{ marginBottom: 16 }} component='subtitleMD' color='colorPrimitive600'>
                            Contato
                        </TypographyGr1d>
                        <TypographyGr1d style={{ marginBottom: 21 }} component='bodySM' color='colorPrimitive500'>
                            <div>+55 11 99302-6416</div>
                        </TypographyGr1d>
                        <div className='network'>
                            <div onClick={() => window.open('https://www.facebook.com/gr1d.io/', '_blank')}>
                                <img src={facebook} alt='facebook'></img>
                            </div>
                            <div onClick={() => window.open('https://www.instagram.com/gr1d_io/', '_blank')}>
                                <img src={instagram} alt='instagram'></img>
                            </div>
                            <div onClick={() => window.open('https://www.linkedin.com/company/gr1d/', '_blank')}>
                                <img src={linkedin} alt='linkedin'></img>
                            </div>
                            <div onClick={() => window.open('https://www.youtube.com/channel/UCQ3yeBr8COYc0djcZCzI9OQ/', '_blank')}>
                                <img src={youtube} alt='youtube'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr></hr>
                <div className='copyright'>
                    <TypographyGr1d component='caption' color='colorPrimitive500'>
                        <div>
                            GR1D TECNOLOGIA S.A. - 28.799.718/0001-09
                        </div>
                    </TypographyGr1d>
                </div>
            </div>
        </div>
    </FooterLayoutStyled >;
}

export default FooterLayout;