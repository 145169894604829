import { Content } from 'antd/lib/layout/layout'
import ButtonGr1d from 'components/portal/button'
import TypographyGr1d from 'components/portal/typography'
import { OrgMembersStyled } from './index.styled'
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons'
import { Table, Switch, Popover, Drawer } from 'antd'
import Delete from 'assets/svg/delete_active.svg'
import DeleteDisabled from 'assets/svg/delete.svg'
import styled from "styled-components";
import Modal from "components/portal/modal"
import { useRef, useState } from 'react'
import useTheme from 'theme/useTheme'
import { useDispatch, useSelector } from 'react-redux'
import { companyActions } from 'store/admin/company/action'
import { useAction } from 'store'
import InputGr1d from 'components/portal/input'
import { orgMember } from 'models/orgMember'
import RegistrationDrawer from './registrationDrawer'
import { useEffect } from 'react'
import { UserModel } from 'models/user'
import { MemberModel } from 'models/member'
import { useHistory } from 'react-router'

const AdminPermissionsInfo: React.FC = () => {
    return (
        <div className="adminpermissionsinfo">
            <TypographyGr1d font="Manrope" color="colorPrimitive100" fontSize="12px" style={{ padding: '8px', paddingBottom: '1px' }}>
                Somente usuários com a função de administrador podem:
                <ul>
                    <li>
                        Visualizar e editar informações de faturamento das API’s.
                    </li>
                    <li>
                        Gerenciar membros da empresa.
                    </li>
                    <li>
                        Contratar novas API’s.
                    </li>
                </ul>
            </TypographyGr1d>
        </div>
    )
}

type UserItemProps = {
    name: string,
    photo?: string,
    email: string
}
const UserItem: React.FC<UserItemProps> = (props) => {
    return (
        <div className="userCell">
            <div className="userphoto">
                <img src={`https://ui-avatars.com/api/?name=${props.name}&background=EDEEF2&color=404554&rounded=true`} alt={props.name} />
            </div>
            <div className="userinfo">
                <TypographyGr1d font="Manrope" fontSize="14px" fontWeight="600" color="colorPrimitive700">
                    {props.name}
                </TypographyGr1d>
                <TypographyGr1d font="Manrope" fontSize="12px" fontWeight="400" color="colorPrimitive400" lineHeight="100%">
                    {props.email}
                </TypographyGr1d>
            </div>
        </div>
    )
}

const OrgMembers: React.FC = (props: any) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const history = useHistory()
    const action = useAction(companyActions());
    const user: UserModel & {orgId: string, isAdmin: boolean}  = useSelector((state: any) => state.login?.user);
    const members: MemberModel[] = useSelector((state: any) => state.company?.orgMembers)
    const [membersFormated, setMembersFormated] = useState<orgMember[]>([])
    const [showRemoveModal, setShowRemoveModal] = useState(false)
    const [showRemoveAdmModal, setShowRemoveAdmModal] = useState(false)
    const [showAddAdmModal, setShowAddAdmModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState<Partial<orgMember & { hasCPF: boolean }> | null>(null)
    const [drawerVisible, setDrawerVisible] = useState(false)
    const addOrgMemberSuccess = useSelector((state: any) => state.company?.addOrgMembersSuccess);
    const updateMemberSuccess = useSelector((state: any) => state.company?.updateOrgMemberSuccess);
    const updatingMember = useSelector((state: any) => state.company?.updatingOrgMember);
    const removingOrgMember = useSelector((state: any) => state.company?.removingOrgMember);
    const orgMemberRemoved = useSelector((state: any) => state.company?.orgMemberRemoved);
    const saveOrgSuccess: boolean = useSelector((state: any) => state.company?.saveSuccess);

    useEffect(() => {
        if (orgMemberRemoved === true) {
            setShowRemoveModal(false)
            window.setTimeout(() => {
                dispatch(action.getOrgMembers(user!.orgId))
            }, 200) // tempo necessário até o keycloak atualizar
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgMemberRemoved])

    useEffect(() => {
        if (addOrgMemberSuccess === true) {
            setDrawerVisible(false)
            window.setTimeout(() => {
                dispatch(action.getOrgMembers(user!.orgId))
            }, 3000) // tempo necessário até o keycloak atualizar
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addOrgMemberSuccess])

    useEffect(() => {
        if (updateMemberSuccess === true) {
            setShowRemoveAdmModal(false)
            setShowAddAdmModal(false)
            window.setTimeout(() => {
                dispatch(action.getOrgMembers(user!.orgId))
            }, 200) // tempo necessário até o keycloak atualizar
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMemberSuccess])

    useEffect(() => {
        if (user && user.orgId !== null) {
            dispatch(action.getOrgMembers(user.orgId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (members && members.length > 0) {
            setMembersFormated(members.map(m => {
                return {
                    canRemove: !(m.isAdmin && members.filter(m => m.isAdmin).length === 1),
                    email: m.email,
                    id: m.id,
                    isAdmin: m.isAdmin,
                    name: m.firstName + (m.lastName ? (' ' + m.lastName) : ''),
                    photo: null,
                    hasCPF: m.hasCPF,
                }
            }))
        }
    }, [members])

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            render: (value: any, row: any, index: any) => {
                return (
                    <UserItem name={row.name} email={row.email} />
                    //   <TypographyGr1d>
                    //       {value}
                    //   </TypographyGr1d>
                )
            },
            title: (test: any) => {
                return (
                    <TypographyGr1d font="Manrope" fontSize="14px" fontWeight="700" color="colorPrimitive600">
                        Usuário
                    </TypographyGr1d>
                )
            }
        },
        {
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (value: any, row: orgMember, index: any) => {
                return (
                    <div className="switch">
                        <Switch disabled={!row.canRemove || !user?.isAdmin} onChange={(value) => {
                            if (value) {
                                makeAdm(row)
                            } else {
                                removeAdm(row)
                            }
                        }} checked={row.isAdmin} />
                    </div>
                )
            },
            title: (test: any) => {
                return (
                    <div className="admtitle">
                        <TypographyGr1d font="Manrope" fontSize="14px" fontWeight="700" color="colorPrimitive600">
                            Administrador
                        </TypographyGr1d>
                        <Popover overlayInnerStyle={{ boxShadow: 'unset', borderRadius: '4px', padding: '0px' }} placement="leftBottom" trigger={'hover'} color={theme.currentTheme.colorPrimitive700} style={{ padding: '0px' }} content={<AdminPermissionsInfo />}>
                            <InfoCircleFilled style={{ color: 'rgba(0,0,0,.75)', marginLeft: '9px' }} />
                        </Popover>
                    </div>
                )
            }
        },
        {
            dataIndex: 'address',
            key: 'address',
            render: (value: any, row: orgMember, index: any) => {
                return (
                    <div className="deleteuser">
                        {row.canRemove && user?.isAdmin ? <img alt="Remover" src={Delete} onClick={() => { removeUser(row) }} />
                            : <img alt="Remover" src={DeleteDisabled} />}
                    </div>
                )
            },
            title: (test: any) => {
                return (
                    <div className="deletetitle">
                        <TypographyGr1d font="Manrope" fontSize="14px" fontWeight="700" color="colorPrimitive600">
                            Excluir
                        </TypographyGr1d>
                    </div>
                )
            }
        },
    ];


    function removeAdm(orgMember: orgMember) {
        setSelectedUser(orgMember)
        setShowRemoveAdmModal(true)
    }

    function makeAdm(orgMember: orgMember) {
        setSelectedUser(orgMember)
        setShowAddAdmModal(true)
    }

    function removeUser(orgMember: orgMember) {
        setSelectedUser(orgMember)
        setShowRemoveModal(true)
    }

    const cpfInput = useRef(null);

    function saveAdm() {
        if (selectedUser === null) {
            return
        }

        const cpf = (cpfInput.current as any).state.input.value.replace(/\./g, "").replace(/-/g, "");
        dispatch(action.updateOrgMember(user!.orgId, selectedUser.id as string, true, selectedUser.hasCPF ? undefined : cpf))
    }

    function saveRemoveAdm() {
        if (selectedUser === null) {
            return
        }

        dispatch(action.updateOrgMember(user!.orgId, selectedUser.id as string, false, undefined))
    }

    function saveRemoveUser() {
        if (selectedUser === null) {
            return
        }

        dispatch(action.removeOrgMember(user!.orgId, selectedUser.id as string))
    }

    function createCompany() {
        dispatch(action.setPageToRedirectAfterCreateCompany("/admin/company/permission"))
        history.push("/admin/company/form")
    }

    return (
        <OrgMembersStyled>
            <Drawer width={748} onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
                <RegistrationDrawer onClose={() => {setDrawerVisible(false)}} openedStatus={drawerVisible} />
            </Drawer>

            {showRemoveModal && <Modal width="520px" modalActions={
                (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ButtonGr1d type="secondary" onClick={() => { setShowRemoveModal(false) }}>
                            Voltar
                        </ButtonGr1d>
                        <ButtonGr1d onClick={saveRemoveUser} loading={removingOrgMember} type="danger">
                            Excluir
                        </ButtonGr1d>
                    </div>
                )
            }>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="20px" lineHeight="150%">
                    Tem certeza que deseja excluir este membro da lista?
                </TypographyGr1d>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="14px">
                    <div className="deletemodalcontent">
                        <span>{selectedUser?.name} </span> não poderá mais acessar as APIs e informações da empresa
                    </div>
                </TypographyGr1d>
            </Modal>}

            {showRemoveAdmModal && <Modal width="520px" modalActions={
                (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ButtonGr1d type="secondary" onClick={() => { setShowRemoveAdmModal(false) }}>
                            Voltar
                        </ButtonGr1d>
                        <ButtonGr1d loading={updatingMember} type="danger" onClick={saveRemoveAdm}>
                            Remover administrador
                        </ButtonGr1d>
                    </div>
                )
            }>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="20px" lineHeight="150%">
                    Tem certeza que deseja remover a permissão de administrador para este membro da empresa?
                </TypographyGr1d>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="14px">
                    <div className="deletemodalcontent">
                        Sem permissão de administrador, <span>{selectedUser?.name}</span> não poderá mais:
                        <ul>
                            <li>Visualizar e editar informações de faturamento das API’s.</li>
                            <li>Gerenciar membros da empresa.</li>
                            <li>Contratar novas API’s.</li>
                        </ul>
                    </div>
                </TypographyGr1d>
            </Modal>}

            {showAddAdmModal && <Modal width="520px" modalActions={
                (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ButtonGr1d type="secondary" onClick={() => { setShowAddAdmModal(false) }}>
                            Voltar
                        </ButtonGr1d>
                        <ButtonGr1d loading={updatingMember} type="primary" onClick={saveAdm}>
                            Tornar administrador
                        </ButtonGr1d>
                    </div>
                )
            }>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="20px" lineHeight="150%">
                    Tem certeza que deseja atribuir permissão de administrador para este membro da empresa?
                </TypographyGr1d>
                <TypographyGr1d font="Manrope" fontWeight="500" fontSize="14px">
                    <div className="deletemodalcontent">
                        Como administrador, <span>{selectedUser?.name}</span> poderá:
                        <ul>
                            <li>Visualizar e editar informações de faturamento das API’s.</li>
                            <li>Gerenciar membros da empresa.</li>
                            <li>Contratar novas API’s.</li>
                        </ul>
                        <br />
                        Para realizar a ação, é necessário cadastrar o CPF deste usuário.
                        <br />
                        <br />
                        <InputGr1d inputRef={cpfInput} style={{ display: selectedUser?.hasCPF ? "none" : "block" }} width="200px" type="masked-input" label="CPF" mask="111.111.111-11" />
                    </div>
                </TypographyGr1d>
            </Modal>}

            <Content>
                <div className="header">
                    <TypographyGr1d font="Manrope" component="headingLG">
                        Usuários e permissões
                    </TypographyGr1d>
                    <ButtonGr1d disabled={!user?.isAdmin} type="brand" onClick={() => { setDrawerVisible(true) }}>
                        <PlusOutlined style={{ marginRight: '10px' }} />
                        Adicionar usuário
                    </ButtonGr1d>
                </div>
                <div className="content">
                    <div className="description">
                        <TypographyGr1d font="Manrope" fontSize="20px" fontWeight="500" color="colorPrimitive700">
                            Membros da empresa
                        </TypographyGr1d>
                        <br />
                        <TypographyGr1d font="Manrope" fontSize="14px" fontWeight="400" color="colorPrimitive500">
                            Gerencie as permissões de acesso a membros da sua empresa
                        </TypographyGr1d>
                    </div>
                    {user && user.orgId !== null &&
                        <div className="usersList">
                            <Table rowKey="id" pagination={false} bordered={false} style={{ width: '100%' }} dataSource={membersFormated} columns={columns} />
                        </div>
                    }
                </div>
            </Content>
            <Modal show={(!user || user.orgId === null) && !saveOrgSuccess} width="480px" modalActions={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonGr1d type="secondary" onClick={() => { history.goBack() }}> Voltar</ButtonGr1d>
                    <ButtonGr1d type="primary" onClick={() => { createCompany() }}> Completar cadastro</ButtonGr1d>
                </div>
            )}>
                <TypographyGr1d font="Manrope" fontWeight="500" color="colorPrimitiveBlack" fontSize="20px">Necessário completar cadastro</TypographyGr1d>
                <TypographyGr1d style={{ marginTop: '21px' }} font="Manrope" fontWeight="400" color="colorPrimitive600" fontSize="14px">Antes de adicionar um novo membro, complete o cadastro da sua empresa.</TypographyGr1d>
            </Modal>
        </OrgMembersStyled>
    )
}

const OrgMembersTheme = styled(OrgMembers)``

export default OrgMembersTheme