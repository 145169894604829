import { ApiModel } from "models/api";
import { ApiSubscriptionModel } from "models/apiSubscription";
import { ApiKeyState } from "store/portal/reducer";
import { AdminApiConsumedActionType } from "./action";

export interface AdminApiState {
    item?: ApiSubscriptionModel[];
    itemLoading: boolean;
    deleteLoading: boolean;
    apiItem?: ApiModel;
    apiKeyProd: ApiKeyState;
    apiKeySandbox: ApiKeyState;
    apiSwagger: any;
    fetchingSwagger: boolean;
}

export const INITIAL_STATE = {
    itemLoading: false, deleteLoading: false, apiKeyProd: { loading: false, loadingSave: false, modal: false }, apiKeySandbox: { loading: false, loadingSave: false, modal: false }, apiSwagger: null, fetchingSwagger: false,
}
export function adminApiConsumedReducer(state: AdminApiState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case AdminApiConsumedActionType.GET_API:
            state = { ...state, itemLoading: true, item: undefined }
            break;
        case AdminApiConsumedActionType.GET_API_SUCCESS:
            state = { ...state, itemLoading: false, item: action.payload }
            break;
        case AdminApiConsumedActionType.GET_API_ERROR:
            state = { ...state, itemLoading: false }
            break;
        case AdminApiConsumedActionType.GET_API_ID:
            state = { ...state, apiItem: undefined }
            break;
        case AdminApiConsumedActionType.GET_API_ID_SUCCESS:
            state = { ...state, apiItem: action.payload }
            break;
        case AdminApiConsumedActionType.GET_API_ID_ERROR:
            state = { ...state, apiItem: undefined }
            break;
        case AdminApiConsumedActionType.GET_API_SWAGGER:
            state = { ...state, apiSwagger: null, fetchingSwagger: true }
            break;
        case AdminApiConsumedActionType.GET_API_SWAGGER_SUCCESS:
            state = { ...state, apiSwagger: action.payload, fetchingSwagger: false }
            break;
        case AdminApiConsumedActionType.GET_API_SWAGGER_ERROR:
            state = { ...state, fetchingSwagger: false };
            break;
        case AdminApiConsumedActionType.GET_API_KEY:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: true, item: undefined } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: true, item: undefined } }
            break;
        case AdminApiConsumedActionType.GET_API_KEY_SUCCESS:
            if (action.payload.type === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: false, item: action.payload } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: false, item: action.payload } }
            break;
        case AdminApiConsumedActionType.GET_API_KEY_ERROR:
            if (action.payload === 'PRODUCTION')
                state = { ...state, apiKeyProd: { ...state.apiKeyProd, loading: false, item: undefined } }
            else
                state = { ...state, apiKeySandbox: { ...state.apiKeySandbox, loading: false, item: undefined } }
            break;
        case AdminApiConsumedActionType.DELETE_API_KEY:
            state = { ...state, deleteLoading: true }
            break;
        case AdminApiConsumedActionType.DELETE_API_KEY_SUCCESS:
            if (action.payload.type === 'PRODUCTION')
                state = { ...state, deleteLoading: false, apiKeyProd: { ...state.apiKeyProd, item: undefined } }
            else
                state = { ...state, deleteLoading: false, apiKeySandbox: { ...state.apiKeySandbox, item: undefined } }
            break;
        case AdminApiConsumedActionType.DELETE_API_KEY_ERROR:
            state = { ...state, deleteLoading: false }
            break;
        case AdminApiConsumedActionType.DELETE_SWAGGER:
            state = { ...state, apiSwagger: null }
            break;
    }

    return state;
}